import { useMutation, useQueryClient } from "react-query";
import apiInstance from "../api/axios";

export const usePostData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await apiInstance.post(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const useGetData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      // Reconstruct the query params conditionally
      const queryParams = {};
      if (data.page) queryParams.page = data.page;
      if (data.fleet_id) queryParams.fleet_id = data.fleet_id;
      if (data.search)
        queryParams.search =
          typeof data.search === "string" ? data.search : data.search.search;

      const queryString = new URLSearchParams(queryParams).toString();
      const response = await apiInstance.get(`${endpoint}?${queryString}`);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const usePatchData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint, data }) => {
      const response = await apiInstance.patch(endpoint, data);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};

export const useDeleteData = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ endpoint }) => {
      const response = await apiInstance.delete(endpoint);
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getData");
      },
    }
  );
};
