import React from "react";
import { Button as MUIButton, useTheme } from "@mui/material";

const FilledButton = ({ startIcon, endIcon, children, sx = {}, ...props }) => {
  const theme = useTheme();

  return (
    <MUIButton
      variant="contained"
      disableRipple
      endIcon={endIcon}
      startIcon={startIcon}
      sx={{
        height: "2.813rem",
        width: "19.375rem",
        background: theme.palette.gradient.main,
        color: "white",
        textTransform: "none",
        borderRadius: "0.75rem",
        fontSize: "0.875rem",
        fontWeight: 500,
        padding: "0px 10px 0px 10px",
        fontFamily: "Helvetica Neue, Helvetica, sans-serif",
        gap: 1,
        '&:hover': {
          background: theme.palette.gradient.main, // Set hover background to match normal background
          transform: 'scale(1.05)', // Scaling effect on hover
          boxShadow: 'none' // Optional: remove boxShadow if present on hover
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </MUIButton>
  );
}

export default FilledButton;
