import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilledButton from "../../components/MainButtons/FilledButton";
import DeleteIcon from "../../assets/Icons/delete.svg";


const DeleteDeactivatedUser = ({ open, handleClose, handleDeleteUser }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "576px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1.5,
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            id="modal-title"
            sx={{ color:"#283246",fontSize:'1.5rem',fontWeight:500 }}
          >
            Delete
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />

        <Box
  sx={{
    background: "#283246",
    width: "2.75rem",
    height: "2.75rem",
    padding: "0.438rem",
    borderRadius: "1.25rem",
    cursor: "pointer",
  margin:'auto',
    mt:2
  }}
>
  <img src={DeleteIcon} alt="delete-icon" style={{ width: '42px', height: '42px' }} />
</Box>


        <Typography
          id="modal-description"
          sx={{ mt: 2,color:'#283246',width:'80%',margin:'auto',p:2,fontSize:'1rem',fontWeight:500 }}
        >
          If you delete the user, you will not see their details anymore. Users will not be able to login to the app and will have to create a new account. This action cannot be reversed. Do you want to delete the user?
        </Typography>
    
        <FilledButton
          sx={{width:'150px'}}
          onClick={handleDeleteUser}
        >
          Delete
        </FilledButton>
      </Box>
    </Modal>
  );
};

export default DeleteDeactivatedUser;
