import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  useTheme,
  Menu,
  IconButton,
  MenuItem,
  Avatar,
  Checkbox,
  Tooltip,
  Chip,
  Badge,
  Rating,
  TablePagination,
} from "@mui/material";
import unlock from "../../assets/Icons/unlock.svg";
import lock from "../../assets/Icons/lock.svg";
import NoResultsFound from "../../components/NoResultFound";
import batterytable from "../../assets/Icons/batterytable.svg";
import menudots from "../../assets/Icons/menudots.svg";
import tablecolumnMenu from "../../assets/Icons/tablecolumn.svg";
import actiondelete from "../../assets/Icons/actiondelete.svg";
import viewvehicle from "../../assets/Icons/viewmode.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TableSkeleton from "../../components/TableSkeleton/TableSkeleton";
import mapicon from "../../assets/Icons/location.svg";

const getStyles = (status) => {
  let borderColor, backgroundColor, textColor;

  switch (status) {
    case "Active":
      borderColor = "1px solid #A5D6A7";
      backgroundColor = "#E8F5E9";
      textColor = "#83C3A0";
      break;
    case "Inactive":
      borderColor = "1px solid #F9BDBB";
      backgroundColor = "#FDECEA";
      textColor = "#F99494";
      break;
    case "Pending":
      borderColor = "1px solid #FFA41B";
      backgroundColor = "#FFF3E0";
      textColor = "#FFA41B";
      break;
    case "Open":
      borderColor = "1px solid #15D4CD";
      backgroundColor = "#E0F7FA";
      textColor = "#15D4CD";
      break;
    default:
      borderColor = "1px solid #B0BEC5";
      backgroundColor = "#FFFFFF";
      textColor = "#000000";
      break;
  }

  return { borderColor, backgroundColor, textColor };
};

const UserTableComponent = ({
  tableHeaders,
  data,
  showActions = false,
  handleEdit = null,
  handleDelete = null,
  handleLocationClick = null,
  handleLockClick = null,
  handleRowClick = () => {},
  handleFlagClick = null,
  tablePage,
  isFleet = false,
  loading = false,
}) => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;
  const [tooltipTitle, setTooltipTitle] = useState("");
  const [visibleColumns, setVisibleColumns] = useState(
    tableHeaders
      .filter(
        (header) =>
          header.label !== "Vehicle Action" && header.label !== "Alerts"
      )
      .map((header) => ({
        ...header,
        isVisible: header.label !== "Flags",
      }))
  );

  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);

  const [showlock, setShowLock] = useState(false);
  const [vehicleAction, setVehicleAction] = useState("");

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setClickedRow(item.id);
    setVehicleAction(item.vehicle_action);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickedRow(null);
  };
  console.log(page, "pagein table");

  // const filteredData = useMemo(() => {
  //   return data?.filter((item) => item);
  // }, [data]);

  const filteredData = useMemo(() => {
    const filtered = data?.length && data?.filter((item) => item); // Apply existing filter
    const start = page * itemsPerPage;
    const end = start + itemsPerPage;
    return filtered?.slice(start, end); // Apply pagination on filtered data
  }, [data, page, itemsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tablePage(newPage);
  };

  const renderCustomContent = (header, item) => {
    const status = item[header.key];
    const { borderColor, backgroundColor, textColor } = getStyles(status);
    switch (header.label) {
      case "Flags":
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            {item.flags ? (
              <img src={item?.flags} alt="flag-icon" />
            ) : (
              <Typography
                sx={{ fontWeight: 500, fontSize: "0.875rem", color: "#283246" }}
              >
                N/A
              </Typography>
            )}
          </Box>
        );
      case "Location":
        return (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handleLocationClick(item.location);
            }}
            sx={{
              cursor: "pointer",
              borderRadius: "10px",
              p: 1,
              width: "30px",
              height: "30px",
              background: "#97A1B61A",
            }}
          >
            <img
              src={mapicon}
              alt="location-icon"
              style={{ width: "30px", height: "30px" }}
            />
          </Box>
        );
      case "Rating":
        return (
          <Rating
            name={`rating-${item}`}
            value={parseFloat(item[header.key]) || 0}
            precision={0.5}
            readOnly
            sx={{
              "& .MuiRating-iconFilled": {
                color: theme.palette.text.tertiary, // Color for filled stars (e.g., gold)
              },
              "& .MuiRating-iconEmpty": {
                color: "#C0C0C0", // Color for empty stars (e.g., silver)
              },
            }}
          />
        );

      case "Battery":
        return (
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <img src={batterytable} alt="Battery" style={{ width: "50px" }} />
            <Box
              sx={{
                position: "absolute",
                top: "35%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "0.7rem",
                fontWeight: 600,
                color: "#263238",
                mt: "0.1rem",
              }}
            >
              {item[header.key]}%
            </Box>
          </Box>
        );
      case "Driver":
        return (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "0.875rem",
              cursor: "pointer",
              color: item[header.key] ? "#3088C5" : "#283246",
              textDecoration: item[header.key] ? "underline" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (item[header.key]) {
                navigate(`/dashboard/drivers/${item.driver_id}`);
              }
            }}
          >
            {item[header.key] ? item[header.key] : "Unassigned"}
          </Typography>
        );
      case "Vehicle":
        return (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "0.875rem",
              cursor: "pointer",
              color: item[header.key] ? "#3088C5" : "#283246",
              textDecoration: item[header.key] ? "underline" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (item[header.key]) {
                navigate(`/dashboard/vehicles/${item.vehicle_id}`);
              }
            }}
          >
            {item[header.key] ? item[header.key] : "Unassigned"}
          </Typography>
        );
      case "Status":
        return (
          <Typography
            sx={{
              display: "inline-block",
              fontWeight: 500,
              fontSize: "0.875rem",
              cursor: "pointer",
              color: textColor,
              backgroundColor: backgroundColor,
              border: borderColor,
              padding: "0.25rem 0.5rem",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "75px",
            }}
          >
            <span
              style={{
                height: "8px",
                width: "8px",
                backgroundColor: textColor,
                borderRadius: "50%",
                display: "inline-block",
                marginRight: "0.5rem",
              }}
            />
            {item[header.key]}
          </Typography>
        );
      case "Vehicle Action":
        console.log(item[header.key], "item[header.key]");
      // setVehicleAction(item[header.key]);
      case "Name":
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "0.875rem",
                color: "#283246",
              }}
            >
              {item[header.key]}
            </Typography>
            {/* {item.alerts && (
              <Chip
                label={item.alerts}
                color="error"
                size="small"
                sx={{ fontWeight: 500, fontSize: "0.75rem",backgroundColor: '#FE7676',
                color: '#fff', 
                '& .MuiChip-deleteIcon': { 
                  color: '#fff'
                }}}
              />
            )} */}
            {item.alerts && item.alerts.length > 0 && (
              <Tooltip
                title={item.alerts.join(", ")}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#424242", // Dark grey background for high contrast
                      color: "white", // White text for clarity
                      fontWeight: "600",
                      fontSize: "14px",
                      padding: "10px 15px",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#424242", // Matching the tooltip background
                    },
                  },
                }}
              >
                <Badge
                  badgeContent={item.alerts.length}
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#fff", // White text color for the badge number
                      backgroundColor: "#E53935", // A rich red for high visibility and urgency
                      fontSize: "0.75rem", // Adjusted font size for balance
                      fontWeight: "500", // Consistent text weight
                    },
                  }}
                >
                  <NotificationsIcon color="action" sx={{ mb: 0.5 }} />
                </Badge>
              </Tooltip>
            )}
          </Box>
        );

      default:
        return (
          <Typography
            sx={{ fontWeight: 500, fontSize: "0.875rem", color: "#283246" }}
          >
            {item[header.key] || "N/A"}
          </Typography>
        );
    }
  };

  const handleMenuClick = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const toggleColumnVisibility = (key) => {
    const newColumns = visibleColumns.map((column) => {
      if (column.key === key) {
        const visibleCount = visibleColumns.filter((c) => c.isVisible).length;
        if (visibleCount === 1 && column.isVisible) {
          return column;
        }
        return { ...column, isVisible: !column.isVisible };
      }
      return column;
    });

    setVisibleColumns(newColumns);
  };

  const handleLockAction = (clickedRow) => {
    handleLockClick(clickedRow);
    handleClose();
  };

  useEffect(() => {
    if (tableHeaders.some((item) => item.label === "Vehicle Action")) {
      setShowLock(true);
    }
  }, [tableHeaders]);

  const totalColumns =
    visibleColumns.filter((item) => item.isVisible).length +
    (showActions ? 3 : 2);

  const tableData = isFleet ? data?.data : filteredData;

  return (
    <>
      {loading ? (
        <TableSkeleton />
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", overflowX: "auto" }}
          >
            <Table
              sx={{
                width: { lg: totalColumns >= 7 ? "120%" : "100%", xl: "100%" },
                overflow: "auto",
              }}
            >
              <TableHead sx={{ background: "#F1F1F2" }}>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  {visibleColumns
                    ?.filter((header) => header.isVisible)
                    .map((header, index) => (
                      <TableCell key={index}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "0.938rem",
                            color: "#283246B2",
                          }}
                        >
                          {header.label}
                        </Typography>
                      </TableCell>
                    ))}
                  {showActions && (
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        fontSize: "0.938rem",
                        color: "#283246B2",
                      }}
                    >
                      Actions
                    </TableCell>
                  )}
                  <IconButton
                    sx={{ mt: 0.5 }}
                    onClick={handleMenuClick}
                    disableRipple
                  >
                    <img src={tablecolumnMenu} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorMenuEl}
                    open={Boolean(anchorMenuEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                      sx: {
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        fontWeight: 500,
                        fontSize: "1rem",
                        color: "#283246",
                        fontFamily: "Helvetica Neue, Helvetica, sans-serif",
                      }}
                    >
                      Fixed Columns
                    </MenuItem>
                    {visibleColumns
                      .filter((column) => column.isVisible)
                      .map((column, index) => (
                        <MenuItem
                          key={`fixed-${index}`}
                          onClick={() => toggleColumnVisibility(column.key)}
                          sx={{ fontSize: "0.875rem", fontWeight: 400 }}
                        >
                          <Checkbox
                            checked={true}
                            sx={{
                              "&.Mui-checked": {
                                color: "#283246",
                              },
                            }}
                          />
                          {column.label}
                        </MenuItem>
                      ))}
                    {visibleColumns.some((column) => !column.isVisible) && (
                      <>
                        <MenuItem
                          sx={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: "#283246",
                            fontFamily: "Helvetica Neue, Helvetica, sans-serif",
                          }}
                        >
                          Available Columns
                        </MenuItem>
                        {visibleColumns
                          .filter((column) => !column.isVisible)
                          .map((column, index) => (
                            <MenuItem
                              key={`available-${index}`}
                              onClick={() => toggleColumnVisibility(column.key)}
                              sx={{ fontSize: "0.75rem", fontWeight: 400 }}
                            >
                              <Checkbox
                                checked={false}
                                sx={{
                                  color: "#b0bec5",
                                }}
                              />
                              {column.label}
                            </MenuItem>
                          ))}
                      </>
                    )}
                  </Menu>
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData?.length > 0 ? (
                  tableData?.map((item, index) => {
                    return (
                      <TableRow
                        key={item.id || index}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#e8e8e8",
                          },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell padding="checkbox"></TableCell>
                        {visibleColumns
                          ?.filter((header) => header.isVisible)
                          .map((header, i) => (
                            <TableCell
                              key={i}
                              sx={{
                                fontSize: "1rem",
                                fontWeight: 500,
                                color: "#283246",
                              }}
                              onClick={() => handleRowClick(item.id)}
                            >
                              {renderCustomContent(header, item)}
                            </TableCell>
                          ))}
                        {showActions && (
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              {true && (
                                <>
                                  <IconButton
                                    onClick={(event) =>
                                      handleClick(event, item)
                                    }
                                    sx={{
                                      background: "white",
                                      borderRadius: "3.2px",
                                      border: "2px solid #DDDEE1",
                                      color: "#f99494",
                                      width: "3rem",
                                      height: "2rem",
                                      "&:hover": { background: "white" },
                                    }}
                                  >
                                    <img src={menudots} />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                    PaperProps={{
                                      sx: {
                                        backgroundColor: "#FFFFFF",
                                        color: "#f99494",
                                        borderRadius: "0.5rem",
                                        border: "1px solid #DDDEE1",
                                        boxShadow: "none",
                                        "& .MuiMenuItem-root:hover": {
                                          backgroundColor: "#FFFFFF",
                                          color: theme.palette.text.primary,
                                        },
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: 0,
                                      },
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: 1,
                                        p: 0.5,
                                      }}
                                    >
                                      <Tooltip
                                        title="View"
                                        placement="top"
                                        arrow
                                        componentsProps={{
                                          tooltip: {
                                            sx: {
                                              backgroundColor: "#424242", // Dark grey background for high contrast
                                              color: "white", // White text for clarity
                                              fontWeight: "600",
                                              fontSize: "14px",
                                              padding: "10px 15px",
                                              borderRadius: "8px",
                                              boxShadow:
                                                "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                                            },
                                          },
                                          arrow: {
                                            sx: {
                                              color: "#424242", // Matching the tooltip background
                                            },
                                          },
                                        }}
                                      >
                                        <IconButton
                                          onClick={() => {
                                            handleEdit(clickedRow);
                                            handleClose();
                                          }}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "2px solid #DADDE1",
                                            borderRadius: "0.5rem",
                                            color: theme.palette.text.primary,
                                          }}
                                        >
                                          <img
                                            src={viewvehicle}
                                            alt="view"
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip
                                        title="Delete"
                                        placement="top"
                                        arrow
                                        componentsProps={{
                                          tooltip: {
                                            sx: {
                                              backgroundColor: "#424242", // Dark grey background for high contrast
                                              color: "white", // White text for clarity
                                              fontWeight: "600",
                                              fontSize: "14px",
                                              padding: "10px 15px",
                                              borderRadius: "8px",
                                              boxShadow:
                                                "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                                            },
                                          },
                                          arrow: {
                                            sx: {
                                              color: "#424242", // Matching the tooltip background
                                            },
                                          },
                                        }}
                                      >
                                        <IconButton
                                          onClick={() => {
                                            handleDelete(clickedRow);
                                            handleClose();
                                          }}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "2px solid #DADDE1",
                                            borderRadius: "0.5rem",
                                            color: theme.palette.text.primary,
                                          }}
                                        >
                                          <img
                                            src={actiondelete}
                                            alt="delete"
                                            style={{
                                              width: "25px",
                                              height: "25px",
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>

                                      {showlock && (
                                        <Tooltip
                                          title={vehicleAction}
                                          placement="top"
                                          arrow
                                          componentsProps={{
                                            tooltip: {
                                              sx: {
                                                backgroundColor: "#424242", // Dark grey background for high contrast
                                                color: "white", // White text for clarity
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                padding: "10px 15px",
                                                borderRadius: "8px",
                                                boxShadow:
                                                  "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                                              },
                                            },
                                            arrow: {
                                              sx: {
                                                color: "#424242", // Matching the tooltip background
                                              },
                                            },
                                          }}
                                        >
                                          <IconButton
                                            onClick={() =>
                                              handleLockAction(clickedRow)
                                            }
                                            sx={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              border: "2px solid #DADDE1",
                                              borderRadius: "0.5rem",
                                              color: theme.palette.text.primary,
                                            }}
                                          >
                                            <img
                                              src={
                                                vehicleAction === "Lock"
                                                  ? lock
                                                  : unlock
                                              }
                                              alt="lock"
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </Menu>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        )}
                        <TableCell>
                          <Box></Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={totalColumns}
                      sx={{
                        fontWeight: 500,
                        mb: { xs: 1, sm: 2 },
                        fontSize: { xs: "1rem", sm: "1rem" },
                        color: "#283246",
                        textAlign: "center",
                      }}
                    >
                      <NoResultsFound />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]} // Set how many rows per page
            component="div"
            count={isFleet ? data?.total : data?.length} // Total number of rows
            rowsPerPage={itemsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </>
      )}
    </>
  );
};

export default UserTableComponent;
