import { useState } from "react";

import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import SearchTextField from "../../components/SearchTextField";
import VehicleSelect from "../../components/Select";
import UserTableComponent from "../UserManagement/UserTable";
import { onGoingTripTableCellItem } from "./utils";
import fleetselect from "../../assets/Icons/fleetselect.svg";
import statusicon from "../../assets/Icons/status.svg";
import ongoingtrip from "../../assets/Icons/ongoingTrip.svg";
import { Paper, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VehicleSelectFleet from "../../components/Select/AllFleetSelect";
import { fleetTableData } from "../Fleet/utils";

export const OnGoingTrips = () => {
  const [searchInput, setSearchInput] = useState("");
  const [fleetSelection, setFleetSelection] = useState("");
  const [status, setStatus] = useState("");

  const { ongoingTrips } = useSelector((state) => state.tripsManage);
  const { fleet } = useSelector((state) => state.fleetManage);
  const theme = useTheme();
  const navigate = useNavigate();

  const fleetOptions = fleetTableData?.map((fleet) => ({
    label: fleet.fleet_name,
    value: fleet.fleet_name,
  }));

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleFleetChange = (value) => {
    setFleetSelection(value);
  };

  const handleViewPage = (id) => {
    navigate(`/dashboard/ongoing/${id}`);
  };

  const filteredScheduleTrips =
    searchInput || status || fleetSelection.length
      ? ongoingTrips
          .filter((trips) => {
            const matchesName = trips?.driver_name
              ?.toLowerCase()
              .includes(searchInput?.toLowerCase() || "");
            const matchesStatus = trips?.status
              ?.toLowerCase()
              .includes(status?.toLocaleLowerCase() || "");
            const matchesFleet =
              fleetSelection && fleetSelection.length
                ? fleetSelection.some(
                    (fleet) =>
                      trips?.fleet?.toLowerCase() === fleet.label.toLowerCase() // Update to use fleet label
                  )
                : true;
            return matchesName && matchesStatus && matchesFleet;
          })
          .sort((a, b) => console.log(a, "a"))
      : ongoingTrips; // Return full list if both search input and flag are empty

  // a.driver_name.localeCompare(b.driver_name)

  return (
    <Paper
      sx={{
        borderRadius: "1.25rem",
        background: "#FFFFFF",
        padding: "1.563rem",
        boxShadow: "none",
        minHeight: "90vh",
        gap: 2,
        mt: 2,
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
        }}
      >
        <img
          src={ongoingtrip}
          style={{
            height: "25px",
            width: "25px",
            marginRight: "10px",
          }}
        />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "1.2rem",
            color: theme.palette.text.primary,
          }}
        >
          Ongoing Trips
        </Typography>
      </Box>
      <Box
        sx={{
          height: "42px",
          justifyContent: "space-between",
          p: 1,
          background: "#FFFFFF",
          display: "flex",
          borderRadius: "6px",
          mb: 5,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <SearchTextField
            value={searchInput}
            onChange={handleSearchChange}
            width={"14.75rem"}
            sx={{
              height: "35px",
              width: "14.75rem",
              "& .MuiOutlinedInput-root": {
                borderRadius: "6px",
                color: "#283246",
                backgroundColor: "#FFF",
                fontSize: "1rem",
                "& fieldset": {
                  border: "1px solid #DDDEE1", // Border color
                },
              },
            }}
          />
          {/* <VehicleSelect
            label="Fleet"
            options={fleetOptions}
            icon={<img src={fleetselect}/> } // Passing the icon dynamically
            onChange={handleFleetChange}
            sx={{ml:0.2}}
          /> */}
          <VehicleSelectFleet
            label="Fleet"
            options={fleetOptions}
            icon={<img src={fleetselect} />} // Passing the icon dynamically
            onChange={handleFleetChange}
            sx={{ ml: 0.2 }}
          />
        </Box>
      </Box>

      <UserTableComponent
        data={filteredScheduleTrips}
        tableHeaders={onGoingTripTableCellItem}
        showActions={false}
        handleRowClick={handleViewPage}
      />
    </Paper>
  );
};
