import { useState } from "react";
import { Grid, InputLabel, Paper, Typography, MenuItem, Select, Box, IconButton, useTheme } from "@mui/material";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import { addDriver } from "../../slices/driverManageSlice";
import backicon from "../../assets/Icons/backicon.svg"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";



const selectStyle = {
  width: "300px", borderRadius: "10px", height:'40px' 
}

export const AddDriver = () => {
  // State to manage the form fields
  const [driverData, setDriverData] = useState({
    driver_name: "",
    phone: "",
    licence_no: "",
    licence_exp_date: "",
    vehicle_assigned: "",
    date_of_joining: "",
    status: "",
    age:"",
    total_exp:"",
    notes:"",
    address:"",
    fleet:""
  });
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  // Options for the select fields
  const vehicleTypes = ["Cybertruck", "GenericCar"];
  const fleets = ["Shyamal Cross Road"];
  const fuelTypes = ["Diesel", "Petrol", "Electric"];
  const drivers = ["Driver 1", "Driver 2"];

  const driverStatus = ["Active", "InActive"];

  // Handle form field changes
  const handleChange = (field, value) => {
    setDriverData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };


  const handleSubmit = () => {
    if (driverData.driver_name) {
      dispatch(addDriver(driverData));
      setDriverData({
        driver_name: "",
        phone: "",
        licence_no: "",
        licence_exp_date: "",
        vehicle_assigned: "",
        date_of_joining: "",
        status: "",
        age: "",
        total_exp: "",
        notes: "",
        address: "",
        fleet: ""
      });
      navigate('/dashboard/drivers')
    } else {
      console.log("Driver name is required.");
    }
  };

  return (
    <Paper
      sx={{
        borderRadius: "1.25rem",
        background: "#F8F8F8",
        padding: "1.563rem",
        boxShadow: "none",
        gap: 2,
        mt: 1,
        mb: 2,
        minHeight:'80vh'
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center",mb:2  }}>
            <IconButton onClick={() => navigate("/dashboard/drivers")}>
            <img src={backicon} alt="back-icon" />
          </IconButton>
                <Typography
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "24px",
                        fontWeight: "800"
                    }}
                >Add Driver</Typography>
            </Box>

      <Typography sx={{ color: theme.palette.text.tertiary, fontSize: "1rem", fontWeight: 500,mb:2,mt:1 }}>
        Driver information
      </Typography>

      <Grid container spacing={2}>
        {/* Driver Name */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
            Driver Name <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={driverData.driver_name}
            onChange={(e) => handleChange("driver_name", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
          />
        </Grid>

        {/* Mobile Number */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
            Mobile Number <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={driverData.phone}
            onChange={(e) => handleChange("phone", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
            placeholder={'9876543210'}
          />
        </Grid>

        {/* Age */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
            Age <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={driverData.age}
            onChange={(e) => handleChange("age", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
            placeholder={'age'}
          />
        </Grid>

        {/* Licence no */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
            Licence No<span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={driverData.licence_no}
            onChange={(e) => handleChange("licence_no", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
          />
        </Grid>

        {/* License Expire Date */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
            License Expire Date <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={driverData.licence_exp_date}
            onChange={(e) => handleChange("licence_exp_date", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
          />
        </Grid>

        {/* Total experience */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
            Total Experience <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={driverData.total_exp}
            onChange={(e) => handleChange("total_exp", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
          />
        </Grid>


   {/* Date of joining */}
   <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
            Date of joining <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={driverData.date_of_joining}
            onChange={(e) => handleChange("date_of_joining", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
          />
        </Grid>

           {/* Reference / Notes */}
   <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
          Reference / Notes <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={driverData.notes}
            onChange={(e) => handleChange("notes", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
          />
        </Grid>


         {/* Address */}
   <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
          Address <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={driverData.address}
            onChange={(e) => handleChange("address", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
          />
        </Grid>


    {/* Fleet */}
    <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
          Fleet <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
           <Select
            value={driverData.fleet}
            onChange={(e) => handleChange("fleet", e.target.value)}
            sx={selectStyle}
            placeholder="fleet"
          >
            {fleets.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Grid>

         {/* Driver Status */}
    <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: theme.palette.text.primary }}>
          Driver Status <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
            <Select
            value={driverData.status}
            onChange={(e) => handleChange("status", e.target.value)}
            sx={selectStyle}
            placeholder="status"
          >
            {driverStatus.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Box item  display="flex" justifyContent="start" gap={2}  sx={{ mt: 15, padding: 2 }}>
      <FilledButton
      sx={{
        width: "150px",
      }} onClick={handleSubmit}>
            Create
          </FilledButton>
          <FilledButton
     sx={{
      border: `1px solid ${theme.palette.text.secondary}`,
      width: "150px",
      color: "black",
      background: "white",
      "&:hover": {
        background: "white",
      },
    }}>
            Cancel
          </FilledButton>
      </Box>
    </Paper>
  );
};

export default AddDriver;
