import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Box, IconButton, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { mergedTripData } from "./utils";
import cartrip from "../../assets/Icons/cartrip.svg";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const nightModeStyle = [
  { elementType: "geometry", stylers: [{ color: "#212121" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#212121" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#757575" }] },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#181818" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [{ color: "#2c2c2c" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#8a8a8a" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#3c3c3c" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#b3b3b3" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#000000" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#3d3d3d" }],
  },
];

const fullScreenContainerStyle = {
  width: "100%",
  height: "100vh",
};

const center = {
  lat: 23.0225,
  lng: 72.5714,
};

const polylineOptions = {
  strokeColor: "#69ACF3 ", // Traveled part color
  strokeOpacity: 1.0,
  strokeWeight: 4,
};

// Custom slider style
const customSliderStyle = {
  appearance: "none",
  width: "100%",
  height: "6px",
  background:
    "linear-gradient(to right, skyblue 0%, skyblue 0%, #d3d3d3 0%, #d3d3d3 100%)",
  borderRadius: "5px",
  outline: "none",
  opacity: "0.7",
  transition: "opacity .2s, background .2s",
};

// Custom thumb style
const customThumbStyle = {
  appearance: "none",
  width: "18px",
  height: "18px",
  background: "#FFFFFF",
  borderRadius: "50%",
  cursor: "pointer",
};

const TripMap = () => {
  const [play, setPlay] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0); // Current marker position index
  const [fullScreen, setFullScreen] = useState(false); // Full screen toggle state
  const [path, setPath] = useState([]); // Stores the dynamic polyline path
  const [sliderTime, setSliderTime] = useState(""); // Holds the time for the slider thumb
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDiytvXP2dWy_3E-rJX2NWwnnFWZe4aSjU",
  });
  const mapRef = useRef(null); // Ref to hold the Google Maps instance

  // Handle loading the Google Map instance
  const onLoad = (map) => {
    mapRef.current = map; // Store the Google Maps instance
  };

  // Function to pan the map only when the marker is out of bounds
  const panToCurrentPosition = (latLng) => {
    const map = mapRef.current;
    const bounds = map.getBounds();

    if (!bounds || !bounds.contains(latLng)) {
      map.panTo(latLng);
    }
  };

  // Incrementally update the polyline as the vehicle moves and adjust the map center
  useEffect(() => {
    let interval;
    if (play && currentPosition < mergedTripData.length-1) {
      interval = setInterval(() => {
        setCurrentPosition(prevPos => {
          const nextPos = prevPos + 1;
          if (nextPos < mergedTripData.length) {
            // Only update path if there's data to add
            setPath(prevPath => [...prevPath, mergedTripData[nextPos]]);
            const currentLatLng = {
              lat: mergedTripData[nextPos].lat,
              lng: mergedTripData[nextPos].lng,
            };
            panToCurrentPosition(currentLatLng);
            setSliderTime(mergedTripData[nextPos].time);
          } else {
            // Stop the trip when the last point is reached
            setPlay(false);
            clearInterval(interval);
          }
          return nextPos;
        });
      }, 1500);
    }
  
    return () => clearInterval(interval);
  }, [play, mergedTripData, currentPosition]);
  
  

  const handlePlayPause = () => {
    if (currentPosition >= mergedTripData.length - 1) {
      // Reset the trip if the trip has ended
      setCurrentPosition(0);
      setPath([mergedTripData[0]]); // Start the path again from the first point
      setSliderTime(mergedTripData[0].time);
    }
    setPlay(!play); // Toggle play/pause state
  };
  

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value);
    setCurrentPosition(newValue);
    setPlay(false); // Pause if slider is manually moved
    setPath(mergedTripData.slice(0, newValue + 1)); // Update polyline based on slider

    // Center the map to the current position when slider is changed
    const currentLatLng = {
      lat: mergedTripData[newValue].lat,
      lng: mergedTripData[newValue].lng,
    };
    panToCurrentPosition(currentLatLng);

    // Update the slider's background fill as per progress
    event.target.style.background = `linear-gradient(to right, skyblue 0%, skyblue ${
      (newValue / (mergedTripData.length - 1)) * 100
    }%, #d3d3d3 ${
      (newValue / (mergedTripData.length - 1)) * 100
    }%, #d3d3d3 100%)`;

    // Set the slider time to show above the thumb
    setSliderTime(mergedTripData[newValue].time);
  };

  if (!isLoaded) {
    return (
      <Box>
        <Typography>Map is loading...</Typography>
      </Box>
    );
  }
  
  console.log(currentPosition,'currentPosition')
  console.log(mergedTripData.len,'mergedTripData')
  console.log(mergedTripData[currentPosition],'mergedTripData[currentPosition]')

  return (
    <Box style={{ position: "relative", height: "90vh", width: "100%" }}>
      {/* Google Map */}
      <GoogleMap
        mapContainerStyle={{ height: "100%", width: "100%" }}
        center={center}
        zoom={16}
        onLoad={(map) => (mapRef.current = map)}
        options={{
          styles: nightModeStyle, // Applying night mode style
          mapTypeControl: false, // Hides Map/Satellite view options
          streetViewControl: false, // Hides the Pegman (Street View control)
        }}
      >
        {/* Dynamically updated Polyline */}
        {path.length > 0 && <Polyline path={path} options={polylineOptions} />}
        {mergedTripData[currentPosition] && window.google && (
          <Marker
            position={{
              lat: mergedTripData[currentPosition].lat,
              lng: mergedTripData[currentPosition].lng,
            }}
            icon={{
              url: cartrip, // Your cartrip icon
              scaledSize: new window.google.maps.Size(60, 60), // Set the size of the icon
            }}
          />
        )}
      </GoogleMap>

      {/* Controls: Play/Pause Button, Slider, and Time Display in One Line */}
      <Box
        sx={{
          position: "absolute",
          bottom: 10,
          left: 10,
          right: 10,
          padding: "0 20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={handlePlayPause}
          style={{
            backgroundColor: "white",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
            marginRight: "10px",
          }}
        >
          {play ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>

        <Box style={{ position: "relative", width: "100%" }}>
          {sliderTime && (
            <Box
              style={{
                position: "absolute",
                left: `${
                  (currentPosition / (mergedTripData.length - 1)) * 100
                }%`,
                transform: "translateX(-50%)",
                top: "-35px", // Slightly move up for better visibility
                backgroundColor: "#fff", // White background for the chip in dark mode
                color: "#283246", // Darker text for contrast
                fontSize: "12px",
                padding: "5px 10px", // Padding to give a chip-like feel
                borderRadius: "20px", // Rounded corners for a modern look
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)", // Soft shadow for better depth
                border: "1px solid rgba(0, 0, 0, 0.1)", // Subtle border to make it pop
                zIndex: 1, // Ensure it's always on top of the slider
                transition: "background-color 0.3s ease, color 0.3s ease", // Smooth transition for dark mode
              }}
            >
              {sliderTime}
            </Box>
          )}
          <input
            type="range"
            min="0"
            max={mergedTripData.length - 1}
            value={currentPosition}
            onInput={handleSliderChange}
            style={{
              appearance: "none",
              width: "100%",
              height: "8px", // Slightly thicker slider
              background: `linear-gradient(to right, #FFFFFF 0%, #FFFFFF ${
                (currentPosition / (mergedTripData.length - 1)) * 100
              }%, #808080 ${
                (currentPosition / (mergedTripData.length - 1)) * 100
              }%, #808080 100%)`,
              borderRadius: "5px",
              outline: "none",
              opacity: "0.9",
              transition: "background 0.15s ease-in-out",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TripMap;
