import React, { createContext, useState, useMemo, useEffect } from 'react';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [buttonSetting, setButtonSetting] = useState({
    type: 'gradient', // 'gradient' or 'solid'
    color: '#283246'  // Default color
  });

  const [firstTheme, setFirstTheme] = useState('#283246'); // Initially set to primary color

  // Update the body background color when `firstTheme` changes
  useEffect(() => {
    document.body.style.setProperty('--background-color', firstTheme);
  }, [firstTheme]);

  const theme = useMemo(() => createTheme({
    typography: {
      fontFamily: '"Helvetica Neue", Arial, sans-serif',
    },
    palette: {
      text: {
        primary: "#283246",
        secondary: "#97A1B6",
        tertiary: "#f99494",
        quaternary: "#ffffff",
      },
      gradient: {
        main: buttonSetting.type === 'gradient' 
            ? `linear-gradient(90deg, #535A6A 0%, ${buttonSetting.color} 100%)`
            : buttonSetting.color,  // Ensure fallback to solid color
      },
      background: {
        default: firstTheme,  // This can be used within the theme for MUI components
        // paper:'#0E0D0D'
      }
    },
  }), [buttonSetting, firstTheme]);

  return (
    <ThemeContext.Provider value={{ setButtonSetting,buttonSetting, setFirstTheme ,firstTheme}}>
      <MUIThemeProvider theme={theme}>
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
