import { Box } from "@mui/material"
import FilledButton from "../../components/MainButtons/FilledButton"
import plus from "../../assets/Icons/plus.svg";
import SearchTextField from "../../components/SearchTextField";
import { fleetOptions, statusOption } from "../Vehicles/utils";
import fleetselect from "../../assets/Icons/fleetselect.svg";
import statusicon from "../../assets/Icons/status.svg";
import AddWorkOrderForm from "./AddWorkOrderForm";
import { workOrderTableCellItem } from "./utils";
import DeleteUserModal from "../../components/DeleteModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTicket } from "../../slices/workorderManageSlice";
import { useSnackbar } from "../../hooks/Snackbar";
import UserTableComponent from "../UserManagement/UserTable";
import VehicleSelect from "../../components/Select";
import VehicleSelectFleet from "../../components/Select/AllFleetSelect";
import { useNavigate } from "react-router-dom";


export const Tickets = ()=>{

    const [searchInput, setSearchInput] = useState("");
    const [createTicket, setCreateTicket] = useState(false);
    const [userDelete, setUserDelete] = useState(false);
    const [userToDelete, setUserToDelete] = useState("");
    const [ticketToDelete, setTicketToDelete] = useState("");

    const { ticketList } = useSelector((state) => state.workorderdata);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const showSnackbar = useSnackbar()


    const handleSearchChange = () => {
        console.log("ss");
      };
      const handleFleetChange = () => {
        console.log("sddc");
      };

      const handleStatusChange = () => {
        console.log("handle-statusss");
      };

      const handleDeleteTicket = (id) => {
        const ticketToDelete = ticketList.find((ticket) => ticket.id === id);
    
        setUserDelete(true);
        setUserToDelete(id);
        setTicketToDelete(ticketToDelete?.issue_type);
      };

      const handleConfirmDeleteTicket = () => {
        setUserDelete(false);
        dispatch(deleteTicket(userToDelete));
        showSnackbar("Successfully deleted the ticket!", "success");
      };

      const handleRowClick = (id)=>{
        console.log(id,'sss')
      }

      const handleTicketEdit = (id)=>{
        navigate(`/dashboard/work-order/${id}`)
        console.log(id,'iddddd')
      }

    return(
        <>
<Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
          <FilledButton
            sx={{ width: "150px", borderRadius: "6px" }}
            startIcon={<img src={plus} />}
            onClick={() => setCreateTicket(true)}
          >
            Create Ticket
          </FilledButton>
        </Box>
        <Box
          sx={{
            border: "1px solid #DDDEE1",
            height: "42px",
            justifyContent: "space-between",
            p: 1,
            background: "#F1F1F2",
            display: "flex",
            borderRadius: "6px",
            mb: 5,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <SearchTextField
              value={searchInput}
              onChange={handleSearchChange}
              width={"14.75rem"}
              sx={{
                height: "35px",
                width: "14.75rem",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "6px",
                  color: "#283246",
                  backgroundColor: "#FFF",
                  fontSize: "1rem",
                  "& fieldset": {
                    border: "1px solid #DDDEE1", // Border color
                  },
                },
              }}
            />
            <VehicleSelectFleet
              label="Fleet"
              options={fleetOptions}
              icon={<img src={fleetselect} />} // Passing the icon dynamically
              onChange={handleFleetChange}
              sx={{ ml: 0.2 }}
            />

            <VehicleSelect
              label="Status"
              options={statusOption}
              icon={<img src={statusicon} />} // Passing a different icon dynamically
              onChange={handleStatusChange}
            />
          </Box>
        </Box>

        <AddWorkOrderForm  drawerOpen={createTicket}
            setDrawerOpen={setCreateTicket}/>
        <UserTableComponent
          data={ticketList}
          tableHeaders={workOrderTableCellItem}
          showActions={true}
          handleEdit={handleTicketEdit}
          handleRowClick={handleRowClick}
          handleDelete={handleDeleteTicket}
        />
          <DeleteUserModal
        open={userDelete}
        handleClose={() => setUserDelete(false)}
        handleDeleteUser={handleConfirmDeleteTicket}
        header={"Confirm Ticket Deletion"}
        title={
          <span>
            Are you sure you want to permanently delete the ticket{" "}
            <strong>{ticketToDelete}</strong>? This action cannot be undone.
          </span>
        }
        confirmText={"Yes, Delete Ticket"}
      />
</>
    )
}