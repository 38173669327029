// import React, { useState, useEffect, useCallback } from "react";
// import { useDispatch, useSelector } from "react-redux";

// // External Library Imports
// import { Box, Card, Paper, Typography, useTheme } from "@mui/material";
// import { CSVLink } from "react-csv";
// import _ from "lodash";

// // Internal Module Imports
// import SearchTextField from "../../components/SearchTextField";
// import FilledButton from "../../components/MainButtons/FilledButton";
// import csv from "../../assets/Icons/csv.svg";
// import qrcode from "../../assets/Icons/qrcode.svg";
// import fleetselect from "../../assets/Icons/fleetselect.svg";
// import statusicon from "../../assets/Icons/status.svg";
// import flagselect from "../../assets/Icons/flag.svg";
// import vehicleinfo from "../../assets/Icons/vehicleinfo.svg";
// import alertsicon from "../../assets/Icons/alerts.svg";

// import UserTableComponent from "../UserManagement/UserTable";
// import {
//   alertOption,
//   statusOption,
//   vehicleTabData,
//   vehicleTableCellItem,
// } from "./utils";
// import plus from "../../assets/Icons/plus.svg";
// import VehicleSelect from "../../components/Select";
// import { useNavigate } from "react-router-dom";
// import AnimatedNumber from "../../components/AnimatedNumbers";
// import {
//   deleteVehicle,
//   setVehiclesData,
//   vehicleAction,
// } from "../../slices/vehicleManageSlice";
// import DeleteUserModal from "../../components/DeleteModal";
// import VehicleLocationModal from "../../components/VehicleLocationModal";
// import VehicleSelectFleet from "../../components/Select/AllFleetSelect";
// import AddVehicleForm from "./AddVehicleForm";
// import { useSnackbar } from "../../hooks/Snackbar";
// import VehicleActionModal from "./VehicleActionModal";
// import { useGetData } from "../../hooks/useApi";
// import apiInstance from "../../api/axios";

// export const Vehicles = () => {
//   const [searchInput, setSearchInput] = useState(""); // State for search input
//   const [userDelete, setUserDelete] = useState(false);
//   const [userAction, setUserLockAction] = useState(false);
//   const [vehicleToDelete, setVehicleToDelete] = useState("");
//   const [vehicleToAction, setVehicleToAction] = useState("");
//   const [vehicleLocationModal, setVehicleLocationModal] = useState(false);
//   const [createVehicle, setCreateVehicle] = useState(false);
//   const [editVehicle, setEditVehicle] = useState("");
//   const [userToDelete, setUserToDelete] = useState("");
//   const [userToAction, setUserToAction] = useState("");
//   const [fleets, setFleets] = useState([]);
//   const [selectFleet, setSelectFleet] = useState([]);
//   const [status, setStatus] = useState("");
//   const [alerts, setAlerts] = useState("");
//   const [location, setLocation] = useState({});
//   const { vehicles } = useSelector((state) => state.vehicleManage);
//   const { fleet } = useSelector((state) => state.fleetManage);
//   const [vehicleData, setVehicleData] = useState({});
//   const [vehicleLoading, setVehicleLoading] = useState(true);
//   const [page, setPage] = useState();
//   const theme = useTheme();
//   const showSnackbar = useSnackbar();
//   const navigate = useNavigate();
//   const getData = useGetData();
//   const dispatch = useDispatch();

//   const getFleetData = async () => {
//     try {
//       const { data } = await getData.mutateAsync({
//         endpoint: "/fleets",
//         data: { page: "All" },
//       });
//       setFleets(data);
//     } catch (error) {
//     } finally {
//     }
//   };

//   const fleetOptions = fleets?.length
//     ? fleets?.map((fleet) => ({
//         label: fleet.name,
//         value: fleet.id,
//       }))
//     : [];

//   console.log(selectFleet, "selectFleet");

//   const getVehicle = (params) => {
//     return apiInstance.get("/vehicles", {
//       params: {
//         ...params,
//       },
//     });
//   };

//   const getVehicleData = (search = "") => {
//     return async (dispatch) => {
//       setVehicleLoading(true);
//       try {
//         const res = await getVehicle({
//           ...(page && { page }),
//           ...(selectFleet?.length && {
//             fleet_id: selectFleet?.map((e) => e?.value),
//           }),

//           ...(search && { search: search }),
//         });
//         if (res.data) {
//           const { data } = res.data;
//           setVehicleData(data);
//           dispatch(setVehiclesData(data));
//           setVehicleLoading(false);
//         }
//       } catch (err) {
//         setVehicleLoading(false);
//         // if (err?.response?.status === 401) {
//         //   showSnackbar(
//         //     "Oops! Something went wrong, and you've been logged out.",
//         //     "error"
//         //   );
//         //   dispatch(logout());
//         //   return;
//         // }
//         showSnackbar("Failed to load data", "error");
//       } finally {
//         setVehicleLoading(false);
//       }
//     };
//   };

//   // const getVehicleData = async (search = "") => {
//   //   try {
//   //     setVehicleLoading(true);
//   //     const { data } = await getData.mutateAsync({
//   //       endpoint: "/vehicles",
//   //       data: {
//   //         search: search,
//   //         page: page + 1,
//   //         fleet_id: selectFleet?.map((e) => e?.value),
//   //       },
//   //     });
//   //     setVehicleData(data);
//   //     dispatch(setVehiclesData(data));
//   //     setVehicleLoading(false);
//   //   } catch (error) {
//   //     setVehicleLoading(false);
//   //     showSnackbar("Failed to load data", "error");
//   //   } finally {
//   //     setVehicleLoading(false);
//   //   }
//   // };

//   useEffect(() => {
//     getFleetData();
//   }, []);

//   useEffect(() => {
//     getVehicleData();
//   }, [page, selectFleet]);

//   const handleOpenDeleteVehicleModal = (userId) => {
//     const vehicleName = vehicleData?.data?.find(
//       (vehicle) => vehicle.id === userId
//     );
//     setUserDelete(true);
//     setUserToDelete(userId);
//     setVehicleToDelete(vehicleName?.vehicle_name);
//   };

//   const handleFleetChange = (value) => {
//     if (value.length) {
//       setSelectFleet(value);
//     } else {
//       setSelectFleet("");
//     }
//   };

//   const handleStatusChange = (value) => {
//     setStatus(value);
//   };

//   const handleFlagChange = (value) => {
//     if (value.length) {
//       setAlerts(value);
//     } else {
//       setAlerts("");
//     }
//   };

//   const handleSearchChange = (event) => {
//     setSearchInput(event.target.value);
//   };

//   const handleDeleteVehicleClose = () => {
//     setUserDelete(false);
//   };

//   const handleActionVehicleClose = () => {
//     setUserLockAction(false);
//   };

//   const handleConfirmDeleteVehicle = () => {
//     setUserDelete(false);
//     dispatch(deleteVehicle(userToDelete));
//     setCreateVehicle(false);
//     showSnackbar("Vehicle successfully deleted from records", "success");
//   };

//   const handleConfirmVehicleAction = () => {
//     setUserLockAction(false);
//     dispatch(vehicleAction(userToAction));
//     showSnackbar("Vehicle status changed successfully", "success");
//   };

//   const handleEditVehicle = (userId) => {
//     setCreateVehicle(true);
//     setEditVehicle(userId);
//   };

//   const handleDetailPage = (userId) => {
//     navigate(`/dashboard/vehicles/${userId}`);
//   };

//   const handleLockClick = (id) => {
//     const vehicleName = vehicleData?.data?.find((vehicle) => vehicle.id === id);
//     setUserLockAction(true);
//     setUserToAction(id);
//     setVehicleToAction(vehicleName?.vehicle_name);
//   };

//   const handleLocationClick = (location) => {
//     setVehicleLocationModal(true);
//     setLocation(location);
//   };

//   const debouncedFetchData = useCallback(
//     _.debounce((payload) => dispatch(getVehicleData(payload)), 1000),
//     []
//   );

//   useEffect(() => {
//     if (searchInput && (status || selectFleet?.length || page)) {
//       dispatch(getVehicleData(searchInput));
//     } else if (searchInput) {
//       debouncedFetchData(searchInput);
//     } else {
//       dispatch(getVehicleData());
//     }

//     return () => {
//       debouncedFetchData.cancel();
//     };
//     // eslint-disable-next-line
//   }, [page, searchInput, debouncedFetchData, selectFleet]);

//   return (
//     <>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "row",
//           flexWrap: "wrap",
//           justifyContent: "space-between", // This ensures spacing is handled evenly
//           gap: "0.5rem", // Adds space between cards if needed
//           width: "100%",
//         }}
//       >
//         {vehicleTabData.map((user) => (
//           <Card
//             sx={{
//               width: "20%", // Adjust width to fit 4 items per line
//               height: "50px",
//               borderRadius: "10px", // Corner radius
//               padding: "20px", // Padding inside the card
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               justifyContent: "space-between", // Space between elements
//               boxShadow: "none",
//               backgroundColor: "#FFFFFF", // Card background color
//             }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "start",
//               }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: "0.875rem",
//                   color: theme.palette.text.primary,
//                   fontWeight: "400",
//                   marginBottom: "0.3rem",
//                 }}
//               >
//                 {user.name}
//               </Typography>

//               <Typography
//                 sx={{
//                   fontSize: "1.375rem",
//                   color: theme.palette.text.primary,
//                   fontWeight: 700,
//                 }}
//               >
//                 <AnimatedNumber value={user.count} />
//               </Typography>
//             </Box>

//             <Box
//               sx={{
//                 width: 55,
//                 height: 55,
//                 backgroundColor: "#97A1B61A",
//                 borderRadius: "50%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <img
//                 src={user.icon}
//                 style={{ width: 35, height: 35 }}
//                 alt="icon"
//               />
//             </Box>
//           </Card>
//         ))}
//       </Box>
//       <Paper
//         sx={{
//           borderRadius: "1.25rem",
//           background: "#FFFFFF",
//           padding: "1.563rem",
//           boxShadow: "none",
//           minHeight: "90vh",
//           gap: 2,
//           mt: 2,
//           mb: 2,
//         }}
//       >
//         <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2.5 }}>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               mb: 2,
//             }}
//           >
//             <img
//               alt="info"
//               src={vehicleinfo}
//               style={{
//                 height: "22px",
//                 width: "22px",
//                 marginRight: "10px", // Adjust the gap here
//               }}
//             />
//             <Typography
//               sx={{
//                 fontWeight: 600,
//                 fontSize: "1.2rem", // Adjust font size as needed
//                 color: theme.palette.text.primary, // Adjust the text color as needed
//               }}
//             >
//               Vehicles Information
//             </Typography>
//           </Box>

//           <Box>
//             <FilledButton
//               startIcon={<img src={plus} alt="plus" />}
//               sx={{
//                 width: "8rem",
//                 height: "2.188rem",
//                 borderRadius: "6px",
//                 ml: 1,
//                 mt: 0.3,
//               }}
//               onClick={() => setCreateVehicle(true)}
//             >
//               Add Vehicle
//             </FilledButton>
//           </Box>
//         </Box>

//         <Box>
//           <Box
//             sx={{
//               // border: "1px solid #DDDEE1",
//               height: "42px",
//               justifyContent: "space-between",
//               p: 1,
//               background: "#FFFFFF",
//               display: "flex",
//               borderRadius: "6px",
//               mb: 5,
//             }}
//           >
//             <Box sx={{ display: "flex" }}>
//               <SearchTextField
//                 value={searchInput}
//                 onChange={handleSearchChange}
//                 width={"14.75rem"}
//                 sx={{
//                   height: "35px",
//                   width: "14.75rem",
//                   "& .MuiOutlinedInput-root": {
//                     borderRadius: "6px",
//                     color: "#283246",
//                     backgroundColor: "#FFF",
//                     fontSize: "1rem",
//                     "& fieldset": {
//                       border: "1px solid #DDDEE1", // Border color
//                     },
//                   },
//                 }}
//               />
//               <VehicleSelectFleet
//                 label="Fleet"
//                 options={fleetOptions}
//                 icon={<img src={fleetselect} alt="fleet" />} // Passing the icon dynamically
//                 onChange={handleFleetChange}
//                 sx={{ ml: 0.2 }}
//               />

//               {/* <VehicleSelect
//                 label="Status"
//                 options={statusOption}
//                 icon={<img src={statusicon} alt="status" />} // Passing a different icon dynamically
//                 onChange={handleStatusChange}
//               />

//               <VehicleSelectFleet
//                 label="Alerts"
//                 options={alertOption}
//                 icon={<img src={alertsicon} alt="alerts" />} // Passing the icon dynamically
//                 onChange={handleFlagChange}
//                 sx={{ ml: 0.2 }}
//               /> */}

//               {/* <VehicleSelect
//                 label="Alerts"
//                 options={alertOption}
//                 icon={<img src={flagselect} />} // Passing a different icon dynamically
//                 onChange={handleFlagChange}
//               /> */}
//             </Box>
//             {/* <Box sx={{ display: "flex" }}>
//               <CSVLink data={filteredvehicless} filename={"vehicles.csv"}>
//                 <FilledButton
//                   startIcon={
//                     <img src={csv} style={{ width: "18px", height: "18px" }} />
//                   }
//                   sx={{
//                     width: "7rem",
//                     height: "2.188rem",
//                     borderRadius: "6px",
//                     ml: 3,
//                     mt: 0.3,
//                     background: "#ffffff",
//                     color: "#283246",
//                     border: "1px solid #283246",
//                     boxShadow: "none",
//                     "&:hover": {
//                       background: "transparent", // Transparent background on hover
//                     },
//                   }}
//                 >
//                   Get CSV
//                 </FilledButton>
//               </CSVLink>
//               <CSVLink data={filteredvehicless} filename={"vehiclesqrcode.csv"}>
//                 <FilledButton
//                   startIcon={
//                     <img
//                       src={qrcode}
//                       style={{ width: "18px", height: "18px" }}
//                     />
//                   }
//                   sx={{
//                     width: "9rem",
//                     height: "2.188rem",
//                     borderRadius: "6px",
//                     ml: 1,
//                     mt: 0.3,
//                     background: "#ffffff",
//                     color: "#283246",
//                     border: "1px solid #283246",
//                     boxShadow: "none",
//                     "&:hover": {
//                       background: "transparent", // Transparent background on hover
//                     },
//                   }}
//                 >
//                   QR Code PDF
//                 </FilledButton>
//               </CSVLink>
//             </Box> */}
//           </Box>
//           {createVehicle ? (
//             <AddVehicleForm
//               drawerOpen={createVehicle}
//               setDrawerOpen={setCreateVehicle}
//               editVehicle={editVehicle}
//               setEditVehicle={setEditVehicle}
//               handleDelete={handleOpenDeleteVehicleModal}
//             />
//           ) : null}
//           <VehicleLocationModal
//             vehicleLocationModal={vehicleLocationModal}
//             setVehicleLocationModal={setVehicleLocationModal}
//             location={location}
//           />
//           <UserTableComponent
//             data={vehicleData}
//             tableHeaders={vehicleTableCellItem}
//             showActions={true}
//             handleEdit={handleEditVehicle}
//             handleDelete={handleOpenDeleteVehicleModal}
//             handleLockClick={handleLockClick}
//             handleLocationClick={handleLocationClick}
//             handleRowClick={handleDetailPage}
//             loading={vehicleLoading}
//             tablePage={setPage}
//           />
//         </Box>

//         <DeleteUserModal
//           open={userDelete}
//           handleClose={handleDeleteVehicleClose}
//           handleDeleteUser={handleConfirmDeleteVehicle}
//           header={"Confirm Vehicle Removal"}
//           title={
//             <span>
//               Are you sure you want to permanently remove the{" "}
//               <strong>{vehicleToDelete}</strong>? This action cannot be undone
//               and will remove all related data.
//             </span>
//           }
//           confirmText={"Yes, Delete"}
//         />
//         <VehicleActionModal
//           open={userAction}
//           handleClose={handleActionVehicleClose}
//           handleDeleteUser={handleConfirmVehicleAction}
//           header={"Confirm Vehicle Action"}
//           title={
//             <span>
//               Are you sure you want to change the status of the{" "}
//               <strong>{vehicleToAction}</strong>?
//             </span>
//           }
//           confirmText={"Yes, Change Status"}
//         />
//       </Paper>
//     </>
//   );
// };

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// External Library Imports
import { Box, Card, Paper, Typography, useTheme } from "@mui/material";
import { CSVLink } from "react-csv";

// Internal Module Imports
import SearchTextField from "../../components/SearchTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import csv from "../../assets/Icons/csv.svg";
import qrcode from "../../assets/Icons/qrcode.svg";
import fleetselect from "../../assets/Icons/fleetselect.svg";
import statusicon from "../../assets/Icons/status.svg";
import alertsicon from "../../assets/Icons/alerts.svg";
import flagselect from "../../assets/Icons/flag.svg";
import vehicleinfo from "../../assets/Icons/vehicleinfo.svg";

import UserTableComponent from "../UserManagement/UserTable";
import {
  alertOption,
  statusOption,
  vehicleData,
  vehicleTableCellItem,
} from "./utils";
import plus from "../../assets/Icons/plus.svg";
import VehicleSelect from "../../components/Select";
import { useNavigate } from "react-router-dom";
import AnimatedNumber from "../../components/AnimatedNumbers";
import { deleteVehicle, vehicleAction } from "../../slices/vehicleManageSlice";
import DeleteUserModal from "../../components/DeleteModal";
import VehicleLocationModal from "../../components/VehicleLocationModal";
import VehicleSelectFleet from "../../components/Select/AllFleetSelect";
import AddVehicleForm from "./AddVehicleForm";
import { useSnackbar } from "../../hooks/Snackbar";
import VehicleActionModal from "./VehicleActionModal";
import { fleetTableData } from "../Fleet/utils";

export const Vehicles = () => {
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [userDelete, setUserDelete] = useState(false);
  const [userAction, setUserLockAction] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState("");
  const [vehicleToAction, setVehicleToAction] = useState("");
  const [vehicleLocationModal, setVehicleLocationModal] = useState(false);
  const [createVehicle, setCreateVehicle] = useState(false);
  const [editVehicle, setEditVehicle] = useState("");
  const [userToDelete, setUserToDelete] = useState("");
  const [userToAction, setUserToAction] = useState("");
  const [fleets, setFleet] = useState("");
  const [status, setStatus] = useState("");
  const [alerts, setAlerts] = useState("");
  const { vehicles } = useSelector((state) => state.vehicleManage);
  const { fleet } = useSelector((state) => state.fleetManage);

  const theme = useTheme();
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fleetOptions = fleetTableData?.map((fleet) => ({
    label: fleet.fleet_name,
    value: fleet.fleet_name,
  }));

  const handleOpenDeleteVehicleModal = (userId) => {
    const vehicleName = vehicles?.find((vehicle) => vehicle.id === userId);
    setUserDelete(true);
    setUserToDelete(userId);
    setVehicleToDelete(vehicleName?.vehicle_name);
  };

  const handleFleetChange = (value) => {
    if (value.length) {
      setFleet(value);
    } else {
      setFleet("");
    }
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleFlagChange = (value) => {
    if (value.length) {
      setAlerts(value);
    } else {
      setAlerts("");
    }
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredvehicless =
    searchInput ||
    (alerts && alerts.length) ||
    status ||
    (fleets && fleets.length)
      ? vehicles
          .filter((vehicle) => {
            // Safe string checking, ensuring strings are not undefined before calling toLowerCase()
            const matchesName = searchInput
              ? vehicle?.vehicle_name
                  ?.toLowerCase()
                  .includes(searchInput.toLowerCase())
              : true;

            // Correct handling of alerts, ensuring we check both if `alerts` and `vehicle.alerts` are defined and non-empty
            const matchesAlerts =
              alerts && alerts.length
                ? alerts.some((alert) =>
                    vehicle?.alerts?.some(
                      (vehicleAlert) =>
                        vehicleAlert.toLowerCase() === alert.label.toLowerCase() // Use alert label similar to fleet
                    )
                  )
                : true;

            // Safe check for status
            const matchesStatus = status
              ? vehicle?.status?.toLowerCase().includes(status.toLowerCase())
              : true;

            // Ensure fleets is an array and correctly handled
            const matchesFleet =
              fleets && fleets.length
                ? fleets.some(
                    (fleet) =>
                      vehicle?.fleet?.toLowerCase() ===
                      fleet.label.toLowerCase() // Update to use fleet label
                  )
                : true;

            return (
              matchesName && matchesAlerts && matchesStatus && matchesFleet
            );
          })
          .sort((a, b) => a.vehicle_name.localeCompare(b.vehicle_name))
      : vehicles; // Return full list if no filters are applied

  const handleDeleteVehicleClose = () => {
    setUserDelete(false);
  };

  const handleActionVehicleClose = () => {
    setUserLockAction(false);
  };

  const handleConfirmDeleteVehicle = () => {
    setUserDelete(false);
    dispatch(deleteVehicle(userToDelete));
    setCreateVehicle(false);
    showSnackbar("Vehicle successfully deleted from records", "success");
  };

  const handleConfirmVehicleAction = () => {
    setUserLockAction(false);
    dispatch(vehicleAction(userToAction));
    showSnackbar("Vehicle status changed successfully", "success");
  };

  const handleEditVehicle = (userId) => {
    setCreateVehicle(true);
    setEditVehicle(userId);
  };

  const handleDetailPage = (userId) => {
    navigate(`/dashboard/vehicles/${userId}`);
  };

  const handleLockClick = (id) => {
    const vehicleName = vehicles?.find((vehicle) => vehicle.id === id);
    setUserLockAction(true);
    setUserToAction(id);
    setVehicleToAction(vehicleName?.vehicle_name);
  };

  const handleLocationClick = (id) => {
    setVehicleLocationModal(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between", // This ensures spacing is handled evenly
          gap: "0.5rem", // Adds space between cards if needed
          width: "100%",
        }}
      >
        {vehicleData.map((user) => (
          <Card
            sx={{
              width: "20%", // Adjust width to fit 4 items per line
              height: "50px",
              borderRadius: "10px", // Corner radius
              padding: "20px", // Padding inside the card
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between", // Space between elements
              boxShadow: "none",
              backgroundColor: "#FFFFFF", // Card background color
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: theme.palette.text.primary,
                  fontWeight: "400",
                  marginBottom: "0.3rem",
                }}
              >
                {user.name}
              </Typography>

              <Typography
                sx={{
                  fontSize: "1.375rem",
                  color: theme.palette.text.primary,
                  fontWeight: 700,
                }}
              >
                <AnimatedNumber value={user.count} />
              </Typography>
            </Box>

            <Box
              sx={{
                width: 55,
                height: 55,
                backgroundColor: "#97A1B61A",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={user.icon}
                style={{ width: 35, height: 35 }}
                alt="icon"
              />
            </Box>
          </Card>
        ))}
      </Box>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#FFFFFF",
          padding: "1.563rem",
          boxShadow: "none",
          minHeight: "90vh",
          gap: 2,
          mt: 2,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2.5 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <img
              src={vehicleinfo}
              alt="vehicle"
              style={{
                height: "22px",
                width: "22px",
                marginRight: "10px", // Adjust the gap here
              }}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "1.2rem", // Adjust font size as needed
                color: theme.palette.text.primary, // Adjust the text color as needed
              }}
            >
              Vehicles Information
            </Typography>
          </Box>

          <Box>
            <FilledButton
              startIcon={<img src={plus} />}
              sx={{
                width: "8rem",
                height: "2.188rem",
                borderRadius: "6px",
                ml: 1,
                mt: 0.3,
              }}
              onClick={() => setCreateVehicle(true)}
            >
              Add Vehicle
            </FilledButton>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              // border: "1px solid #DDDEE1",
              height: "42px",
              justifyContent: "space-between",
              p: 1,
              background: "#FFFFFF",
              display: "flex",
              borderRadius: "6px",
              mb: 5,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <SearchTextField
                value={searchInput}
                onChange={handleSearchChange}
                width={"14.75rem"}
                sx={{
                  height: "35px",
                  width: "14.75rem",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "6px",
                    color: "#283246",
                    backgroundColor: "#FFF",
                    fontSize: "1rem",
                    "& fieldset": {
                      border: "1px solid #DDDEE1", // Border color
                    },
                  },
                }}
              />
              <VehicleSelectFleet
                label="Fleet"
                options={fleetOptions}
                icon={<img src={fleetselect} />} // Passing the icon dynamically
                onChange={handleFleetChange}
                sx={{ ml: 0.2 }}
              />

              <VehicleSelect
                label="Status"
                options={statusOption}
                icon={<img src={statusicon} />} // Passing a different icon dynamically
                onChange={handleStatusChange}
              />

              <VehicleSelectFleet
                label="Alerts"
                options={alertOption}
                icon={<img src={alertsicon} />} // Passing the icon dynamically
                onChange={handleFlagChange}
                sx={{ ml: 0.2 }}
              />

              {/* <VehicleSelect
                label="Alerts"
                options={alertOption}
                icon={<img src={flagselect} />} // Passing a different icon dynamically
                onChange={handleFlagChange}
              /> */}
            </Box>
            {/* <Box sx={{ display: "flex" }}>
              <CSVLink data={filteredvehicless} filename={"vehicles.csv"}>
                <FilledButton
                  startIcon={
                    <img src={csv} style={{ width: "18px", height: "18px" }} />
                  }
                  sx={{
                    width: "7rem",
                    height: "2.188rem",
                    borderRadius: "6px",
                    ml: 3,
                    mt: 0.3,
                    background: "#ffffff",
                    color: "#283246",
                    border: "1px solid #283246",
                    boxShadow: "none",
                    "&:hover": {
                      background: "transparent", // Transparent background on hover
                    },
                  }}
                >
                  Get CSV
                </FilledButton>
              </CSVLink>
              <CSVLink data={filteredvehicless} filename={"vehiclesqrcode.csv"}>
                <FilledButton
                  startIcon={
                    <img
                      src={qrcode}
                      style={{ width: "18px", height: "18px" }}
                    />
                  }
                  sx={{
                    width: "9rem",
                    height: "2.188rem",
                    borderRadius: "6px",
                    ml: 1,
                    mt: 0.3,
                    background: "#ffffff",
                    color: "#283246",
                    border: "1px solid #283246",
                    boxShadow: "none",
                    "&:hover": {
                      background: "transparent", // Transparent background on hover
                    },
                  }}
                >
                  QR Code PDF
                </FilledButton>
              </CSVLink>
            </Box> */}
          </Box>
          <AddVehicleForm
            drawerOpen={createVehicle}
            setDrawerOpen={setCreateVehicle}
            editVehicle={editVehicle}
            setEditVehicle={setEditVehicle}
            handleDelete={handleOpenDeleteVehicleModal}
          />
          <VehicleLocationModal
            vehicleLocationModal={vehicleLocationModal}
            setVehicleLocationModal={setVehicleLocationModal}
          />
          <UserTableComponent
            data={filteredvehicless}
            tableHeaders={vehicleTableCellItem}
            showActions={true}
            handleEdit={handleEditVehicle}
            handleDelete={handleOpenDeleteVehicleModal}
            handleLockClick={handleLockClick}
            handleLocationClick={handleLocationClick}
            handleRowClick={handleDetailPage}
          />
        </Box>

        <DeleteUserModal
          open={userDelete}
          handleClose={handleDeleteVehicleClose}
          handleDeleteUser={handleConfirmDeleteVehicle}
          header={"Confirm Vehicle Removal"}
          title={
            <span>
              Are you sure you want to permanently remove the{" "}
              <strong>{vehicleToDelete}</strong>? This action cannot be undone
              and will remove all related data.
            </span>
          }
          confirmText={"Yes, Delete"}
        />
        <VehicleActionModal
          open={userAction}
          handleClose={handleActionVehicleClose}
          handleDeleteUser={handleConfirmVehicleAction}
          header={"Confirm Vehicle Action"}
          title={
            <span>
              Are you sure you want to change the status of the{" "}
              <strong>{vehicleToAction}</strong>?
            </span>
          }
          confirmText={"Yes, Change Status"}
        />
      </Paper>
    </>
  );
};
