import React, { useState } from "react";
import { Autocomplete, Avatar, Box, Button, Card, Checkbox, Chip, Grid, IconButton, InputLabel, MenuItem, Paper, Popper, Select, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material";
import { deactivateTableCellItem, deletedTableCellItem, paymentTableCellItem, tripDetailsTableCellItem, userDataEdit } from "./utils";
import SearchTextField from "../../components/SearchTextField";
import TripsTableComponent from "../../components/TripsTable/index"
import {useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton"
import backicon from "../../assets/Icons/backicon.svg"
import UserTimeline from "./UserTimeline";
import TripDetails from "./TripDetails";



const tabStyles = {
  width: '191px',
  height: '40px',
  fontWeight: 500,
  fontSize: '1rem',
  textTransform: 'none',
  color: '#F99494',
  '&.Mui-selected': {
    background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
    color: '#FFFFFF',
    borderRadius: '12px',
  },
};


const inputLabel = { fontSize: "1rem",
fontWeight:500,
mb:1,
color:"#283246"}

export const EditUser = () => {
  const [value,setValue] = useState(0)
  const [searchInput, setSearchInput] = useState("")
  const [openTransaction,setTransactionOpen] = useState(false)
  const [tripDetails,setTripDetails] = useState("")


  const { activeUser, deactivatedUser } = useSelector((state) => state.userManage);

  const navigate = useNavigate()
  const { id } = useParams();
  const theme = useTheme();
  // const dispatch = useDispatch()


  // Filter the user from either activeUser or deactivatedUser based on the id
  const user = activeUser.find((u) => u.id === id) || deactivatedUser.find((u) => u.id === id);

  const [formData, setFormData] = useState({
    user_name: user?.user_name,
    phone: user?.phone,
    email: user?.email,
    fleet: user?.fleet,
    register_date: user?.register_date,
    status: user.status,
  });

  // Handle the case when the user is not found
  if (!user) {
    return <Typography variant="h4">User not found</Typography>;
  }



    // Handle input changes for text fields
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    // Handle save changes
    const handleSaveChanges = () => {
      console.log('Saved data:', formData);

    };
  
    // Handle cancel (reset the form data back to original user data)
    const handleCancel = () => {
      setFormData({
        user_name: user.user_name,
        phone: user.phone,
        email: user.email,
        fleet: user.fleet,
        register_date: user.register_date,
        status: user.status,
      });
    };

  const handleChange = (event, newValue) => {
    setSearchInput('')
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };


  const filteredTripDetails = searchInput
  ? user.trip_details
      .filter((user) =>
        user?.vehicle?.toLowerCase().includes(searchInput?.toLowerCase())
      )
      .sort((a, b) => a.vehicle.localeCompare(b.vehicle))
  : user.trip_details;


  const filteredPaymentDetails = searchInput
  ? user.payment_details
      .filter((user) =>
        user?.fleet?.toLowerCase().includes(searchInput?.toLowerCase())
      )
      .sort((a, b) => a.fleet.localeCompare(b.fleet))
  : user.payment_details;




  const handleTransactionDetails = (userId)=>{
    setTransactionOpen(true)
    setTripDetails(userId)
  }


  const hanldeTrip = (trip)=>{
    setTransactionOpen(true)
    setTripDetails(trip)
console.log(trip,'trip---')
  }

  const handleTransactionClose = ()=>{
    setTransactionOpen(false)
  }

  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
         <Box sx={{ display: "flex", alignItems: "center",mb:2  }}>
          <IconButton onClick={() => navigate("/dashboard/user-management")}>
            <img src={backicon} alt="back-icon" />
          </IconButton>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              ml: 2,
              fontSize: "1.375rem",
              color: "#283246",
            }}
          >
            {user.user_name}

            <Typography sx={{color:theme.palette.text.tertiary,fontSize:'1rem',fontWeight:500}}>&#8226; Last active:{user.last_active}</Typography>
          </Typography>
         
        </Box>
        <Box sx={{display:"flex",  gap: 2,
          mt: 1,
          mb: 2,}}>
        {userDataEdit.map((user) => (
          <Card
            sx={{
              width: "20%",
              borderRadius: "1rem",
              padding: "1rem 3.438rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center content horizontally
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                width: "52px",
                height: "52px",
                background: "#97A1B61A",
                display: "flex",
                alignItems: "center", // Center content vertically
                justifyContent: "center", // Center content horizontally
                mb: 1,
              }}
            >
              <Avatar src={user.icon} />
            </Box>

            <Typography
              sx={{
                fontSize:"0.75rem",
                color: "#283246",
                fontWeight: 500,
                marginBottom: "0.5rem", // Optional: Adjust spacing between text elements
              }}
            >
              {user.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.375rem",
                color: "#283246",
                fontWeight: 700,
              }}
            >
              {user.count}
            </Typography>
          </Card>
        ))}
        </Box>
      </Paper>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "start",mb:2 }}>
        <Tabs
      value={value}
      onChange={handleChange}
      aria-label="Active Users"
      sx={{
        bgcolor: '#F8F8F8',
        borderRadius: '12px',
        border: '1px solid #DDDEE1',
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      }}
    >
      {['User Information', 'Trips Details', 'Payment','Timeline'].map((label, index) => (
        <Tab
          key={label}
          disableRipple
          label={label}
          sx={tabStyles}
        />
      ))}
    </Tabs>
          </Box>
        
        <Box>

        {value === 0 && (
          <>
                      <Typography sx={{fontSize:'1.4rem',fontWeight:500,ml:4,color:"#283246"}}>User Details</Typography>
          <Grid container spacing={4} sx={{ padding: 4 }}>
  {/* User Name */}
  <Grid item xs={12} md={4}>
    <InputLabel sx={inputLabel}>
      User Name <span style={{ color: theme.palette.text.tertiary }}>*</span>
    </InputLabel>
    <CustomTextField value={formData.user_name}  onChange={handleInputChange} sx={{ "& .MuiOutlinedInput-root": {
      width: "300px",
    }}} />
  </Grid>

  {/* Phone Number */}
  <Grid item xs={12} md={4}>
    <InputLabel sx={inputLabel}>
      Phone Number <span style={{ color: theme.palette.text.tertiary }}>*</span>
    </InputLabel>
    <CustomTextField value={formData.phone} onChange={handleInputChange} sx={{ "& .MuiOutlinedInput-root": {
      width: "300px",
    }}} />
  </Grid>

  {/* Email */}
  <Grid item xs={12} md={4}>
    <InputLabel sx={inputLabel}>
      Email <span style={{ color: theme.palette.text.tertiary }}>*</span>
    </InputLabel>
    <CustomTextField value={formData.email} onChange={handleInputChange} sx={{ "& .MuiOutlinedInput-root": {
      width: "300px",
    }}} />
  </Grid>

  {/* Fleet Assignment */}
  <Grid item xs={12} md={4}>
    <InputLabel sx={inputLabel}>
      Fleet Assignment <span style={{ color: theme.palette.text.tertiary }}>*</span>
    </InputLabel>

    <Autocomplete
  multiple
  options={formData.fleet}
  value={formData.fleet}
  onChange={handleInputChange}
  renderTags={(value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        label={option}
        {...getTagProps({ index })}
        sx={{ maxWidth: "100%" }}
      />
    ))
  }
  renderInput={(params) => (
    <TextField
      {...params}
      variant="outlined"
      placeholder="Select fleet"
      sx={{ width: "300px", height: "40px" }}
    />
  )}
  PaperComponent={(props) => (
    <Paper {...props} sx={{ maxHeight: 200, overflowY: "auto" }} />
  )}
  PopperComponent={(props) => (
    <Popper {...props} placement="bottom" sx={{ width: "300px" }} />
  )}
  fullWidth={false} // Disable fullWidth if needed
/>

  </Grid>

  {/* Register Date */}
  <Grid item xs={12} md={4}>
    <InputLabel sx={inputLabel}>
      Register Date <span style={{ color: theme.palette.text.tertiary }}>*</span>
    </InputLabel>
    <CustomTextField value={formData.register_date} onChange={handleInputChange} sx={{ "& .MuiOutlinedInput-root": {
      width: "300px",
    }}} />
  </Grid>

  {/* Status */}
  <Grid item xs={12} md={4}>
    <InputLabel sx={inputLabel}>
      Status <span style={{ color: theme.palette.text.tertiary }}>*</span>
    </InputLabel>
    <Select
  value={formData.status}
  onChange={handleInputChange}
  sx={{ 
    borderRadius: "10px", 
    width: '300px' ,
    height:'40px'
  }}
>
  <MenuItem value="active">Active</MenuItem>
  <MenuItem value="inactive">Inactive</MenuItem>
</Select>

  </Grid>

  {/* Buttons */}
  <Box item  display="flex" justifyContent="start" gap={2} sx={{mt:15 ,ml:3}}>
    <FilledButton
      sx={{
        width: "150px",
      }}
      onClick={handleSaveChanges}
    >
      Save Changes
    </FilledButton>
    <FilledButton
     sx={{
      border: `1px solid ${theme.palette.text.secondary}`,
      width: "150px",
      color: "black",
      background: "white",
      "&:hover": {
        background: "white",
      },
    }}
    >
      Cancel
    </FilledButton>
  </Box>
</Grid>
</>
)}


  {value===1 && 
  <>
    <Box sx={{border:'1px solid #DDDEE1',height:'42px',p:1,background:'#DDDEE1',borderRadius:'6px',mb:3}}>
        <SearchTextField value={searchInput}
                onChange={handleSearchChange}/>
        </Box>
  
  <TripsTableComponent
    data={filteredTripDetails}
    tableHeaders={tripDetailsTableCellItem}
    showActions={true}
    handleEdit={hanldeTrip}
    handleDelete={()=>{}}
  />
  </>
  }

{value===2 && 
<>
  <Box sx={{border:'1px solid #DDDEE1',height:'42px',p:1,background:'#DDDEE1',borderRadius:'6px',mb:3}}>
        <SearchTextField  value={searchInput}
                onChange={handleSearchChange}
                placeholder="Search by fleet"
                />
        </Box>
<TripsTableComponent
    data={filteredPaymentDetails}
    tableHeaders={paymentTableCellItem}
    showActions={true}
    handleEdit={() => {}}
    handleDelete={()=>{}}
  />
  </>
  }

{value===3 &&  <UserTimeline timelineData={user.timeline_details}/>}
</Box>

<TripDetails open={openTransaction} handleClose={handleTransactionClose} TripData={tripDetails}/>
      </Paper>
    </>
  );
};




