import totaldrivers from "../../assets/Icons/totaldriversicon.svg";
import totaldeactivateddriver from "../../assets/Icons/totaldeactivateddriversicon.svg";
import todayactivedrivers from "../../assets/Icons/todayactivedrivers.svg";
import tripactivedrivers from "../../assets/Icons/tripactivedrivericon.svg";
import dummypng from "../../assets/Images/dummy.png";

export const driverData = [
  { name: "Total Drivers", count: "120", icon: totaldrivers },
  { name: "Inactive Drivers", count: "50", icon: totaldeactivateddriver },
  { name: "Today Active Drivers", count: "40", icon: todayactivedrivers },
  { name: "Active on trips Drivers", count: "30", icon: tripactivedrivers },
];

export const driverTableCellItem = [
  { label: "Name", key: "driver_name" },
  { label: "Phone", key: "mobile_phone" },
  { label: "License No", key: "licence_no" },
  { label: "License Exp Date", key: "licence_exp_date" },
  { label: "Vehicle Assigned", key: "vehicle_assigned" },
  { label: "Date of joining", key: "date_of_joining" },
  { label: "Status", key: "status" },
];

export const driverTripTableCellItem = [
  { label: "Trip ID", key: "trip_id" },
  { label: "Vehicle", key: "vehicle_name" },
  { label: "Duration", key: "duration" },
  { label: "Start Date", key: "start_date_time" },
  { label: "End Date", key: "end_date_time" },
  { label: "Distance ( KM )", key: "distance" },
  { label: "Rating", key: "rating" },
];

export const driverTableData = [
  {
    id: "usr_Bob1234SmithXYZ",
    driver_name: "Bob Smith",
    last_name: "Smith",
    mobile_phone: "9876543210",
    work_phone: "9876543210",
    licence_no: "GFRT6@7OPL",
    licence_exp_date: "2025-03-15",
    licence_start_date: "2020-03-15",
    vehicle_assigned: "Van",
    date_of_joining: "2022-05-12",
    status: "Active",
    date_of_birth: "1995-06-20",
    email: "bob@mail.com",
    licence_state: "Gujarat",
    total_exp: "2",
    issue_attachment: "",
    notes: "No current issues.",
    address: "saaa",
    city: "New York",
    state: "NY",
    zip_code: "10001",
    country: "USA",
    address_lane2: "Near main market",
    fleet: ["King Street Transit"],
    driver_image: dummypng,
    email_verification: false,
    mobile_phone_verification: true,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_Alice5678JohnABC",
    driver_name: "Alice Johnson",
    last_name: "Johnson",
    mobile_phone: "9988776655",
    work_phone: "9988776655",
    licence_no: "HJ7K9@9RTY",
    licence_exp_date: "2026-07-21",
    licence_start_date: "2021-07-21",
    vehicle_assigned: "Truck",
    date_of_joining: "2021-11-10",
    status: "Active",
    date_of_birth: "1989-08-20",
    email: "alice@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "3",
    city: "Los Angeles",
    state: "CA",
    zip_code: "90001",
    country: "USA",
    notes: "No current issues.",
    address: "bbb",
    address_lane2: "Near main road",
    fleet: ["King Street Transit"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_John7890DoeXYZ",
    driver_name: "John Doe",
    last_name: "Doe",
    mobile_phone: "9123456789",
    work_phone: "9123456789",
    licence_no: "ZXCV8@1MLP",
    licence_exp_date: "2024-12-30",
    licence_start_date: "2019-12-30",
    vehicle_assigned: "Sedan",
    date_of_joining: "2020-09-15",
    status: "Inactive",
    date_of_birth: "1981-05-12",
    email: "john@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "5",
    notes: "No current issues.",
    city: "Chicago",
    state: "IL",
    zip_code: "60601",
    country: "USA",
    address: "ccc",
    address_lane2: "Near park",
    fleet: ["King Street Transit"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_Sarah2345WillDEF",
    driver_name: "Sarah Wilson",
    last_name: "Wilson",
    mobile_phone: "9012345678",
    work_phone: "9012345678",
    licence_no: "OP7R4@3ZXW",
    licence_exp_date: "2025-06-08",
    licence_start_date: "2020-06-08",
    vehicle_assigned: "Bus",
    date_of_joining: "2023-02-01",
    status: "Active",
    date_of_birth: "1993-10-03",
    email: "sarah@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "1",
    notes: "No current issues.",
    city: "Houston",
    state: "TX",
    zip_code: "77001",
    country: "USA",
    address: "ddd",
    address_lane2: "Near school",
    fleet: ["King Street Transit"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_James4567BrownXYZ",
    driver_name: "James Brown",
    last_name: "Brown",
    mobile_phone: "9098765432",
    work_phone: "9098765432",
    licence_no: "LPO6@9GHJK",
    licence_exp_date: "2025-08-14",
    licence_start_date: "2020-08-14",
    vehicle_assigned: "Van",
    date_of_joining: "2022-04-20",
    status: "Active",
    date_of_birth: "1986-07-19",
    email: "james@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "4",
    notes: "No current issues.",
    city: "Phoenix",
    state: "AZ",
    zip_code: "85001",
    country: "USA",
    address: "eee",
    address_lane2: "Near hospital",
    fleet: ["Evans City Shuttle"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_Emily6789DavisDEF",
    driver_name: "Emily Davis",
    last_name: "Davis",
    mobile_phone: "9087654321",
    work_phone: "9087654321",
    licence_no: "UIO9@7BNMK",
    licence_exp_date: "2027-10-12",
    licence_start_date: "2022-10-12",
    vehicle_assigned: "Truck",
    date_of_joining: "2023-01-18",
    status: "Active",
    date_of_birth: "1992-04-25",
    email: "emily@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "2",
    notes: "No current issues.",
    city: "Philadelphia",
    state: "PA",
    zip_code: "19019",
    country: "USA",
    address: "fff",
    address_lane2: "Near mall",
    fleet: ["Evans City Shuttle"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_Mike1234JonesXYZ",
    driver_name: "Mike Jones",
    last_name: "Jones",
    mobile_phone: "9886643210",
    work_phone: "9886643210",
    licence_no: "ABC6@7XYZ",
    licence_exp_date: "2026-08-15",
    licence_start_date: "2021-08-15",
    vehicle_assigned: "SUV",
    date_of_joining: "2023-03-12",
    status: "Active",
    date_of_birth: "1997-02-05",
    email: "mike@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "2",
    notes: "No current issues.",
    city: "San Antonio",
    state: "TX",
    zip_code: "78201",
    country: "USA",
    address: "ggg",
    address_lane2: "Near bus station",
    fleet: ["Jackson Line Metro"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_Linda5678ClarkABC",
    driver_name: "Linda Clark",
    last_name: "Clark",
    mobile_phone: "9778776655",
    work_phone: "9778776655",
    licence_no: "JKL8@2XYZ",
    licence_exp_date: "2028-05-21",
    licence_start_date: "2023-05-21",
    vehicle_assigned: "Compact",
    date_of_joining: "2022-08-10",
    status: "Active",
    date_of_birth: "1994-01-18",
    email: "linda@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "3",
    notes: "No current issues.",
    city: "San Diego",
    state: "CA",
    zip_code: "92101",
    country: "USA",
    address: "hhh",
    address_lane2: "Near lake",
    fleet: ["Martin Urban Transit"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_Robert7890LeeXYZ",
    driver_name: "Robert Lee",
    last_name: "Lee",
    mobile_phone: "9223456780",
    work_phone: "9223456780",
    licence_no: "MNO9@3XYZ",
    licence_exp_date: "2024-11-30",
    licence_start_date: "2019-11-30",
    vehicle_assigned: "Convertible",
    date_of_joining: "2021-06-15",
    status: "Inactive",
    date_of_birth: "1983-02-12",
    email: "robert@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "6",
    notes: "No current issues.",
    city: "Dallas",
    state: "TX",
    zip_code: "75201",
    country: "USA",
    address: "iii",
    address_lane2: "Near stadium",
    fleet: ["Norton Neighborhood Buses"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_Nancy2345HillDEF",
    driver_name: "Nancy Hill",
    last_name: "Hill",
    mobile_phone: "9312345678",
    work_phone: "9312345678",
    licence_no: "QRS1@4XYZ",
    licence_exp_date: "2027-04-08",
    licence_start_date: "2022-04-08",
    vehicle_assigned: "Minivan",
    date_of_joining: "2023-05-01",
    status: "Active",
    date_of_birth: "1989-05-03",
    email: "nancy@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "4",
    notes: "No current issues.",
    address: "jjj",
    city: "San Jose",
    state: "CA",
    zip_code: "95101",
    country: "USA",
    address_lane2: "Near office",
    fleet: ["Norton Neighborhood Buses"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
  {
    id: "usr_Charles4567MannXYZ",
    driver_name: "Charles Mann",
    last_name: "Mann",
    mobile_phone: "9398765433",
    work_phone: "9398765433",
    licence_no: "TUV5@6XYZ",
    licence_exp_date: "2026-09-14",
    licence_start_date: "2021-09-14",
    vehicle_assigned: "Pickup",
    date_of_joining: "2022-07-20",
    status: "Active",
    date_of_birth: "1995-06-18",
    email: "charles@mail.com",
    licence_state: "Gujarat",
    issue_attachment: "",
    total_exp: "1",
    notes: "No current issues.",
    city: "Austin",
    state: "TX",
    zip_code: "73301",
    country: "USA",
    address: "kkk",
    address_lane2: "Near bridge",
    fleet: ["Norton Neighborhood Buses"],
    driver_image: dummypng,
    email_verification: true,
    mobile_phone_verification: false,
    work_phone_verification: true,
    timeline_details: [
      {
        id: "timeline_6",
        title: "Chris Evans completed advanced driver training.",
        description:
          "Participated in an advanced driving course to enhance safety and performance skills.",
        date: "May 28, 2024",
      },
      {
        id: "timeline_5",
        title: "Chris Evans updated vehicle software.",
        description:
          "Performed a software update to ensure the latest features and fixes are applied.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "Chris Evans reported a maintenance issue.",
        description:
          "Reported an issue with the vehicle's braking system for immediate attention.",
        date: "May 24, 2024",
      },
      {
        id: "timeline_3",
        title: "Chris Evans performed vehicle inspection.",
        description: "Completed a full inspection of the assigned vehicle.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "Chris Evans implemented IoT system.",
        description: "Installed IoT monitoring in the new vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "Chris Evans assigned a new vehicle.",
        description: "A new vehicle was assigned to the fleet.",
        date: "May 18, 2024",
      },
    ],
    trips_details: [
      {
        trip_id: "trip-1",
        vehicle_name: "GenCar2",
        vehicle_id: "usr_Xyz1234abcDEF2",
        duration: "2h min",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        distance: "120 km",
        rating: 3,
      },
      {
        trip_id: "trip-2",
        vehicle_name: "Sedan001",
        vehicle_id: "usr_Yty4567defGHI3",
        duration: "1h 30min",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:45:32",
        distance: "90 km",
        rating: 2.5,
      },
      {
        trip_id: "trip-3",
        vehicle_name: "Hatchback003",
        vehicle_id: "usr_Abc9876ghiJKL4",
        duration: "2h 30min",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 16:50:12",
        distance: "150 km",
        rating: 4,
      },
      {
        trip_id: "trip-4",
        vehicle_name: "SUV004",
        vehicle_id: "usr_Jkl5432mnoPQR5",
        duration: "1h 50min",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:50:00",
        distance: "110 km",
        rating: "3.5",
      },
      {
        trip_id: "trip-5",
        vehicle_name: "Sedan005",
        vehicle_id: "usr_Qrs1290pqrSTU6",
        duration: "2 hours",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
        distance: "100 km",
        rating: 2,
      },
      {
        trip_id: "trip-6",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "1h 40min",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:25:05",
        distance: "100 km",
        rating: 4,
      },
      {
        trip_id: "trip-7",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
      {
        trip_id: "trip-8",
        vehicle_name: "Cybertruck006",
        vehicle_id: "usr_Wxy6789stuVWX7",
        duration: "3h 0min",
        start_date_time: "Sep 20th, 2024 13:10:30",
        end_date_time: "Sep 20th, 2024 16:10:30",
        distance: "180 km",
        rating: 4,
      },
    ],
    timeline_heading: "Sedan010 Management",
    timeline_description:
      "Responsible for overseeing Sedan010’s vehicle and IoT assets. This role ensures timely updates and new additions to our system, enhancing operational efficiency and integrating services seamlessly.",
  },
];

export const Tripdata = {
  trip_id: 1,
  user_id: 101,
  vehicle_qr: "vehicle001",
  username: "user101",
  duration: "01:05:25",
  distance: "20.00 KM",
  cost: "$80.00",
  date: "Jul 12th, 2024 17:19:09",
  ratings: 4.3,
  pause_duration: "00:25:12",
  vehicle_id: "01",
  fleet: "Shyamal Fleet",
  status: "Force Stop",
  activity: [
    {
      userId: "9",
      vehicleId: "01",
      type: "ended",
      desc: "This Ride has been ended.",
      time: "Oct 5th, 2024 18:33:45",
    },
    {
      userId: "9",
      vehicleId: "01",
      type: "pause",
      desc: "This Ride has been stopped.",
      time: "Oct 4th, 2024 18:33:45",
    },
    {
      userId: "9",
      vehicleId: "01",
      type: "started",
      desc: "This Ride has been started.",
      time: "Oct 3rd, 2024 18:33:45",
    },
    {
      userId: "9",
      vehicleId: "01",
      type: "checked",
      desc: "Payment Successfully Done.",
      time: "Oct 2nd, 2024 18:33:45",
    },
  ],
};

const timelineData = [
  {
    time: "3:30",
    title: "Vehicle (Mobility Scooter) Trip Successfully Done",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    color: "success", // MUI color property for the dot
  },
  {
    time: "2:45",
    title: "Scheduled Maintenance Completed",
    description: "Nulla consequat massa quis enim",
    color: "info",
  },
  {
    time: "1:30",
    title: "Low Battery Alert",
    description: "Donec quam felis, ultricies nec, pellentesque eu",
    color: "warning",
  },
  {
    time: "12:15",
    title: "New Firmware Update Available",
    description: "Aenean leo ligula, porttitor eu, consequat vitae",
    color: "primary",
  },
  {
    time: "11:00",
    title: "Vehicle Assigned to New Driver",
    description: "Etiam ultricies nisi vel augue",
    color: "secondary",
  },
  {
    time: "9:45",
    title: "Incident Reported: Minor Collision",
    description: "Curabitur ullamcorper ultricies nisi",
    color: "error",
  },
];
