import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  useTheme,
  Divider,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import crossicon from "../../assets/Icons/drawercross.svg";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { addReminders } from "../../slices/remindersManageSlice";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "../../hooks/Snackbar";
import actionedit from "../../assets/Icons/actionedit.svg";
import actiondelete from "../../assets/Icons/actiondelete.svg";
import dayjs from "dayjs";

// Function to format date to desired output
const formatDateToDisplay = (date) => {
  return new Date(date).toString(); // Converts to "Fri Oct 11 2024 16:30:00 GMT+0530 (India Standard Time)"
};

const inputLabel = {
  fontSize: "0.8rem",
  fontWeight: 700,
  mb: 1,
  color: "#283246",
};

// InputFieldWrapper Component
const InputFieldWrapper = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  height,
  editable = true, // New prop to control editability
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
      </InputLabel>
      <CustomTextField
        value={value}
        onChange={onChange}
        name={name}
        error={!!error}
        sx={{
          "& .MuiOutlinedInput-root": {
            width: "100%",
            height: "40px",
          },
          input: {
            fontSize: "0.9rem",
            height: height ? `${height}px` : "auto", // Input text area height
          },
        }}
        disabled={!editable}
        placeholder={placeholder}
      />
      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

// CustomSelect Component
const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  name,
  placeholder,
  error,
  optionFields, // Array of possible fields to use
  editable = true,
}) => {
  const getOptionLabel = (option) => {
    // Loop through the optionFields and return the first non-empty field
    for (let field of optionFields) {
      if (option[field]) {
        return option[field];
      }
    }
    return "Unnamed"; // Default if no field matches
  };

  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        sx={{
          borderRadius: "10px",
          width: "100%",
          height: "40px",
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Adjust this value to control the height of the visible area
            },
          },
        }}
        name={name}
        error={!!error}
        disabled={!editable}
        displayEmpty
      >
        <MenuItem value="">
          <Typography color="text.primary">{placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.id} value={getOptionLabel(option)}>
            {getOptionLabel(option)}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

const AddReminderForm = ({
  drawerOpen,
  setDrawerOpen,
  editUser,
  setEditUser,
  handleDelete,
}) => {
  const [editable, setEditable] = useState(true);

  const { reminders } = useSelector((state) => state.remindersManage);
  const { driver } = useSelector((state) => state.driverManage);
  const { vehicles } = useSelector((state) => state.vehicleManage);

  const reminderss = reminders?.find((u) => u.id === editUser);

  const [formData, setFormData] = useState({
    driver_name: reminderss?.driver_name || "",
    vehicle_name: reminderss?.vehicle_name || "",
    date_and_time: reminderss?.date_and_time
      ? dayjs(reminderss.date_and_time)
      : dayjs(), // Ensure it's a Date object
    message: reminderss?.message || "",
    id: reminders?.id,
  });

  const [errors, setErrors] = useState({});
  const theme = useTheme();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown") {
      return;
    }
    setFormData({
      driver_name: "",
      vehicle_name: "",
      date_and_time: dayjs(), // Ensure it's a Date object
      message: "",
      id: "",
    });
    setEditUser("");
    setDrawerOpen(open);
    setEditable(true);
  };

  const handleInputChange = (e, fieldName = null) => {
    if (e?.target) {
      // Handle standard input fields (text, number, etc.)
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

      // Update error state if any validation exists
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        if (value.trim() !== "") {
          delete newErrors[name];
        }
        return newErrors;
      });
    } else if (fieldName) {
      // Handle DateTimePicker or date inputs using Dayjs
      console.log(e, "e-fieldname");
      console.log(dayjs(e), "dayjs(e)");
      console.log(dayjs(), "dayjs()");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: dayjs(e).isValid() ? dayjs(e) : dayjs(), // Ensure consistent date handling with Dayjs
      }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.driver_name.trim()) {
      newErrors.driver_name = "Driver name is required";
    }
    if (!formData.vehicle_name.trim()) {
      newErrors.vehicle_name = "Vehicle name is required";
    }
    if (!formData.date_and_time) {
      newErrors.date_and_time = "Date and time are required";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  useEffect(() => {
    setFormData({
      driver_name: reminderss?.driver_name || "",
      vehicle_name: reminderss?.vehicle_name || "",
      date_and_time: reminderss?.date_and_time || dayjs(),
      message: reminderss?.message || "",
      id: reminderss?.id || "",
    });
  }, [reminderss]);

  useEffect(() => {
    if (editUser) {
      setEditable(false);
    }
  }, [editUser]);

  const handleSaveChanges = () => {
    const isValid = validateForm();
    if (isValid) {
      const formattedData = {
        ...formData,
        date_and_time: formatDateToDisplay(formData.date_and_time),
      };
      dispatch(addReminders(formattedData));
      showSnackbar(
        editUser
          ? "Reminder edited successfully!"
          : "Reminder added successfully!",
        "success"
      );
      setDrawerOpen(false);
    } else {
      console.log("Validation errors:", errors);
    }
  };

  const handleEditview = () => {
    setEditable(!editable)
  };

  const handleReminderDelete = () => {
    handleDelete(editUser);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        transitionDuration={{
          enter: 500,
          exit: 500,
        }}
        PaperProps={{
          sx: {
            width: "30%",
            boxSizing: "border-box",
            background: theme.palette.text.quaternary,
            border: "none",
            borderRadius: "1rem",
          },
        }}
        sx={{
          width: "30%",
          flexShrink: 0,
        }}
      >
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box
            sx={{
              width: "100%",
              padding: "20px",
              backgroundColor: "#ffff",
              height: "100%",
            }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 0.5,
                mb: 2,
              }}
            >
              <Typography sx={{ fontSize: "1rem", mt: 0.5, fontWeight: 500 }}>
                {editUser ? "Edit Reminders" : "Add New Reminders"}
              </Typography>
              <Box sx={{ display: "flex" }}>
                {editUser && (
                  <>
                    <Box
                      onClick={handleEditview}
                      sx={{
                        border: "1px solid #DDDEE1",
                        borderRadius: "6px",
                        p: 0.2,
                        mr: 0.5,
                        ml: 0.5,
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip
                        title="Edit"
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#424242", // Dark grey background for high contrast
                              color: "white", // White text for clarity
                              fontWeight: "600",
                              fontSize: "14px",
                              padding: "10px 15px",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                            },
                          },
                          arrow: {
                            sx: {
                              color: "#424242", // Matching the tooltip background
                            },
                          },
                        }}
                      >
                        <IconButton
                          sx={{
                            color: theme.palette.text.primary,
                            width: "25px",
                            height: "25px",
                          }}
                        >
                          <img
                            src={actionedit}
                            alt="edit"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      onClick={handleReminderDelete}
                      sx={{
                        border: "1px solid #DDDEE1",
                        borderRadius: "6px",
                        p: 0.2,
                        mr: 0.5,
                        ml: 0.5,
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip
                        title="Delete"
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#424242", // Dark grey background for high contrast
                              color: "white", // White text for clarity
                              fontWeight: "600",
                              fontSize: "14px",
                              padding: "10px 15px",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                            },
                          },
                          arrow: {
                            sx: {
                              color: "#424242", // Matching the tooltip background
                            },
                          },
                        }}
                      >
                        <IconButton
                          sx={{
                            color: theme.palette.text.primary,
                            width: "25px",
                            height: "25px",
                          }}
                        >
                          <img
                            src={actiondelete}
                            alt="delete"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </>
                )}
                <IconButton
                  onClick={toggleDrawer(false)}
                  sx={{ color: theme.palette.text.primary }}
                >
                  <img src={crossicon} alt="Close" />
                </IconButton>
              </Box>
            </Box>
            <Divider sx={{ mb: 2 }} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                flex: "1 1 auto",
              }}
            >
              <CustomSelect
                label="Driver"
                value={formData.driver_name}
                onChange={handleInputChange}
                options={driver}
                editable={editable}
                name="driver_name"
                placeholder="Select an option"
                optionFields={["driver_name"]}
                error={errors.driver_name}
              />

              <CustomSelect
                label="Vehicle"
                value={formData.vehicle_name}
                onChange={handleInputChange}
                options={vehicles}
                editable={editable}
                name="vehicle_name"
                error={errors.vehicle_name}
                optionFields={["vehicle_name"]}
              />

              <Box>
                <InputLabel sx={inputLabel}>
                  Date & Time{" "}
                  <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
                </InputLabel>
                <DateTimePicker
                  value={dayjs(formData.date_and_time)}
                  disabled={!editable}
                  onChange={(date) => handleInputChange(date, "date_and_time")}
                  minDateTime={dayjs()}
                  sx={{
                    width: "100%",
                    height: "45px",
                    "& .MuiInputBase-root": {
                      height: "100%",
                      borderRadius:'0.625rem'
                    },
                  }}
                />
              </Box>

              <InputFieldWrapper
                label="Message / Note"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Enter Message"
                error={errors.message}
                editable={editable}
              />
            </Box>

            <Box
              sx={{
                p: 2,
                width: "80%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <FilledButton
                sx={{ mt: { lg: 16, xl: 30 }, width: "150px",
                '&.Mui-disabled': { 
                  color: 'white',
                  bgcolor: 'grey'
                }
              }}
                onClick={handleSaveChanges}
                disabled={!editable}
              >
                {editUser?"Save Changes":"Create"}
              </FilledButton>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </LocalizationProvider>
  );
};

export default AddReminderForm;
