import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { ColorPickerWrapper } from "./style";
import { debounce } from "lodash";

const ColorPickers = ({ defaultColor = "", handleColorChange }) => {
  const [color, setColor] = useState(defaultColor);

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);

  return (
    <ColorPickerWrapper>
      <span className="text-sm font-style-Medium rounded-t">Pick a color</span>
      <HexColorPicker
        color={color}
        onChange={debounce((e) => {
          setColor(e);
          handleColorChange(e.toUpperCase());
        }, 28)}
      />
    </ColorPickerWrapper>
  );
};

export default ColorPickers;
