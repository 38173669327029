import Chart from "react-apexcharts"
import TripsColorIcon from "../../../../assets/Icons/TripsColorIcon.svg"
import { Box, Typography } from "@mui/material";
import { useState } from "react";

const TripDurationPieChart = () => {
  const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(null)
  const series = [16,16,16,15,15];
  const options = {
    chart: {
      type: "pie",
    },
    labels: ["In service",
      "Rebalance Pick Up",
      "Maintenance Pickup",
      "Agency Pickup",
      "Missing"
    ],
    colors: ["#F99494", "#283246", "#CAD7F5", "#F8E4CC", "#E1CFFD"],
    legend: {
      position: "bottom",
      markers: {
        shape: "square"
      },
      fontSize: '12px',
      fontFamily: 'Helvetica Neue, Arial',
      fontWeight:500
    },
    dataLabels: {
      enabled: true,

      style: {

        // fontfamily: "Helvetica Neue",
       
        fontSize: "16px",
        // fontWeight:"bold",
        // padding:"20px",
        colors: ["black", "#FFFFFF", "#283246","#283246","#283246"],
        textShadow: "none"
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.45
    }
    },

    plotOptions: {
      pie: {
        expandOnClick: false, // Disable expanding on click
        dataLabels: {
          offset: -20, // Center the labels
          minAngleToShowLabel: 10, // Ensure labels are visible for all angles
        },
      },
    },
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="10px"
      bgcolor="#F8F8F8"
      borderRadius="25px"
      sx={{p:0.5,minHeight:'300px',width:"60%"}}
    >
      <Box sx={{display:'flex',alignItems:"center",justifyContent:"space-between",marginInline:"20px"}}  >      
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingInline: "10px",
         
        }}
      >
        {/* <Box>
          <img src={TripsColorIcon} width={"30px"} alt="" />
        </Box> */}
        <Typography
          variant="body1"
          sx={{
           
            color: "#283246",
            fontSize: "16px",
            fontWeight: "500"
          }}
        >
          Vehicle Health</Typography>
      </Box>
      <Box>
       
      </Box>
      <Box  >
        <Chart
          options={options}
          series={series}
          type="pie"
          height={"310px"}
        />
      </Box>
    </Box>
  )
}
export default TripDurationPieChart