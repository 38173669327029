import { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  Typography,
  TextField,
  useTheme,
  CircularProgress,
} from "@mui/material";
// import { useSnackbar } from "../hooks/Snackbar";
import { useDispatch } from "react-redux";
// import { loginEmail, setOtpNew, setOtpTimeStamp } from "../slices/authSlice";
import { differenceInMilliseconds } from "date-fns";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axonslablogin from "../../assets/Images/axonslab.svg";
import axonslogin from "../../assets/Images/axonslogin.svg";
import axonslogin2 from "../../assets/Images/axonslogin2.svg";
import axonslogin3 from "../../assets/Images/axonslogin3.svg";
import axonslogin4 from "../../assets/Images/axonslogin4.svg";
import axonslogin5 from "../../assets/Images/axonslogin5.svg";
import axonslogin6 from "../../assets/Images/axonslogin6.svg";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../hooks/Snackbar";
import { usePostData } from "../../hooks/useApi";
import {
  login,
  setAuthToken,
  setCurrentRole,
  setOtpNew,
  setOtpTimeStamp,
} from "../../slices/authSlice";
import { useSelector } from "react-redux";
import { calculateTimeLeft } from "../../assets/countdownUtils";
const images = [
  axonslogin,
  axonslogin2,
  axonslogin3,
  axonslogin4,
  axonslogin5,
  axonslogin6,
];
const users = {
  "admin@axonslab.io": {
    role: "admin",
    password: "admin123", // You can change this as needed
  },
  "operator@axonslab.io": {
    role: "operator",
    password: "operator123", // You can change this as needed
  },
};

const LoginOtp = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [validationError, setValidationError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const showSnackbar = useSnackbar();
  const postData = usePostData();
  const email = useSelector((state) => state.auth?.email);
  const expiryTimestamp = useSelector((state) => state.auth?.otpTimeStamp);
  const actualOtp = useSelector((state) => state.auth.newOtp);
  const { minutes, seconds, expired } = calculateTimeLeft(expiryTimestamp);
  const [loading, setIsLoading] = useState(false);
  console.log(useSelector((state) => state));
  const formattedTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  // const classes = useStyles();
  const [otp, setOtp] = useState(Array(6).fill(""));

  const handleChangeOtp = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const extendedImages = [...images, images[0]];

  const handleChangeCarousal = (index) => {
    if (index === images.length) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(index);
    }
  };

  useEffect(() => {
    if (currentSlide === 0) {
      const carouselElement = document.querySelector(".carousel");
      carouselElement.style.transition = "none";
      carouselElement.style.transform = "translate3d(0px, 0px, 0px)";

      setTimeout(() => {
        carouselElement.style.transition = "";
      }, 0);
    }
  }, [currentSlide]);

  // const handleSubmit = async () => {
  //   navigate("/dashboard/analytics");
  // };

  const handleSubmit = async () => {
    try {
      if (otp.join("").length < 6) {
        setValidationError(true);
        setHelperText("Oops! Looks like the OTP is incomplete.");
        return; // Exit early if OTP is incomplete
      }

      // Reset validation state if OTP is correct
      setValidationError(false);
      setHelperText("");

      setIsLoading(true);
      const { data, error } = await postData.mutateAsync({
        endpoint: "/auth/validate",
        data: { email: email, otp: otp.join("") },
      });

      // Check if there was an error in the API response
      if (error) {
        showSnackbar(error?.response?.data?.message, "error");
        return; // Exit early if there's an error
      }

      if (data) {
        dispatch(setAuthToken(data?.user?.token));
        // Cookies.set("authToken", data?.user?.token, {
        //   expires: 23 / 24,
        //   secure: true,
        // });
        dispatch(setCurrentRole(data?.user?.role));
        dispatch(setAuthToken(data?.user?.token));
        dispatch(setCurrentRole(data?.user?.role?.name));
        dispatch(login(true));
        navigate("/dashboard/analytics");
      }
    } catch (error) {
      showSnackbar(error?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  // const handleResendOtp = async () => {
  //   setOtp(Array(6).fill(""));
  // };

  const handleResendOtp = async () => {
    setOtp(Array(6).fill(""));
    try {
      setIsLoading(true);
      const { data } = await postData.mutateAsync({
        endpoint: "/manage/auth/request",
        data: { email: email },
      });

      if (data) {
        dispatch(setOtpNew(data?.otp));
        dispatch(setOtpTimeStamp(data?.otp_interval_time));
        showSnackbar("Otp sent successfully to your email!", "success");
      }
    } catch (error) {
      showSnackbar(error?.response?.data?.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid
      container
      sx={{ bgcolor: "#FFFFFF", padding: "1rem" }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", md: "90vh" },
        }}
      >
        <Box>
          <img src={axonslablogin} alt="axonslablogin" />
        </Box>
        <Card
          sx={{
            width: { xs: "100%", sm: 440 },
            minHeight: 320,
            borderRadius: "25px",
            mt: 7,
          }}
        >
          <CardContent sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                fontFamily: theme.typography.fontFamily,
                color: "#283246",
              }}
            >
              Confirm Your Email
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 500,
                fontFamily: theme.typography.fontFamily,
                color: "#283246",
              }}
            >
              We sent you a 6-digit code to {email}. Please enter it below.
              Can't find it? Check your spam folder.
              <span
                style={{
                  fontWeight: 800,
                  fontSize: "1rem", // Keep the font size
                  letterSpacing: "0.3rem", // Maintain spacing between characters
                  padding: "0.2rem 0.5rem", // Existing padding
                  backgroundColor: "#e9ecef", // Light gray background for subtlety
                  color: "#495057", // Dark gray color for the text, easy on the eyes
                  borderRadius: "5px", // Keep the rounded edges
                  border: "1px solid #ced4da", // Soft gray border to blend with the background
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)", // Lighter shadow for a soft effect
                  textShadow: "0 1px 1px rgba(0, 0, 0, 0.05)", // Subtle text shadow for depth
                  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', // Modern and clean font
                }}
              >
                {" "}
                {String(actualOtp).split("").join(" ")}
              </span>
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              sx={{ p: "0.5rem" }}
            >
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  id={`otp-input-${index}`}
                  value={digit}
                  onChange={(e) => handleChangeOtp(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  variant="outlined"
                  type={"text"}
                  sx={{
                    // width: "40px",
                    // height: "40px",
                    borderRadius: "12px",
                    border: "1px solid #97A1B6",
                    textAlign: "center",
                    padding: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                    "& .MuiInputBase-input": {
                      textAlign: "center",
                      padding: "0",
                      fontSize: "16px",
                    },
                  }}
                />
              ))}
            </Box>
            {validationError && (
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#d32f2f",
                  fontFamily: "DM Sans, Arial, sans-serif",
                }}
              >
                {helperText}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                p: expired ? 1 : "",
              }}
            >
              <Button
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  fontFamily: theme.typography.fontFamily,
                  color: expired ? "#283246" : "gray",
                  backgroundColor: expired
                    ? "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)"
                    : "linear-gradient(to right, #ccc, #ddd)",
                  borderRadius: "30px",
                  textTransform: "none",
                  transition:
                    "background-color 0.4s ease, box-shadow 0.4s ease",
                  "&:hover": {
                    border: expired ? "1px solid gray" : "none",
                  },
                  "&:disabled": {
                    cursor: "not-allowed",
                  },
                  ml: { xs: 4, sm: 10, md: expired ? 15 : 10 },
                }}
                disabled={!expired}
                onClick={handleResendOtp}
              >
                {expired ? "Send a new code" : `Resend code available in`}
              </Button>

              {formattedTime !== "0:00" && (
                <Typography sx={{ color: "#283246" }}>
                  {formattedTime}
                </Typography>
              )}
            </Box>

            <Button
              variant="contained"
              disableRipple
              sx={{
                mt: 1,
                borderRadius: "12px",
                width: "80%",
                height: 55,
                textTransform: "none",
                background:
                  "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                transition: "all 0.3s ease",
                "&:hover": {
                  background:
                    "linear-gradient(to right, rgba(60, 70, 90, 1) 40%, rgba(20, 30, 50, 1) 100%)",
                  transform: "scale(1.02)",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
                "&:active": {
                  transform: "scale(0.98)",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
                },
              }}
              onClick={handleSubmit}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                "Verify OTP"
              )}
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", md: "90vh" },
          mt: { xs: 5, md: 2 },
        }}
      >
        <Card
          sx={{
            width: "90%",
            borderRadius: "15px",
            marginLeft: "4rem",
          }}
        >
          <Carousel
            selectedItem={currentSlide}
            onChange={handleChangeCarousal}
            autoPlay
            infiniteLoop
            interval={2000}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={false}
          >
            {extendedImages.map((URL, index) => (
              <div className="slide" key={index}>
                <img alt="carousel_image" src={URL} />
              </div>
            ))}
          </Carousel>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginOtp;
