export const reminderTableCellItem = [
    { label: "Driver Name", key: "driver_name" },
    { label: "Vehicle Name", key: "vehicle_name" },
    { label: "Message", key: "message" },
    { label: "Reminder Date", key: "date_and_time" },
    { label: "Created Date", key: "created_date" }
  ];
  
  export const reminderTableData = [
    {
      id: "866b1a08-8ea6-4895-bde6-742019911604",
      driver_name: "Bob Smith",
      vehicle_name: "SUV004",
      message: "Maintenance check",
      date_and_time: "2024-10-25 03:00",
      created_date: "2024-08-29",
    },
    {
      id: "1dde763c-a536-4465-9f49-083df9b659c7",
      driver_name: "Bob Smith",
      vehicle_name: "Sedan005",
      message: "Oil change reminder",
      date_and_time: "2024-10-16 15:00",
      created_date: "2024-08-17",
    },
    {
      id: "f35444af-37ce-4f0d-82b5-32079da276d4",
      driver_name: "Charles Mann",
      vehicle_name: "Sedan010",
      message: "Brake inspection",
      date_and_time: "2024-10-18 13:00",
      created_date: "2024-09-05",
    },
    {
      id: "933c1317-2b54-45a6-b9b5-297650c43b4d",
      driver_name: "Nancy Hill",
      vehicle_name: "Hatchback003",
      message: "Tire replacement",
      date_and_time: "2024-10-18 14:00",
      created_date: "2024-08-12",
    },
    {
      id: "d735c970-4190-477a-bfef-d0d0f0e90097",
      driver_name: "Sarah Wilson",
      vehicle_name: "Sedan001",
      message: "Engine diagnostic",
      date_and_time: "2024-10-20 18:00",
      created_date: "2024-08-24",
    },
    {
      id: "82997bd7-d4bc-466d-86cd-ab15b0ab94bd",
      driver_name: "John Doe",
      vehicle_name: "SUV004",
      message: "Battery check",
      date_and_time: "2024-10-25 19:00",
      created_date: "2024-08-30",
    },
    {
      id: "62871030-79d0-41ca-b293-71877e480cad",
      driver_name: "Mike Jones",
      vehicle_name: "Cybertruck006",
      message: "Transmission fluid check",
      date_and_time: "2024-10-22 17:00",
      created_date: "2024-08-18",
    },
    {
      id: "498a66bb-6b56-4f87-b4ee-9f38fbc27fdd",
      driver_name: "Linda Clark",
      vehicle_name: "GenCar2",
      message: "Air filter replacement",
      date_and_time: "2024-10-28 09:00",
      created_date: "2024-09-06",
    },
    {
      id: "fe7571f9-5905-4830-a08b-38606a1705b5",
      driver_name: "Robert Lee",
      vehicle_name: "Sedan001",
      message: "Safety inspection",
      date_and_time: "2024-10-19 11:00",
      created_date: "2024-08-17",
    },
    {
      id: "e1fd9730-72ce-4ab9-8947-239f1b57a24c",
      driver_name: "Linda Clark",
      vehicle_name: "GenericCar-vehicle",
      message: "Annual service reminder",
      date_and_time: "2024-10-23 18:00",
      created_date: "2024-08-31",
    }
  ];
  