import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import { UserManagement } from "./features/UserManagement";
import { EditUser } from "./features/UserManagement/EditUser";

import { Analytics } from "./features/Analytics";
import { Trips } from "./features/Trips";
import { MapPage } from "./features/MapPage";
import { Vehicles } from "./features/Vehicles";
import { Settings } from "./features/Settings";
import { AddVehicle } from "./features/Vehicles/Addvehicle";
import { EditVehicle } from "./features/Vehicles/Editvehicle";
import { Drivers } from "./features/Drivers";
import AddDriver from "./features/Drivers/AddDriver";
import { EditDriver } from "./features/Drivers/EditDriver";
import { FleetManagment } from "./features/Fleet";
import { Geofences } from "./features/Geofencing";
import CreateParkingZone from "./features/Geofencing/AddParking";
import EditParkingZone from "./features/Geofencing/EditParking";
import Login from "./features/Login";
import { Reminder } from "./features/Reminders";
import AddReminders from "./features/Reminders/addReminders";
import { WorkOrders } from "./features/WorkOrders";
import { EditTicket } from "./features/WorkOrders/EditTicket";
import MapWithRoutes from "./features/MapWithRoute";
import { OnGoingTrips } from "./features/Trips/OnGoingTrips";
import {OnGoingTripTimeline} from "./features/Trips/ongoingTripTimeline"
import { ScheduledTrips } from "./features/Trips/ScheduleTrips";
import { CompletedTrips } from "./features/Trips/CompletedTrips";
import { TripsTimeline } from "./features/Trips/TripsTimeline";

function App() {
  return (
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard" element={<DashboardLayout />}>
                <Route path="analytics" element={<Analytics />} />
                <Route path="user-management" element={<UserManagement />} />
                <Route path="user-edit/:id" element={<EditUser/>}/>
                <Route path="assignments" element={<Trips />} />
                <Route path="ongoing" element={<OnGoingTrips />} />
                <Route path="ongoing/:id" element={<OnGoingTripTimeline />} />
                <Route path="scheduled" element={<ScheduledTrips />} />
                <Route path="completed" element={<CompletedTrips />} />
                <Route path="completed/:id" element={<TripsTimeline />} />
                <Route path="vehicles" element={<Vehicles />} />
                <Route path="add-vehicles" element={<AddVehicle />} />
                <Route path="vehicles/:id" element={<EditVehicle />} />
                <Route path="drivers" element={<Drivers />} />
                <Route path="add-drivers" element={<AddDriver />} />  
                <Route path="add-reminder" element={<AddReminders />} />  
                <Route path="drivers/:id" element={<EditDriver />} />
                <Route path="map" element={<MapPage />} />
                <Route path="fleet" element={<FleetManagment />} />
                <Route path="geozones" element={<Geofences/>} />
                <Route path="geozones/:id" element={<EditParkingZone/>} />
                <Route path="add-geofences" element={<CreateParkingZone/>} />
                <Route path="reminder" element={<Reminder />} />
                <Route path="work-orders" element={<WorkOrders />} />
                <Route path="work-order/:id" element={<EditTicket/>}/>
                <Route path="mapwith-route" element={<MapWithRoutes/>}/>
                <Route path="settings" element={<Settings />} />
              </Route>

              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </Router>
  );
}

export default App;






