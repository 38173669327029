import React from 'react';
import CountUp from 'react-countup';

const AnimatedNumber = ({ value }) => {
  // Check if the value is a valid number
  const isNumber = !isNaN(parseFloat(value)) && isFinite(value);
  const number = parseFloat(value);
  const unit = isNumber ? value.replace(number, '').trim() : '';

  return (
    <div>
      {isNumber ? (
        <>
          <CountUp end={number} duration={3} decimals={number % 1 !== 0 ? 1 : 0} />
          {unit}
        </>
      ) : (
        value
      )}
    </div>
  );
};

export default AnimatedNumber;
