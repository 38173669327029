// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Drawer,
//   Select,
//   MenuItem,
//   InputLabel,
//   Typography,
//   IconButton,
//   useTheme,
//   Divider,
//   FormHelperText,
//   FormControlLabel,
//   Tooltip,
//   Chip,
//   Badge,
// } from "@mui/material";
// import crossicon from "../../assets/Icons/drawercross.svg";
// import CustomTextField from "../../components/MainTextField/CustomTextField";
// import FilledButton from "../../components/MainButtons/FilledButton";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import redDot from "../../assets/Icons/reddot.svg";
// import completedCheck from "../../assets/Icons/completedCheck.svg";
// import graydot from "../../assets/Icons/graydot.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { useSnackbar } from "../../hooks/Snackbar";
// import { addVehicle } from "../../slices/vehicleManageSlice";
// import serviceicon from "../../assets/Icons/serviceflag.svg";
// import maintenanceicon from "../../assets/Icons/maintenanceflag.svg";
// import batteryflagicon from "../../assets/Icons/batteryflag.svg";
// import iotflagicon from "../../assets/Icons/iotfaultflag.svg";
// import IOSSwitch from "../../components/Switch";
// import viewvehicle from "../../assets/Icons/viewmode.svg";
// import actionedit from "../../assets/Icons/actionedit.svg";
// import actiondelete from "../../assets/Icons/actiondelete.svg";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import { useGetData } from "../../hooks/useApi";

// const inputLabel = {
//   fontSize: "0.8rem",
//   fontWeight: 700,
//   mb: 1,
//   color: "#283246",
// };

// const textFieldStyle = {
//   "& .MuiOutlinedInput-root": {
//     // Apply responsive width based on breakpoints
//     width: {
//       xs: "100px", // Width for small screens
//       sm: "300px", // Width for medium screens
//       md: "400px", // Width for large screens
//       lg: "300px",
//       xl: "400px",
//     },
//   },
// };

// const selectStyle = {
//   borderRadius: "10px",
//   height: "40px",
//   width: {
//     xs: "100px", // Width for small screens
//     sm: "300px", // Width for medium screens
//     md: "400px", // Width for large screens
//     lg: "300px", // Width for larger screens
//     xl: "400px", // Width for extra-large screens
//   },
// };

// // Reusable Component for custom switch with label and icon
// const CustomSwitch = ({ icon, label, color }) => (
//   <FormControlLabel
//     control={<IOSSwitch color={color} sx={{ ml: 1 }} />}
//     label={
//       <Box sx={{ display: "flex", alignItems: "center" }}>
//         <img src={icon} alt={label} style={{ marginRight: "8px" }} />
//         <Typography sx={{ fontWeight: 500, fontSize: "1rem" }}>
//           {label}
//         </Typography>
//       </Box>
//     }
//     labelPlacement="start"
//     sx={{
//       mr: 3,
//       mb: 2.5,
//       minWidth: "220px",
//       justifyContent: "flex-end", // Changed from 'end' to 'flex-start' for better control
//     }}
//   />
// );

// const InputFieldWrapper = ({
//   label,
//   name,
//   value,
//   onChange,
//   placeholder,
//   error,
//   required = true, // Added a new prop to control the asterisk display,
//   editable = true, // New prop to control editability
// }) => {
//   return (
//     <Box>
//       <InputLabel sx={inputLabel}>
//         {label} {""}
//         {required && (
//           <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
//         )}
//       </InputLabel>
//       <CustomTextField
//         value={value}
//         onChange={onChange}
//         name={name}
//         sx={textFieldStyle}
//         error={!!error}
//         placeholder={placeholder}
//         disabled={!editable}
//       />

//       {error && (
//         <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
//           {error}
//         </FormHelperText>
//       )}
//     </Box>
//   );
// };

// const CustomSelect = ({
//   label,
//   value,
//   onChange,
//   options,
//   name,
//   placeholder,
//   error,
//   required = true, // Added a new prop to control the asterisk display
//   editable = true, // New prop to control editability
// }) => {
//   return (
//     <Box>
//       <InputLabel sx={inputLabel}>
//         {label}
//         {required && (
//           <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
//         )}
//       </InputLabel>
//       <Select
//         value={value}
//         onChange={onChange}
//         sx={selectStyle}
//         MenuProps={{
//           PaperProps: {
//             style: {
//               maxHeight: 200, // Adjust this value to control the height of the visible area
//             },
//           },
//         }}
//         name={name}
//         error={!!error}
//         displayEmpty
//         disabled={!editable}
//       >
//         <MenuItem value="">
//           <Typography color="text.primary">{placeholder}</Typography>
//         </MenuItem>
//         {options.map((option) => (
//           <MenuItem key={option.value} value={option.value}>
//             {option.label}
//           </MenuItem>
//         ))}
//       </Select>
//       {error && (
//         <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
//           {error}
//         </FormHelperText>
//       )}
//     </Box>
//   );
// };

// // Options for the select fields

// const fuelTypes = [
//   { label: "Diesel", value: "Diesel" },
//   { label: "Petrol", value: "Petrol" },
//   { label: "Electric", value: "Electric" },
// ];
// const iotTypes = [
//   { label: "Axons Micro Pro", value: "Axons Micro Pro" },
//   { label: "Axons Micro", value: "Axons Micro" },
// ];

// const bluetoothOptions = [
//   { label: "Yes", value: "Yes" },
//   { label: "No", value: "No" },
// ];

// const requiredFields = [
//   "vehicle_type",
//   "fleet",
//   "vehicle_name",
//   "vehicle_qr",
//   "fuelType",
//   "vehicle_identifier",
// ];

// const steps = [
//   { title: "Set Up Your Vehicle", subtitle: "Enter Basic Details" },
//   { title: "Connect IoT Devices", subtitle: "Configure Your IoT Settings" },
// ];

// // Component to render each step header with status and subtitle
// const StepHeader = ({
//   value,
//   completedSteps,
//   editable,
//   handleNext,
//   handleBack,
// }) => (
//   <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
//     {steps.map((step, index) => {
//       const isCompleted = completedSteps.includes(index);
//       const isActive = index === value;

//       const handleClick = () => {
//         if (step.title === "Connect IoT Devices" && !isActive) {
//           handleNext();
//         } else if (step.title === "Set Up Your Vehicle" && !isActive) {
//           handleBack();
//         }
//       };

//       return (
//         <>
//           <Box
//             key={step.title}
//             sx={{
//               flex: 1,
//               cursor: "pointer",
//               borderBottom:
//                 editable && isActive
//                   ? "4px solid #FE7676"
//                   : editable && isCompleted
//                   ? "4px solid #52C37F"
//                   : "2px solid #ddd",
//               padding: 1,
//             }}
//             onClick={handleClick}
//           >
//             <Typography
//               sx={{
//                 display: "flex",
//                 color: "#97A1B6",
//                 alignItems: "center",
//                 fontWeight: 300,
//                 fontSize: "0.938rem",
//               }}
//             >
//               {isActive ? "Current" : isCompleted ? "Complete" : "In progress"}
//             </Typography>

//             <Typography
//               variant="subtitle1"
//               sx={{
//                 fontWeight: 600,
//                 display: "flex",
//                 alignItems: "center",
//                 color: "#283246",
//               }}
//             >
//               {`${index + 1}. ${step.title}`}
//               {editable && (
//                 <>
//                   {isActive ? (
//                     <img
//                       src={redDot}
//                       style={{
//                         height: "25px",
//                         width: "25px",
//                         marginLeft: "12px",
//                       }}
//                     />
//                   ) : isCompleted ? (
//                     <img
//                       src={completedCheck}
//                       style={{
//                         height: "25px",
//                         width: "25px",
//                         marginLeft: "12px",
//                       }}
//                     />
//                   ) : (
//                     <img
//                       src={graydot}
//                       style={{
//                         height: "25px",
//                         width: "25px",
//                         marginLeft: "12px",
//                       }}
//                     />
//                   )}
//                 </>
//               )}
//             </Typography>

//             <Typography
//               sx={{ color: "#97A1B6", fontWeight: 300, fontSize: "0.938rem" }}
//             >
//               {step.subtitle}
//             </Typography>
//           </Box>
//         </>
//       );
//     })}
//   </Box>
// );

// const AddVehicleForm = ({
//   drawerOpen,
//   setDrawerOpen,
//   editVehicle,
//   setEditVehicle,
//   handleDelete,
// }) => {
//   // State to manage the form fields
//   const { vehicles } = useSelector((state) => state.vehicleManage);
//   const vehicle = vehicles?.data?.find((u) => u.id === editVehicle);
//   const [vehicleDetails, setVehicleDetails] = useState([]);
//   const details = vehicleDetails ? vehicleDetails?.[0] : {};
//   const [vehicleData, setVehicleData] = useState({
//     vehicle_type: "",
//     fleet: "",
//     name: "",
//     qr_code: "",
//     fuel_type: "",
//     driver: "",
//     iot_type: "",
//     iot_name: "",
//     imei: "",
//     vehicle_identifier: "",
//     bluetooth_flow: "No",
//     driver_id: "",
//     id: "",
//   });
//   const [value, setValue] = useState(0);
//   const [completedSteps, setCompletedSteps] = useState([]);
//   const [editable, setEditable] = useState(true);
//   const [vehicleType, setVehicleType] = useState([]);
//   const [errors, setErrors] = useState({});
//   const { driver } = useSelector((state) => state.driverManage);
//   // const { fleet } = useSelector((state) => state.fleetManage);
//   const [fleet, setFleet] = useState([]);
//   const getData = useGetData();
//   const showSnackbar = useSnackbar();
//   const dispatch = useDispatch();
//   const theme = useTheme();

//   const getFleetData = async () => {
//     try {
//       const { data } = await getData.mutateAsync({
//         endpoint: "/fleets",
//         data: { page: "All" },
//       });
//       setFleet(data);
//     } catch (error) {
//     } finally {
//     }
//   };
//   const getVehicleTypeData = async () => {
//     try {
//       // setVehicleLoading(true);
//       const { data } = await getData.mutateAsync({
//         endpoint: "/vehicle-types",
//         data: {},
//       });
//       setVehicleType(data);
//       // setVehicleLoading(false);
//     } catch (error) {
//       // setVehicleLoading(false);
//       // showSnackbar("Failed to load data", "error");
//     } finally {
//       // setVehicleLoading(false);
//     }
//   };

//   const getVehicleDetails = async () => {
//     try {
//       const { data } = await getData.mutateAsync({
//         endpoint: `vehicles/${editVehicle}`,
//         data: {},
//       });
//       setVehicleDetails(data);
//     } catch (error) {
//       showSnackbar("Failed to load data", "error");
//     } finally {
//     }
//   };
//   useEffect(() => {
//     getVehicleTypeData();
//     getFleetData();
//     getVehicleDetails();
//   }, []);
//   console.log(editVehicle, vehicleDetails, "vehicleDetails");
//   useEffect(() => {
//     setVehicleData({
//       vehicle_type: vehicleDetails?.vehicle_type || "",
//       fleet: vehicleDetails?.fleet || "",
//       vehicle_name: vehicleDetails?.name || "",
//       qr_code: vehicleDetails?.qr_code || "",
//       fuel_type: vehicleDetails?.fuel_type || "",
//       driver: vehicleDetails?.driver || "",
//       iot_type: vehicleDetails?.iot_type || "",
//       iot_name: vehicleDetails?.iot_name || "",
//       imei: vehicleDetails?.imei || "",
//       vehicle_identifier: vehicleDetails?.vehicle_identifier || "",
//       bluetooth_flow: vehicleDetails?.bluetooth_flow || "",
//       driver_id: vehicleDetails?.driver_id || "",
//       id: vehicleDetails?.id || "",
//     });
//   }, [vehicleDetails]);

//   useEffect(() => {
//     if (editVehicle) {
//       setEditable(false);
//     }
//   }, [editVehicle]);

//   const toggleDrawer = (open) => (event) => {
//     if (event.type === "keydown") {
//       return;
//     }
//     setDrawerOpen(open);
//     setErrors({});
//     setValue(0);
//     setCompletedSteps([]);
//     setVehicleData({
//       vehicle_type: "",
//       fleet: "",
//       name: "",
//       qr_code: "",
//       fuel_type: "",
//       driver: "",
//       iot_type: "",
//       iot_name: "",
//       imei: "",
//       vehicle_identifier: "",
//       bluetooth_flow: "No",
//       driver_id: "",
//       id: "",
//     });
//     setEditVehicle("");
//     setEditable(true);
//   };

//   const fleetOptions = fleet?.map((fleet) => ({
//     label: fleet?.name,
//     value: fleet?.id,
//   }));

//   const vehicleTypes = vehicleType?.map((e) => ({
//     label: e?.name.charAt(0).toUpperCase() + e?.name.slice(1).toLowerCase(),
//     value: e?.id,
//   }));

//   const driverOptions = driver?.map((driver) => ({
//     label: driver.driver_name,
//     value: driver.driver_name,
//   }));

//   const handleChange = (field, value) => {
//     setVehicleData((prevState) => ({
//       ...prevState,
//       [field]: value,
//     }));

//     // Clear errors on change
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [field]: "",
//     }));
//   };

//   const handleSubmit = () => {
//     const newErrors = {};
//     // Check only required fields
//     requiredFields.forEach((key) => {
//       if (!vehicleData[key]) {
//         newErrors[key] = "This field is required";
//       }
//     });

//     if (Object.keys(newErrors).length > 0) {
//       setErrors(newErrors);
//     } else {
//       dispatch(addVehicle(vehicleData));
//       setDrawerOpen(false);
//       showSnackbar(
//         editVehicle
//           ? "Vehicle edited successfully!"
//           : "Vehicle added successfully!",
//         "success"
//       );
//       setErrors({});
//       setValue(0);
//       setCompletedSteps([]);
//       setVehicleData({
//         vehicle_type: "",
//         fleet: "",
//         name: "",
//         qr_code: "",
//         fuel_type: "",
//         driver: "",
//         iot_type: "",
//         iot_name: "",
//         imei: "",
//         vehicle_identifier: "",
//         bluetooth_flow: "No",
//         driver_id: "",
//         id: "",
//       });
//     }
//   };

//   const handleNext = () => {
//     const requiredFields = [
//       "vehicle_type",
//       "fleet",
//       "vehicle_name",
//       "vehicle_qr",
//       "fuelType",
//       "vehicle_identifier",
//     ];
//     const errors = {};

//     // Validate required fields
//     requiredFields.forEach((key) => {
//       if (typeof vehicleData[key] === "string" && !vehicleData[key].trim()) {
//         errors[key] = "This field is required";
//       } else if (vehicleData[key] == null || vehicleData[key] === "") {
//         errors[key] = "This field is required";
//       }
//     });

//     setErrors(errors);

//     // Proceed only if no errors
//     if (Object.keys(errors).length === 0) {
//       setCompletedSteps((prev) => [...prev, value]);
//       setValue((prev) => prev + 1);
//     } else {
//       console.error("Form errors:", errors);
//     }
//   };

//   const handleEditview = () => {
//     setEditable(!editable);
//   };

//   const handleVehicleDelete = () => {
//     handleDelete(editVehicle);
//   };

//   const handleBack = () => {
//     setCompletedSteps((prev) => prev.slice(0, -1));
//     setValue((prev) => prev - 1);
//   };

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <Drawer
//         anchor="right"
//         open={drawerOpen}
//         onClose={toggleDrawer(false)}
//         transitionDuration={{
//           enter: 500,
//           exit: 500,
//         }}
//         PaperProps={{
//           sx: {
//             width: { lg: "60%", xl: "56%" },
//             boxSizing: "border-box",
//             background: theme.palette.text.quaternary,
//             border: "none",
//             borderRadius: "1rem",
//           },
//         }}
//         sx={{
//           width: { lg: "60%", xl: "56%" },
//         }}
//       >
//         <Box sx={{ display: "flex" }}>
//           <Box
//             sx={{
//               width: "100%",
//               padding: "20px",
//               backgroundColor: "#ffff",
//               // height: '100%'
//             }}
//             role="presentation"
//             onKeyDown={toggleDrawer(false)}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 background: "F8F8F8",
//                 justifyContent: "space-between",
//                 p: 0.5,
//                 alignItems: "center",
//                 mb: 2,
//               }}
//             >
//               <Typography sx={{ fontSize: "1rem", mt: 0.5, fontWeight: 600 }}>
//                 {editVehicle ? vehicleData.vehicle_name : "Add New Vehicle"}
//                 {/* {editVehicle && vehicle?.alerts &&  <Chip
//                 label={vehicle?.alerts}
//                 size="small"
//                 sx={{ fontWeight: 500, fontSize: "0.75rem",ml:1, backgroundColor: '#FE7676',
//                 color: '#fff',
//                 '& .MuiChip-deleteIcon': {
//                   color: '#fff'
//                 }}}
//               /> } */}
//                 {editVehicle && vehicle.alerts && vehicle.alerts.length > 0 && (
//                   <Tooltip
//                     title={vehicle?.alerts?.join(", ")}
//                     arrow
//                     placement="top"
//                     componentsProps={{
//                       tooltip: {
//                         sx: {
//                           backgroundColor: "#424242", // Dark grey background for high contrast
//                           color: "white", // White text for clarity
//                           fontWeight: "600",
//                           fontSize: "14px",
//                           padding: "10px 15px",
//                           borderRadius: "8px",
//                           boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
//                         },
//                       },
//                       arrow: {
//                         sx: {
//                           color: "#424242", // Matching the tooltip background
//                         },
//                       },
//                     }}
//                   >
//                     <Badge
//                       badgeContent={vehicle?.alerts?.length}
//                       color="error"
//                       sx={{ ml: 1, cursor: "pointer" }}
//                     >
//                       <NotificationsIcon color="action" />
//                     </Badge>
//                   </Tooltip>
//                 )}
//               </Typography>

//               <Box sx={{ display: "flex" }}>
//                 {editVehicle && (
//                   <>
//                     <Box
//                       onClick={handleEditview}
//                       sx={{
//                         border: "1px solid #DDDEE1",
//                         borderRadius: "6px",
//                         p: 0.2,
//                         mr: 0.5,
//                         ml: 0.5,
//                         cursor: "pointer",
//                       }}
//                     >
//                       <Tooltip
//                         title="Edit"
//                         placement="top"
//                         arrow
//                         componentsProps={{
//                           tooltip: {
//                             sx: {
//                               backgroundColor: "#424242", // Dark grey background for high contrast
//                               color: "white", // White text for clarity
//                               fontWeight: "600",
//                               fontSize: "14px",
//                               padding: "10px 15px",
//                               borderRadius: "8px",
//                               boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
//                             },
//                           },
//                           arrow: {
//                             sx: {
//                               color: "#424242", // Matching the tooltip background
//                             },
//                           },
//                         }}
//                       >
//                         <IconButton
//                           sx={{
//                             color: theme.palette.text.primary,
//                             width: "25px",
//                             height: "25px",
//                           }}
//                         >
//                           <img
//                             src={actionedit}
//                             alt="edit"
//                             style={{ width: "20px", height: "20px" }}
//                           />
//                         </IconButton>
//                       </Tooltip>
//                     </Box>
//                     <Box
//                       onClick={handleVehicleDelete}
//                       sx={{
//                         border: "1px solid #DDDEE1",
//                         borderRadius: "6px",
//                         p: 0.2,
//                         mr: 0.5,
//                         ml: 0.5,
//                         cursor: "pointer",
//                       }}
//                     >
//                       <Tooltip
//                         title="Delete"
//                         placement="top"
//                         arrow
//                         componentsProps={{
//                           tooltip: {
//                             sx: {
//                               backgroundColor: "#424242", // Dark grey background for high contrast
//                               color: "white", // White text for clarity
//                               fontWeight: "600",
//                               fontSize: "14px",
//                               padding: "10px 15px",
//                               borderRadius: "8px",
//                               boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
//                             },
//                           },
//                           arrow: {
//                             sx: {
//                               color: "#424242", // Matching the tooltip background
//                             },
//                           },
//                         }}
//                       >
//                         <IconButton
//                           sx={{
//                             color: theme.palette.text.primary,
//                             width: "25px",
//                             height: "25px",
//                           }}
//                         >
//                           <img
//                             src={actiondelete}
//                             alt="delete"
//                             style={{ width: "20px", height: "20px" }}
//                           />
//                         </IconButton>
//                       </Tooltip>
//                     </Box>
//                   </>
//                 )}
//                 <IconButton
//                   onClick={toggleDrawer(false)}
//                   sx={{ color: theme.palette.text.primary }}
//                 >
//                   <img src={crossicon} alt="Close" />
//                 </IconButton>
//               </Box>
//             </Box>
//             {editVehicle && vehicle?.alerts_description && (
//               <Typography
//                 sx={{
//                   p: 1, // Padding around the text
//                   bgcolor: "#FE7676", // Background color using the theme's warning color
//                   color: "warning.contrastText", // Text color that contrasts with the background
//                   borderLeft: "6px solid", // Solid left border for emphasis
//                   borderColor: "warning.dark", // Border color darker than the background
//                   borderRadius: 1, // Slightly rounded corners
//                   fontSize: "0.875rem", // Font size
//                   fontWeight: "bold", // Bold font weight
//                   mb: 2, // Margin bottom for spacing below the note
//                   display: "block", // Ensure it's a block element if not already
//                   maxWidth: "100%", // Maximum width to fit its container
//                   boxSizing: "border-box", // Includes padding and border in the element's total width
//                 }}
//               >
//                 {vehicle?.alerts_description}
//               </Typography>
//             )}

//             <Divider />
//             <StepHeader
//               value={value}
//               completedSteps={completedSteps}
//               editable={editable}
//               handleNext={handleNext}
//               handleBack={handleBack}
//             />

//             <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}></Box>
//             {value === 0 && (
//               <>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     // gap: { lg: "1rem", xl: "1.6rem" },
//                     gap: "20px",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", gap: "2rem" }}>
//                     <CustomSelect
//                       label="Vehicle Type"
//                       value={vehicleData.vehicle_type}
//                       onChange={(e) =>
//                         handleChange("vehicle_type", e.target.value)
//                       }
//                       error={errors.vehicle_type}
//                       editable={editable}
//                       options={vehicleTypes}
//                       placeholder="Select vehicle"
//                       name="vehicle_type"
//                     />
//                     <CustomSelect
//                       label="Fleet"
//                       value={vehicleData.fleet}
//                       onChange={(e) => handleChange("fleet", e.target.value)}
//                       error={errors.fleet}
//                       editable={editable}
//                       options={fleetOptions}
//                       name="fleet"
//                       required={false}
//                       placeholder="Select fleet"
//                     />
//                   </Box>

//                   <Box sx={{ display: "flex", gap: "2rem" }}>
//                     <InputFieldWrapper
//                       label="Vehicle Name"
//                       name="email"
//                       value={vehicleData.vehicle_name}
//                       onChange={(e) =>
//                         handleChange("vehicle_name", e.target.value)
//                       }
//                       error={errors.vehicle_name}
//                       editable={editable}
//                       placeholder="Enter vehicle name"
//                     />
//                     <InputFieldWrapper
//                       label="Vehicle QR"
//                       name="vehicle_qr"
//                       value={vehicleData.vehicle_qr}
//                       onChange={(e) =>
//                         handleChange("vehicle_qr", e.target.value)
//                       }
//                       error={errors.vehicle_qr}
//                       editable={editable}
//                       placeholder="Vehicle qr"
//                     />
//                   </Box>
//                   <Box sx={{ display: "flex", gap: "2rem" }}>
//                     <CustomSelect
//                       label="Fuel Type"
//                       value={vehicleData.fuelType}
//                       onChange={(e) => handleChange("fuelType", e.target.value)}
//                       error={errors.fuelType}
//                       editable={editable}
//                       options={fuelTypes}
//                       name="fuelType"
//                     />
//                     <CustomSelect
//                       label="Driver"
//                       value={vehicleData.driver}
//                       onChange={(e) => handleChange("driver", e.target.value)}
//                       error={errors.driver}
//                       editable={editable}
//                       options={driverOptions}
//                       name="driver"
//                       required={false}
//                     />
//                   </Box>
//                   <InputFieldWrapper
//                     label="Vehicle Identifier"
//                     name="vehicle_identifier"
//                     value={vehicleData.vehicle_identifier}
//                     onChange={(e) =>
//                       handleChange("vehicle_identifier", e.target.value)
//                     }
//                     error={errors.vehicle_identifier}
//                     editable={editable}
//                     placeholder="Enter vehicle identifier"
//                   />
//                 </Box>
//                 {/* {editVehicle && (
//                   <>
//                     <Box sx={{ mt: 2.5 }}>
//                       <Typography variant="subtitle1" sx={inputLabel}>
//                         Flags
//                       </Typography>
//                       <Box
//                         sx={{
//                           display: "flex",
//                           flexDirection: "row",
//                           flexWrap: "wrap",
//                           p: 1.5,
//                           alignItems: "center",
//                           border: "1px solid #DDDEE1",
//                           borderRadius: "0.8rem",
//                         }}
//                       >
//                         {[
//                           {
//                             icon: maintenanceicon,
//                             label: "Servicing Due",
//                             color: "error",
//                           },
//                           {
//                             icon: iotflagicon,
//                             label: "IOT Fault",
//                             color: "error",
//                           },
//                         ].map((flag, index) => (
//                           <CustomSwitch
//                             key={index}
//                             icon={flag.icon}
//                             label={flag.label}
//                             color={flag.color}
//                           />
//                         ))}
//                       </Box>
//                     </Box>
//                   </>
//                 )} */}

//                 <Box
//                   sx={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "start",
//                     marginTop: "20px",
//                     mt: {
//                       lg: 8,
//                       xl: 28,
//                     },
//                   }}
//                 >
//                   <FilledButton sx={{ width: "150px" }} onClick={handleNext}>
//                     Next
//                   </FilledButton>
//                 </Box>
//               </>
//             )}

//             {value === 1 && (
//               <>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     // gap: { lg: "1rem", xl: "1.6rem" },
//                     gap: "20px",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", gap: "2rem" }}>
//                     <CustomSelect
//                       label="IOT Type"
//                       value={vehicleData.iot_type}
//                       onChange={(e) => handleChange("iot_type", e.target.value)}
//                       error={errors.iot_type}
//                       editable={editable}
//                       options={iotTypes}
//                       name="iot_type"
//                       required={false}
//                     />
//                     <InputFieldWrapper
//                       label="IOT Name"
//                       name="iot_name"
//                       value={vehicleData.iot_name}
//                       onChange={(e) => handleChange("iot_name", e.target.value)}
//                       error={errors.iot_name}
//                       editable={editable}
//                       placeholder="Enter IoT Name"
//                       required={false}
//                     />
//                   </Box>

//                   <Box sx={{ display: "flex", gap: "2rem" }}>
//                     <InputFieldWrapper
//                       label="IMEI"
//                       name="address"
//                       value={vehicleData.imei}
//                       onChange={(e) => handleChange("imei", e.target.value)}
//                       error={errors.imei}
//                       editable={editable}
//                       placeholder="Enter IMEI"
//                       required={false}
//                     />

//                     <CustomSelect
//                       label="Bluetooth Flow Enabled"
//                       name="bluetooth_flow"
//                       value={vehicleData.bluetooth_flow}
//                       onChange={(e) =>
//                         handleChange("bluetooth_flow", e.target.value)
//                       }
//                       error={errors.bluetooth_flow}
//                       editable={editable}
//                       options={bluetoothOptions}
//                       required={false}
//                     />
//                   </Box>
//                 </Box>

//                 <Box
//                   sx={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "start",
//                     gap: 2,
//                     mt: {
//                       lg: 26,
//                       xl: 53,
//                     },
//                   }}
//                 >
//                   {/* <FilledButton
//                     sx={{
//                       border: `1px solid ${theme.palette.text.secondary}`,
//                       width: "150px",
//                       color: "black",
//                       background: "white",
//                       "&:hover": {
//                         background: "white",
//                         transform: "scale(1.05)",
//                       },
//                     }}
//                     onClick={handleBack}
//                   >
//                     Back
//                   </FilledButton> */}
//                   <FilledButton
//                     sx={{
//                       marginTop: "20px",
//                       width: "150px",
//                       "&.Mui-disabled": {
//                         color: "white",
//                         bgcolor: "grey",
//                       },
//                     }}
//                     onClick={handleSubmit}
//                     disabled={!editable}
//                   >
//                     {editVehicle ? "Save Changes" : "Create"}
//                   </FilledButton>
//                 </Box>
//               </>
//             )}
//           </Box>
//         </Box>
//       </Drawer>
//     </LocalizationProvider>
//   );
// };

// export default AddVehicleForm;
import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  useTheme,
  Divider,
  FormHelperText,
  FormControlLabel,
  Tooltip,
  Chip,
  Badge,
} from "@mui/material";
import crossicon from "../../assets/Icons/drawercross.svg";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import redDot from "../../assets/Icons/reddot.svg";
import completedCheck from "../../assets/Icons/completedCheck.svg";
import graydot from "../../assets/Icons/graydot.svg";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "../../hooks/Snackbar";
import { addVehicle } from "../../slices/vehicleManageSlice";
import serviceicon from "../../assets/Icons/serviceflag.svg";
import maintenanceicon from "../../assets/Icons/maintenanceflag.svg";
import batteryflagicon from "../../assets/Icons/batteryflag.svg";
import iotflagicon from "../../assets/Icons/iotfaultflag.svg";
import IOSSwitch from "../../components/Switch";
import viewvehicle from "../../assets/Icons/viewmode.svg";
import actionedit from "../../assets/Icons/actionedit.svg";
import actiondelete from "../../assets/Icons/actiondelete.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { fleetTableData } from "../Fleet/utils";

const inputLabel = {
  fontSize: "0.8rem",
  fontWeight: 700,
  mb: 1,
  color: "#283246",
};

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    // Apply responsive width based on breakpoints
    width: {
      xs: "100px", // Width for small screens
      sm: "300px", // Width for medium screens
      md: "400px", // Width for large screens
      lg: "300px",
      xl: "400px",
    },
  },
};

const selectStyle = {
  borderRadius: "10px",
  height: "40px",
  width: {
    xs: "100px", // Width for small screens
    sm: "300px", // Width for medium screens
    md: "400px", // Width for large screens
    lg: "300px", // Width for larger screens
    xl: "400px", // Width for extra-large screens
  },
};

// Reusable Component for custom switch with label and icon
const CustomSwitch = ({ icon, label, color }) => (
  <FormControlLabel
    control={<IOSSwitch color={color} sx={{ ml: 1 }} />}
    label={
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img src={icon} alt={label} style={{ marginRight: "8px" }} />
        <Typography sx={{ fontWeight: 500, fontSize: "1rem" }}>
          {label}
        </Typography>
      </Box>
    }
    labelPlacement="start"
    sx={{
      mr: 3,
      mb: 2.5,
      minWidth: "220px",
      justifyContent: "flex-end", // Changed from 'end' to 'flex-start' for better control
    }}
  />
);

const InputFieldWrapper = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  required = true, // Added a new prop to control the asterisk display,
  editable = true, // New prop to control editability
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} {""}
        {required && (
          <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
        )}
      </InputLabel>
      <CustomTextField
        value={value}
        onChange={onChange}
        name={name}
        sx={textFieldStyle}
        error={!!error}
        placeholder={placeholder}
        disabled={!editable}
      />

      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  name,
  placeholder,
  error,
  required = true, // Added a new prop to control the asterisk display
  editable = true, // New prop to control editability
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label}
        {required && (
          <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
        )}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        sx={selectStyle}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Adjust this value to control the height of the visible area
            },
          },
        }}
        name={name}
        error={!!error}
        displayEmpty
        disabled={!editable}
      >
        <MenuItem value="">
          <Typography color="text.primary">{placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

// Options for the select fields
const vehicleTypes = [
  { label: "Cybertruck", value: "Cybertruck" },
  { label: "SportsCar", value: "SportsCar" },
  { label: "Pickup", value: "Pickup" },
  { label: "Minivan", value: "Minivan" },
  { label: "HeavyTruck", value: "HeavyTruck" },
  { label: "Tanker", value: "Tanker" },
  { label: "DumpTruck", value: "DumpTruck" },
  { label: "Flatbed", value: "Flatbed" },
  { label: "Tractor", value: "Tractor" },
];

const fuelTypes = [
  { label: "Diesel", value: "Diesel" },
  { label: "Petrol", value: "Petrol" },
  { label: "Electric", value: "Electric" },
];
const iotTypes = [
  { label: "Axons Micro Pro", value: "Axons Micro Pro" },
  { label: "Axons Micro", value: "Axons Micro" },
];

const bluetoothOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const requiredFields = [
  "vehicle_type",
  "vehicle_name",
  "vehicle_qr",
  "fuelType",
  "vehicle_identifier",
];

const steps = [
  { title: "Set Up Your Vehicle", subtitle: "Enter Basic Details" },
  { title: "Connect IoT Devices", subtitle: "Configure Your IoT Settings" },
];

// Component to render each step header with status and subtitle
const StepHeader = ({
  value,
  completedSteps,
  editable,
  handleNext,
  handleBack,
}) => (
  <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
    {steps.map((step, index) => {
      const isCompleted = completedSteps.includes(index);
      const isActive = index === value;

      const handleClick = () => {
        if (step.title === "Connect IoT Devices" && !isActive) {
          handleNext();
        } else if (step.title === "Set Up Your Vehicle" && !isActive) {
          handleBack();
        }
      };

      return (
        <>
          <Box
            key={step.title}
            sx={{
              flex: 1,
              cursor: "pointer",
              borderBottom:
                editable && isActive
                  ? "4px solid #FE7676"
                  : editable && isCompleted
                  ? "4px solid #52C37F"
                  : "2px solid #ddd",
              padding: 1,
            }}
            onClick={handleClick}
          >
            <Typography
              sx={{
                display: "flex",
                color: "#97A1B6",
                alignItems: "center",
                fontWeight: 300,
                fontSize: "0.938rem",
              }}
            >
              {isActive ? "Current" : isCompleted ? "Complete" : "In progress"}
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                color: "#283246",
              }}
            >
              {`${index + 1}. ${step.title}`}
              {editable && (
                <>
                  {isActive ? (
                    <img
                      src={redDot}
                      style={{
                        height: "25px",
                        width: "25px",
                        marginLeft: "12px",
                      }}
                    />
                  ) : isCompleted ? (
                    <img
                      src={completedCheck}
                      style={{
                        height: "25px",
                        width: "25px",
                        marginLeft: "12px",
                      }}
                    />
                  ) : (
                    <img
                      src={graydot}
                      style={{
                        height: "25px",
                        width: "25px",
                        marginLeft: "12px",
                      }}
                    />
                  )}
                </>
              )}
            </Typography>

            <Typography
              sx={{ color: "#97A1B6", fontWeight: 300, fontSize: "0.938rem" }}
            >
              {step.subtitle}
            </Typography>
          </Box>
        </>
      );
    })}
  </Box>
);

const AddVehicleForm = ({
  drawerOpen,
  setDrawerOpen,
  editVehicle,
  setEditVehicle,
  handleDelete,
}) => {
  // State to manage the form fields
  const { vehicles } = useSelector((state) => state.vehicleManage);

  const vehicle = vehicles.find((u) => u.id === editVehicle);
  const [vehicleData, setVehicleData] = useState({
    vehicle_type: "",
    fleet: "",
    vehicle_name: "",
    vehicle_qr: "",
    fuelType: "",
    driver: "",
    iot_type: "",
    iot_name: "",
    imei: "",
    vehicle_identifier: "",
    bluetooth_flow: "No",
    driver_id: "",
    id: "",
  });
  const [value, setValue] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [editable, setEditable] = useState(true);

  const [errors, setErrors] = useState({});
  const { driver } = useSelector((state) => state.driverManage);
  const { fleet } = useSelector((state) => state.fleetManage);

  const showSnackbar = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    setVehicleData({
      vehicle_type: vehicle?.vehicle_type || "",
      fleet: vehicle?.fleet || "",
      vehicle_name: vehicle?.vehicle_name || "",
      vehicle_qr: vehicle?.vehicle_qr || "",
      fuelType: vehicle?.fuelType || "",
      driver: vehicle?.driver || "",
      iot_type: vehicle?.iot_type || "",
      iot_name: vehicle?.iot_name || "",
      imei: vehicle?.imei || "",
      vehicle_identifier: vehicle?.vehicle_identifier || "",
      bluetooth_flow: vehicle?.bluetooth_flow || "",
      driver_id: vehicle?.driver_id || "",
      id: vehicle?.id || "",
    });
  }, [vehicle]);

  useEffect(() => {
    if (editVehicle) {
      setEditable(false);
    }
  }, [editVehicle]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown") {
      return;
    }
    setDrawerOpen(open);
    setErrors({});
    setValue(0);
    setCompletedSteps([]);
    setVehicleData({
      vehicle_type: "",
      fleet: "",
      vehicle_name: "",
      vehicle_qr: "",
      fuelType: "",
      driver: "",
      iot_type: "",
      iot_name: "",
      imei: "",
      vehicle_identifier: "",
      bluetooth_flow: "No",
      driver_id: "",
      id: "",
    });
    setEditVehicle("");
    setEditable(true);
  };

  const fleetOptions = fleetTableData?.map((fleet) => ({
    label: fleet.fleet_name,
    value: fleet.fleet_name,
  }));

  const driverOptions = driver?.map((driver) => ({
    label: driver.driver_name,
    value: driver.driver_name,
  }));

  const handleChange = (field, value) => {
    setVehicleData((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    // Clear errors on change
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleSubmit = () => {
    const newErrors = {};
    // Check only required fields
    requiredFields.forEach((key) => {
      if (!vehicleData[key]) {
        newErrors[key] = "This field is required";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      dispatch(addVehicle(vehicleData));
      setDrawerOpen(false);
      showSnackbar(
        editVehicle
          ? "Vehicle edited successfully!"
          : "Vehicle added successfully!",
        "success"
      );
      setErrors({});
      setValue(0);
      setCompletedSteps([]);
      setVehicleData({
        vehicle_type: "",
        fleet: "",
        vehicle_name: "",
        vehicle_qr: "",
        fuelType: "",
        driver: "",
        iot_type: "",
        iot_name: "",
        imei: "",
        vehicle_identifier: "",
        bluetooth_flow: "No",
        driver_id: "",
        id: "",
      });
    }
  };

  const handleNext = () => {
    const requiredFields = [
      "vehicle_type",
      "vehicle_name",
      "vehicle_qr",
      "fuelType",
      "vehicle_identifier",
    ];
    const errors = {};

    // Validate required fields
    requiredFields.forEach((key) => {
      if (typeof vehicleData[key] === "string" && !vehicleData[key].trim()) {
        errors[key] = "This field is required";
      } else if (vehicleData[key] == null || vehicleData[key] === "") {
        errors[key] = "This field is required";
      }
    });

    setErrors(errors);

    // Proceed only if no errors
    if (Object.keys(errors).length === 0) {
      setCompletedSteps((prev) => [...prev, value]);
      setValue((prev) => prev + 1);
    } else {
      console.error("Form errors:", errors);
    }
  };

  const handleEditview = () => {
    setEditable(!editable);
    console.log("handleedit-view");
  };

  const handleVehicleDelete = () => {
    handleDelete(editVehicle);
  };

  const handleBack = () => {
    setCompletedSteps((prev) => prev.slice(0, -1));
    setValue((prev) => prev - 1);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        transitionDuration={{
          enter: 500,
          exit: 500,
        }}
        PaperProps={{
          sx: {
            width: { lg: "60%", xl: "56%" },
            boxSizing: "border-box",
            background: theme.palette.text.quaternary,
            border: "none",
            borderRadius: "1rem",
          },
        }}
        sx={{
          width: { lg: "60%", xl: "56%" },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: "100%",
              padding: "20px",
              backgroundColor: "#ffff",
              // height: '100%'
            }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
          >
            <Box
              sx={{
                display: "flex",
                background: "F8F8F8",
                justifyContent: "space-between",
                p: 0.5,
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography sx={{ fontSize: "1rem", mt: 0.5, fontWeight: 600 }}>
                {editVehicle ? vehicleData.vehicle_name : "Add New Vehicle"}
                {/* {editVehicle && vehicle?.alerts &&  <Chip
                label={vehicle?.alerts}
                size="small"
                sx={{ fontWeight: 500, fontSize: "0.75rem",ml:1, backgroundColor: '#FE7676',
                color: '#fff', 
                '& .MuiChip-deleteIcon': { 
                  color: '#fff'
                }}}
              /> } */}
                {editVehicle && vehicle.alerts && vehicle.alerts.length > 0 && (
                  <Tooltip
                    title={vehicle?.alerts?.join(", ")}
                    arrow
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "#424242", // Dark grey background for high contrast
                          color: "white", // White text for clarity
                          fontWeight: "600",
                          fontSize: "14px",
                          padding: "10px 15px",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                        },
                      },
                      arrow: {
                        sx: {
                          color: "#424242", // Matching the tooltip background
                        },
                      },
                    }}
                  >
                    <Badge
                      badgeContent={vehicle?.alerts?.length}
                      color="error"
                      sx={{ ml: 1, cursor: "pointer" }}
                    >
                      <NotificationsIcon color="action" />
                    </Badge>
                  </Tooltip>
                )}
              </Typography>

              <Box sx={{ display: "flex" }}>
                {editVehicle && (
                  <>
                    <Box
                      onClick={handleEditview}
                      sx={{
                        border: "1px solid #DDDEE1",
                        borderRadius: "6px",
                        p: 0.2,
                        mr: 0.5,
                        ml: 0.5,
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip
                        title="Edit"
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#424242", // Dark grey background for high contrast
                              color: "white", // White text for clarity
                              fontWeight: "600",
                              fontSize: "14px",
                              padding: "10px 15px",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                            },
                          },
                          arrow: {
                            sx: {
                              color: "#424242", // Matching the tooltip background
                            },
                          },
                        }}
                      >
                        <IconButton
                          sx={{
                            color: theme.palette.text.primary,
                            width: "25px",
                            height: "25px",
                          }}
                        >
                          <img
                            src={actionedit}
                            alt="edit"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      onClick={handleVehicleDelete}
                      sx={{
                        border: "1px solid #DDDEE1",
                        borderRadius: "6px",
                        p: 0.2,
                        mr: 0.5,
                        ml: 0.5,
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip
                        title="Delete"
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#424242", // Dark grey background for high contrast
                              color: "white", // White text for clarity
                              fontWeight: "600",
                              fontSize: "14px",
                              padding: "10px 15px",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                            },
                          },
                          arrow: {
                            sx: {
                              color: "#424242", // Matching the tooltip background
                            },
                          },
                        }}
                      >
                        <IconButton
                          sx={{
                            color: theme.palette.text.primary,
                            width: "25px",
                            height: "25px",
                          }}
                        >
                          <img
                            src={actiondelete}
                            alt="delete"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </>
                )}
                <IconButton
                  onClick={toggleDrawer(false)}
                  sx={{ color: theme.palette.text.primary }}
                >
                  <img src={crossicon} alt="Close" />
                </IconButton>
              </Box>
            </Box>
            {editVehicle && vehicle?.alerts_description && (
              <Typography
                sx={{
                  p: 1, // Padding around the text
                  bgcolor: "#FE7676", // Background color using the theme's warning color
                  color: "warning.contrastText", // Text color that contrasts with the background
                  borderLeft: "6px solid", // Solid left border for emphasis
                  borderColor: "warning.dark", // Border color darker than the background
                  borderRadius: 1, // Slightly rounded corners
                  fontSize: "0.875rem", // Font size
                  fontWeight: "bold", // Bold font weight
                  mb: 2, // Margin bottom for spacing below the note
                  display: "block", // Ensure it's a block element if not already
                  maxWidth: "100%", // Maximum width to fit its container
                  boxSizing: "border-box", // Includes padding and border in the element's total width
                }}
              >
                {vehicle?.alerts_description}
              </Typography>
            )}

            <Divider />
            <StepHeader
              value={value}
              completedSteps={completedSteps}
              editable={editable}
              handleNext={handleNext}
              handleBack={handleBack}
            />

            <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}></Box>
            {value === 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { lg: "1rem", xl: "1.6rem" },
                  }}
                >
                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <CustomSelect
                      label="Vehicle Type"
                      value={vehicleData.vehicle_type}
                      onChange={(e) =>
                        handleChange("vehicle_type", e.target.value)
                      }
                      error={errors.vehicle_type}
                      editable={editable}
                      options={vehicleTypes}
                      placeholder="Select vehicle"
                      name="vehicle_type"
                    />
                    <CustomSelect
                      label="Fleet"
                      value={vehicleData.fleet}
                      onChange={(e) => handleChange("fleet", e.target.value)}
                      error={errors.fleet}
                      editable={editable}
                      options={fleetOptions}
                      name="fleet"
                      required={false}
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <InputFieldWrapper
                      label="Vehicle Name"
                      name="email"
                      value={vehicleData.vehicle_name}
                      onChange={(e) =>
                        handleChange("vehicle_name", e.target.value)
                      }
                      error={errors.vehicle_name}
                      editable={editable}
                      placeholder="Enter vehicle name"
                    />
                    <InputFieldWrapper
                      label="Vehicle QR"
                      name="vehicle_qr"
                      value={vehicleData.vehicle_qr}
                      onChange={(e) =>
                        handleChange("vehicle_qr", e.target.value)
                      }
                      error={errors.vehicle_qr}
                      editable={editable}
                      placeholder="Vehicle qr"
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <CustomSelect
                      label="Fuel Type"
                      value={vehicleData.fuelType}
                      onChange={(e) => handleChange("fuelType", e.target.value)}
                      error={errors.fuelType}
                      editable={editable}
                      options={fuelTypes}
                      name="fuelType"
                    />
                    <CustomSelect
                      label="Driver"
                      value={vehicleData.driver}
                      onChange={(e) => handleChange("driver", e.target.value)}
                      error={errors.driver}
                      editable={editable}
                      options={driverOptions}
                      name="driver"
                      required={false}
                    />
                  </Box>
                  <InputFieldWrapper
                    label="Vehicle Identifier"
                    name="vehicle_identifier"
                    value={vehicleData.vehicle_identifier}
                    onChange={(e) =>
                      handleChange("vehicle_identifier", e.target.value)
                    }
                    error={errors.vehicle_identifier}
                    editable={editable}
                    placeholder="Enter vehicle identifier"
                  />
                </Box>
                {/* {editVehicle && (
                  <>
                    <Box sx={{ mt: 2.5 }}>
                      <Typography variant="subtitle1" sx={inputLabel}>
                        Flags
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          p: 1.5,
                          alignItems: "center",
                          border: "1px solid #DDDEE1",
                          borderRadius: "0.8rem",
                        }}
                      >
                        {[
                          {
                            icon: maintenanceicon,
                            label: "Servicing Due",
                            color: "error",
                          },
                          {
                            icon: iotflagicon,
                            label: "IOT Fault",
                            color: "error",
                          },
                        ].map((flag, index) => (
                          <CustomSwitch
                            key={index}
                            icon={flag.icon}
                            label={flag.label}
                            color={flag.color}
                          />
                        ))}
                      </Box>
                    </Box>
                  </>
                )} */}

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    mt: {
                      lg: 8,
                      xl: 28,
                    },
                  }}
                >
                  <FilledButton sx={{ width: "150px" }} onClick={handleNext}>
                    Next
                  </FilledButton>
                </Box>
              </>
            )}

            {value === 1 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { lg: "1rem", xl: "1.6rem" },
                  }}
                >
                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <CustomSelect
                      label="IOT Type"
                      value={vehicleData.iot_type}
                      onChange={(e) => handleChange("iot_type", e.target.value)}
                      error={errors.iot_type}
                      editable={editable}
                      options={iotTypes}
                      name="iot_type"
                      required={false}
                    />
                    <InputFieldWrapper
                      label="IOT Name"
                      name="iot_name"
                      value={vehicleData.iot_name}
                      onChange={(e) => handleChange("iot_name", e.target.value)}
                      error={errors.iot_name}
                      editable={editable}
                      placeholder="Enter IoT Name"
                      required={false}
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <InputFieldWrapper
                      label="IMEI"
                      name="address"
                      value={vehicleData.imei}
                      onChange={(e) => handleChange("imei", e.target.value)}
                      error={errors.imei}
                      editable={editable}
                      placeholder="Enter IMEI"
                      required={false}
                    />

                    <CustomSelect
                      label="Bluetooth Flow Enabled"
                      name="bluetooth_flow"
                      value={vehicleData.bluetooth_flow}
                      onChange={(e) =>
                        handleChange("bluetooth_flow", e.target.value)
                      }
                      error={errors.bluetooth_flow}
                      editable={editable}
                      options={bluetoothOptions}
                      required={false}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    gap: 2,
                    mt: {
                      lg: 26,
                      xl: 53,
                    },
                  }}
                >
                  {/* <FilledButton
                    sx={{
                      border: `1px solid ${theme.palette.text.secondary}`,
                      width: "150px",
                      color: "black",
                      background: "white",
                      "&:hover": {
                        background: "white",
                        transform: "scale(1.05)",
                      },
                    }}
                    onClick={handleBack}
                  >
                    Back
                  </FilledButton> */}
                  <FilledButton
                    sx={{
                      width: "150px",
                      "&.Mui-disabled": {
                        color: "white",
                        bgcolor: "grey",
                      },
                    }}
                    onClick={handleSubmit}
                    disabled={!editable}
                  >
                    {editVehicle ? "Save Changes" : "Create"}
                  </FilledButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Drawer>
    </LocalizationProvider>
  );
};

export default AddVehicleForm;
