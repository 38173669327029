import React from 'react';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { Box, InputLabel, TextField } from '@mui/material';
import dayjs from "dayjs";



const inputLabel = {
  fontSize: "0.8rem",
  fontWeight: 700,
  mb: 0.5,
  color: "#283246"
}

const ReusableCalendar = ({ startDate, setStartDate, endDate, setEndDate, includeTime }) => {
  // Render either a DateTimePicker or a DatePicker based on includeTime
  const DatePickerComponent = includeTime ? DateTimePicker : DatePicker;
  

  return (
    <Box sx={{mt:2,display:'flex',gap:'2rem',mb:2}}>
      <Box>
       <InputLabel sx={inputLabel}>
       Start Date
        </InputLabel>
        <DatePickerComponent
  value={startDate}
  onChange={newValue => setStartDate(newValue)}
  minDateTime={dayjs()}
  renderInput={(params) => (
    <TextField
      {...params}
      fullWidth
      margin="normal"
      InputProps={{
        ...params.InputProps,
        sx: {
          borderRadius: '10px',
          bgcolor: 'background.paper',
          '&:hover': {
            bgcolor: 'background.default',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.light',
          },
        }
      }}
    />
  )}
  sx={{
    width: '250px',
    height: '45px',
    "& .MuiInputBase-root": {
      height: "100%"
    },
  }}
  slotProps={{
    textField: {
      InputProps: {
        sx: {
          borderRadius: '10px',
        },
      },
    },
  }}
/>


      </Box>

      <Box>
      <InputLabel sx={inputLabel}>
       End Date
        </InputLabel>
        <DatePickerComponent
        value={endDate}
        onChange={newValue => setEndDate(newValue)}
        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
        sx={{
          width: '250px',  // Adjust width as needed
          height: '45px',  // Adjust height as needed
          "& .MuiInputBase-root": {
            height: "100%"
          }
        }}

        slotProps={{
          textField: {
            InputProps: {
              sx: { borderRadius: '10px'}, 
            },
          },
        }}
      />
      </Box>
    </Box>
  );
};

export default ReusableCalendar;
