import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useTheme } from '@emotion/react';

const CustomSelect = styled(FormControl)(({ theme, width }) => ({
  borderRadius: '6px',
  minWidth: width || '105px',
  height: '35px',
  background: 'transparent',
  marginRight: '2px',
  marginLeft: '2px',
  display: 'flex',
  alignItems: 'center', // Center content vertically
  justifyContent: 'center',
  '.MuiOutlinedInput-root': {
    padding: '0',
    '& fieldset': {
      border: 'none',
    },
  },
  '.MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0', // Ensure no additional padding
  },
  '.MuiSelect-icon': {
    right: '8px', // Adjust icon positioning to keep it inside the box
  },
}));

const GeofenceSelect = ({ label, options, icon, sx = {}, width = '105px', onChange }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const theme = useTheme()

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onChange(newValue);
  };

  return (
    <Box sx={sx}>
      <CustomSelect width={width}>
      <Select
  value={selectedValue}
  onChange={handleChange}
  displayEmpty
  inputProps={{ 'aria-label': label }}
  IconComponent={ArrowDropDownIcon} // Custom dropdown arrow
  startAdornment={
    <InputAdornment position="start">
      <IconButton>{icon}</IconButton> {/* Custom icon */}
    </InputAdornment>
  }
  renderValue={(value) =>
    value === '' ? (
      <Typography color={theme.palette.text.primary}>{label}</Typography> // Display label when nothing is selected
    ) : (
      value
    )
  }
>
  <MenuItem value="">
    <Typography color={theme.palette.text.secondary}>None</Typography> {/* Deselect option */}
  </MenuItem>
  {options.map((option) => (
    <MenuItem key={option.value} value={option.label}>
      <Box display="flex" alignItems="center">
        <IconButton>
            <img src={option.icon}/>
            </IconButton> {/* Display option icon */}
        <Typography>{option.label}</Typography>
      </Box>
    </MenuItem>
  ))}
</Select>

      </CustomSelect>
    </Box>
  );
};

export default GeofenceSelect;
