// src/slices/geofenceParkingManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { driverTableData } from "../features/Drivers/utils";
import { v4 as uuidv4 } from 'uuid'; // Assuming you're using uuid to generate unique IDs
import { parkingGeofenceTableData } from "../features/Geofencing/utils";


const initialState = {
  parkingGeofence: parkingGeofenceTableData,
};

const geofenceParkingManageSlice = createSlice({
  name: "parkingGeofence",
  initialState,
  reducers: {
    updatedParking: (state, action) => {
      state.parkingGeofence = action.payload;
    },
    addParking: (state, action) => {
      console.log(state.parkingGeofence,'state-parkingGeofence')
      const newDriver = { ...action.payload, id: `usr_${uuidv4()}` };
      state.parkingGeofence.push(newDriver);
    },
    deleteParking: (state, action) => {
      const userId = action.payload;
      // Find the user in the activeUser array
      const userToDelete = state.activeUser.find(user => user.id === userId);

      if (userToDelete) {
        // Remove the user from the activeUser array
        state.activeUser = state.activeUser.filter(user => user.id !== userId);
        
        // Add the user to the deletedUser array with static fields
        state.deletedUser.push({
          ...userToDelete,
          deleted_by: "Admin1",  // Static value
          deleted_date: "Jan 3rd, 2024 10:25:00"  // Static value
        });
      }
    },
  },
});

export const { updatedParking,deleteParking,addParking} = geofenceParkingManageSlice.actions;
export default geofenceParkingManageSlice.reducer;
