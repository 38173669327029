import React, { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Pagination,
  Rating,
  useTheme,
} from "@mui/material";
import ShowIcon from "../../assets/Icons/show.svg";
import NoResultsFound from "../NoResultFound";

// Reusable Table Component
const TripsTableComponent = ({
  tableHeaders,
  data, 
  showActions = false, 
  handleEdit = null, 
  handleDelete = null 
}) => {

  const [page, setPage] = useState(1); // State for current page
  const itemsPerPage = 10; // Set items per page
  const theme = useTheme()



  const filteredData = useMemo(() => {
    return data?.filter((item) => item); // Adjust the filter logic if needed
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const totalColumns = tableHeaders.length + (showActions ? 2 : 0); // Calculate total columns


  return (
    <>
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table>
        <TableHead sx={{ background: '#DDDEE1'}}>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            {tableHeaders?.map((header, index) => (
              <TableCell
                key={index}
                sx={{
                  fontWeight: 600,
                  fontSize: "0.938rem",
                  color: "#283246B2",
                }}
              >
                {header.label}
              </TableCell>
            ))}
            {showActions && <TableCell sx={{ fontWeight: 500, fontSize: "0.938rem" }}>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData?.length > 0 ? (
            filteredData.map((item, index) => (
              <TableRow key={item.id || index}>
                <TableCell padding="checkbox"></TableCell>
                {tableHeaders?.map((header, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      color:theme.palette.text.primary
                    }}
                  >
                    {header.label === "Rating" ? (
                      // Use MUI Rating component for rating field
                      <Rating
                      name={`rating-${index}`}
                      value={parseFloat(item[header.key]) || 0}
                      precision={0.5}
                      readOnly
                      sx={{
                        "& .MuiRating-iconFilled": {
                          color: theme.palette.text.tertiary, // Color for filled stars (e.g., gold)
                        },
                        "& .MuiRating-iconEmpty": {
                          color: "#C0C0C0", // Color for empty stars (e.g., silver)
                        },
                      }}
                    />
                    
                    ) : (
                      <Typography
                      sx={{ fontWeight: 500, fontSize: "0.875rem", color: "#283246" }}
                    >
                       {item[header.key] || "N/A"}
                      </Typography>
                    )}
                  </TableCell>
                ))}
                {showActions && (
                  <TableCell>
                    <Box
                       sx={{
                        display: "flex",
                         width: "auto"
                      }}
                    >
                      {handleEdit && (
                        <Box
                          onClick={() => handleEdit(item)}
                          sx={{
                            background: theme.palette.text.primary,
                            width: "1rem",
                            height: "1rem",
                            padding: "0.438rem",
                            borderRadius: "0.313rem",
                            cursor: "pointer",
                            marginRight: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={ShowIcon} alt="edit-icon" style={{ width: '25px', height: '25px' }} />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                )}
              </TableRow>

              
            ))
          ) : (
            <TableRow>
            <TableCell
              colSpan={totalColumns}
              sx={{ fontWeight: 500, mb: { xs: 1, sm: 2 },fontSize: { xs: "1rem", sm: "1rem" }, color: "#283246", textAlign: 'center' }}
            >
              <NoResultsFound />
            </TableCell>
          </TableRow>
            // <Typography>No data available</Typography>
          )}
        </TableBody>
      </Table>
    </TableContainer>
      {/* Pagination Component */}
      {/* <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
  <Pagination
    count={Math.ceil(data.length / itemsPerPage)}
    page={page}
    onChange={handleChangePage}
   color='primary'
  />
</Box> */}

      </>
  );
};

export default TripsTableComponent;
