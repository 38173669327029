import { Box, Paper, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilledButton from "../../components/MainButtons/FilledButton";
import SearchTextField from "../../components/SearchTextField";
import plus from "../../assets/Icons/plus.svg";
import _ from "lodash";
import UserTableComponent from "../UserManagement/UserTable";
import { fleetTableCellItem } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import fleetinfo from "../../assets/Icons/fleetinfo.svg";
import AddFleetForm from "./AddFleetForm";
import DeleteUserModal from "../../components/DeleteModal";
import { deleteFleet, setFleetData } from "../../slices/fleetManageSlice";
import { useSnackbar } from "../../hooks/Snackbar";
import DatePickerDemo from "../../components/ReusableDatePicker/Date";
import apiInstance from "../../api/axios";
import { useDeleteData, useGetData } from "../../hooks/useApi";

export const FleetManagment = () => {
  const [searchInput, setSearchInput] = useState("");
  const [createFleet, setCreateFleet] = useState(false);
  const [editUser, setEditUser] = useState("");
  const [userDelete, setUserDelete] = useState(false);
  const [fleetDelete, setFleetDelete] = useState("");
  const [userToDelete, setUserToDelete] = useState("");
  const [fleetLoading, setFleetLoading] = useState(false);
  const [fleet, setFleet] = useState({});
  const [page, setPage] = useState();
  const deleteFleetData = useDeleteData();
  const getData = useGetData();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const getFleetData = async (search = "") => {
    try {
      setFleetLoading(true);
      const { data } = await getData.mutateAsync({
        endpoint: "/fleets",
        data: { search: search, page: page + 1 },
      });
      setFleet(data);
      dispatch(setFleetData(data));
      setFleetLoading(false);
    } catch (error) {
      setFleetLoading(false);
      showSnackbar("Failed to load data", "error");
    } finally {
      setFleetLoading(false);
    }
  };

  useEffect(() => {
    getFleetData();
  }, [page]);

  const handApiCall = () => {
    getFleetData();
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleCreateFleet = () => {
    setCreateFleet(true);
  };

  const handleDeleteFleetClose = () => {
    setUserDelete(false);
  };

  const handleEditUser = (userId) => {
    console.log(userId, "useidd");
    setCreateFleet(true);
    setEditUser(userId);
  };

  const deleteFleet = async (fleetToDelete) => {
    try {
      const { data } = await deleteFleetData.mutateAsync({
        endpoint: `/fleets/${fleetToDelete}`,
      });

      if (data) {
        showSnackbar("Fleet deleted successfully!", "success");
      }
      getFleetData();
    } catch (error) {
      // if (error?.response?.status === 401) {
      //   showSnackbar(
      //     "Oops! Something went wrong, and you've been logged out.",
      //     "error"
      //   );
      //   dispatch(logout());
      //   return;
      // }
      showSnackbar(
        error?.response?.data?.message ||
          "Failed to delete asset please try again",
        "error"
      );
    } finally {
      // setIsLoadingData(false);
    }
  };

  const handleDeleteUser = (id) => {
    deleteFleet(id);
    // const fleetToDelete = fleet.find((fleet) => fleet.id === userId);
    // setUserDelete(true);
    setUserToDelete(id);
    // setFleetDelete(fleetToDelete.fleet_name);
  };

  const handleConfirmDeleteFleet = () => {
    setUserDelete(false);
    dispatch(deleteFleet(userToDelete));
    deleteFleet(userToDelete);
    setCreateFleet(false);
    showSnackbar("Fleet deleted successfully!", "success");
  };

  const debouncedFetchData = useCallback(
    _.debounce((payload) => getFleetData(payload), 1000),
    []
  );

  useEffect(() => {
    if (searchInput) {
      debouncedFetchData(searchInput);
    } else {
      getFleetData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
    // eslint-disable-next-line
  }, [searchInput]);

  // const filteredFleets = searchInput
  //   ? fleet
  //       .filter((user) => {
  //         const matchesName = user?.fleet_name
  //           ?.toLowerCase()
  //           .includes(searchInput?.toLowerCase() || "");
  //         return matchesName;
  //       })
  //       .sort((a, b) => a.fleet_name.localeCompare(b.fleet_name))
  //   : fleet; // Return full list if both search input and flag are empty

  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#FFFFFF",
          padding: "1.563rem",
          boxShadow: "none",
          minHeight: "90vh",
        }}
      >
        {/* <DatePickerDemo/> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            borderRadius: "15px 100px 0 6px",
          }}
        >
          {/* <Avatar src={fleetinfo} sx={{height:'22px',width:'22px'}}/> */}
          <img
            src={fleetinfo}
            style={{
              height: "22px",
              width: "22px",
              marginRight: "10px", // Adjust the gap here
            }}
            alt="fleetinfo"
          />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1.2rem", // Adjust font size as needed
              color: theme.palette.text.primary, // White text color
            }}
          >
            Fleet Information
          </Typography>
        </Box>
        <Box
          sx={{
            // border: "1px solid #DDDEE1",
            height: "42px",
            justifyContent: "space-between",
            p: 1,
            background: "#FFFFFF",
            display: "flex",
            borderRadius: "6px",
            mb: 5,
          }}
        >
          <Box>
            <SearchTextField
              value={searchInput}
              onChange={handleSearchChange}
            />
          </Box>
          <Box onClick={handleCreateFleet}>
            <FilledButton
              startIcon={
                <img
                  src={plus}
                  style={{ width: "18px", height: "18px" }}
                  alt="plus"
                />
              }
              sx={{
                width: "130px",
                height: "35px",
                borderRadius: "6px",
                ml: 3,
              }}
            >
              Add Fleet
            </FilledButton>
          </Box>
        </Box>
        <AddFleetForm
          drawerOpen={createFleet}
          setDrawerOpen={setCreateFleet}
          editUser={editUser}
          setEditUser={setEditUser}
          handleDelete={handleDeleteUser}
          handApiCall={handApiCall}
        />

        <UserTableComponent
          data={fleet}
          tableHeaders={fleetTableCellItem}
          showActions={true}
          handleEdit={handleEditUser}
          handleDelete={handleDeleteUser}
          handleRowClick={handleEditUser}
          tablePage={setPage}
          loading={fleetLoading}
          isFleet={true}
        />

        <DeleteUserModal
          open={userDelete}
          handleClose={handleDeleteFleetClose}
          handleDeleteUser={handleConfirmDeleteFleet}
          header={"Confirm Fleet Deletion"}
          title={
            <span>
              Are you sure you want to permanently delete the{" "}
              <strong>{fleetDelete}</strong>? This action cannot be undone and
              will remove all related data.
            </span>
          }
          confirmText={"Yes, Delete"}
        />
      </Paper>
    </>
  );
};
