// src/slices/workorderManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid'; // Assuming you're using uuid to generate unique IDs
import { issueTypeTableData, workOrderTableData } from "../features/WorkOrders/utils";


const initialState = {
  ticketList: workOrderTableData,
  issueTypeList:issueTypeTableData
};

function getFormattedDate() {
  const options = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit', 
    hour12: false 
  };
  return new Date().toLocaleString('en-US', options).replace(',', '');
}

const workorderManageSlice = createSlice({
  name: "workorder",
  initialState,
  reducers: {
    addTicket: (state, action) => {
      const issueIndex = state.ticketList.findIndex(ticket => ticket.id === action.payload.id);
  
      if (issueIndex !== -1) {
          // Fleet exists, so update it
          state.ticketList[issueIndex] = {
              ...state.ticketList[issueIndex],
              ...action.payload,
          };
      } else {
          // Fleet does not exist, add new
          const newTicket = {
              ...action.payload,
              id: action.payload.id || `usr_${uuidv4()}`, // Use provided id or generate a new one
              status:"Open",
              created_by: "Shubham Patel",
              created_date: getFormattedDate(),
              assigned_to:'N/A'
          };
          state.ticketList.push(newTicket);
      }
  },
  deleteTicket: (state, action) => {
    const userId = action.payload;
    // Find the user in the ticketList array
    const userToDelete = state.ticketList.find(ticket => ticket.id === userId);

    if (userToDelete) {
      // Remove the user from the ticketList array
      state.ticketList = state.ticketList.filter(ticket => ticket.id !== userId);
    }
  },
    addIssue: (state, action) => {
      const issueIndex = state.issueTypeList.findIndex(issuetype => issuetype.id === action.payload.id);
  
      if (issueIndex !== -1) {
          // Fleet exists, so update it
          state.issueTypeList[issueIndex] = {
              ...state.issueTypeList[issueIndex],
              ...action.payload,
          };
      } else {
          // Fleet does not exist, add new
          const newIssueType = {
              ...action.payload,
              id: action.payload.id || `usr_${uuidv4()}`, // Use provided id or generate a new one
              status:"Active"
          };
          state.issueTypeList.push(newIssueType);
      }
  },
  deleteIssue: (state, action) => {
    const userId = action.payload;
    // Find the user in the issueTypeList array
    const userToDelete = state.issueTypeList.find(user => user.id === userId);

    if (userToDelete) {
      // Remove the user from the issueTypeList array
      state.issueTypeList = state.issueTypeList.filter(user => user.id !== userId);
    }
  },
  },
});

export const {addTicket,addIssue,deleteIssue,deleteTicket } = workorderManageSlice.actions;
export default workorderManageSlice.reducer;
