import React from 'react';
import { ToggleButton, ToggleButtonGroup, Box, Typography } from '@mui/material';

function FeatureToggle({ features, onChange }) {
  const [selected, setSelected] = React.useState(() => new Set());

  const handleFeatureChange = (event, feature) => {
    const newSet = new Set(selected);
    if (newSet.has(feature)) {
      newSet.delete(feature);
    } else {
      newSet.add(feature);
    }
    setSelected(newSet);
    onChange(newSet);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {features.map((feature) => (
        <ToggleButtonGroup key={feature.key} exclusive fullWidth>
          <ToggleButton
            value="check"
            selected={selected.has(feature.key)}
            onChange={() => handleFeatureChange(null, feature.key)}
            sx={{
              justifyContent: 'flex-start',
              textTransform: 'none',
              color: 'black',
              border: '1px solid #ccc',
              backgroundColor: selected.has(feature.key) ? '#f99494' : '#fff',
              '&:hover': {
                backgroundColor: '#f99494',
              },
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{feature.label}</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      ))}
    </Box>
  );
}

export default FeatureToggle;
