import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useTheme } from "@emotion/react";

// Styled FormControl for the custom select component
const CustomSelect = styled(FormControl)(({ theme, width }) => ({
  borderRadius: "6px",
  minWidth: width || "105px",
  height: "38px",
  background: "#FFFFFF",
  marginRight: "2px",
  marginLeft: "2px",
  border: "1px solid #DDDEE1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ".MuiOutlinedInput-root": {
    padding: "0",
    "& fieldset": {
      border: "none",
    },
  },
  ".MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    paddingLeft: "0",
  },
  ".MuiSelect-icon": {
    right: "8px",
  },
}));

const VehicleSelectFleet = ({
  label,
  options, // Options should be an array of objects with `label`, `lat`, and `lng`
  icon, // Icon for the select dropdown
  sx = {}, // Additional styles
  width = "105px",
  onChange, // Function to handle the change of selection
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const theme = useTheme();

  // Handle the change of the fleet selection
  const handleChange = (event) => {
    const value = event.target.value;

    if (value.includes("selectAll")) {
      // Toggle select all functionality
      if (selectedValues.length === options.length) {
        setSelectedValues([]);
        onChange([]); // Pass an empty array if deselecting all
      } else {
        setSelectedValues(options.map((option) => option.label));
        onChange(options); // Pass all fleet objects when 'Select All' is selected
      }
    } else {
      const selectedFleets = options.filter((option) =>
        value.includes(option.label)
      );
      setSelectedValues(value);
      onChange(selectedFleets); // Pass the selected fleet objects, including lat and lng
    }
  };

  // Render the selected value in the dropdown
  const renderSelectedValue = (selected) => {
    if (selected.length === 0) return label;
    if (selected.length === 1) return selected[0];
    return `${selected[0]}, ${selected.length - 1} other${
      selected.length > 2 ? "s" : ""
    }`;
  };

  return (
    <Box sx={sx}>
      <CustomSelect width={width}>
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": label }}
          IconComponent={ArrowDropDownIcon}
          startAdornment={
            <InputAdornment position="start">
              <IconButton>{icon}</IconButton>
            </InputAdornment>
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Limit the height of the dropdown menu
              },
            },
          }}
          renderValue={(selected) => (
            <Typography color={theme.palette.text.primary}>
              {renderSelectedValue(selected)}
            </Typography>
          )}
        >
          <MenuItem key="selectAll" value="selectAll">
            <Checkbox
              checked={selectedValues.length === options.length}
              sx={{
                "&.Mui-checked": {
                  color: "#283246",
                },
              }}
            />
            <Typography>Select All</Typography>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.label}>
              <Checkbox
                checked={selectedValues.includes(option.label)}
                sx={{
                  "&.Mui-checked": {
                    color: "#283246",
                  },
                }}
              />
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </CustomSelect>
    </Box>
  );
};

export default VehicleSelectFleet;
