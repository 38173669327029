import { Box, Typography } from "@mui/material";
import Star from "../../../../assets/Icons/Star.svg"
import Chart from "react-apexcharts"
import React, { useState, useEffect } from 'react';


const TripsRatingsSemiPieChart = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(null)
    const TripsRatingsData = [
        {
            type: "Assigned",
            count: 47
        },
        {
            type: "Total Vehicles",
            count: 60
        },
        {
            type: "Unassigned",
            count: 13
        },
     
    ]
    const series = [50, 50];
    const options = {
        chart: {
            type: 'donut',
        },
        labels: ['Assigned Vehicles', 'Unassigned Vehicles'],
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
            },
        },
        dataLabels: {
            enabled: false, // Disable data labels
        },
        stroke: {
            show: true,
            width: 20, // Adjust the width of the border around the slices
            colors: '#F8F8F8',
            curve: 'smooth',
        },
        legend: {
            show: false,
        },
        annotations: {
            position: 'front',
            texts: [
                {
                    text: '60%',
                    x: "50%", // Centering on the x-axis
                    y: "50%", // Centering on the y-axis
                    fontSize: '30px',
                    fontWeight: 'bold',
                    fontFamily: "Helvetica Neue, Helvetica, sans-serif",
                    color: '#283246', // Text color
                    textAnchor: 'middle', // Ensures the text is centered
                    verticalAlign: 'middle', // Vertically aligns text in the middle
                    backgroundColor: 'transparent',
                    borderRadius: 5,
                    padding: 10,
                },
                {
                    text: 'Assigned',
                    x: "50%", // Centering on the x-axis
                    y: "60%", // Adjusted vertical position for clarity
                    fontSize: '20px',
                    fontWeight: '800',
                    fontFamily: "Helvetica Neue, Helvetica, sans-serif",
                    color: '#283246', // Text color
                    textAnchor: 'middle', // Ensures the text is centered
                    verticalAlign: 'middle', // Vertically aligns text in the middle
                    backgroundColor: 'transparent',
                    borderRadius: 5,
                    padding: 10,
                },
            ],
        },
        colors: ["#F99494", "#283246"]
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            bgcolor="#F8F8F8"
            sx={{width:'40%',minHeight:'300px'}}
            borderRadius="25px">
            <Box sx={{display:'flex',alignItems:"center",justifyContent:"space-between"}}>
               <Box>
               <Typography sx={{
                    paddingInline: "15px",
                    paddingBlock: "10px",
                    color: "#283246",
                    fontSize: "16px",
                    fontWeight: "500"
                }}  >Vehicle Assignments</Typography>
               </Box>
            </Box>
            <Box sx={{ height: {
                lg:"255px",
                xl:"275px"
            } }}>
                <Chart
                    type="donut"
                    series={series}
                    options={options}
                    height={"350px"}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    textAlign:"center",
                    gap:"5px",
                    marginInline:"25px",
                    p:1.5
                }}
            >
                {
                    TripsRatingsData.map((data, key) => {
                        return (
                            <Box
                                key={key}
                                sx={{
                                    // paddingInline: "9px",
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                        color: "#283246",
                                        fontSize: "16px",
                                        fontFamily: "Helvetica Neue, Helvetica, sans-serif",
                                        fontWeight: "600"
                                        }}
                                    >{data.count}</Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                        color: "#1F1F1F",
                                        fontSize: "14px",
                                        fontFamily: "Helvetica Neue, Helvetica, sans-serif",
                                        fontWeight: "500"
                                        }}
                                    >{data.type}</Typography>
                                </Box>

                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default TripsRatingsSemiPieChart