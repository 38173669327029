import { Box, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilledButton from "../../components/MainButtons/FilledButton";
import SearchTextField from "../../components/SearchTextField";
import plus from "../../assets/Icons/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { geofenceOptions, parkingGeofenceTableCellItem } from "./utils";
import VehicleSelect from "../../components/Select";
import fleetselect from "../../assets/Icons/fleetselect.svg";
import statusicon from "../../assets/Icons/status.svg";
import geofencess from "../../assets/Icons/geofences.svg";
import GeofenceSelect from "./GeofenceSelect";
import { MapComponent } from "../../components/MapComponent";

import GeofenceForm from "./GeofenceForm";
import DeleteUserModal from "../../components/DeleteModal";
import { deleteGeofences } from "../../slices/mapManageSlice";
import UserTableComponent from "../UserManagement/UserTable";
import VehicleSelectFleet from "../../components/Select/AllFleetSelect";
import { useSnackbar } from "../../hooks/Snackbar";
import { fleetTableData } from "../Fleet/utils";

const calculateCenter = (selectedFleets) => {
  if (selectedFleets.length === 1) {
    return { lat: selectedFleets[0].lat, lng: selectedFleets[0].lng };
  }
  console.log(selectedFleets, "selectedFleetsssss");

  const lats = selectedFleets.map((fleet) => fleet.lat);
  const lngs = selectedFleets.map((fleet) => fleet.lng);

  const centerLat = (Math.min(...lats) + Math.max(...lats)) / 2;
  const centerLng = (Math.min(...lngs) + Math.max(...lngs)) / 2;

  return { lat: centerLat, lng: centerLng };
};

// Calculate the appropriate zoom level to fit all selected selectedFleets
const calculateZoom = (selectedFleets) => {
  if (selectedFleets.length === 1) {
    return 12; // Zoom level for a single fleet
  }

  const lats = selectedFleets.map((fleet) => fleet.lat);
  const lngs = selectedFleets.map((fleet) => fleet.lng);

  const latDiff = Math.max(...lats) - Math.min(...lats);
  const lngDiff = Math.max(...lngs) - Math.min(...lngs);

  const maxDiff = Math.max(latDiff, lngDiff);

  // Adjust zoom level based on the maximum difference, ensuring we don't zoom out too far
  if (maxDiff < 0.01) return 14; // Fleets are very close (like within the same city)
  if (maxDiff < 0.05) return 12; // Zoom level for nearby fleets
  if (maxDiff < 0.1) return 10; // Zoom level for moderately close fleets
  if (maxDiff < 0.5) return 8; // Zoom level for fleets in different parts of a region
  return 6; // Maximum zoom out level to prevent viewing entire country/world
};

export const Geofences = () => {
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [fleets, setFleet] = useState("");
  const [zone, setZone] = useState("");
  const [status, setStatus] = useState("");
  const [createGeofence, setCreateGeofence] = useState(false);
  const [userDelete, setUserDelete] = useState(false);
  const [center, setCenter] = useState({ lat: 23.017056, lng: 72.615138 }); // Default center
  const [zoom, setZoom] = useState(10); // Default zoom
  const [userToDelete, setUserToDelete] = useState("");
  const [geofenceToDelete, setGeofenceToDelete] = useState("");
  const [editGeofence, setEditGeofence] = useState("");

  const { geofences } = useSelector((state) => state.geofencedata);
  const { fleet } = useSelector((state) => state.fleetManage);

  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleCreateFleet = () => {
    setCreateGeofence(true);
  };

  useEffect(() => {
    if (fleets.length > 0) {
      console.log(fleets, "selectedfleets");
      const newCenter = calculateCenter(fleets);
      const newZoom = calculateZoom(fleets);

      setCenter(newCenter);
      setZoom(newZoom);
    }
  }, [fleets]);

  const handleEditUser = (userId) => {
    navigate(`/dashboard/geofences/${userId}`);
  };

  const handleDeleteUser = (userId) => {
    const geofenceToDelete = geofences.find(
      (geofence) => geofence.id === userId
    );
    setUserDelete(true);
    setUserToDelete(userId);

    setGeofenceToDelete(geofenceToDelete.geofence_name);
  };

  const handleFleetChange = (value) => {
    if (value.length) {
      console.log(value, "valueee");
      setFleet(value);
    } else {
      setFleet("");
    }
  };

  const handleZoneChange = (value) => {
    setZone(value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleConfirmDeleteGeofence = () => {
    setUserDelete(false);
    setCreateGeofence(false);
    dispatch(deleteGeofences(userToDelete));
    showSnackbar("Geofence deleted successfully!", "success");
  };

  const handleDeleteGeofenceClose = () => {
    setUserDelete(false);
  };

  const fleetOptions = fleetTableData?.map((fleet) => ({
    label: fleet.fleet_name,
    value: fleet.fleet_name,
    lat: fleet.location_lat,
    lng: fleet.location_lng,
  }));

  const filteredFleets =
    searchInput || status || fleets.length || zone
      ? geofences
          .filter((user) => {
            const matchesName = user?.geofence_name
              ?.toLowerCase()
              .includes(searchInput?.toLowerCase() || "");
            const matchesStatus = user?.status
              ?.toLowerCase()
              .includes(status?.toLocaleLowerCase() || "");
            const matchesFleet =
              fleets && fleets.length
                ? fleets.some(
                    (fleet) =>
                      user?.fleet?.toLowerCase() === fleet.label.toLowerCase() // Update to use fleet label
                  )
                : true;
            const matchesZone = user?.zone
              ?.toLowerCase()
              .includes(zone?.toLocaleLowerCase() || "");

            return matchesName && matchesStatus && matchesZone && matchesFleet;
          })
          .sort((a, b) => a.geofence_name.localeCompare(b.geofence_name))
      : geofences;

  function calculateCommonCenter(filteredFleets) {
    let totalLat = 0;
    let totalLng = 0;
    let count = 0;

    filteredFleets?.forEach((fleet) => {
      const { coordinates } = fleet;
      if (coordinates.center) {
        totalLat += coordinates.center.lat;
        totalLng += coordinates.center.lng;
        count++;
      }
    });

    if (count === 0) return null;

    const commonCenter = {
      lat: totalLat / count,
      lng: totalLng / count,
    };

    return commonCenter;
  }

  function calculateBoundingBox(filteredFleets) {
    let minLat = Infinity;
    let maxLat = -Infinity;
    let minLng = Infinity;
    let maxLng = -Infinity;

    filteredFleets.forEach((fleet) => {
      const { coordinates, type } = fleet;
      if (type === "polygon" && coordinates.path) {
        coordinates.path.forEach((point) => {
          minLat = Math.min(minLat, point.lat);
          maxLat = Math.max(maxLat, point.lat);
          minLng = Math.min(minLng, point.lng);
          maxLng = Math.max(maxLng, point.lng);
        });
      } else if (type === "circle" && coordinates.center) {
        const { lat, lng } = coordinates.center;
        const radiusInDegrees = coordinates.radius / 111320;
        minLat = Math.min(minLat, lat - radiusInDegrees);
        maxLat = Math.max(maxLat, lat + radiusInDegrees);
        minLng = Math.min(minLng, lng - radiusInDegrees);
        maxLng = Math.max(maxLng, lng + radiusInDegrees);
      }
    });

    return { minLat, maxLat, minLng, maxLng };
  }

  const handleEditGeofence = (userId) => {
    setCreateGeofence(true);
    setEditGeofence(userId);
  };

  return (
    <Paper
      sx={{
        borderRadius: "1.25rem",
        background: "#F8F8F8",
        padding: "1.563rem",
        boxShadow: "none",
        minHeight: "90vh",
      }}
    >
      <Box
        sx={{
          height: "42px",
          justifyContent: "space-between",
          p: 1,
          display: "flex",
          borderRadius: "6px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <SearchTextField value={searchInput} onChange={handleSearchChange} />
          <VehicleSelectFleet
            label="Fleet"
            options={fleetOptions}
            icon={<img src={fleetselect} />}
            onChange={handleFleetChange}
            sx={{ ml: 1, mr: 1 }}
          />
          <VehicleSelect
            label="Status"
            options={[
              { value: "Active", label: "Active" },
              { value: "Inactive", label: "Inactive" },
            ]}
            icon={<img src={statusicon} />}
            onChange={handleStatusChange}
          />
          <GeofenceSelect
            label="Geofences"
            options={geofenceOptions}
            icon={<img src={geofencess} />}
            onChange={handleZoneChange}
            sx={{
              ml: 1,
              mr: 1,
              height: "38px",
              border: "1px solid #DDDEE1",
              background: "#FFFFFF",
              borderRadius: "6px",
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <FilledButton
            onClick={handleCreateFleet}
            startIcon={
              <img src={plus} style={{ width: "18px", height: "18px" }} />
            }
            sx={{ width: "135px", height: "35px", borderRadius: "6px", ml: 3 }}
          >
            Create Zone
          </FilledButton>
        </Box>
      </Box>
      <MapComponent
        height={"450px"}
        width={"100%"}
        geofenceData={filteredFleets}
        center={center}
        drawGeofence={true}
        zoomlevel={zoom}
      />
      <GeofenceForm
        drawerOpen={createGeofence}
        setDrawerOpen={setCreateGeofence}
        setEditGeofence={setEditGeofence}
        editGeofence={editGeofence}
        handleDelete={handleDeleteUser}
      />
      <DeleteUserModal
        open={userDelete}
        handleClose={handleDeleteGeofenceClose}
        handleDeleteUser={handleConfirmDeleteGeofence}
        header={"Confirm Geofence Deletion"}
        title={
          <span>
            Are you sure you want to permanently delete the geofence{" "}
            <strong>{geofenceToDelete}</strong>? This action cannot be undone.
          </span>
        }
        confirmText={"Yes, Delete"}
      />
      <UserTableComponent
        data={filteredFleets}
        tableHeaders={parkingGeofenceTableCellItem}
        showActions={true}
        handleEdit={handleEditGeofence}
        handleDelete={handleDeleteUser}
      />
    </Paper>
  );
};
