// utils/countdownUtils.js
import { differenceInMilliseconds } from "date-fns";

export const calculateTimeLeft = (timestamp) => {
  const now = new Date();
  const endTime = new Date(timestamp * 1000); // Convert seconds to milliseconds

  const millisecondsLeft = differenceInMilliseconds(endTime, now);

  if (millisecondsLeft <= 0) {
    return { minutes: 0, seconds: 0, expired: true };
  }

  const secondsLeft = Math.floor(millisecondsLeft / 1000); // Convert milliseconds to seconds
  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;

  return { minutes, seconds, expired: false };
};
