import { Box, InputLabel } from "@mui/material";
import CustomTextField from "../MainTextField/CustomTextField";

const inputLabel = {
  fontSize: "0.8rem",
  fontWeight: 700,
  mb: 1,
  color: "#283246",
};

export const InputFieldWrapper = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  height,
  width,
  maxLength,
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label}{" "}
        <span style={{ color: "#f99494", fontSize: "1rem", width }}>*</span>
      </InputLabel>
      <CustomTextField
        value={value}
        onChange={onChange}
        name={name}
        inputProps={{ maxLength: maxLength || undefined }} // Conditionally apply maxLength
        sx={{
          "& .MuiOutlinedInput-root": {
            width: width || {
              xs: "100px", // Width for small screens
              sm: "300px", // Width for medium screens
              md: "400px", // Width for large screens
              lg: "300px",
              xl: "400px",
            },
            height: height || "auto", // Conditionally set height
          },
          input: {
            fontSize: "0.9rem",
            height: height ? `${height}px` : "auto", // Input text area height
          },
        }}
        placeholder={placeholder}
      />
    </Box>
  );
};
