import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { TextField, Button, Box, Paper, Typography } from '@mui/material';

const socket = io('http://localhost:5000'); // Connect to backend

const Chat = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const role = localStorage.getItem("role");
  const issueId = 1234;

  const [sender, setSender] = useState(role === 'admin' ? 'Admin' : 'Operator');

  useEffect(() => {
    // Join the room based on the issue ID
    socket.emit('join_room', issueId);

    // Fetch chat history from the server
    fetch(`http://localhost:5000/issues/${issueId}/chats`)
      .then((res) => res.json())
      .then((data) => setMessages(data));

    // Listen for new messages
    socket.on('receive_message', (data) => {
      setMessages((prev) => [...prev, data]);
    });

    return () => {
      socket.off('receive_message');
    };
  }, [issueId]);

  const sendMessage = () => {
    if (message.trim()) {
      const messageData = {
        issueId,
        sender,
        message,
      };

      socket.emit('send_message', messageData); // Send message to the backend
      setMessage(''); // Clear input field
    }
  };

  return (
    <Box sx={{ padding: 3, width: '100%', maxWidth: '500px', margin: '0 auto' }}>
      <Typography variant="h5" gutterBottom>
        {sender} - Issue Chat: {issueId}
      </Typography>
      <Box sx={{ height: '400px', overflowY: 'auto', marginBottom: 2, border: '1px solid #ddd', padding: 2, borderRadius: '8px' }}>
        {messages.map((msg, index) => (
          <Paper key={index} sx={{ padding: 2, marginBottom: 2, backgroundColor: msg.sender === sender ? '#e1f5fe' : '#ffebee' }}>
            <Typography variant="body1" color="textPrimary"><strong>{msg.sender}:</strong> {msg.message}</Typography>
          </Paper>
        ))}
      </Box>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
        sx={{ marginBottom: 1 }}
      />
      <Button variant="contained" color="primary" fullWidth onClick={sendMessage}>
        Send
      </Button>
    </Box>
  );
};

export default Chat;
