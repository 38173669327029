import { Box } from "@mui/material"
import UsersAreaChart from "./Graphs/UsersAreaChart"
import TotalUserPieChart from "./Graphs/TotalUserPieChart"
import AverageTripLineChart from "./Graphs/AverageTripLineChart"
import TripsRatingsSemiPieChart from "./Graphs/TripsRatingsSemiPieChart"
import TripDurationPieChart from "./Graphs/TripDurationPieChart"
import VechiclesLineChart from "./Graphs/VehiclesLineChart"
import NumberOfTripsBarChart from "./Graphs/NumberOfTripsBarChart"


export const AnalyticsGraphs=()=>{
    return(
        <Box display="flex" flexDirection="row" gap={2}>
              
                    <TripsRatingsSemiPieChart/>
             
              
                    <TripDurationPieChart/>
               
           
        </Box>
    )
}
