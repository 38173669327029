// // src/slices/vehicleManageSlice.js
// import { createSlice } from "@reduxjs/toolkit";
// import service from "../assets/Icons/serviceflag.svg";
// import mapicon from "../assets/Icons/location.svg";

// const initialState = {
//   vehicles: null,
// };

// // Utility function to generate random ID
// const generateRandomId = () => {
//   return "usr_" + Math.random().toString(36).substr(2, 9);
// };

// const generateRandomBattery = () => {
//   return Math.floor(Math.random() * 101); // Generates a number between 0 and 100
// };

// const vehicleManageSlice = createSlice({
//   name: "vehicle",
//   initialState,
//   reducers: {
//     setVehiclesData: (state, action) => {
//       state.vehicles = action.payload;
//     },
//     updatedVehicle: (state, action) => {
//       const { id, updatedFields } = action.payload;
//       const vehicleIndex = state.vehicles?.data?.findIndex(
//         (vehicle) => vehicle.id === id
//       );
//       if (vehicleIndex !== -1) {
//         // Only update the fields that are provided in updatedFields
//         state.vehicles[vehicleIndex] = {
//           ...state.vehicles[vehicleIndex],
//           ...updatedFields,
//         };
//       }
//     },
//     addVehicle: (state, action) => {
//       const fleetIndex = state.vehicles?.data?.findIndex(
//         (v) => v.id === action.payload.id
//       );
//       if (fleetIndex !== -1) {
//         // Fleet exists, so update it
//         state.vehicles[fleetIndex] = {
//           ...state.vehicles[fleetIndex],
//           ...action.payload,
//         };
//       } else {
//         const newVehicle = {
//           ...action.payload,
//           id: generateRandomId(),
//           battery: generateRandomBattery(),
//           flags: service,
//           location: mapicon,
//           vehicle_action: "Lock",
//           status: "Force Stop",
//         };
//         state.vehicles.push(newVehicle);
//       }
//     },
//     deleteVehicle: (state, action) => {
//       const userId = action.payload;
//       // Find the vehicle in the vehicles array
//       const vehicleToDelete = state.vehicles?.data?.find(
//         (vehicle) => vehicle.id === userId
//       );

//       if (vehicleToDelete) {
//         // Remove the vehicle from the vehicles array
//         state.vehicles = state.vehicles.filter(
//           (vehicle) => vehicle.id !== userId
//         );
//       }
//     },
//     vehicleAction: (state, action) => {
//       const VehicleId = action.payload;
//       const VehicleUpdateIndex = state.vehicles?.data?.findIndex(
//         (vehicle) => vehicle.id === VehicleId
//       );

//       if (VehicleUpdateIndex != -1) {
//         state.vehicles[VehicleUpdateIndex] = {
//           ...state.vehicles[VehicleUpdateIndex],
//           vehicle_action:
//             state.vehicles[VehicleUpdateIndex].vehicle_action === "Lock"
//               ? "Unlock"
//               : "Lock",
//         };
//       }
//     },
//   },
// });

// export const {
//   updatedVehicle,
//   addVehicle,
//   vehicleAction,
//   deleteVehicle,
//   setVehiclesData,
// } = vehicleManageSlice.actions;
// export default vehicleManageSlice.reducer;
// src/slices/vehicleManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { vehicleTableData } from "../features/Vehicles/utils";
import service from "../assets/Icons/serviceflag.svg";
import mapicon from "../assets/Icons/location.svg";

const initialState = {
  vehicles: vehicleTableData,
};

// Utility function to generate random ID
const generateRandomId = () => {
  return "usr_" + Math.random().toString(36).substr(2, 9);
};

const generateRandomBattery = () => {
  return Math.floor(Math.random() * 101); // Generates a number between 0 and 100
};

const vehicleManageSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    updatedVehicle: (state, action) => {
      const { id, updatedFields } = action.payload;
      const vehicleIndex = state.vehicles.findIndex(
        (vehicle) => vehicle.id === id
      );
      if (vehicleIndex !== -1) {
        // Only update the fields that are provided in updatedFields
        state.vehicles[vehicleIndex] = {
          ...state.vehicles[vehicleIndex],
          ...updatedFields,
        };
      }
    },
    addVehicle: (state, action) => {
      const fleetIndex = state.vehicles.findIndex(
        (v) => v.id === action.payload.id
      );
      if (fleetIndex !== -1) {
        // Fleet exists, so update it
        state.vehicles[fleetIndex] = {
          ...state.vehicles[fleetIndex],
          ...action.payload,
        };
      } else {
        const newVehicle = {
          ...action.payload,
          id: generateRandomId(),
          battery: generateRandomBattery(),
          flags: service,
          location: mapicon,
          vehicle_action: "Lock",
          status: "Force Stop",
        };
        state.vehicles.push(newVehicle);
      }
    },
    deleteVehicle: (state, action) => {
      const userId = action.payload;
      // Find the vehicle in the vehicles array
      const vehicleToDelete = state.vehicles.find(
        (vehicle) => vehicle.id === userId
      );

      if (vehicleToDelete) {
        // Remove the vehicle from the vehicles array
        state.vehicles = state.vehicles.filter(
          (vehicle) => vehicle.id !== userId
        );
      }
    },
    vehicleAction: (state, action) => {
      const VehicleId = action.payload;
      const VehicleUpdateIndex = state.vehicles.findIndex(
        (vehicle) => vehicle.id === VehicleId
      );

      if (VehicleUpdateIndex != -1) {
        state.vehicles[VehicleUpdateIndex] = {
          ...state.vehicles[VehicleUpdateIndex],
          vehicle_action:
            state.vehicles[VehicleUpdateIndex].vehicle_action === "Lock"
              ? "Unlock"
              : "Lock",
        };
      }
    },
  },
});

export const { updatedVehicle, addVehicle, vehicleAction, deleteVehicle } =
  vehicleManageSlice.actions;
export default vehicleManageSlice.reducer;
