import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
  useTheme,
} from "@mui/material";

const TableSkeleton = () => {
  const theme = useTheme();

  const tableCellItem = [
    "Type",
    "Manufacturer",
    "Model",
    "Type",
    "Manufacturer",
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ background: theme.palette.background.main }}>
          <TableRow>
            {tableCellItem.map((table, index) => (
              <TableCell
                key={index}
                sx={{
                  fontWeight: 500,
                  fontSize: "0.938rem",
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.secondary.main,
                }}
              >
                <Skeleton variant="rounded" width="100%" height={14} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(new Array(6)).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "0.938rem",
                  fontWeight: 500,
                  color: theme.palette.secondary.main,
                }}
              >
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={14}
                  borderRadius={"1rem"}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "0.938rem",
                  fontWeight: 500,
                  color: theme.palette.secondary.main,
                }}
              >
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={14}
                  borderRadius={"1rem"}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "0.938rem",
                  fontWeight: 500,
                  color: theme.palette.secondary.main,
                }}
              >
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={14}
                  borderRadius={"1rem"}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "0.938rem",
                  fontWeight: 500,
                  color: theme.palette.secondary.main,
                }}
              >
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={14}
                  borderRadius={"1rem"}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "0.938rem",
                  fontWeight: 500,
                  color: theme.palette.secondary.main,
                }}
              >
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={14}
                  borderRadius={"1rem"}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
