// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import userReducer from "../features/user/userSlice"; // Example slice
import userManageReducer from "../slices/userManageSlice";
import vehicleManageReducer from "../slices/vehicleManageSlice";
import driverManageReducer from "../slices/driverManageSlice";
import fleetManageReducer from "../slices/fleetManageSlice";
import parkingGeofenceManageReducer from "../slices/geofenceManageSlice";
import remindersManageReducer from "../slices/remindersManageSlice";
import tripsManageReducer from "../slices/tripsManageSlice";
import geofenceManageReducer from "../slices/mapManageSlice";
import workorderManageReducer from "../slices/workorderManageSlice";
import authReducer from "../slices/authSlice.js";

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Combine all the reducers into one root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  userManage: userManageReducer,
  vehicleManage: vehicleManageReducer,
  driverManage: driverManageReducer,
  fleetManage: fleetManageReducer,
  parkingGeofenceManage: parkingGeofenceManageReducer,
  remindersManage: remindersManageReducer,
  tripsManage: tripsManageReducer,
  geofencedata: geofenceManageReducer,
  workorderdata: workorderManageReducer,
  // Add more slices here if necessary
});

// Apply the persist reducer to the root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
});

// Create the persistor for the store
export const persistor = persistStore(store);
