import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { MapComponent } from "../../components/MapComponent";
import SearchTextField from "../../components/SearchTextField";
import fleetselect from "../../assets/Icons/fleetselect.svg";
import flagselect from "../../assets/Icons/flag.svg";
import { flagOption } from "./utils";
import VehicleSelect from "../../components/Select";
import { useSelector } from "react-redux";
import VehicleSelectFleet from "../../components/Select/AllFleetSelect";
import { alertOption } from "../Vehicles/utils";
import alertsicon from "../../assets/Icons/alerts.svg";
import { fleetTableData } from "../Fleet/utils";

const calculateCenter = (selectedFleets) => {
  if (selectedFleets.length === 1) {
    return { lat: selectedFleets[0].lat, lng: selectedFleets[0].lng };
  }
  console.log(selectedFleets, "selectedFleetsssss");

  const lats = selectedFleets.map((fleet) => fleet.lat);
  const lngs = selectedFleets.map((fleet) => fleet.lng);

  const centerLat = (Math.min(...lats) + Math.max(...lats)) / 2;
  const centerLng = (Math.min(...lngs) + Math.max(...lngs)) / 2;

  return { lat: centerLat, lng: centerLng };
};

// Calculate the appropriate zoom level to fit all selected selectedFleets
const calculateZoom = (selectedFleets) => {
  if (selectedFleets.length === 1) {
    return 12; // Zoom level for a single fleet
  }

  const lats = selectedFleets.map((fleet) => fleet.lat);
  const lngs = selectedFleets.map((fleet) => fleet.lng);

  const latDiff = Math.max(...lats) - Math.min(...lats);
  const lngDiff = Math.max(...lngs) - Math.min(...lngs);

  const maxDiff = Math.max(latDiff, lngDiff);

  // Adjust zoom level based on the maximum difference, ensuring we don't zoom out too far
  if (maxDiff < 0.01) return 14; // Fleets are very close (like within the same city)
  if (maxDiff < 0.05) return 12; // Zoom level for nearby fleets
  if (maxDiff < 0.1) return 10; // Zoom level for moderately close fleets
  if (maxDiff < 0.5) return 8; // Zoom level for fleets in different parts of a region
  return 6; // Maximum zoom out level to prevent viewing entire country/world
};

export const MapPage = () => {
  const [value, setValue] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [selectedFleets, setSelectedFleets] = useState([]);
  const [alerts, setAlerts] = useState("");
  const [center, setCenter] = useState({ lat: 23.017056, lng: 72.615138 }); // Default center
  const [zoom, setZoom] = useState(10); // Default zoom
  const theme = useTheme();

  const { geofences } = useSelector((state) => state.geofencedata);
  const { fleet } = useSelector((state) => state.fleetManage);
  const { vehicles } = useSelector((state) => state.vehicleManage);

  const centerLat = 23.017056;
  const centerLng = 72.615138;
  const radius = 0.2; // Roughly 5km radius

  function generateRandomPoints(center, radius, count) {
    const types = ["car", "truck", "scooter"];
    const points = [];
    for (let i = 0; i < count; i++) {
      const angle = Math.random() * Math.PI * 2;
      const distance = Math.random() * radius;
      const lat = center.lat + distance * Math.cos(angle);
      const lng = center.lng + distance * Math.sin(angle);
      const type = types[Math.floor(Math.random() * types.length)];
      points.push({ lat, lng, type });
    }
    return points;
  }

  const nearbyVehicles = generateRandomPoints(
    { lat: centerLat, lng: centerLng },
    radius,
    78
  );

  const fleetOptions = fleetTableData?.map((fleet) => ({
    label: fleet.fleet_name,
    value: fleet.fleet_name,
    lat: fleet.location_lat,
    lng: fleet.location_lng,
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (selectedFleets.length > 0) {
      const newCenter = calculateCenter(selectedFleets);
      const newZoom = calculateZoom(selectedFleets);

      setCenter(newCenter);
      setZoom(newZoom);
    }
  }, [selectedFleets]);

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleFleetChange = (value) => {
    setSelectedFleets(value);
  };

  const handleFlagChange = (value) => {
    if (value.length) {
      setAlerts(value);
    } else {
      setAlerts("");
    }
  };

  const filteredFleets =
    searchInput || selectedFleets || (alerts && alerts.length)
      ? geofences
          .filter((user) => {
            const matchesName = user?.geofence_name
              ?.toLowerCase()
              .includes(searchInput?.toLowerCase() || "");
            const matchesFleet =
              selectedFleets && selectedFleets.length
                ? selectedFleets.some(
                    (fleet) =>
                      user?.fleet?.toLowerCase() === fleet.label.toLowerCase() // Update to use fleet label
                  )
                : true;
            const matchesAlerts =
              alerts && alerts.length
                ? alerts.some((alert) =>
                    user?.alerts?.some(
                      (vehicleAlert) =>
                        vehicleAlert.toLowerCase() === alert.label.toLowerCase() // Use alert label similar to fleet
                    )
                  )
                : true;

            return matchesName && matchesFleet && matchesAlerts;
          })
          .sort((a, b) => a.geofence_name.localeCompare(b.geofence_name))
      : geofences; // Return full list if both search input and flag are empty

  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
          minHeight: "90vh",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1.2rem", // Adjust font size as needed
              color: theme.palette.text.primary, // White text color
            }}
          >
            Map
          </Typography>
        </Box>

        {value === 0 && (
          <>
            <Box
              sx={{
                border: "1px solid #DDDEE1",
                height: "42px",
                justifyContent: "space-between",
                p: 1,
                background: "#DDDEE140",
                display: "flex",
                borderRadius: "6px",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex" }}>
                {/* <VehicleSelect
                  label="Fleet"
                  options={fleetOptions}
                  icon={<img src={fleetselect} />} // Passing the icon dynamically
                  onChange={handleFleetChange}
                  sx={{ mr: 1, ml: 1 }}
                /> */}
                <VehicleSelectFleet
                  label="Fleet"
                  options={fleetOptions}
                  icon={<img src={fleetselect} />} // Passing the icon dynamically
                  onChange={handleFleetChange}
                  sx={{ ml: 0.2 }}
                />
                <VehicleSelectFleet
                  label="Alerts"
                  options={alertOption}
                  icon={<img src={alertsicon} />} // Passing the icon dynamically
                  onChange={handleFlagChange}
                  sx={{ ml: 0.2 }}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <SearchTextField
                  value={searchInput}
                  onChange={handleSearchChange}
                />
              </Box>
            </Box>
            <MapComponent
              height={"650px"}
              width={"100%"}
              geofenceData={filteredFleets}
              vehicleList={vehicles}
              zoomlevel={zoom}
              center={center}
            />
          </>
        )}

        {value === 1 && (
          <>
            <Box
              sx={{
                border: "1px solid #DDDEE1",
                height: "42px",
                justifyContent: "space-between",
                p: 1,
                background: "#DDDEE140",
                display: "flex",
                borderRadius: "6px",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex" }}>
                {/* <VehicleSelect
                  label="Fleet"
                  options={fleetOptions}
                  icon={<img src={fleetselect} />}
                  onChange={handleFleetChange}
                  sx={{ mr: 1, ml: 1 }}
                /> */}
              </Box>
            </Box>
            <MapComponent height={"650px"} width={"100%"} zoomlevel={10} />
          </>
        )}
      </Paper>

      {/* {value === 0 && (
        <Paper
          sx={{
            borderRadius: "1.25rem",
            background: "#F8F8F8",
            padding: "1.563rem",
            boxShadow: "none",
            gap: 2,
            mt: 2,
          }}
        >
          <Typography
            sx={{
              color: "#283246",
              fontSize: "1.5rem",
              mb: 2,
              fontWeight: 500,
              fontFamily: "Helvetica Neue, Helvetica, sans-serif",
            }}
          >
            Flags
          </Typography>
          <Grid container spacing={4}>
            {flagDetails.map((flag, i) => (
              <>
                <Grid item xs={2.4}>
                  <Box display="flex" alignItems="center">
                    <Box sx={{ cursor: "pointer" }}>
                      <img src={flag.icon} alt="flag-icon" />
                    </Box>
                    <Typography
                      sx={{
                        ml: 1,
                        color: "#283246",
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        fontFamily: "Helvetica Neue, Helvetica, sans-serif",
                      }}
                    >
                      {flag.name}
                    </Typography>
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Paper>
      )} */}
    </>
  );
};
