import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Paper, Button } from "@mui/material";
import FilledButton from "../../components/MainButtons/FilledButton";

const ReminderSection = () => {
  const { reminders } = useSelector((state) => state.remindersManage); // Getting reminders from Redux
  const [readReminders, setReadReminders] = useState([]);

  const markAsRead = (id) => {
    setReadReminders([...readReminders, id]);
  };

  const getUnreadReminders = () =>
    reminders.filter((reminder) => !readReminders.includes(reminder.id));

  const unreadReminders = getUnreadReminders();

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 3,minHeight:'500px',width:"40%" }}>
      <Typography variant="h6" gutterBottom>
        Reminders
      </Typography>

      {unreadReminders.length > 0 ? (
        unreadReminders.slice(0, 4).map((reminder) => (
          <Box
            key={reminder.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
              marginBottom: "8px",
              borderRadius: "8px",
              backgroundColor: "#f7f7f7",
              "&:hover button": {
                visibility: "visible",
              },
              "&:hover":{
                background:'#FFF1F1',
                border:'1px solid #F99494',
                cursor:'pointer'
              }
            }}
          >
            <Box>
              <Typography variant="body1" sx={{fontWeight: 500,color:"#283246"}}>{reminder.message}</Typography>
              <Typography variant="body2" color="textSecondary">
                {`${reminder.driver_name} - ${reminder.vehicle_name}`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {`Date: ${reminder.date_and_time}`}
              </Typography>
            </Box>
            <Button
              variant="outlined"
              size="small"
              onClick={() => markAsRead(reminder.id)}
              sx={{
                borderColor: "#4C5B6B",
                color: "#4C5B6B",
                visibility: "hidden",
                textTransform:'none',
                // "&:hover": {
                //   borderColor: "#bcbcbc",
                // },
                '&:hover': {
                  borderColor: "#bcbcbc",
                  transform: 'scale(1.05)', // Scaling effect on hover
                  boxShadow: 'none' // Optional: remove boxShadow if present on hover
                },
              }}
            >
              Mark as Read
            </Button>
          </Box>
        ))
      ) : (
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          There is no unread reminder.
        </Typography>
      )}
    </Paper>
  );
};

export default ReminderSection;
