import {
  Box,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import backicon from "../../assets/Icons/backicon.svg";
import FilledButton from "../../components/MainButtons/FilledButton";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import { fleetOptions } from "../Vehicles/utils";
import attachmentplus from "../../assets/Icons/attachmentplus.svg";

const inputLabel = {
  fontSize: "0.8rem",
  fontWeight: 700,
  mb: 1,
  color: "#283246",
};

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    // Apply responsive width based on breakpoints
    width: {
      xs: "100px", // Width for small screens
      sm: "300px", // Width for medium screens
      md: "400px", // Width for large screens
      lg: "300px",
      xl: "400px",
    },
  },
};

const selectStyle = {
  borderRadius: "10px",
  height: "40px",
  width: {
    xs: "100px", // Width for small screens
    sm: "300px", // Width for medium screens
    md: "400px", // Width for large screens
    lg: "300px", // Width for larger screens
    xl: "400px", // Width for extra-large screens
  },
};

const InputFieldWrapper = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  required = true, // Added a new prop to control the asterisk display
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} {""}
        {required && (
          <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
        )}
      </InputLabel>
      <CustomTextField
        value={value}
        onChange={onChange}
        name={name}
        sx={textFieldStyle}
        error={!!error}
        placeholder={placeholder}
      />

      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  name,
  placeholder,
  error,
  required = true, // Added a new prop to control the asterisk display
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label}
        {required && (
          <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
        )}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        sx={selectStyle}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Adjust this value to control the height of the visible area
            },
          },
        }}
        name={name}
        error={!!error}
        displayEmpty
      >
        <MenuItem value="">
          <Typography color="text.primary">{placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

export const EditTicket = () => {
  const { id } = useParams();
  const { ticketList } = useSelector((state) => state.workorderdata);
  const navigate = useNavigate();

  const tickets = ticketList.find((u) => u.id === id);
  const [vehicleData, setVehicleData] = useState({
    vehicle_type: "",
    fleet: "",
    vehicle_name: "",
    issue_type: "",
    issue_description: "",
    issue_attachment: null, // For storing the file
  });
  const [errors, setErrors] = useState({});

  const handleChange = (field, value) => {
    setVehicleData((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    // Clear errors on change
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVehicleData((prevState) => ({
        ...prevState,
        issue_attachment: file, // Store the file in state
      }));
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Paper>
          <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
            <IconButton onClick={() => navigate("/dashboard/work-orders")}>
              <img src={backicon} alt="back-icon" />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                ml: 2,
                fontSize: "1.375rem",
                color: "#283246",
              }}
            >
              Ticket ID:{tickets?.id}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              sx={{ fontSize: "1rem", color: "#283246", fontWeight: 500 }}
            >
              Details
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <CustomSelect
                label="Vehicle Type"
                value={tickets.vehicle_type}
                onChange={(e) => handleChange("vehicle_type", e.target.value)}
                error={errors.vehicle_type}
                options={fleetOptions}
                name="vehicle_type"
              />
              <CustomSelect
                label="Fleet"
                value={tickets.fleet}
                onChange={(e) => handleChange("vehicle_type", e.target.value)}
                error={errors.vehicle_type}
                options={fleetOptions}
                name="fleet"
              />
              <CustomSelect
                label="Vehicle Name"
                value={tickets.vehicle_type}
                onChange={(e) => handleChange("vehicle_type", e.target.value)}
                error={errors.vehicle_type}
                options={fleetOptions}
                name="vehicle_name"
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <CustomSelect
                label="Issue Types"
                value={tickets.vehicle_type}
                onChange={(e) => handleChange("vehicle_type", e.target.value)}
                error={errors.vehicle_type}
                options={fleetOptions}
                name="vehicle_type"
              />{" "}
              <CustomSelect
                label="Status"
                value={tickets.vehicle_type}
                onChange={(e) => handleChange("vehicle_type", e.target.value)}
                error={errors.vehicle_type}
                options={fleetOptions}
                name="vehicle_type"
              />{" "}
              <CustomSelect
                label="Priority Class"
                value={tickets.vehicle_type}
                onChange={(e) => handleChange("vehicle_type", e.target.value)}
                error={errors.vehicle_type}
                options={fleetOptions}
                name="vehicle_type"
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Box>
                <InputLabel sx={inputLabel}>
                  Issue Created Date{" "}
                  <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
                </InputLabel>
                <DateTimePicker
                  name="date_and_time"
                  sx={{
                    width: {
                      xs: "100px", // Width for small screens
                      sm: "300px", // Width for medium screens
                      md: "400px", // Width for large screens
                      lg: "300px", // Width for larger screens
                      xl: "400px", // Width for extra-large screens
                    },
                    height: "45px",
                    "& .MuiInputBase-root": {
                      height: "100%",
                    },
                  }}
                  slotProps={{
                    textField: {
                      InputProps: {
                        sx: {
                          borderRadius: "10px",
                        },
                      },
                    },
                  }}
                />
              </Box>

              <Box>
                <InputLabel sx={inputLabel}>
                  Scheduled Start Date{" "}
                  <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
                </InputLabel>
                <DateTimePicker
                  name="date_and_time"
                  sx={{
                    width: {
                      xs: "100px", // Width for small screens
                      sm: "300px", // Width for medium screens
                      md: "400px", // Width for large screens
                      lg: "300px", // Width for larger screens
                      xl: "400px", // Width for extra-large screens
                    },
                    height: "45px",
                    "& .MuiInputBase-root": {
                      height: "100%",
                    },
                  }}
                  slotProps={{
                    textField: {
                      InputProps: {
                        sx: {
                          borderRadius: "10px",
                        },
                      },
                    },
                  }}
                />
              </Box>
              <CustomSelect
                label="Created By"
                value={tickets.vehicle_type}
                onChange={(e) => handleChange("vehicle_type", e.target.value)}
                error={errors.vehicle_type}
                options={fleetOptions}
                name="vehicle_type"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,

              }}
            >
              <CustomSelect
                label="Assigned To"
                value={tickets.vehicle_type}
                onChange={(e) => handleChange("vehicle_type", e.target.value)}
                error={errors.vehicle_type}
                options={fleetOptions}
                name="vehicle_type"
              />

              <InputFieldWrapper
                label="Issue Description"
                name="issue_description"
                value={vehicleData.issue_description}
                onChange={(e) =>
                  handleChange("issue_description", e.target.value)
                }
                error={errors.issue_description}
                placeholder="Enter Issue Description"
              />

              <Box>
                <InputLabel sx={inputLabel}>Issue Attachment</InputLabel>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    borderStyle: "dotted",
                    borderWidth: "4px",
                    borderColor: "#DDDEE1",
                    cursor: "pointer",
                    p: 4,
                    width: {
                      xs: "100px", // Width for small screens
                      sm: "300px", // Width for medium screens
                      md: "400px", // Width for large screens
                      lg: "230px",
                      xl: "330px",
                    },
                  }}
                  onClick={() => document.getElementById("upload-file").click()} // Trigger file input on box click
                >
                  <input
                    type="file"
                    accept="image/*, .pdf, .doc, .docx"
                    style={{ display: "none" }}
                    id="upload-file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="upload-file">
                    <FilledButton
                      sx={{
                        width: "150px",
                        height: "50px",
                        background: "none",
                        boxShadow: "none",
                        color: "#97A1B6",
                        "&:hover": {
                          background: "none",
                          boxShadow: "none",
                        },
                      }}
                    >
                      <img src={attachmentplus} />
                    </FilledButton>
                    <Typography sx={{ color: "#97A1B6" }}>
                      {vehicleData.issue_attachment &&
                        vehicleData.issue_attachment.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#97A1B6",
                        mt: 2,
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Click to pick files
                    </Typography>
                  </label>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </LocalizationProvider>
    </>
  );
};
