import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Card, Paper, Typography, useTheme } from "@mui/material";
import { tripsData } from "./utils"; // Import your custom utils or tripsData if needed
import AnimatedNumber from "../../components/AnimatedNumbers"; // Example component, you can adjust
import FilledButton from "../../components/MainButtons/FilledButton";
import plus from "../../assets/Icons/plus.svg"; // Example icon, adjust path as needed
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import TripCalendar from "./TripCalender";
import AssignVehicleDialog from "./Assignvehicle";
import { useSelector, useDispatch } from "react-redux";
import { CustomSelect } from "../../components/CustomSelection";
import { addScheduledTrip } from "../../slices/tripsManageSlice";
import { useSnackbar } from "../../hooks/Snackbar";

dayjs.extend(advancedFormat);

export const Trips = () => {
  const [value, setValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState("");
  const [selectedTrip, setSelectedTrip] = useState(null); // Track selected trip for editing
  const { driver } = useSelector((state) => state.driverManage);
  const { vehicles } = useSelector((state) => state.vehicleManage);
  const { scheduleTrips } = useSelector((state) => state.tripsManage);
  const [selectedVehicleFilter, setSelectedVehicle] = useState("");
  const [selectedDriverFilter, setSelectedDriver] = useState("");

  const drivers = driver?.map((driver) => ({
    id: driver.id,
    label: driver.driver_name,
    value: driver.driver_name,
  }));

  const vehiclesOption = vehicles?.map((vehicle) => ({
    id: vehicle.id,
    vehicle_qr: vehicle.vehicle_qr,
    label: vehicle.vehicle_qr,
    value: vehicle.vehicle_qr,
  }));

  const formattedTrips = scheduleTrips.map((trip) => ({
    ...trip,
    start: dayjs(trip.start).toDate(),
    end: dayjs(trip.end).toDate(),
  }));

  const [trips, setTrips] = useState(formattedTrips);

  const theme = useTheme();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const handleEditTrip = (trip) => {
    setSelectedTrip(trip);
    setOpenDialog(true);
  };

  const handleAssign = (assignment) => {
    const startDate = dayjs(assignment.startDate);
    const endDate = dayjs(assignment.endDate);
    const durationInHours = endDate.diff(startDate, "hour"); // Get difference in hours
    const durationFormatted = `${durationInHours} hour${
      durationInHours !== 1 ? "s" : ""
    }`; // Format duration

    const newTrip = {
      title: assignment.driver,
      vehicle_qr_code: assignment.vehicle,
      start: assignment.startDate,
      end: assignment.endDate,
      allDay: false,
      notes: assignment.notes,
      duration: durationFormatted,
      id:
        assignment?.id || `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
    };

    const newTripSchedule = {
      title: assignment.driver,
      vehicle_qr_code: assignment.vehicle,
      start: dayjs(assignment.startDate).format("YYYY-MM-DD HH:mm"),
      end: dayjs(assignment.endDate).format("YYYY-MM-DD HH:mm"),
      allDay: false,
      notes: assignment.notes,
      duration: durationFormatted,
      id:
        assignment?.id || `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
    };

    // Check for overlapping assignments
    const isOverlap = trips.some((trip) => {
      const sameVehicle = trip.vehicle_qr_code === newTrip.vehicle_qr_code;
      const sameDriver = trip.title === newTrip.title;
      const overlapTime = newTrip.start < trip.end && newTrip.end > trip.start;
      const isDifferentTrip = trip.id !== newTrip.id; // Exclude the current trip from overlap checks

      return (sameVehicle || sameDriver) && overlapTime && isDifferentTrip;
    });

    if (isOverlap) {
      setError(
        "This vehicle or driver is already assigned during the selected time."
      );
    } else {
      setError("");
      if (assignment?.id) {
        dispatch(addScheduledTrip(newTripSchedule));
        setTrips((prevTrips) =>
          prevTrips.map((trip) => (trip.id === assignment.id ? newTrip : trip))
        );
        showSnackbar("Assignment edited successfully!", "success");
        setOpenDialog(false);
      } else {
        dispatch(addScheduledTrip(newTripSchedule));
        setTrips((prevTrips) => [...prevTrips, newTrip]);
        showSnackbar("Assignment scheduled successfully!", "success");
        setOpenDialog(false);
      }
    }
  };

  const handleDateClick = (date) => {
    setSelectedTrip({
      start: date,
      end: dayjs(date).add(1, "hour").toDate(), // Set default end time as 1 hour after the start time
      notes: "",
      vehicle_qr_code: "",
      driver: "",
    });
    setOpenDialog(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenDialog = () => {
    setSelectedTrip(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {tripsData.map((user) => (
            <Card
              key={user.name}
              sx={{
                width: "28%",
                height: "50px",
                borderRadius: "10px",
                padding: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "none",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    color: theme.palette.text.primary,
                    fontWeight: "400",
                    marginBottom: "0.3rem",
                  }}
                >
                  {user.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1.375rem",
                    color: theme.palette.text.primary,
                    fontWeight: 700,
                  }}
                >
                  <AnimatedNumber value={user.count} />
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 55,
                  height: 55,
                  backgroundColor: "#97A1B61A",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={user.icon}
                  alt={user.name}
                  style={{ width: 35, height: 35 }}
                />
              </Box>
            </Card>
          ))}
        </Box> */}

        <Paper
          sx={{
            borderRadius: "1.25rem",
            background: "#F8F8F8",
            padding: "1.563rem",
            boxShadow: "none",
            minHeight: "90vh",
            gap: 2,
            mt: 2,
            mb: 2,
          }}
        >
          <>
            <Box
              sx={{ justifyContent: "space-between", display: "flex", p: 1 }}
            >
              <FilledButton
                startIcon={<img src={plus} alt="plus" />}
                onClick={handleOpenDialog}
                sx={{ width: "180px" }}
              >
                Add Assignment
              </FilledButton>
            </Box>
            <TripCalendar
              trips={trips}
              onEditTrip={handleEditTrip}
              onDateClick={handleDateClick}
              selectedVehicleFilter={selectedVehicleFilter}
              selectedDriverFilter={selectedDriverFilter}
            />
          </>
        </Paper>

        <AssignVehicleDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onAssign={handleAssign}
          vehicles={vehiclesOption}
          drivers={drivers}
          selectedTrip={selectedTrip}
          errorsText={error}
          setError={setError}
        />
      </LocalizationProvider>
    </>
  );
};
