import { useState } from "react";
import {
  Box,
  Card,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import AnimatedNumber from "../../components/AnimatedNumbers";
import { driverDataa, tabLabels } from "./utils";
import { IssueType } from "./AddIssueType";
import { Tickets } from "./Tickets";
import Chat from "../../components/Chat";

const tabStyles = {
  width: "191px",
  height: "40px",
  fontWeight: 500,
  fontSize: "1rem",
  textTransform: "none",
  color: "#F99494",
  "&.Mui-selected": {
    background: "linear-gradient(90deg, #535A6A 0%, #283246 100%)",
    color: "#FFFFFF",
    borderRadius: "12px",
  },
};

export const WorkOrders = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const handleChange = (_, newValue) => setValue(newValue);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between", // This ensures spacing is handled evenly
          gap: "0.5rem", // Adds space between cards if needed
          width: "100%",
        }}
      >
        {driverDataa.map((user) => (
          <Card
            sx={{
              width: { lg: "28%", xl: "30%" }, // Adjust width to fit 4 items per line
              height: "50px",
              borderRadius: "10px", // Corner radius
              padding: "20px", // Padding inside the card
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between", // Space between elements
              boxShadow: "none",
              backgroundColor: "#FFFFFF", // Card background color
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: theme.palette.text.primary,
                  fontWeight: "400",
                  marginBottom: "0.5rem",
                }}
              >
                {user.name}
              </Typography>

              <Typography
                sx={{
                  fontSize: "1.5rem",
                  color: theme.palette.text.primary,
                  fontWeight: 700,
                }}
              >
                <AnimatedNumber value={user.count} />
              </Typography>
            </Box>
            <Box
              sx={{
                width: 55, // Background size
                height: 55,
                backgroundColor: "#97A1B61A", // Background color
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={user.icon} style={{ width: 35, height: 35 }} />
            </Box>
          </Card>
        ))}
      </Box>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 2,
          minHeight: "65vh",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              bgcolor: "#F8F8F8",
              borderRadius: "12px",
              border: "1px solid #DDDEE1",
              "& .MuiTabs-indicator": { display: "none" },
            }}
          >
            {tabLabels.map((label) => (
              <Tab key={label} label={label} disableRipple sx={tabStyles} />
            ))}
          </Tabs>
        </Box>
        {value === 0 && <Tickets />}

        {value === 2 && <Chat/>}
      </Paper>
    </>
  );
};
