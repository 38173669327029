import React from 'react';
import { Box, List, ListItem, ListItemAvatar, Avatar, IconButton, Typography, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import driverinfo from "../../assets/Icons/driverinfo.svg";

const drivers = [
  { name: "Bob Smith", score: 5, rank: 30, avatar: driverinfo },
  { name: "Alice Johnson", score: 4, rank: 22, avatar: driverinfo },
  { name: "John Doe", score: 3, rank: 10, avatar: driverinfo },
  { name: "Sarah Wilson", score: 2, rank: 20, avatar: driverinfo }
];

function TopPerformingDriver() {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: '1rem', boxShadow: 3, minHeight: '500px',width:"60%" }}>
      <Typography variant="h6" sx={{ mb: 2,color:"#283246" }}>
        Top Performing Driver
      </Typography>
      <List>
        {drivers.map((driver, index) => (
          <ListItem key={index} sx={{ border: 1, borderColor: 'divider', mb: 1, borderRadius: 2, p: 2 }}>
            <ListItemAvatar>
              <Avatar src={driver.avatar} />
            </ListItemAvatar>
            <Grid container spacing={2} sx={{ flex: 1, alignItems: 'center' }}>
              <Grid item xs={4}>
                <Typography sx={{ fontWeight: 500,color:"#283246"  }}>
                  {driver.name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{
                    bgcolor: "#FFF1F1", // light pink background
                    border: 1, // 1px border
                    borderColor: "#F99494", // pink border color
                    borderRadius: "10px", // rounded corners
                    p: 0.2, // padding inside the box
                  }}
                >
                  <Typography
                
                    sx={{ fontWeight: 500, color: "#283246" ,fontSize:'14px',textAlign:'center'}}
                  >
                    Score
                    <Typography>
                    {driver.score} / 10
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
              <Box
                  sx={{
                    bgcolor: "#FEFEFE", // light pink background
                    border: 1, // 1px border
                    borderColor: "#F99494", // pink border color
                    borderRadius: "10px", // rounded corners
                    p: 0.2, // padding inside the box
                  }}
                >
                <Typography variant="body2" sx={{fontWeight: 500,fontSize:'14px',color:"#283246",textAlign:'center'  }}>
                  Rank out of 120 vehicles
                  <Typography>
                  {driver.rank}
                    </Typography>
                </Typography>
                </Box>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default TopPerformingDriver;
