import React, { useState } from "react";
import {
  Box,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import SearchTextField from "../../components/SearchTextField";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import backicon from "../../assets/Icons/backicon.svg";
import tripsicon from "../../assets/Icons/tripstab.svg";
import timelineicon from "../../assets/Icons/timelinetab.svg";
import { tripTableCellItem, userVehicleEdit } from "./utils";
import AnimatedNumber from "../../components/AnimatedNumbers";
import UserTableComponent from "../UserManagement/UserTable";
import UserTimeline from "../UserManagement/UserTimeline";
import NoResultsFound from "../../components/NoTImelineData";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const tabsData = [
  { label: "Vehicle Trips", Icon: tripsicon },
  { label: "Timeline", Icon: timelineicon },
];

export const EditVehicle = () => {
  const [value, setValue] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [status, setStatus] = useState("");

  const { vehicles } = useSelector((state) => state.vehicleManage);

  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();

  // Filter the vehicle from either vehicles or deactivatedUser based on the id
  const vehicle = vehicles?.find((u) => u.id === id);

  // Handle the case when the vehicle is not found
  if (!vehicle) {
    return <Typography variant="h4">User not found</Typography>;
  }

  const handleChange = (event, newValue) => {
    setSearchInput("");
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredTripDetails = vehicle?.trip_details
    ?.filter((vehicle) => {
      const matchesName = searchInput
        ? vehicle?.driver.toLowerCase().includes(searchInput.toLowerCase())
        : true; // If searchInput is empty, return true to ignore this filter
      const matchesStatus = status
        ? vehicle?.status.toLowerCase().includes(status.toLowerCase())
        : true; // If status is empty, return true to ignore this filter
      return matchesName && matchesStatus;
    })
    .sort((a, b) => a.driver.localeCompare(b.driver));

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleDateChange = (selected) => {
    console.log("Selected Date/Range:", selected);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Paper
          sx={{
            borderRadius: "1.25rem",
            background: "#FFFFFF",
            padding: "1.563rem",
            boxShadow: "none",
            gap: 2,
            mt: 1,
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton onClick={() => navigate("/dashboard/vehicles")}>
              <img src={backicon} alt="back-icon" />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                ml: 2,
                mr: 2,
                fontSize: "1.375rem",
                color: "#283246",
                display: "flex",
                alignItems: "center",
              }}
            >
              {vehicle?.vehicle_name}
              <Typography
                sx={{
                  color: theme.palette.text.tertiary,
                  fontSize: "1rem",
                  fontWeight: 500,
                  ml: 2,
                }}
              >
                {vehicle?.last_active && (
                  <Typography
                    sx={{
                      color: theme.palette.text.tertiary,
                      fontSize: "1rem",
                      fontWeight: 500,
                      ml: 2,
                    }}
                  >
                    &#8226; Last active: {vehicle.last_active}
                  </Typography>
                )}
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2, mt: 1, mb: 2 }}>
            {vehicle.trip_details && vehicle.trip_details.length > 0 ? (
              userVehicleEdit?.map((vehicleDetail) => (
                <Box
                  sx={{
                    width: "30%", // Adjust width to fit 4 items per line
                    height: "50px",
                    borderRadius: "10px", // Corner radius
                    padding: "20px", // Padding inside the card
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between", // Space between elements
                    boxShadow: "none",
                    backgroundColor: "#FFFFFF", // Card background color
                    border: "1px solid #DDDEE1",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.875rem",
                        color: theme.palette.text.primary,
                        fontWeight: "400",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {vehicleDetail.name}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "1.375rem",
                        color: theme.palette.text.primary,
                        fontWeight: 700,
                      }}
                    >
                      <AnimatedNumber value={vehicleDetail.count} />
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 55, // Background size
                      height: 55,
                      backgroundColor: "#97A1B61A", // Background color
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={vehicleDetail.icon}
                      style={{ width: 35, height: 35 }}
                    />
                  </Box>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center", // Center vertically
                  justifyContent: "center", // Center horizontally
                  // height: "100px", // Set a height for the box to make vertical centering visible
                  textAlign: "center", // Ensure text is centered if it wraps to a new line
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: theme.palette.text.primary,
                    marginBottom: "1.5rem",
                    fontFamily: "DM Sans, Arial, sans-serif",
                  }}
                >
                  No Trip Details Found For This Vehicle
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>

        <Paper
          sx={{
            borderRadius: "1.25rem",
            background: "#FFFFFF",
            padding: "1.563rem",
            boxShadow: "none",
            gap: 2,
            mt: 1,
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Active Users"
              indicatorColor="primary"
              sx={{
                borderRadius: "12px",
                p: 1,
                "& .MuiTabs-indicator": {
                  background: "#FE7676",
                  height: "4px",
                },
                "& .Mui-selected": {
                  "& .MuiTab-root": {
                    color: "red",
                    borderBottom: "2px solid red",
                  },
                },
              }}
            >
              {tabsData.map(({ label, Icon }, index) => (
                <Tab
                  key={label}
                  disableRipple
                  label={label}
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: 500,
                    "&.Mui-selected": {
                      color: "#283246",
                    },
                    minWidth: "250px",
                  }}
                />
              ))}
            </Tabs>
          </Box>
          <Box>
            {value === 0 && (
              <>
                <Box
                  sx={{
                    // border: "1px solid #DDDEE1",
                    display: "flex",
                    height: "42px",
                    p: 1,
                    background: "#FFFFFF",
                    borderRadius: "6px",
                    mb: 3,
                  }}
                >
                  <SearchTextField
                    value={searchInput}
                    onChange={handleSearchChange}
                    placeholder="Search by Driver"
                    sx={{
                      height: "35px",
                      width: "14.75rem",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "6px",
                        color: "#283246",
                        backgroundColor: "#FFF",
                        mr: 1,
                        fontSize: "1rem",
                        "& fieldset": {
                          border: "1px solid #DDDEE1", // Border color
                        },
                      },
                    }}
                  />
                </Box>
                <UserTableComponent
                  data={filteredTripDetails}
                  tableHeaders={tripTableCellItem}
                  showActions={false}
                  handleEdit={() => {}}
                  handleDelete={() => {}}
                />
              </>
            )}
            {value === 1 && (
              <>
                {vehicle.timeline_details ? (
                  <UserTimeline
                    timelineData={vehicle.timeline_details}
                    heading={vehicle.timeline_heading}
                    description={vehicle.timeline_description}
                  />
                ) : (
                  <NoResultsFound />
                )}
              </>
            )}
          </Box>
        </Paper>
      </LocalizationProvider>
    </>
  );
};
