import React, { useEffect, useLayoutEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import alert from "../assets/Icons/alert.svg";
import analytics from "../assets/Icons/analytics.svg";
import mapicon from "../assets/Icons/mapicon.svg";
import vehicleicon from "../assets/Icons/vehicleicon.svg";
import tripsicon from "../assets/Icons/trips.svg";
import fleeticon from "../assets/Icons/fleet.svg";
import drivers from "../assets/Icons/drivers.svg";
import remindericon from "../assets/Icons/reminders.svg";
import geofencing from "../assets/Icons/geofence.svg";
import settings from "../assets/Icons/settings.svg";

// import logout from "../assets/Icons/logout.svg";

import axonsimg from "../assets/Images/axons.svg";
import axonslab from "../assets/Images/axonslab.svg";
import backicon from "../assets/Icons/backicon.svg";
import workorder from "../assets/Icons/workorder.svg";
import ongoingtrip from "../assets/Icons/ongoingtripssidebar.svg";
import scheduledtrip from "../assets/Icons/scheduledtripsicon.svg";
import completedtrip from "../assets/Icons/completedtrips.svg";
import assignedtrips from "../assets/Icons/assignedtrips.svg";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  useTheme,
  Collapse,
  Button,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilledButton from "../components/MainButtons/FilledButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"; // Close icon
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotificationDrwer from "./NotificationDrawer";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import apiInstance from "../api/axios";
import { useDispatch } from "react-redux";
import { usePostData } from "../hooks/useApi";
import {
  login,
  logout,
  setAuthToken,
  setCurrentRole,
} from "../slices/authSlice.js";
import { useSnackbar } from "../hooks/Snackbar";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1.5,
  borderRadius: "15px",
  textAlign: "center",
};

const buttonStyle = {
  background:
    "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
  color: "white",
  width: "10rem",
  padding: "10px 20px",
  fontFamily: "DM Sans, Arial, sans-serif",
  borderRadius: "15px",
  border: "none",
  cursor: "pointer",
  marginTop: "10px",
  marginBottom: "10px",
  textTransform: "none",
};
const drawerWidth = 230;

const DashboardLayout = () => {
  const [open, setOpen] = useState(true);
  const [openGeofencing, setOpenGeofencing] = useState(false);
  const [openPayments, setOpenPayments] = useState(false);
  const [profileDrawer, setProfileDrawer] = useState(false);
  const [notificationDrawer, setNotificatioDrawer] = useState(false);
  const [tripsOpen, setTripsOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("Dashboard"); // Add state for header title
  const location = useLocation(); // Get the current path
  const [profileData, setProfileData] = useState({});
  const [profileLoading, setProfileLoading] = useState(true);
  const [openLogout, setOpenLogout] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const postData = usePostData();
  const showSnackbar = useSnackbar();

  const handleClickGeofencing = () => {
    setOpenGeofencing(!openGeofencing);
  };

  const handleClickPayments = () => {
    setOpenPayments(!openPayments);
  };
  const handleTripsClick = () => {
    setTripsOpen(!tripsOpen);
  };

  const getProfile = (params) => {
    return apiInstance.get(`/profile`, {
      params: {
        ...params,
      },
    });
  };

  const getProfileData = () => {
    return async (dispatch) => {
      setProfileLoading(true);
      try {
        const res = await getProfile();
        if (res.data) {
          const { data } = res.data;
          console.log(data);
          setProfileData(data);
        }
        setProfileLoading(false);
      } catch (err) {
        setProfileLoading(false);
        if (err?.response?.status === 401) {
          showSnackbar(
            "Oops! Something went wrong, and you've been logged out.",
            "error"
          );
          dispatch(logout());
          navigate("/login");
          dispatch(setAuthToken(null));
          dispatch(setCurrentRole(null));
          return;
        }
        console.log(err);
      } finally {
        setProfileLoading(false);
      }
    };
  };

  const handleProfile = () => {
    setProfileDrawer(true);
    dispatch(getProfileData());
  };

  const pathTitleMap = {
    "/dashboard/analytics": "Analytics",
    "/dashboard/map": "Map",
    "/dashboard/vehicles": "Vehicles",
    "/dashboard/drivers": "Drivers",
    "/dashboard/trips": "Trips",
    "/dashboard/fleet": "Fleet",
    "/dashboard/reminder": "Reminder",
    "/dashboard/geozones": "GeoZones",
    "/dashboard/work-orders": "Work Orders",
    "/dashboard/settings": "Settings",
    "/dashboard/assignments": "Assignments",
    "/dashboard/scheduled": "Scheduled Trips",
    "/dashboard/ongoing": "Ongoing Trips",
    "/dashboard/completed": "Completed Trips",
  };

  // Use effect to update the header title based on the current path
  useLayoutEffect(() => {
    const currentPath = location.pathname;

    // Handle dynamic routes like `/dashboard/drivers/usr_Bob1234SmithXYZ`
    if (currentPath.startsWith("/dashboard/drivers/")) {
      setHeaderTitle("Driver Details");
    } else if (currentPath.startsWith("/dashboard/vehicles/")) {
      setHeaderTitle("Vehicle Details");
    } else if (currentPath.startsWith("/dashboard/ongoing/")) {
      setHeaderTitle("Ongoing Trips");
    } else if (currentPath.startsWith("/dashboard/completed/")) {
      setHeaderTitle("Completed Trips");
    } else {
      // Match exact paths from the map or fallback to "Dashboard"
      const matchedTitle = pathTitleMap[currentPath] || "Dashboard";
      setHeaderTitle(matchedTitle);
    }
  }, [location.pathname]); // Run this effect whenever the location changes

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleOpen = () => {
    setOpenLogout(true);
  };
  const handleClose = () => setOpenLogout(false);

  // const handleLogout = () => {
  //   navigate("/login");
  // };

  const menuItems = [
    {
      text: "Analytics",
      icon: (
        <img
          src={analytics}
          alt="analytics"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "analytics",
    },
    {
      text: "Map",
      icon: (
        <img
          src={mapicon}
          alt="map"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "map",
    },
    {
      text: "Fleet",
      icon: (
        <img
          src={fleeticon}
          alt="Fleet"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "fleet",
    },
    {
      text: "Vehicles",
      icon: (
        <img
          src={vehicleicon}
          alt="vehicle"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "vehicles",
    },
    {
      text: "Drivers",
      icon: (
        <img
          src={drivers}
          alt="user"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "drivers",
    },
    {
      text: "Trips",
      icon: (
        <img
          src={tripsicon}
          alt="trips"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "trips",
    },

    {
      text: "Reminder",
      icon: (
        <img
          src={remindericon}
          alt="Fleet"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "reminder",
    },
    {
      text: "GeoZones",
      icon: (
        <img
          src={geofencing}
          alt="Geozones"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      onClick: handleClickGeofencing,
      path: "geozones",
    },
    // {
    //   text: "Work Orders",
    //   icon: (
    //     <img
    //       src={workorder}
    //       alt="Work Orders"
    //       style={{ width: "25px", height: "25px" }}
    //     />
    //   ),
    //   path: "work-orders",
    // },

    {
      text: "Settings",
      icon: (
        <img
          src={settings}
          alt="Settings"
          style={{ width: "25px", height: "25px" }}
        />
      ),
      path: "settings",
    },
  ];

  const handleLogout = async () => {
    try {
      const { data } = await postData.mutateAsync({
        endpoint: "/auth/logout",
      });

      if (data) {
        navigate("/login");
        dispatch(setAuthToken(null));
        dispatch(setCurrentRole(null));
        dispatch(logout());
      }
    } catch (error) {
      navigate("/login");
      dispatch(setAuthToken(null));
      dispatch(setCurrentRole(null));
      dispatch(logout());
    } finally {
      navigate("/login");
      dispatch(setAuthToken(null));
      dispatch(setCurrentRole(null));
      dispatch(logout());
    }
  };

  const ConditionalTooltip = ({ open, title, children, sx }) => {
    if (!open) {
      return (
        <Tooltip
          title={title}
          placement="right"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#E0E0E0", // Light grey background
                color: "black", // Dark text for contrast
                fontWeight: "600",
                fontSize: "14px",
                padding: "10px 15px",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              },
            },
            arrow: {
              sx: {
                color: "#E0E0E0", // Matching the tooltip background
              },
            },
          }}
        >
          {children}
        </Tooltip>
      );
    }
    return children;
  };

  const handleNotifcation = () => {
    setNotificatioDrawer(true);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Drawer
          sx={{
            width: open ? drawerWidth : 70,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : 70,
              boxSizing: "border-box",
              background: theme.palette.background.default,
              border: "none",
              overflowX: "hidden",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box
            sx={{
              visibility: open ? "visible" : "hidden",
              width: "8.375rem",
              height: "4.625rem",
              mt: "1.563rem",
              ml: "1.563rem",
              mb: "0.313rem",
            }}
          >
            <img src={axonsimg} alt="logo" />
          </Box>
          <IconButton
            onClick={toggleDrawer}
            sx={{ color: "white" }}
            disableRipple
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <List>
            {menuItems.map((item) => (
              <React.Fragment key={item.text}>
                {item.text === "Trips" ? (
                  <>
                    <ListItem
                      button
                      onClick={handleTripsClick}
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: 0,
                        mb: 2,
                        background: location.pathname.includes(item.path)
                          ? `${theme.palette.gradient.main}`
                          : "",
                      }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: "white" }}
                        primary={item.text}
                      />
                      {tripsOpen ? (
                        <ExpandLess sx={{ color: "white", fontSize: "20px" }} />
                      ) : (
                        <ExpandMore sx={{ color: "white", fontSize: "20px" }} />
                      )}
                    </ListItem>
                    <Collapse in={tripsOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <Tooltip
                          title="Assignments"
                          disableHoverListener={open}
                          placement="right"
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#E0E0E0", // Light grey background
                                color: "black", // Dark text for contrast
                                fontWeight: "600",
                                fontSize: "14px",
                                padding: "10px 15px",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              },
                            },
                            arrow: {
                              sx: {
                                color: "#E0E0E0", // Matching the tooltip background
                              },
                            },
                          }}
                        >
                          <ListItem
                            sx={{
                              pl: open ? 4 : 2,
                              gap: 0,
                              mb: 2,
                              background: location.pathname.includes(
                                "assignments"
                              )
                                ? `${theme.palette.gradient.main}`
                                : "",
                            }}
                            component={Link}
                            to="/dashboard/assignments"
                          >
                            <ListItemIcon sx={{ color: "white" }}>
                              <img
                                src={assignedtrips}
                                alt="assignedtrips"
                                style={{ width: "25px", height: "25px" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ color: "white" }}
                              primary="Assignments"
                            />
                          </ListItem>
                        </Tooltip>
                        <Tooltip
                          title="Scheduled"
                          disableHoverListener={open}
                          placement="right"
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#E0E0E0", // Light grey background
                                color: "black", // Dark text for contrast
                                fontWeight: "600",
                                fontSize: "14px",
                                padding: "10px 15px",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              },
                            },
                            arrow: {
                              sx: {
                                color: "#E0E0E0", // Matching the tooltip background
                              },
                            },
                          }}
                        >
                          <ListItem
                            sx={{
                              pl: open ? 4 : 2,
                              gap: 0,
                              mb: 2,
                              background: location.pathname.includes(
                                "scheduled"
                              )
                                ? `${theme.palette.gradient.main}`
                                : "",
                            }}
                            component={Link}
                            to="/dashboard/scheduled"
                          >
                            <ListItemIcon sx={{ color: "white" }}>
                              <img
                                src={scheduledtrip}
                                alt="scheduledtrip"
                                style={{ width: "25px", height: "25px" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ color: "white" }}
                              primary="Scheduled"
                            />
                          </ListItem>
                        </Tooltip>
                        <Tooltip
                          title="Trips"
                          disableHoverListener={open}
                          placement="right"
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#E0E0E0", // Light grey background
                                color: "black", // Dark text for contrast
                                fontWeight: "600",
                                fontSize: "14px",
                                padding: "10px 15px",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              },
                            },
                            arrow: {
                              sx: {
                                color: "#E0E0E0", // Matching the tooltip background
                              },
                            },
                          }}
                        >
                          <ListItem
                            component={Link}
                            to="/dashboard/ongoing"
                            sx={{
                              pl: open ? 4 : 2,
                              gap: 0,
                              mb: 2,
                              background: location.pathname.includes("ongoing")
                                ? `${theme.palette.gradient.main}`
                                : "",
                            }}
                          >
                            <ListItemIcon sx={{ color: "white" }}>
                              <img
                                src={ongoingtrip}
                                alt="ongoingtrip"
                                style={{ width: "25px", height: "25px" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ color: "white" }}
                              primary="Ongoing"
                            />
                          </ListItem>
                        </Tooltip>
                        <Tooltip
                          title="Completed"
                          disableHoverListener={open}
                          placement="right"
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#E0E0E0", // Light grey background
                                color: "black", // Dark text for contrast
                                fontWeight: "600",
                                fontSize: "14px",
                                padding: "10px 15px",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              },
                            },
                            arrow: {
                              sx: {
                                color: "#E0E0E0", // Matching the tooltip background
                              },
                            },
                          }}
                        >
                          <ListItem
                            sx={{
                              pl: open ? 4 : 2,
                              gap: 0,
                              mb: 2,
                              background: location.pathname.includes(
                                "completed"
                              )
                                ? `${theme.palette.gradient.main}`
                                : "",
                            }}
                            component={Link}
                            to="/dashboard/completed"
                          >
                            <ListItemIcon sx={{ color: "white" }}>
                              <img
                                src={completedtrip}
                                alt="completedtrip"
                                style={{ width: "25px", height: "25px" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ color: "white" }}
                              primary="Completed"
                            />
                          </ListItem>
                        </Tooltip>
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <ConditionalTooltip
                    open={open}
                    title={item.text}
                    sx={{ fontSize: "1rem" }}
                  >
                    <ListItem
                      component={item.path ? Link : "div"}
                      to={item.path}
                      onClick={() => {
                        item.onClick && item.onClick();
                      }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: 0,
                        mb: 2,
                        background: location.pathname.includes(item.path)
                          ? `${theme.palette.gradient.main}`
                          : "",
                      }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: "white" }}
                        primary={item.text}
                      />
                    </ListItem>
                  </ConditionalTooltip>
                )}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
        <Drawer
          sx={{
            width: { lg: 600, xl: 800 },
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: { lg: 600, xl: 800 },
              boxSizing: "border-box",
              border: "none",
            },
          }}
          anchor={"right"}
          open={notificationDrawer}
          onClose={() => setNotificatioDrawer(false)}
          transitionDuration={{
            enter: 500,
            exit: 500,
          }}
        >
          <NotificationDrwer setNotificatioDrawer={setNotificatioDrawer} />
        </Drawer>
        <Drawer
          sx={{
            width: 400,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 400,
              boxSizing: "border-box",
              border: "none",
            },
          }}
          anchor={"right"}
          open={profileDrawer}
          onClose={() => setProfileDrawer(false)}
          transitionDuration={{
            enter: 500,
            exit: 500,
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", mb: 2, mt: 2, p: 1 }}
          >
            <IconButton onClick={() => setProfileDrawer(false)}>
              <img src={backicon} alt="back-icon" />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                ml: 2,
                fontSize: "1.375rem",
                color: theme.palette.text.primary,
              }}
            >
              User Profile
            </Typography>
          </Box>
          <Divider style={{ border: "2px solid #F99494" }} />

          <Box sx={{ height: "100%" }}>
            {" "}
            {/* Ensures the Box takes full height of the Drawer */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 10,
                mb: 3,
              }}
            >
              <Box
                sx={{
                  width: "142px",
                  height: "142px",
                  border: "1px solid red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", // Center the content inside the circle
                  background: theme.palette.text.tertiary,
                  borderRadius: "50%",
                  color: "white",
                  border: `1px solid ${theme.palette.text.primary}`,
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center", // Center the text inside the Box
                    color: "white",
                    fontSize: "3.5rem",
                    fontWeight: 400,
                  }}
                >
                  {profileData?.name ? profileData?.name[0] : ""}
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: 500,
                textAlign: "center",
                color: theme.palette.text.primary,
              }}
            >
              {profileData?.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: 500,
                textAlign: "center",
                color: theme.palette.text.primary,
              }}
            >
              {profileData?.email}
            </Typography>
            {/* This Box takes up 50% of the Drawer height */}
            <Box
              sx={{
                mt: 10,
                background: "#D9D9D9",
                display: "flex",
                flexDirection: "column", // Stack content within this Box
                justifyContent: "center", // Align content vertically in the center
                alignItems: "center", // Align content horizontally in the center
                height: "50%", // Set to half of the Drawer height
              }}
            >
              <Box sx={{ mb: 5 }}>
                <img src={axonslab} alt="axonslab" />
              </Box>
              <Box
                onClick={handleOpen}
                sx={{
                  display: "flex",
                  justifyContent: "center", // Centers the button horizontally
                  mt: 2, // Optional: Add margin-top to space it from other content
                }}
              >
                <FilledButton sx={{ width: "230px", justifyContent: "center" }}>
                  Log out
                </FilledButton>
              </Box>
            </Box>
          </Box>
        </Drawer>

        <Box sx={{ width: "100%", p: 2 }}>
          <Box
            sx={{
              width: "98%",
              display: "flex",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: 700, fontSize: "1.625rem" }}>
                {headerTitle}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100px",
                justifyContent: "space-around",
              }}
            >
              <Box>
                <Tooltip
                  title="Notification"
                  placement="top"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "#E0E0E0", // Light grey background
                        color: "black", // Dark text for contrast
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "10px 15px",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "#E0E0E0", // Matching the tooltip background
                      },
                    },
                  }}
                  onClick={handleNotifcation}
                >
                  <Avatar
                    src={alert}
                    style={{ width: "26px", height: "26px", cursor: "pointer" }}
                  />
                </Tooltip>
              </Box>
              <Box
                sx={{
                  border: "0.5px solid #FFFFFF",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  background: theme.palette.text.tertiary,
                  cursor: "pointer",
                  display: "flex", // This enables Flexbox layout
                  justifyContent: "center", // Centers content horizontally
                  alignItems: "center", // Centers content vertically
                }}
                onClick={handleProfile}
              >
                <Tooltip
                  title="Profile"
                  placement="top"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "#E0E0E0", // Light grey background
                        color: "black", // Dark text for contrast
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "10px 15px",
                        borderRadius: "8px",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "#E0E0E0", // Matching the tooltip background
                      },
                    },
                  }}
                >
                  <Typography>CE</Typography>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Modal
        open={openLogout}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              sx={{ fontFamily: theme.typography.fontFamily, color: "#283246" }}
            >
              Log Out
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Typography
            id="modal-description"
            sx={{
              mt: 2,
              color: "#283246",
              fontFamily: theme.typography.fontFamily,
              fontWeight: 500,
            }}
          >
            We hope to see you back soon! Logging out will end your current
            session, and you’ll need to log in again to continue. Are you sure
            you want to proceed?
          </Typography>
          <Button
            sx={{
              "&:hover": {
                boxShadow: "none",
                transform: "scale(1.05)",
              },
            }}
            style={buttonStyle}
            onClick={handleLogout}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default DashboardLayout;
