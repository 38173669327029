import React, { useState } from "react";
import { AnalyticsCards } from "./AnalyticsCards/index";
import { AnalyticsGraphs } from "./AnalyticsGraphs/index";
import Chart from "react-apexcharts";
import { Box, Card, CardContent, Typography } from "@mui/material";
import TopPerformingDriver from "./TopPerformingDriver";
import ReminderSection from "./ReminderSelection";

const ConnectedVechicleData = [
  {
    type: "Thumbs up",
    Number: 50,
  },
  {
    type: "Total ratings",
    Number: 100,
  },
  {
    type: "Thumbs down",
    Number: 50,
  },
];

const chartOptions = {
  chartOptionsSubscription: {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
        borderRadius: 10,
        borderRadiusApplication: "both",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      type: "month",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        // text: 'Trips',
        style: {
          color: "#9aa0ac",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          colors: "#9aa0ac",
          fontSize: "14px",
        },
      },
    },
    tooltip: {
      x: {
        format: "yyyy",
      },
      y: {
        formatter: (value) => `${value} No. of trips`,
      },
    },
    colors: ["#535A6A", "#FFB1B1"],
    fill: {
      opacity: 0.8,
    },
    markers: {
      size: 4,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  },

  chartOptionsVehicleUtilization: {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
        borderRadius: 10,
        borderRadiusApplication: "both",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      type: "month",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        // text: 'Trips',
        style: {
          color: "#9aa0ac",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          colors: "#9aa0ac",
          fontSize: "14px",
        },
      },
    },
    tooltip: {
      x: {
        format: "yyyy",
      },
      y: {
        formatter: (value) => `${value} Utilized`,
      },
    },
    colors: ["#283246", "#FFB1B1"],
    fill: {
      opacity: 0.8,
    },
    markers: {
      size: 4,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  },
  chartOptionsActiveDriver: {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
        borderRadius: 10,
        borderRadiusApplication: "both",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      type: "month",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        // text: 'Trips',
        style: {
          color: "#9aa0ac",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          colors: "#9aa0ac",
          fontSize: "14px",
        },
      },
    },
    tooltip: {
      x: {
        format: "yyyy",
      },
    },
    colors: ["#F99494", "#FFB1B1"],
    fill: {
      opacity: 0.8,
    },
    markers: {
      size: 4,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  },
  chartOptionsDriver: {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
        borderRadius: 10,
        borderRadiusApplication: "both",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      type: "month",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
      ],
    },
    yaxis: {
      title: {
        // text: 'Trips',
        style: {
          color: "#9aa0ac",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          colors: "#9aa0ac",
          fontSize: "14px",
        },
      },
    },
    tooltip: {
      x: {
        format: "yyyy",
      },
      y: {
        formatter: (value) => `${value} Active drivers`,
      },
    },
    colors: ["#F99494", "#F99494"],
    fill: {
      opacity: 0.8,
    },
    markers: {
      size: 4,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  },
  chartOptionsTotalTripTime: {
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: false,
        tools: {
          download: false, // Allows downloading the chart as a PNG, SVG, or CSV.
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    dataLabels: {
      enabled: false, // Disables the display of data labels on the chart.
    },
    stroke: {
      curve: "smooth",
      width: 3, // Defines the line thickness.
    },
    xaxis: {
      type: "category", // Changed from "month" to "category" for proper categorization.
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    tooltip: {
      x: {
        format: "MMMM yyyy", // Updated to show month and year in the tooltip.
      },
    },
    colors: ["#F99494", "#A6C1E0"], // Customizes the colors of the series.
    fill: {
      opacity: 0.3, // Adjusts the fill opacity of the area under the line.
    },
    markers: {
      size: 4, // Size of the markers at data points.
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left", // Positions the legend at the top left.
    },
  },
  chartOptionsvehicleavailability: {
    chart: {
      type: "area",
      height: 350,
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      type: "month",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    tooltip: {
      x: {
        format: "yyyy",
      },
    },
    colors: ["#283246", "#F99494"],
    fill: {
      opacity: 0.3,
    },
    markers: {
      size: 4,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
    },
  },
  chartoptionsAxonsTrips: {
    chart: {
      type: "donut",
      background: "transparent", // Make the background transparent
      events: {
        click: function (event, chartContext, config) {
          console.log("Donut chart clicked", event, chartContext, config);
          // Handle click event as needed
        },
      },
    },
    labels: ["Negative", "Positive"],
    colors: ["#283246", "#FFA9A9"], // Custom slice colors
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 15, // Adjust the width of the border around the slices
      colors: ["transparent"], // Set stroke color to transparent to avoid the white border
      curve: "smooth",
    },
    annotations: {
      position: "front",
      texts: [
        {
          text: "50%", // Centered percentage text
          x: "50%", // Centering on the x-axis
          y: "50%", // Centering on the y-axis
          fontSize: "30px",
          fontWeight: "bold",
          fontFamily: "Helvetica Neue, Helvetica, sans-serif",
          color: "#283246", // Text color
          textAnchor: "middle", // Ensures the text is centered
          verticalAlign: "middle", // Vertically aligns text in the middle
          backgroundColor: "transparent", // Ensure background is transparent
          borderRadius: 5,
          padding: 10,
        },
        {
          text: "Positive", // Centered label text
          x: "50%", // Centering on the x-axis
          y: "60%", // Adjusted vertical position for clarity
          fontSize: "20px",
          fontWeight: "800",
          fontFamily: "Helvetica Neue, Helvetica, sans-serif",
          color: "#283246", // Text color
          textAnchor: "middle", // Ensures the text is centered
          verticalAlign: "middle", // Vertically aligns text in the middle
          backgroundColor: "transparent", // Ensure background is transparent
          borderRadius: 5,
          padding: 10,
        },
      ],
    },
  },
};

const chartSeries = {
  subscriptionSeries: [
    {
      name: "2023",
      data: [250, 380, 500, 450, 280, 400, 250, 400, 250, 300],
    },
    {
      name: "2024",
      data: [300, 420, 400, 350, 250, 500, 350, 250, 350, 320],
    },
  ],
  vehicleutilizationSeries: [
    {
      name: "Vehicle",
      data: [300, 420, 400, 350, 250, 500, 350, 250, 350, 320],
    },
  ],
  driverSeries: [
    {
      name: "Driver",
      data: [50, 60, 20, 60, 80, 70, 30, 40, 45, 120],
    },
  ],
  totalTripsSeries: [
    {
      name: "2023",
      data: [310, 280, 250, 270, 280, 300, 320, 300, 250, 200, 250, 200],
    },
    {
      name: "2024",
      data: [320, 300, 280, 300, 280, 250, 270, 280, 300, 250, 300, 280],
    },
  ],
  vehicleAvailabilitySeries: [
    {
      name: "Availability",
      data: [315, 285, 255, 275, 285, 305, 325, 305, 255, 205, 255, 205], // Slightly increased values
    },
    {
      name: "Productivity",
      data: [300, 280, 200, 240, 285, 260, 285, 285, 245, 180, 230, 185], // Slightly increased values
    },
  ],
  axonsTrip: [50, 50],
};
export const Analytics = () => {
  const [selectedCard, setSelectedCard] = useState("Trips");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Box>
        <AnalyticsCards
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
        />
      </Box>
      {selectedCard === "Trips" && (
        <>
          <Card
            sx={{
              boxShadow: "0px 1px 10px 0px #00000017",
              borderRadius: "1rem",
              width: "100%",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  sx={{
                    color: "#283246",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                >
                  Trips
                </Typography>
              </Box>
              <Chart
                options={chartOptions.chartOptionsSubscription}
                series={chartSeries.subscriptionSeries}
                type="bar"
                height={350}
              />
            </CardContent>
          </Card>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Card
              sx={{
                boxShadow: "0px 1px 10px 0px #00000017",
                borderRadius: "1rem",
                width: "70%",
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Typography
                    sx={{
                      color: "#283246",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    Total Trip Time
                  </Typography>
                </Box>
                <Chart
                  options={chartOptions.chartOptionsTotalTripTime}
                  series={chartSeries.totalTripsSeries}
                  type="area"
                  height={350}
                />
              </CardContent>
            </Card>
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              bgcolor="#FFFFFF"
              borderRadius="25px"
              sx={{ p: 0.5, width: "30%" }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        paddingInline: "15px",
                        paddingTop: "20px",
                        color: "#283246",
                        fontSize: "1rem",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      Axons Trip Ratings{" "}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Chart
                  options={chartOptions.chartoptionsAxonsTrips}
                  series={chartSeries.axonsTrip}
                  type="donut"
                  height={"330px"}
                />
              </Box>
              <Box display="flex" justifyContent="space-between">
                {ConnectedVechicleData.map((data, key) => {
                  return (
                    <Box textAlign="center" key={key}>
                      <Box>
                        <Typography
                          sx={{
                            paddingInline: "15px",
                            paddingBlock: "5px",
                            color: "#1F1F1F",
                            fontSize: "16px",
                            fontFamily: "Helvetica Neue, Helvetica, sans-serif",
                            fontWeight: "600",
                          }}
                        >
                          {data.Number}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            paddingInline: "15px",
                            paddingBlock: "5px",
                            color: "#1F1F1F",
                            fontSize: "14px",
                            fontFamily: "Helvetica Neue, Helvetica, sans-serif",
                            fontWeight: "400",
                          }}
                        >
                          {data.type}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {selectedCard === "Drivers" && (
        <>
          {/**driver start here  */}
          <Card
            sx={{
              boxShadow: "0px 1px 10px 0px #00000017",
              borderRadius: "1rem",
              width: "100%",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  sx={{
                    color: "#283246",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                >
                  Active Driver
                </Typography>
              </Box>
              <Chart
                options={chartOptions.chartOptionsActiveDriver}
                series={chartSeries.driverSeries}
                type="bar"
                height={350}
              />
            </CardContent>
          </Card>
          <Box>
            <Box display="flex" flexDirection="row" gap={2}>
              <TopPerformingDriver />
              <ReminderSection />
            </Box>
          </Box>
        </>
      )}

      {selectedCard === "Vehicles" && (
        <>
          <Card
            sx={{
              boxShadow: "0px 1px 10px 0px #00000017",
              borderRadius: "1rem",
              width: "100%",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  sx={{ color: "#283246", fontSize: "1rem", fontWeight: "500" }}
                >
                  Vehicle Availability & Productivity
                </Typography>
              </Box>
              <Chart
                options={chartOptions.chartOptionsvehicleavailability}
                series={chartSeries.vehicleAvailabilitySeries}
                type="area"
                height={350}
              />
            </CardContent>
          </Card>
          <Card
            sx={{
              boxShadow: "0px 1px 10px 0px #00000017",
              borderRadius: "1rem",
              width: "100%",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  sx={{
                    color: "#283246",
                    fontSize: "1rem",
                    fontWeight: "500",
                  }}
                >
                  Vehicle Utilization
                </Typography>
              </Box>
              <Chart
                options={chartOptions.chartOptionsVehicleUtilization}
                series={chartSeries.vehicleutilizationSeries}
                type="bar"
                height={350}
              />
            </CardContent>
          </Card>
          <Box>
            <AnalyticsGraphs />
          </Box>
        </>
      )}
    </Box>
  );
};