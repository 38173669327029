import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  useTheme,
  Tabs,
  Tab,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { MapComponent } from "../../components/MapComponent";
import crossicon from "../../assets/Icons/drawercross.svg";
import actionedit from "../../assets/Icons/actionedit.svg";
import actiondelete from "../../assets/Icons/actiondelete.svg";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { addFleet } from "../../slices/fleetManageSlice";
import { usePatchData, usePostData } from "../../hooks/useApi";
import { useSnackbar } from "../../hooks/Snackbar";

const inputLabel = {
  fontSize: "0.8rem",
  fontWeight: 700,
  mb: 1,
  color: "#283246",
};

const InputFieldWrapper = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  height,
  errors,
  editable = true,
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
      </InputLabel>
      <CustomTextField
        value={value}
        onChange={onChange}
        name={name}
        error={!!errors}
        sx={{
          "& .MuiOutlinedInput-root": {
            width: {
              xs: "100px", // Width for small screens
              sm: "300px", // Width for medium screens
              md: "400px", // Width for large screens
              lg: "300px",
              xl: "400px",
            },
            height: "40px",
          },
          input: {
            fontSize: "0.9rem",
            height: height ? `${height}px` : "auto", // Input text area height
          },
        }}
        disabled={!editable}
        placeholder={placeholder}
      />
      {errors && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {errors}
        </FormHelperText>
      )}
    </Box>
  );
};

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  name,
  placeholder,
  errors,
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        sx={{
          borderRadius: "10px",
          width: {
            xs: "100px", // Width for small screens
            sm: "300px", // Width for medium screens
            md: "400px", // Width for large screens
            lg: "300px", // Width for larger screens
            xl: "400px", // Width for extra-large screens
          },
          height: "40px",
          fontSize: "0.9rem",
        }}
        name={name}
        displayEmpty
        errors={!!errors}
      >
        <MenuItem value="">
          <Typography color="text.primary">{placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {errors && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {errors}
        </FormHelperText>
      )}
    </Box>
  );
};

const AddFleetForm = ({
  drawerOpen,
  setDrawerOpen,
  editUser,
  setEditUser,
  handleDelete,
  handApiCall,
}) => {
  const [editable, setEditable] = useState(true);
  const { fleet } = useSelector((state) => state.fleetManage);
  const fleets = fleet?.data?.find((u) => u.id === editUser);
  const postData = usePostData();
  const patchData = usePatchData();
  console.log(fleets, "fleets");
  const [formData, setFormData] = useState({
    fleet_name: fleets?.name || "",
    support_email: fleets?.support_email || "",
    alert_email: fleets?.alert_email || "",
    address: fleets?.address || "",
    location_lat: fleets?.location?.coordinates[1] || "",
    location_lng: fleets?.location?.coordinates[0] || "",
    id: fleets?.id || "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [value, setValue] = useState(0);

  const theme = useTheme();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const [location, setLocation] = useState({
    lat: formData.location_lat || "",
    lng: formData.location_lng || "",
  });

  useEffect(() => {
    if (fleets) {
      setFormData({
        fleet_name: fleets?.name || "",
        support_email: fleets?.support_email || "",
        alert_email: fleets?.alert_email || "",
        address: fleets?.address || "",
        location_lat: fleets?.location?.coordinates[1] || "",
        location_lng: fleets?.location?.coordinates[0] || "",
        // parking_requirement: fleets?.parking_requirement || "",
        // auto_end_ride_critical_battery_vehicle:
        //   fleets?.auto_end_ride_critical_battery_vehicle || "",
        // distance_lock_unlock_vehicle:
        //   fleets?.distance_lock_unlock_vehicle || "",
        // auto_end_ride_idle_vehicle: fleets?.auto_end_ride_idle_vehicle || "",
        // low_battery_level: fleets?.low_battery_level || "",
        // pause_ride: fleets?.pause_ride || "",
        // auto_end_ride_paused_vehicle:
        //   fleets?.auto_end_ride_paused_vehicle || "",
        // auto_close_vehicle: fleets?.auto_close_vehicle || "",
        id: fleets?.id || "",
      });

      if (fleets?.location?.coordinates) {
        setLocation({
          lat: parseFloat(fleets?.location?.coordinates[1]),
          lng: parseFloat(fleets?.location?.coordinates[0]),
        });
      }
    }
  }, [fleets]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown") {
      return;
    }
    setDrawerOpen(open);
    setEditUser("");
    setFormData({
      fleet_name: "",
      support_email: "",
      alert_email: "",
      address: "",
      location_lat: "",
      location_lng: "",
      id: "",
    });
    setValue(0);
    setEditable(true);
  };

  const handleLocationSelected = (latLng) => {
    setLocation(latLng);
    setFormData((prevState) => ({
      ...prevState,
      location_lat: latLng.lat,
      location_lng: latLng.lng,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setFormErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (
        name !== "id" &&
        ((typeof value === "string" && value.trim()) || value)
      ) {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (editUser) {
      setEditable(false);
    }
  }, [editUser]);

  const handleFleetDelete = () => {
    handleDelete(editUser);
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (key !== "id") {
        if (typeof formData[key] === "string" && !formData[key].trim()) {
          errors[key] = "This field is required";
        } else if (formData[key] == null || formData[key] === "") {
          errors[key] = "This field is required";
        }
      }
    });

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const payload = {
    name: formData?.fleet_name,
    support_email: formData?.support_email,
    alert_email: formData?.alert_email,
    address: formData?.address,
    location: {
      longitude: formData?.location_lng,
      latitude: formData?.location_lng,
    },
  };

  const addNewFleet = async () => {
    try {
      const { data } = await postData.mutateAsync({
        endpoint: `/fleets`,
        data: payload,
      });

      if (data) {
        showSnackbar("Fleet added successfully!", "success");
      }
      handApiCall();
    } catch (error) {
      // if (error?.response?.status === 401) {
      //   showSnackbar(
      //     "Oops! Something went wrong, and you've been logged out.",
      //     "error"
      //   );
      //   dispatch(logout());
      //   return;
      // }
      showSnackbar(
        error?.response?.data?.message || "Failed to load data",
        "error"
      );
    } finally {
      // setIsLoadingData(false);
    }
  };

  const editFleet = async (id) => {
    try {
      const { data } = await patchData.mutateAsync({
        endpoint: `fleets/${id}`,
        data: payload,
      });

      if (data) {
        showSnackbar("Fleet edited successfully!", "success");
      }
      handApiCall();
    } catch (error) {
      // if (error?.response?.status === 401) {
      //   showSnackbar(
      //     "Oops! Something went wrong, and you've been logged out.",
      //     "error"
      //   );
      //   dispatch(logout());
      //   return;
      // }
      showSnackbar(
        error?.response?.data?.message || "Failed to load data",
        "error"
      );
    } finally {
    }
  };

  const handleSaveChanges = () => {
    if (validateForm()) {
      // dispatch(addFleet(formData));
      setDrawerOpen(false);
      toggleDrawer();
      editUser ? editFleet(editUser) : addNewFleet();
      // showSnackbar(
      //   editUser ? "Fleet edited successfully!" : "Fleet added successfully!",
      //   "success"
      // );
    } else {
      console.error("Form errors:", formErrors);
    }
  };

  const handleEditview = () => {
    setEditable(!editable);
  };

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      transitionDuration={{
        enter: 500,
        exit: 500,
      }}
      PaperProps={{
        sx: {
          width: "80%",
          boxSizing: "border-box",
          background: theme.palette.text.quaternary,
          border: "none",
        },
      }}
      sx={{
        width: "80%",
        flexShrink: 0,
        borderRadius: "1rem",
      }}
    >
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box sx={{ flex: 1, width: "65%" }}>
          <MapComponent
            height={"98vh"}
            width={"100%"}
            enablePin={"true"}
            onLocationSelected={handleLocationSelected}
          />
        </Box>

        <Box
          sx={{
            width: "35%",
            padding: "20px",
            backgroundColor: "#ffff",
            minHeight: "80vh",
            overflow: "auto",
          }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              display: "flex",
              background: "F8F8F8",
              justifyContent: "space-between",
              p: 0.5,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: "1rem", mt: 0.5 }}>
              {editUser ? "Edit Fleet" : "Create New Fleet"}
            </Typography>
            <Box sx={{ display: "flex" }}>
              {editUser && (
                <>
                  <Box
                    onClick={handleEditview}
                    sx={{
                      border: "1px solid #DDDEE1",
                      borderRadius: "6px",
                      p: 0.2,
                      mr: 0.5,
                      ml: 0.5,
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip
                      title="Edit"
                      placement="top"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#424242", // Dark grey background for high contrast
                            color: "white", // White text for clarity
                            fontWeight: "600",
                            fontSize: "14px",
                            padding: "10px 15px",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#424242", // Matching the tooltip background
                          },
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          color: theme.palette.text.primary,
                          width: "25px",
                          height: "25px",
                        }}
                      >
                        <img
                          src={actionedit}
                          alt="edit"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box
                    onClick={handleFleetDelete}
                    sx={{
                      border: "1px solid #DDDEE1",
                      borderRadius: "6px",
                      p: 0.2,
                      mr: 0.5,
                      ml: 0.5,
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip
                      title="Delete"
                      placement="top"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#424242", // Dark grey background for high contrast
                            color: "white", // White text for clarity
                            fontWeight: "600",
                            fontSize: "14px",
                            padding: "10px 15px",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#424242", // Matching the tooltip background
                          },
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          color: theme.palette.text.primary,
                          width: "25px",
                          height: "25px",
                        }}
                      >
                        <img
                          src={actiondelete}
                          alt="delete"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )}
              <IconButton
                onClick={toggleDrawer(false)}
                sx={{ color: theme.palette.text.primary }}
              >
                <img src={crossicon} alt="Close" />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Active Users"
              indicatorColor="primary"
              sx={{
                borderRadius: "12px",
                p: 1,
                "& .MuiTabs-indicator": {
                  background: "#FE7676",
                  height: "4px",
                },
                "& .Mui-selected": {
                  "& .MuiTab-root": {
                    color: "red",
                    borderBottom: "2px solid red",
                  },
                },
              }}
            >
              {["Fleet Information"].map((label, index) => (
                <Tab
                  key={label}
                  disableRipple
                  disabled={true}
                  label={label}
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: 500,
                    "&.Mui-selected": {
                      color: "#283246",
                    },
                  }}
                />
              ))}
            </Tabs>
          </Box>

          {value === 0 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "80vh",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                    flex: "1 1 auto",
                  }}
                >
                  <InputFieldWrapper
                    label="Name"
                    name="fleet_name"
                    value={formData.fleet_name}
                    onChange={handleInputChange}
                    placeholder="Enter Fleet Name"
                    errors={formErrors.fleet_name}
                    editable={editable}
                  />
                  <InputFieldWrapper
                    label="Support Email"
                    name="support_email"
                    value={formData.support_email}
                    onChange={handleInputChange}
                    placeholder="Enter Support Email"
                    errors={formErrors.support_email}
                    editable={editable}
                  />
                  <InputFieldWrapper
                    label="Alert Email"
                    name="alert_email"
                    value={formData.alert_email}
                    onChange={handleInputChange}
                    placeholder="Enter Alert Email"
                    errors={formErrors.alert_email}
                    editable={editable}
                  />
                  <InputFieldWrapper
                    label="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    placeholder="Enter Fleet Address"
                    errors={formErrors.address}
                    editable={editable}
                  />
                  <InputFieldWrapper
                    label="Location Latitude"
                    name="location_lat"
                    value={formData.location_lat}
                    onChange={handleInputChange}
                    placeholder="Enter Fleet Latitude"
                    errors={formErrors.location_lat}
                    editable={editable}
                  />
                  <InputFieldWrapper
                    label="Location Longitude"
                    name="location_lng"
                    value={formData.location_lng}
                    onChange={handleInputChange}
                    placeholder="Enter Fleet Longitude"
                    errors={formErrors.location_lng}
                    editable={editable}
                  />
                </Box>

                <Box
                  sx={{
                    p: 2,
                    width: "80%",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <FilledButton
                    sx={{
                      width: "150px",
                      "&.Mui-disabled": {
                        color: "white",
                        bgcolor: "grey",
                      },
                    }}
                    onClick={handleSaveChanges}
                    disabled={!editable}
                  >
                    {editUser ? "Save Changes" : "Create"}
                  </FilledButton>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddFleetForm;
