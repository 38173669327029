// src/slices/geofenceManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid'; // Assuming you're using uuid to generate unique IDs
import { geofenceData } from "../features/MapPage/utils";


const initialState = {
  geofences: geofenceData,
};

const geofenceManageSlice = createSlice({
  name: "geofences",
  initialState,
  reducers: {
    updatedGeofence: (state, action) => {
      state.geofences = action.payload;
    },
    addGeofenceData: (state, action) => {
      const fleetIndex = state.geofences.findIndex(d => d.id === action.payload.id);
      if (fleetIndex !== -1) {
        // Geofence exists, so update it
        state.geofences[fleetIndex] = {
            ...state.geofences[fleetIndex],
            ...action.payload,
        };
    } else{
      const newGeofences = { ...action.payload, id: `usr_${uuidv4()}`,create_date: "2023-05-10",
      create_by: "Admin",
      updated_date: "2023-05-10",status:'Active' };
      state.geofences.push(newGeofences);
    }




    },
    deleteGeofences: (state, action) => {
      const userId = action.payload;
      // Find the user in the geofences array
      const userToDelete = state.geofences.find(user => user.id === userId);

      if (userToDelete) {
        // Remove the user from the geofences array
        state.geofences = state.geofences.filter(user => user.id !== userId);
        
        
      }
    },
  },
});

export const { updatedGeofence,deleteGeofences,addGeofenceData} = geofenceManageSlice.actions;
export default geofenceManageSlice.reducer;
