import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilledButton from "../../components/MainButtons/FilledButton";
import DeleteIcon from "../../assets/Icons/delete.svg";

const DeleteUserModal = ({
  open,
  handleClose,
  handleDeleteUser,
  header = "Confirm Action",
  title = "Are you sure you want to delete this item? This action is irreversible.",
  confirmText = "Yes, Delete",
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "576px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            id="modal-title"
            sx={{
              color: theme.palette.text.primary,
              fontSize: "1.5rem",
              fontWeight: 500,
            }}
          >
            {header}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />

        <Box
          sx={{
            background: theme.palette.text.primary,
            width: "2.75rem",
            height: "2.75rem",
            padding: "0.438rem",
            borderRadius: "1.25rem",
            margin: "auto",
            mt: 2,
          }}
        >
          <img
            src={DeleteIcon}
            alt="delete-icon"
            style={{ width: "42px", height: "42px" }}
          />
        </Box>

        <Typography
          id="modal-description"
          sx={{
            mt: 2,
            color: theme.palette.text.primary,
            width: "80%",
            margin: "auto",
            p: 2,
            fontSize: "1rem",
            fontWeight: 500,
          }}
        >
          {title}
        </Typography>

        <Box sx={{ display: "flex", justifyContent:'center',gap:2, mt: 3 }}>
          <FilledButton
            onClick={handleDeleteUser}
            sx={{ width: "200px", bgcolor: theme.palette.error.main }}
          >
            {confirmText}
          </FilledButton>
          <FilledButton
            onClick={handleClose}
            sx={{
              border: `1px solid ${theme.palette.text.secondary}`,
              width: "150px",
              color: "black",
              background: "white",
              "&:hover": {
                background: "white",
                transform: 'scale(1.05)',
              },

            }}
           
          >
           No, Cancel
          </FilledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteUserModal;
