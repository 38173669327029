import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '../../assets/Icons/search.svg';

const SearchTextField = ({
  placeholder = "Search by Name",
  variant = "outlined",
  size = "small",
  InputProps = {},
  endAdornment = <img src={SearchIcon} alt="search"/> ,
  sx = {},
  onChange,
  width = '20.625rem',
  backgroundColor = '#FFF',
  textColor = '#283246',
  fontSize = '1rem',
  placeholderColor = '#888',  // New prop for placeholder color
}) => {
  return (
    <TextField
      placeholder={placeholder}
      variant={variant}
      size={size}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {endAdornment}
          </InputAdornment>
        ),
        ...InputProps,
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "11px",
          color: textColor,
          backgroundColor: backgroundColor,
          fontSize: fontSize,
          "& fieldset": {
            border: '1px solid #DDDEE1', // Border color
          },
        },
        "& .MuiOutlinedInput-root:hover fieldset": {
          borderColor: "#DDDEE1", // Hover border color
        },
        "& .MuiOutlinedInput-root.Mui-focused fieldset": {
          borderColor: "#DDDEE1", // Focus border color
        },
        "& .MuiOutlinedInput-input::placeholder": {
          color: placeholderColor, // Placeholder color customization
          opacity: 1, // Ensures the color is fully applied
        },
        width: width,
        marginBottom: "1.5rem",
        ...sx, // Spread custom sx styles provided as prop
      }}
    />
  );
};

export default SearchTextField;
