import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";

// Importing icons
import tripstarted from "../../assets/Icons/tripstarted.svg"
import destination from "../../assets/Icons/destination.svg";
import destinationend from "../../assets/Icons/destinationend.svg";
import stopage from "../../assets/Icons/stopage.svg";

const getIcon = (iconName) => {
  switch (iconName) {
    case "destinationIcon":
      return destinationend;
    case "stopIcon":
      return stopage;
    case "startIcon":
      return tripstarted;
    case "driveIcon":
      return destination;
    case "destinationendIcon":
      return destinationend;
    default:
      return destination; // Default icon for driving
  }
};

const CustomTimeline = ({ timelinedata }) => {
  return (
    <Timeline
      sx={{
        "& .MuiTimelineItem-root:before": { display: "none" },
        background: "#F8F8F8",
        borderRadius: "0.5rem",
        maxHeight: "500px",
        overflowY: "auto",
        padding: "10px 0",
        width: "100%",
      }}
    >
      {timelinedata.map((item, index) => (
        <TimelineItem key={index} sx={{ minHeight: "110px" }}>
          <TimelineSeparator
            sx={{ marginRight: "16px", alignContent: "flex-start" }}
          >
            <Avatar
              src={getIcon(item.icon)}
              sx={{ width: "24px", height: "24px" }}
            />
            {index !== timelinedata.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 0 }}>
            <Typography variant="body2" color="textSecondary">
              {item.time}
            </Typography>
            <Typography variant="h6" color="textPrimary">
              {item.eventTitle}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {item.eventDescription}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default CustomTimeline;
