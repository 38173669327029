import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import "./CalendarStyles.css";

const localizer = momentLocalizer(moment);

const TripCalendar = ({
  trips,
  onEditTrip,
  onDateClick,
  selectedVehicleFilter,
  selectedDriverFilter,
}) => {
  const handleEventSelect = (event) => {
    onEditTrip(event); // Trigger the edit modal with the selected trip
  };

  const handleDateClick = (date) => {
    onDateClick(date); // Trigger the modal for creating a new assignment
  };

  // Custom event component to display title and times
  const EventDisplay = ({ event }) => (
    <Tooltip
      title={event.notes || ""}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#E0E0E0", // Light grey background
            color: "black", // Dark text for contrast
            fontWeight: "600",
            fontSize: "14px",
            padding: "10px 15px",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          },
        },
        arrow: {
          sx: {
            color: "#E0E0E0", // Matching the tooltip background
          },
        },
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: 500, fontSize: "1rem" }}>
          {event.title}
        </Typography>
        {/* <Typography sx={{ fontWeight: 400, fontSize: "0.875rem" }}>
          {moment(event.start).format("HH:mm")} -{" "}
          {moment(event.end).format("HH:mm")}
        </Typography> */}
      </Box>
    </Tooltip>
  );

  const eventPropGetter = (event) => {
    const isMatch =
      event.vehicle_qr_code === selectedVehicleFilter &&
      event.title === selectedDriverFilter;
    console.log(
      event.vehicle_qr_code,
      selectedVehicleFilter,
      event.title,
      selectedDriverFilter,
      "Event and Filters"
    );
    console.log(isMatch, "isMatch");
    return {
      style: {
        backgroundColor: isMatch ? "#FFFF00" : "#FFC0CB", // Yellow for matching, pinkish for others
        color: "white",
      },
    };
  };

  return (
    <Paper
      style={{
        height: "600px",
        padding: "10px",
        background: "#F8F8F8",
        border: "none",
        boxShadow: "none",
      }}
    >
      
      <Calendar
        localizer={localizer}
        events={trips}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%" }}
        views={["month", "week", "day"]}
        defaultView="month"
        selectable // Enable clicking on empty dates
        onSelectSlot={(slotInfo) => handleDateClick(slotInfo.start)} // Handle date clicks
        onSelectEvent={handleEventSelect} // Handle event clicks
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: "#1976d2",
            color: "white",
          },
        })}
      />
    </Paper>
  );
};

export default TripCalendar;
