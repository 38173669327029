import service from "../../assets/Icons/serviceflag.svg"
import batteryflagicon from "../../assets/Icons/batteryflag.svg"
import maintenanceicon from "../../assets/Icons/maintenanceflag.svg"
import iotflagicon from "../../assets/Icons/iotfaultflag.svg"
import missingflagicon from "../../assets/Icons/missingflag.svg"
import unavailableflag from "../../assets/Icons/unavailableflag.svg"
import ebikeflagicon from "../../assets/Icons/ebikeflag.svg"
import motorbikeflagicon from "../../assets/Icons/motorbikeflag.svg"
import scooterflagicon from "../../assets/Icons/scooterflag.svg"
import carflagicon from "../../assets/Icons/carflag.svg"
import tractorflagicon from "../../assets/Icons/tractorflag.svg"
import busflagicon from "../../assets/Icons/busflag.svg"
import heavyvehicleflagicon from "../../assets/Icons/heavyvehicleflag.svg"

import parkingflag from "../../assets/Icons/parkingflagmap.svg"
import otherflag from "../../assets/Icons/otherflag.svg"


export const fleetOptions = [
    { value: 'shyamal_fleet', label: 'Shyamal' },
    { value: 'maninagar_fleet', label: 'Maninagar' },
    { value: 'satellite_fleet', label: 'Satellite' },
  ];


  export const flagOption = [
    {
      value: "iot_fault",
      label: "IoT Fault",
      description:
        "Issues with vehicle's IoT connectivity or sensor malfunction.",
    },
    {
      value: "service_due",
      label: "Service Due",
      description: "Scheduled maintenance or service is due soon.",
    }
  ];
  

    export const vehicleConnectOptions = [
      { value: '3_hourse', label: '3 Hours' },
      { value: '6_hourse', label: '6 Hours'  },
      { value: '9_hourse', label: '9 Hours'  },
      { value: '12_hourse', label: '12 Hours'  },
    ];


    export const flagDetails = [
      {
        icon: service,
        name: 'Service Start/End'
      },
      {
        icon: batteryflagicon,
        name: 'Battery Critical'
      },
      {
        icon: maintenanceicon,
        name: 'Maintenance'
      },
      {
        icon: iotflagicon,
        name: 'IoT Fault'
      },
      {
        icon: missingflagicon,
        name: 'Missing'
      },
      {
        icon: unavailableflag,
        name: 'Unavailable'
      },
      {
        icon: ebikeflagicon,
        name: 'E-bike'
      },
      {
        icon: motorbikeflagicon,
        name: 'Motorbike'
      },
      {
        icon: scooterflagicon,
        name: 'Scooter'
      },
      {
        icon: carflagicon,
        name: 'Car'
      },
      {
        icon: tractorflagicon,
        name: 'Tractor'
      },
      {
        icon: busflagicon,
        name: 'Bus'
      },
      {
        icon: heavyvehicleflagicon,
        name: 'Heavy Vehicle'
      },
      {
        icon: parkingflag,
        name: 'Parking'
      },
      {
        icon: otherflag,
        name: 'Other'
      }
    ];
    

    export const geofenceData = [
      {
        id: "usr_Bob1234Smith",
        geofence_name: "Main Street Parking",
        fleet: 'King Street Transit',
        zone: 'Parking Zone',
        type:'polygon',
        status: 'Active',
        throttle: 'off',
        alarm: 'on',
        create_date: "2023-05-10",
        create_by: "Admin",
        updated_date: "2023-05-10",
        description:"Testing",
        coordinates:{path:[
          { lat: 23.0220, lng: 72.5710 },
          { lat: 23.0220, lng: 72.5730 },
          { lat: 23.0230, lng: 72.5740 },
          { lat: 23.0240, lng: 72.5730 },
          { lat: 23.0240, lng: 72.5710 }
        ],center:{ lat: 23.0230, lng: 72.5720 }}
      },
      {
        id: "usr_Diana0987Prince",
        geofence_name: "East Side Bus Stop",
        fleet: "Jackson Line Metro",
        zone: 'Fuel Station Zone',
        type: 'marker',
        status: 'Active',
        throttle: 'off',
        alarm: 'on',
        create_date: "2023-08-10",
        create_by: "Admin",
        updated_date: "2023-08-10",
        description:"Fuel Station",
        coordinates:{center:{ lat: 23.0325, lng: 72.5875 }},
      },
      {
        id: "usr_Eve1234Adams",
        geofence_name: "Martin Urban Transit",
        fleet: "Evans City Shuttle",
        zone: 'Service Zone',
        status: 'Inactive',
        throttle: 'off',
        alarm: 'on',
        type: 'marker',
        create_date: "2023-08-10",
        create_by: "Admin",
        description:"Service Station",
        updated_date: "2023-08-10",
        coordinates:{center:{ lat: 23.0390, lng: 72.5975 },radius:350},
      },
      
    ];
    