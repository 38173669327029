import {
    Box,
    Divider,
    IconButton,
    Paper,
    Tab,
    Tabs,
    Typography,
    useTheme,
  } from "@mui/material";

  import backicon from "../../assets/Icons/backicon.svg";
  import { useNavigate } from "react-router-dom";
  import { useState } from "react";
  import OnGoingTripMap from "../../components/LiveTrackingMap";
  
  const options = {
    chart: {
      type: "line",
      height: 200,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#556cd6", "#20a96b", "#6f42c1"], // Colors for the lines
    xaxis: {
      categories: [
        "12:00",
        "12:05",
        "12:10",
        "12:15",
        "12:20",
        "12:25",
        "12:30",
        "12:35",
        "12:40",
        "12:45",
        "12:50",
        "12:55",
        "13:00",
        "13:05",
        "13:10",
        "13:15",
        "13:20",
        "13:25",
        "13:30",
        "13:35",
        "13:40",
        "13:45",
        "13:50",
        "13:55",
        "14:00",
        "14:05",
        "14:10",
        "14:15",
        "14:20",
        "14:25",
        "14:30",
      ],
      labels: {
        style: {
          fontSize: "12px",
        },
        rotate: -45,
        rotateAlways: true,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      labels: {
        colors: ["#444"],
        useSeriesColors: true,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 4,
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    },
  };

  export const OnGoingTripTimeline = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const theme = useTheme();
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      // <Box
      //   sx={{
      //     minHeight: "90vh",
      //     width: "100%",
      //     background: "#FFFFFF",
      //     boxShadow: "none",
      //   }}
      // >
        <Paper
          sx={{
            width: "100%",
            boxShadow: "none",
            height: "100%",
            p: 1,
            borderRadius: "10px",
          }}
        >
          <Box sx={{ display:'flex', alignItems: "center", mb: 2,p:1 }}>
            <IconButton onClick={() => navigate("/dashboard/ongoing")}>
              <img src={backicon} alt="back-icon" />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                ml: 2,
                mr: 2,
                fontSize: "1.375rem",
                color: "#283246",
                display: "flex",
                alignItems: "center",
              }}
            >
              Trip ID: 248362
            </Typography>
            {/* <Typography
              sx={{
                color: theme.palette.text.tertiary,
                fontSize: "1rem",
                fontWeight: 500,
                ml: 2,
              }}
            >
              &#8226; Last active:Today
            </Typography> */}
          </Box>
          <Divider />
          <Box sx={{
  mb: 2,
  display: 'flex',
  justifyContent: 'center', // Horizontally center the content
  alignItems: 'center', // Vertically center the content (optional)
  width: '100%', // Make the Box take full width of its container
}}>
  <Tabs
    value={value}
    onChange={handleChange}
    aria-label="Active Users"
    indicatorColor="primary"
    sx={{
      borderRadius: "12px",
      p: 1,
      width: "30%", // Set the width of the Tabs to be 30% of its parent (the Box)
      "& .MuiTabs-indicator": {
        background: "#FE7676",
        height: "4px",
      },
      "& .Mui-selected": {
        "& .MuiTab-root": {
          color: "red",
          borderBottom: "2px solid red",
        },
      },
    }}
  >
    {["Live Tracking"].map((label, index) => (
      <Tab
        key={label}
        disableRipple
        label={label}
        sx={{
          textTransform: "none",
          fontSize: "1rem",
          fontWeight: 500,
          "&.Mui-selected": {
            color: "#283246",
          },
          minWidth: { lg: "230px", xl: "300px" }, // Adjust as needed
        }}
      />
    ))}
  </Tabs>
</Box>

          <Box sx={{ width: "95%", height: "100%" ,margin:'auto'}}>
            <OnGoingTripMap />
            </Box>
          
        </Paper>
    );
  };
  