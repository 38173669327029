// src/slices/reminderManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { reminderTableData } from "../features/Reminders/utils";
import { v4 as uuidv4 } from 'uuid'; // Assuming you're using uuid to generate unique IDs


const initialState = {
  reminders: reminderTableData,
};

const reminderManageSlice = createSlice({
  name: "reminders",
  initialState,
  reducers: {
    updatedReminders: (state, action) => {
      state.reminders = action.payload;
    },
    addReminders: (state, action) => {
      const fleetIndex = state.reminders.findIndex(reminder => reminder.id === action.payload.id);
  
      if (fleetIndex !== -1) {
          // Fleet exists, so update it
          state.reminders[fleetIndex] = {
              ...state.reminders[fleetIndex],
              ...action.payload,
          };
      } else {
          // Fleet does not exist, add new
          const newReminders = {
              ...action.payload,
              id: action.payload.id || `usr_${uuidv4()}`, // Use provided id or generate a new one
              created_date: new Date().toISOString().slice(0, 10) // Set the create date
          };
          state.reminders.push(newReminders);
      }
  },
    deleteReminders: (state, action) => {
      const userId = action.payload;
      // Find the user in the reminders array
      const userToDelete = state.reminders.find(user => user.id === userId);

      if (userToDelete) {
        // Remove the user from the reminders array
        state.reminders = state.reminders.filter(user => user.id !== userId);
      }
    },
  },
});

export const { updatedReminders,addReminders, deleteReminders } = reminderManageSlice.actions;
export default reminderManageSlice.reducer;
