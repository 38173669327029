import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Box } from "@mui/material";
import cartrip from "../../assets/Icons/cartrip.svg";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const nightModeStyle = [
    { elementType: "geometry", stylers: [{ color: "#212121" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#212121" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#757575" }] },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#181818" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [{ color: "#2c2c2c" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#8a8a8a" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#3c3c3c" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#b3b3b3" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#000000" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#3d3d3d" }],
    },
  ];

const polylineOptions = {
  strokeColor: "#69ACF3",
  strokeOpacity: 1.0,
  strokeWeight: 4,
};

const dummyTripData = [
    { lng: 72.58962383758288, lat: 23.02338236562423 },
    { lng: 72.58959047876624, lat: 23.02352180212354 },
    { lng: 72.58958908881604, lat: 23.02360111456335 },
    { lng: 72.58959047876624, lat: 23.023666355406746 },
    { lng: 72.58957935916109, lat: 23.023700894663904 },
    { lng: 72.58957657925833, lat: 23.02396057842566 },
    { lng: 72.58956823955478, lat: 23.023990000691782 },
    { lng: 72.58956962950501, lat: 23.024129436564493 },
    { lng: 72.58956962950501, lat: 23.024253876561502 },
    { lng: 72.58962522753188, lat: 23.024258993466105 },
    { lng: 72.58979480151496, lat: 23.02441122130952 },
    { lng: 72.58990321766439, lat: 23.024560375646473 },
    { lng: 72.58995186594072, lat: 23.024612823795877 },
    { lng: 72.59017425804672, lat: 23.02465120047802 },
    { lng: 72.59035912149028, lat: 23.02467422648185 },
    { lng: 72.59069826945287, lat: 23.02475737591007 },
    { lng: 72.59083170471774, lat: 23.024781681117773 },
    { lng: 72.59103741741782, lat: 23.02480342787868 },
    { lng: 72.59117641248469, lat: 23.02480342787868 },
    { lng: 72.59133208695872, lat: 23.024817499309705 },
    { lng: 72.59147386192828, lat: 23.02482005775184 },
    { lng: 72.591819959645, lat: 23.024844362948812 },
    { lng: 72.59195895471186, lat: 23.024844362948812 },
    { lng: 72.5919617346122, lat: 23.024763772019313 },
    { lng: 72.59200482308663, lat: 23.024553979529827 },
    { lng: 72.59205208140952, lat: 23.024348024390918 },
    { lng: 72.59209100002539, lat: 23.024179166528967 },
    { lng: 72.5921424282017, lat: 23.024026938426786 },
    { lng: 72.59215910760875, lat: 23.023965535444106 },
    { lng: 72.59228837302189, lat: 23.02390413243424 },
    { lng: 72.59242458818832, lat: 23.023870872459796 },
    { lng: 72.59254968374847, lat: 23.02385168400903 },
    { lng: 72.59263586069, lat: 23.023836333246223 },
    { lng: 72.59278597536198, lat: 23.023781326332795 },
    { lng: 72.59304412130876, lat: 23.0237007347677 },
    { lng: 72.59328190239458, lat: 23.02365340319089 },
    { lng: 72.59341533766266, lat: 23.02357281154802 },
    { lng: 72.5936238302611, lat: 23.02351780452925 },
    { lng: 72.59379618414499, lat: 23.02345384284797 }
  ];  

const OnGoingTripMap = () => {
  const [path, setPath] = useState([]);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDiytvXP2dWy_3E-rJX2NWwnnFWZe4aSjU",
  });

  const mapRef = useRef();

  useEffect(() => {
    const moveAlongPath = () => {
      if (currentPositionIndex < dummyTripData.length) {
        setPath(prevPath => [...prevPath, dummyTripData[currentPositionIndex]]);
        setCurrentPositionIndex(currentPositionIndex + 1);
        // Update map center to the current position
        mapRef?.current?.panTo(dummyTripData[currentPositionIndex]);
      }
    };

    const intervalId = setInterval(moveAlongPath, 1000); // Move the marker every second

    return () => clearInterval(intervalId);
  }, [currentPositionIndex]);



  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
      <Box style={{ position: "relative", height: "90vh", width: "100%" }}>
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          zoom={15}
          onLoad={(map) => (mapRef.current = map)}
          options={{
            styles: nightModeStyle,
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          {path.length > 0 && (
            <Polyline path={path} options={polylineOptions} />
          )}
          {path.length > 0 && (
            <Marker
              position={path[path.length - 1]}
              icon={{
                url: cartrip,
                scaledSize: new window.google.maps.Size(60, 60),
              }}
            />
          )}
        </GoogleMap>
      </Box>
  );
};

export default OnGoingTripMap;
