
// export const tripData = [
//     {
//       lat: 23.02458087524292,
//       lng: 72.58957628577542,
//       time: "12:00",
//       stop: false,
//     },
//     {
//       lat: 23.024459961923128,
//       lng: 72.58879058592888,
//       time: "12:05",
//       stop: false,
//     },
//     {
//       lat: 23.024421965716783,
//       lng: 72.58851887917905,
//       time: "12:10",
//       stop: false,
//     },
//     {
//       lat: 23.02435863172211,
//       lng: 72.58826512024035,
//       time: "12:15",
//       stop: false,
//     },
//     {
//       lat: 23.024303214451777,
//       lng: 72.58793824432104,
//       time: "12:20",
//       stop: false,
//     },
//     {
//       lat: 23.02432300633643,
//       lng: 72.58748663942961,
//       time: "12:25",
//       stop: false,
//     },
//     {
//       lat: 23.024358631721356,
//       lng: 72.58661512768037,
//       time: "12:30",
//       stop: false,
//     },
//     {
//       lat: 23.024362590096217,
//       lng: 72.58642158272775,
//       time: "12:35",
//       stop: false,
//     },
//     {
//       lat: 23.024524883393084,
//       lng: 72.5863914757347,
//       time: "12:40",
//       stop: true,
//     }, // Stop at this point
//     {
//       lat: 23.02497613689927,
//       lng: 72.58647319471547,
//       time: "12:45",
//       stop: false,
//     },
//     {
//       lat: 23.02546697233258,
//       lng: 72.58662372967842,
//       time: "12:50",
//       stop: false,
//     },
//     {
//       lat: 23.0258034310731,
//       lng: 72.58668824466085,
//       time: "12:55",
//       stop: false,
//     },
//     {
//       lat: 23.026353638260133,
//       lng: 72.58683447862583,
//       time: "13:00",
//       stop: false,
//     },
//     {
//       lat: 23.027402588189446,
//       lng: 72.58711834455411,
//       time: "13:05",
//       stop: false,
//     },
//     {
//       lat: 23.02760841891697,
//       lng: 72.58716565554363,
//       time: "13:10",
//       stop: false,
//     },
//     {
//       lat: 23.027596544075422,
//       lng: 72.58730758850857,
//       time: "13:15",
//       stop: false,
//     },
//     {
//       lat: 23.02755696126445,
//       lng: 72.58748392946472,
//       time: "13:20",
//       stop: false,
//     },
//     {
//       lat: 23.02770341760737,
//       lng: 72.58827531327194,
//       time: "13:25",
//       stop: false,
//     },
//     {
//       lat: 23.027802374507615,
//       lng: 72.58878069539185,
//       time: "13:30",
//       stop: false,
//     },
//     {
//       lat: 23.02789341478939,
//       lng: 72.58936993224995,
//       time: "13:35",
//       stop: false,
//     },
//     {
//       lat: 23.02793695577232,
//       lng: 72.58965879041034,
//       time: "13:40",
//       stop: false,
//     },
//     {
//       lat: 23.02805174556842,
//       lng: 72.58994695734026,
//       time: "13:45",
//       stop: false,
//     },
//     {
//       lat: 23.028182368321566,
//       lng: 72.59032974624608,
//       time: "13:50",
//       stop: false,
//     },
//     {
//       lat: 23.02832090746608,
//       lng: 72.59094187698753,
//       time: "13:55",
//       stop: false,
//     },
//     {
//       lat: 23.02848319599768,
//       lng: 72.59172035779699,
//       time: "14:00",
//       stop: false,
//     },
//     {
//       lat: 23.028554444559163,
//       lng: 72.59237410963749,
//       time: "14:05",
//       stop: false,
//     },
//     {
//       lat: 23.02856631931631,
//       lng: 72.59275689854462,
//       time: "14:10",
//       stop: false,
//     },
//     {
//       lat: 23.02848319599768,
//       lng: 72.59308377446396,
//       time: "14:15",
//       stop: false,
//     },
//     {
//       lat: 23.02834861527748,
//       lng: 72.59343215537987,
//       time: "14:20",
//       stop: false,
//     },
//     {
//       lat: 23.028162577002433,
//       lng: 72.59376763329749,
//       time: "14:25",
//       stop: false,
//     },
//     {
//       lat: 23.028776106513888,
//       lng: 72.59433536515792,
//       time: "14:30",
//       stop: false,
//     },
//     {
//       lat: 23.029417340820174,
//       lng: 72.59492030101626,
//       time: "14:35",
//       stop: false,
//     },
//     {
//       lat: 23.029599419142443,
//       lng: 72.59513965196197,
//       time: "14:40",
//       stop: false,
//     },
//     {
//       lat: 23.029931909364905,
//       lng: 72.5957843469406,
//       time: "14:45",
//       stop: false,
//     },
//     {
//       lat: 23.03018127648599,
//       lng: 72.59638218579511,
//       time: "14:50",
//       stop: false,
//     },
//     {
//       lat: 23.030541472625274,
//       lng: 72.59714346260981,
//       time: "14:55",
//       stop: false,
//     },
//     {
//       lat: 23.030727507617428,
//       lng: 72.5975950674981,
//       time: "15:00",
//       stop: false,
//     },
//     {
//       lat: 23.03082646229626,
//       lng: 72.59802946839167,
//       time: "15:05",
//       stop: false,
//     },
//   ]

export const mergedTripData = [
  {
    lat: 23.02458087524292,
    lng: 72.58957628577542,
    time: "12:00",
    stop: false,
    eventTitle: "Trip Initiated",
    eventDescription:
      "The trip started from Shyamal Cross Roads, Nehru Nagar, Shyamal, Ahmedabad, Gujarat 380015. All systems are set for a smooth journey.",
    icon: "startIcon",
  },
  {
    lat: 23.024459961923128,
    lng: 72.58879058592888,
    time: "12:05",
    stop: false,
    eventTitle: "Steady Driving",
    eventDescription:
      "Heading towards the next checkpoint, maintaining a steady pace.",
    icon: "driveIcon",
  },
  {
    lat: 23.024421965716783,
    lng: 72.58851887917905,
    time: "12:10",
    stop: false,
    eventTitle: "Smooth Progress",
    eventDescription:
      "Continuing smoothly with no interruptions or deviations.",
    icon: "driveIcon",
  },
  {
    lat: 23.02435863172211,
    lng: 72.58826512024035,
    time: "12:15",
    stop: false,
    eventTitle: "Approaching Checkpoint",
    eventDescription: "Approaching a key checkpoint. The road ahead is clear.",
    icon: "driveIcon",
  },
  {
    lat: 23.024303214451777,
    lng: 72.58793824432104,
    time: "12:20",
    stop: false,
    eventTitle: "Progressing Well",
    eventDescription: "The journey is proceeding as planned, no interruptions.",
    icon: "driveIcon",
  },
  {
    lat: 23.02432300633643,
    lng: 72.58748663942961,
    time: "12:25",
    stop: false,
    eventTitle: "Cruising Along",
    eventDescription: "Maintaining a good pace, nothing unusual so far.",
    icon: "driveIcon",
  },
  {
    lat: 23.024358631721356,
    lng: 72.58661512768037,
    time: "12:30",
    stop: false,
    eventTitle: "Consistent Progress",
    eventDescription: "The vehicle is cruising through the planned route.",
    icon: "driveIcon",
  },
  {
    lat: 23.024362590096217,
    lng: 72.58642158272775,
    time: "12:35",
    stop: false,
    eventTitle: "Approaching Next Stop",
    eventDescription: "Heading towards the next major stop checkpoint.",
    icon: "driveIcon",
  },
  {
    lat: 23.024524883393084,
    lng: 72.5863914757347,
    time: "12:40",
    stop: true,
    eventTitle: "Short Pause",
    eventDescription: "A brief stop to assess surroundings and take a break.",
    icon: "stopIcon",
  },
  {
    lat: 23.02497613689927,
    lng: 72.58647319471547,
    time: "12:45",
    stop: false,
    eventTitle: "Resuming Journey",
    eventDescription: "Trip resumed after a short break at the checkpoint.",
    icon: "driveIcon",
  },
  {
    lat: 23.02546697233258,
    lng: 72.58662372967842,
    time: "12:50",
    stop: false,
    eventTitle: "Back on Track",
    eventDescription: "The journey continues smoothly along the planned route.",
    icon: "driveIcon",
  },
  {
    lat: 23.0258034310731,
    lng: 72.58668824466085,
    time: "12:55",
    stop: false,
    eventTitle: "Open Roads Ahead",
    eventDescription: "Clear roads ahead, making good time.",
    icon: "driveIcon",
  },
  {
    lat: 23.026353638260133,
    lng: 72.58683447862583,
    time: "13:00",
    stop: false,
    eventTitle: "Short Pause",
    eventDescription: "Passing a major checkpoint, all systems nominal.",
    icon: "checkpointIcon",
  },
  {
    lat: 23.027402588189446,
    lng: 72.58711834455411,
    time: "13:05",
    stop: false,
    eventTitle: "Smooth Sailing",
    eventDescription: "No interruptions, moving smoothly through the route.",
    icon: "driveIcon",
  },
  {
    lat: 23.02760841891697,
    lng: 72.58716565554363,
    time: "13:10",
    stop: false,
    eventTitle: "Approaching Destination",
    eventDescription: "Heading towards the destination with minimal delays.",
    icon: "driveIcon",
  },
  {
    lat: 23.027596544075422,
    lng: 72.58730758850857,
    time: "13:15",
    stop: false,
    eventTitle: "Key Point Ahead",
    eventDescription: "Approaching a key point, maintaining steady progress.",
    icon: "driveIcon",
  },
  {
    lat: 23.02755696126445,
    lng: 72.58748392946472,
    time: "13:20",
    stop: true,
    eventTitle: "Brief Stop",
    eventDescription: "Taking a brief stop to check systems.",
    icon: "stopIcon",
  },
  {
    lat: 23.02770341760737,
    lng: 72.58827531327194,
    time: "13:25",
    stop: false,
    eventTitle: "Continuing Journey",
    eventDescription: "Resuming after a brief stop, everything looks good.",
    icon: "driveIcon",
  },
  {
    lat: 23.027802374507615,
    lng: 72.58878069539185,
    time: "13:30",
    stop: false,
    eventTitle: "Progress Made",
    eventDescription: "Making steady progress towards the final destination.",
    icon: "driveIcon",
  },
  {
    lat: 23.02789341478939,
    lng: 72.58936993224995,
    time: "13:35",
    stop: false,
    eventTitle: "Nearing Destination",
    eventDescription:
      "The destination is getting closer, keeping a steady pace.",
    icon: "driveIcon",
  },
  {
    lat: 23.02793695577232,
    lng: 72.58965879041034,
    time: "13:40",
    stop: false,
    eventTitle: "Final Stretch",
    eventDescription:
      "Approaching the final leg of the trip, destination in sight.",
    icon: "driveIcon",
  },
  {
    lat: 23.02805174556842,
    lng: 72.58994695734026,
    time: "13:45",
    stop: false,
    eventTitle: "In the Vicinity",
    eventDescription: "The destination is nearby, wrapping up the trip.",
    icon: "driveIcon",
  },
  {
    lat: 23.028182368321566,
    lng: 72.59032974624608,
    time: "13:50",
    stop: false,
    eventTitle: "Almost There",
    eventDescription:
      "The destination is just minutes away, final preparations in place.",
    icon: "driveIcon",
  },
  {
    lat: 23.02832090746608,
    lng: 72.59094187698753,
    time: "13:55",
    stop: false,
    eventTitle: "Final Approach",
    eventDescription: "Making the final approach to the destination.",
    icon: "driveIcon",
  },
  {
    lat: 23.02848319599768,
    lng: 72.59172035779699,
    time: "14:00",
    stop: true,
    eventTitle: "Pause Before Destination",
    eventDescription: "A final pause before reaching the destination.",
    icon: "stopIcon",
  },
  {
    lat: 23.028776106513888,
    lng: 72.59433536515792,
    time: "14:30",
    stop: false,
    eventTitle: "Trip Completed",
    eventDescription:
      "The trip has concluded at 132 Feet Ring Rd, Shyamal, Ahmedabad, Gujarat 380015. Total journey time: 2 hours 30 minutes.",
    icon: "destinationIcon",
  },
];
