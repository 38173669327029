import geofenceparking from "../../assets/Icons/geofenceparking.svg"
import geofencerestricted from "../../assets/Icons/geofencerestricted.svg"
import geofenceservice from "../../assets/Icons/geofenceservice.svg"
import geofenceslowspeed from "../../assets/Icons/geofenceslowspeed.svg"

export const parkingGeofenceTableCellItem = [{label:"Name",key:"geofence_name"},{label:"Created Date",key:"create_date"},{label:"Create By",key:"create_by"},{label:"Status",key:"status"},{label:"Updated Date",key:"updated_date"}];

export const parkingGeofenceTableData = [
    // {
    //     id: "usr_Bob1234Smith",
    //     geofence_name: "Main Street Parking",
    //     fleet_name: "Main Street Public Fleet",
    //     zone_type: 'Parking Zone',
    //     minimum_vehicle: '3',
    //     max_vehicle: '15',
    //     geofence_description: 'Main street parking zone',
    //     status: 'Active',
    //     show_in_operator: 'on',
    //     throttle: 'off',
    //     alarm: 'on',
    //     description: 'Efficient and secure parking zone monitoring at Main Street for fleet management.',
    //     timeline_details: [
    //       { id: "timeline_1", title: "John Doe assigned a new vehicle.", description: "A new vehicle was assigned to the Main Street fleet.", date: "July 10, 2024" },
    //       { id: "timeline_2", title: "John Doe installed IoT system.", description: "IoT monitoring installed in the vehicle.", date: "July 12, 2024" },
    //       { id: "timeline_3", title: "John Doe performed vehicle inspection.", description: "Vehicle inspection completed.", date: "July 14, 2024" }
    //     ],
    //     create_date: "2023-05-10",
    //     create_by: "Admin",
    //     updated_date: "2023-05-10"
    // },
    
    // {
    //     id: "usr_Charlie5678Brown",
    //     geofence_name: "Downtown Charging Station",
    //     fleet_name: "Downtown Public Fleet",
    //     zone_type: 'Charging Zone',
    //     minimum_vehicle: '2',
    //     max_vehicle: '10',
    //     geofence_description: 'Charging zone for electric vehicles',
    //     status: 'Inactive',
    //     show_in_operator: 'off',
    //     throttle: 'on',
    //     alarm: 'off',
    //     description: 'Downtown EV charging station for eco-friendly public transport fleet.',
    //     timeline_details: [
    //       { id: "timeline_1", title: "Sam Wilson initiated charging station setup.", description: "Charging station setup in progress.", date: "August 1, 2024" },
    //       { id: "timeline_2", title: "Sam Wilson connected the IoT system.", description: "IoT system linked to charging monitors.", date: "August 3, 2024" }
    //     ],
    //     create_date: "2023-06-15",
    //     create_by: "Admin",
    //     updated_date: "2023-06-15"
    // },
    
    // {
    //     id: "usr_Diana0987Prince",
    //     geofence_name: "East Side Bus Stop",
    //     fleet_name: "East Side Public Fleet",
    //     zone_type: 'Bus Stop Zone',
    //     minimum_vehicle: '4',
    //     max_vehicle: '12',
    //     geofence_description: 'Bus stop monitoring for public transport',
    //     status: 'Active',
    //     show_in_operator: 'on',
    //     throttle: 'off',
    //     alarm: 'on',
    //     description: 'Real-time bus stop monitoring for improved public transportation services.',
    //     timeline_details: [
    //       { id: "timeline_1", title: "Bruce Wayne assigned a new bus.", description: "Bus assigned to East Side fleet.", date: "June 10, 2024" },
    //       { id: "timeline_2", title: "Bruce Wayne upgraded bus tracking system.", description: "Tracking system enhanced for better performance.", date: "June 12, 2024" }
    //     ],
    //     create_date: "2023-07-20",
    //     create_by: "Admin",
    //     updated_date: "2023-07-20"
    // },
    
    // {
    //     id: "usr_Eve1234Adams",
    //     geofence_name: "Westfield Depot",
    //     fleet_name: "Westfield Public Fleet",
    //     zone_type: 'Depot Zone',
    //     minimum_vehicle: '6',
    //     max_vehicle: '25',
    //     geofence_description: 'Depot for fleet maintenance and parking',
    //     status: 'Active',
    //     show_in_operator: 'on',
    //     throttle: 'off',
    //     alarm: 'off',
    //     description: 'Westfield Depot providing maintenance and parking for public transport vehicles.',
    //     timeline_details: [
    //       { id: "timeline_1", title: "Clark Kent assigned vehicle maintenance.", description: "Scheduled maintenance for vehicles at the depot.", date: "May 15, 2024" },
    //       { id: "timeline_2", title: "Clark Kent upgraded depot facilities.", description: "Depot facilities upgraded for better operations.", date: "May 18, 2024" }
    //     ],
    //     create_date: "2023-08-10",
    //     create_by: "Admin",
    //     updated_date: "2023-08-10"
    // },
    
    // {
    //     id: "usr_Frank0987Castle",
    //     geofence_name: "North City Loading Bay",
    //     fleet_name: "North City Public Fleet",
    //     zone_type: 'Loading Zone',
    //     minimum_vehicle: '5',
    //     max_vehicle: '20',
    //     geofence_description: 'Loading zone for heavy vehicles',
    //     status: 'Inactive',
    //     show_in_operator: 'off',
    //     throttle: 'on',
    //     alarm: 'off',
    //     description: 'Loading bay for North City fleet, supporting heavy vehicle operations.',
    //     timeline_details: [
    //       { id: "timeline_1", title: "Peter Parker assigned cargo vehicle.", description: "Cargo vehicle assigned to loading bay.", date: "March 25, 2024" },
    //       { id: "timeline_2", title: "Peter Parker performed cargo inspection.", description: "Cargo inspection completed at the loading bay.", date: "March 28, 2024" }
    //     ],
    //     create_date: "2023-04-01",
    //     create_by: "Admin",
    //     updated_date: "2023-04-01"
    // }
    
];

export const geofenceOptions = [
  { value: 'parking_zone', label: 'Parking Zone',icon:geofenceparking },
  { value: 'service_zone', label: 'Service Zone',icon:geofenceservice },
  { value: 'slow_speed_zone', label: 'Fuel Station Zone',icon:geofenceslowspeed },
];