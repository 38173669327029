import { Box, Typography, Paper, Grid, InputLabel, Select, MenuItem, FormControl, IconButton, useTheme } from "@mui/material"
import { useState } from "react"
import FilledButton from "../../components/MainButtons/FilledButton"
import CustomTextField from "../../components/MainTextField/CustomTextField"
import { MapComponent } from "../../components/MapComponent"
import backicon from "../../assets/Icons/backicon.svg"
import IOSSwitch from "../../components/Switch"
import { useNavigate } from "react-router-dom"



const inputLabel = {
    fontSize: "0.8rem",
    fontWeight: 700,
    mb: 1,
    color: "#283246"
}
const CreateParkingZone = () => {
    const [formData, setFormData] = useState({
        name: "",
        zone_type: "Parking Zone",
        fleet: "Select Fleet",
        desc: "",
        vehicle_exits_this_zone: []
    })
    const navigate = useNavigate()
    const theme = useTheme()

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleSwitchChange = (zoneType) => {
        setFormData(prevState => {
            const updatedExitZones = prevState.vehicle_exits_this_zone.includes(zoneType)
                ? prevState.vehicle_exits_this_zone.filter(zone => zone !== zoneType) // remove if exists
                : [...prevState.vehicle_exits_this_zone, zoneType]; // add if doesn't exist

            return {
                ...prevState,
                vehicle_exits_this_zone: updatedExitZones
            };
        });
    };

    console.log(formData)
    return (
        <>
        <Paper
            sx={{
                borderRadius: "1.25rem",
                background: "#F8F8F8",
                padding: "1.563rem",
                boxShadow: "none",
                gap: 2,
                mt: 1,
                mb: 2,
            }}>
            <Box>
            <Box sx={{ display: "flex", alignItems: "center",mb:2  }}>
            <IconButton onClick={() => navigate("/dashboard/geofences")}>
            <img src={backicon} alt="back-icon" />
          </IconButton>
                <Typography
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: "24px",
                        fontWeight: "800"
                    }}
                >Add Parking Zone</Typography>
            </Box>
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>
                            Name <span style={{ color: theme.palette.text.tertiary }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.name}
                            onChange={handleInputChange}
                            name="name"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    width: "300px",
                                }
                            }}
                            placeholder={"Enter Parking Zone Name"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl sx={{ margin: "25px" }} disabled >
                            <InputLabel sx={{ fontSize: "0.8rem", fontWeight: 700, mb: 1, color: theme.palette.text.primary, margin: "-13px" }}>
                                Zone Type  <span style={{ color: theme.palette.text.tertiary }}>*</span>
                            </InputLabel>
                            <Select
                                value={formData.zone_type}
                                defaultValue={formData.zone_type}
                                onChange={handleInputChange}
                                sx={{
                                    borderRadius: "10px",
                                    width: '300px',
                                    height: '40px'
                                }}
                                name="zone_type"
                            >
                                <MenuItem value={"Select Zone Type"}>Select Zone Type</MenuItem>
                                <MenuItem value="Parking Zone">Parking Zone</MenuItem>
                                {/* <MenuItem value="Restricted Zone">Restricted Zone</MenuItem> */}
                                <MenuItem value="Service Zone">Service Zone</MenuItem>
                                <MenuItem value="Fuel Station Zone">Fuel Station Zone</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>

                        <InputLabel sx={inputLabel}>
                            Fleet  <span style={{ color: theme.palette.text.tertiary }}>*</span>
                        </InputLabel>
                        <Select
                            value={formData.fleet}
                            defaultValue={formData.fleet}
                            onChange={handleInputChange}
                            sx={{
                                borderRadius: "10px",
                                width: '300px',
                                height: '40px'
                            }}
                            name="fleet"

                        >
                            <MenuItem value={"Select Fleet"}>Select Fleet</MenuItem>
                            <MenuItem value={"Shymal Cross Road Public Fleet"}>Shymal Cross Road Public Fleet</MenuItem>
                            <MenuItem value={"Ranip Road Private Fleet"}>Ranip Road Private Fleet</MenuItem>
                            <MenuItem value={"Shivranjini Cross Road Public Fleet"}>Shivranjini Cross Road Public Fleet</MenuItem>
                            <MenuItem value={"Gujarat University Public Fleet"}>Gujarat University Public Fleet</MenuItem>
                            <MenuItem value={"Nikol Cross Road Public Fleet"}>Nikol Cross Road Public Fleet</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>
                            Description <span style={{ color: theme.palette.text.tertiary }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.desc}
                            onChange={handleInputChange}
                            name="desc"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    width: "300px",
                                }
                            }}
                            placeholder={"Enter Description of Parking Zone"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>
                            When a vehicle exits this zone, the following will be activated
                        </InputLabel>
                        <Box sx={{ display: "flex", gap: "15px" }} >
                            <Box sx={{ display: "flex", gap: "10px" }} >
                                <IOSSwitch onChange={() => handleSwitchChange("Throttle")} defaultChecked={formData.vehicle_exits_this_zone.includes("Throttle")} />
                                <Typography sx={{ color: theme.palette.text.primary, fontSize: "15px", fontWeight: "700" }} >Throttle</Typography>
                            </Box>
                            <Box sx={{ display: "flex", gap: "10px" }} >
                                <IOSSwitch onChange={() => handleSwitchChange("Alert")} defaultChecked={formData.vehicle_exits_this_zone.includes("Alarm")} />
                                <Typography sx={{ color: theme.palette.text.primary, fontSize: "15px", fontWeight: "700" }} >Alarm</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{mt:5}}>  
            <MapComponent height={'500px'} width='100%'/>
            </Box>
                <Box sx={{ display: "flex", gap: "10px",mt:2 }} >

                    <Box>
                        <FilledButton sx={{
        width: "150px",
      }}>
                            Create
                        </FilledButton>
                    </Box>
                    <Box>
                        <FilledButton  sx={{
      border: `1px solid ${theme.palette.text.secondary}`,
      width: "150px",
      color: "black",
      background: "white",
      "&:hover": {
        background: "white",
      },
    }} >
                            Cancel
                        </FilledButton>
                    </Box>
                </Box>
        </Paper>
        </>
    )
}

export default CreateParkingZone