import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  useTheme,
  TextField,
} from "@mui/material";
import FilledButton from "../../components/MainButtons/FilledButton";
import { styled } from "styled-components";
import verification from "../../assets/Images/verification.gif"
import { useSnackbar } from "../../hooks/Snackbar";


const CustomTextField = styled(TextField)({
    width: '35px',
    height: '35px',
    borderRadius: '6px',
    border: '1px solid #97A1B6',
    textAlign: 'center',
  
    '& .MuiOutlinedInput-root': {
      borderColor: 'transparent',
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      padding: '5px',
      fontSize: '16px',
    },
  });

const OtpVerificationModal = ({
    open,
    handleClose,
    setOtpModal,
    verifyField
}) => {
  const theme = useTheme();
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [loading,setIsLoading] = useState(false)
  const [validationError, setValidationError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const showSnackbar = useSnackbar()

  const handleChangeOtp = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
  
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  
  const handleSubmit = async () => {
    const enteredOtp = otp.join('');
    if (enteredOtp.length === 6) {
      setIsLoading(true);
      setTimeout(() => {
        if (enteredOtp === "456132") {
          showSnackbar("OTP verification success", "success");
          handleClose(); // Close the modal
          setOtpModal(false, verifyField, true); // Assuming 'setOtpModal' updates some state to reflect successful verification
          setIsLoading(false);
          setOtp(Array(6).fill('')); // Clear OTP fields after successful verification
        } else {
          showSnackbar("Incorrect OTP. Please try again.", "error");
          setIsLoading(false);
          setOtp(Array(6).fill('')); // Clear OTP fields even if the verification fails
        }
      }, 2000);
    } else {
      setValidationError(true);
      setHelperText('Please enter a complete 6-digit OTP.');
    }
  };



  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "430px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        {loading?<>
            <img src={verification} style={{width:'200px',width:"200px"}}/>
            <Typography sx={{ color: "#283246",fontWeight:600 ,fontSize:'1rem'}}>Verifying... Please wait.</Typography>
        </>:
        <>
        <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                color: "#283246",
                mt:1
              }}
            >
              Confirm Your Email</Typography>
              <Typography
              sx={{
                fontSize: 12,
                fontWeight: 500,
                // fontFamily: theme.typography.fontFamily,
                color: "#283246",
                mt:2
              }}
              
            >
  We sent you a 6-digit code. Please enter it below. Can't find it? Check your spam folder.
  <span
  style={{
    fontWeight: 800,
    fontSize: '1rem', // Keep the font size
    letterSpacing: '0.3rem', // Maintain spacing between characters
    padding: '0.2rem 0.5rem', // Existing padding
    backgroundColor: '#e9ecef', // Light gray background for subtlety
    color: '#495057', // Dark gray color for the text, easy on the eyes
    borderRadius: '5px', // Keep the rounded edges
    border: '1px solid #ced4da', // Soft gray border to blend with the background
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.05)', // Lighter shadow for a soft effect
    textShadow: '0 1px 1px rgba(0, 0, 0, 0.05)', // Subtle text shadow for depth
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif', // Modern and clean font
  }}
>
  {" "}{String("456132").split('').join(' ')}
</span>

</Typography>
              <Box display="flex" justifyContent="center" alignItems="center" gap="10px" sx={{p:'1rem'}}>
              {otp.map((digit, index) => (
  <CustomTextField
    key={index}
    id={`otp-input-${index}`}
    value={digit}
    onChange={(e) => handleChangeOtp(e, index)}
    onKeyDown={(e) => handleKeyDown(e, index)}
    variant="outlined"
    type="text"
  />
))}
    
    </Box>

        <Box sx={{ display: "flex", justifyContent:'center',gap:2, mt: 3 }}>
          <FilledButton
            onClick={handleSubmit}
            sx={{ width: "200px", bgcolor: theme.palette.error.main }}
          >
                Verify OTP
          </FilledButton>
        </Box>
        </>
        }
      </Box>
      
    </Modal>
  );
};

export default OtpVerificationModal;
