import { useState } from "react";
import { Grid, InputLabel, Paper, Typography, MenuItem, Select, IconButton, Box, useTheme, FormHelperText, FormControl } from "@mui/material";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import backicon from "../../assets/Icons/backicon.svg"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addVehicle } from "../../slices/vehicleManageSlice";


const selectStyle = {
  borderRadius: "10px", height:'40px' ,
  width: {
    xs: "100px",   // Width for small screens
    sm: "300px",   // Width for medium screens
    md: "400px",   // Width for large screens
    lg: "300px",   // Width for larger screens
    xl: "400px"    // Width for extra-large screens
  }
}

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    // Apply responsive width based on breakpoints
    width: {
      xs: "100px",   // Width for small screens
      sm: "300px",    // Width for medium screens
      md: "400px",    // Width for large screens
      lg:"300px",
      xl:"400px"
    },
  } 
}

export const AddVehicle = () => {
  // State to manage the form fields
  const [vehicleData, setVehicleData] = useState({
    vehicle_type: "",
    fleet: "",
    vehicle_name: "",
    vehicle_qr: "",
    fuelType: "",
    driver: "",
    iot_type: "",
    iot_name: "",
    imei: "",
    bluetooth_flow: "No",
    driver_id:'',
  });

  const [errors, setErrors] = useState({});
  const {driver} = useSelector((state)=>(state.driverManage))
  const {fleet} = useSelector((state)=>(state.fleetManage))


  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme()

  // Options for the select fields
const vehicleTypes = [
  "Cybertruck",
  "SportsCar",
  "Pickup",
  "Minivan",
  "HeavyTruck",
  "Tanker",
  "DumpTruck",
  "Flatbed",
  "Tractor"
];
  const fleets = fleet;
  const fuelTypes = ["Diesel", "Petrol", "Electric"];
  const drivers = driver
  const iotTypes = ["Axons Micro Pro", "Axons Micro"];
  const bluetoothOptions = ["Yes", "No"];

  const requiredFields = ["vehicle_type", "fleet", "vehicle_name", "vehicle_qr", "fuelType"];


  // Handle form field changes
  const handleChange = (field, value) => {
    setVehicleData((prevState) => ({
      ...prevState,
      [field]: value,
    }));

      // Clear errors on change
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
  };

  const handleSubmit = () => {
    const newErrors = {};
 // Check only required fields
 requiredFields.forEach((key) => {
  if (!vehicleData[key]) {
    newErrors[key] = "This field is required";
  }
});

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      dispatch(addVehicle(vehicleData));
      navigate("/dashboard/vehicles");
    }
  };

  return (
    <Paper
      sx={{
        borderRadius: "1.25rem",
        background: "#F8F8F8",
        padding: "1.563rem",
        boxShadow: "none",
        gap: 2,
        mt: 1,
        mb: 2,
        minHeight:'80vh'
      }}
    >
         <Box sx={{ display: "flex", alignItems: "center",mb:2  }}>
                <Typography
                    sx={{
                        color: "#283246",
                        fontSize: "1.5rem",
                        fontWeight: "800"
                    }}
                >Add Vehicle</Typography>
            </Box>
      <Typography sx={{ color: theme.palette.text.primary, fontSize: "1.3rem", fontWeight: 500,mt:2,mb:2 }}>
        Vehicle Configuration
      </Typography>

      <Grid container spacing={2}>
        {/* Vehicle Type */}
       
        <Grid item xs={12} md={4}>
    
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Vehicle Type <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <Select
      value={vehicleData.vehicle_type}
      onChange={(e) => handleChange("vehicle_type", e.target.value)}
      sx={{ ...selectStyle}} // Ensure selectStyle is defined or remove it if not needed
      displayEmpty
      error={!!errors.vehicle_type}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200, // Adjust this value to control the height of the visible area
          },
        },
      }}
    >
      {vehicleTypes.map((type) => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      ))}
    </Select>
          {errors.vehicle_type && (
    <FormHelperText sx={{color:'red',fontSize:'0.8rem',ml:1}}>{errors.vehicle_type}</FormHelperText>
  )}

        </Grid>

        {/* Fleet */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Fleet <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <Select
            value={vehicleData.fleet}
            onChange={(e) => handleChange("fleet", e.target.value)}
            sx={selectStyle}
            error={!!errors.fleet}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Adjust this value to control the height of the visible area
                },
              },
            }}
          >
             {fleets.map((fleet) => (
                    <MenuItem key={fleet.id} value={fleet.fleet_name}>
                        {fleet.fleet_name}
                    </MenuItem>
                ))}
          </Select>
          {errors.fleet && (
    <FormHelperText sx={{color:'red',fontSize:'0.8rem',ml:1}}>{errors.fleet}</FormHelperText>
  )}
          
        </Grid>

        {/* Vehicle Name */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Vehicle Name <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={vehicleData.vehicle_name}
            onChange={(e) => handleChange("vehicle_name", e.target.value)}
            sx={textFieldStyle}
            placeholder='Enter Vehicle Name'
            error={!!errors.vehicle_name}
          />
           {errors.vehicle_name && (
    <FormHelperText sx={{color:'red',fontSize:'0.8rem',ml:1}}>{errors.vehicle_name}</FormHelperText>
  )}
        </Grid>

        {/* QR Code */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Vehicle QR<span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={vehicleData.vehicle_qr}
            onChange={(e) => handleChange("vehicle_qr", e.target.value)}
            sx={textFieldStyle}
            placeholder='Vehicle qr'
            error={!!errors.vehicle_qr}
          />
           {errors.vehicle_qr && (
    <FormHelperText sx={{color:'red',fontSize:'0.8rem',ml:1}}>{errors.vehicle_qr}</FormHelperText>
  )}
        </Grid>

        {/* Fuel Type */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Fuel Type <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <Select
            value={vehicleData.fuelType}
            onChange={(e) => handleChange("fuelType", e.target.value)}
            sx={selectStyle}
            error={!!errors.fuelType}
          >
            {fuelTypes.map((fuel) => (
              <MenuItem key={fuel} value={fuel}>
                {fuel}
              </MenuItem>
            ))}
          </Select>
          {errors.fuelType && (
    <FormHelperText sx={{color:'red',fontSize:'0.8rem',ml:1}}>{errors.fuelType}</FormHelperText>
  )}
        </Grid>

        {/* Driver */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Driver <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <Select
            value={vehicleData.driver}
            onChange={(e) => handleChange("driver", e.target.value)}
            sx={selectStyle}
            error={!!errors.driver}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Adjust this value to control the height of the visible area
                },
              },
            }}
          >
             {drivers.map((driver) => (
                    <MenuItem key={driver.id} value={driver.driver_name}>
                        {driver.driver_name}
                    </MenuItem>
                ))}
          </Select>
          {errors.driver && (
    <FormHelperText sx={{color:'red',fontSize:'0.8rem',ml:1}}>{errors.driver}</FormHelperText>
  )}
        </Grid>
      </Grid>

      <Typography sx={{ color: theme.palette.text.primary, fontSize: "1.3rem", fontWeight: 500,mt:5,mb:2 }}>
          IOT Configuration
        </Typography>
      <Grid container spacing={2}>      

        {/* IOT Type */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            IOT Type <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <Select
            value={vehicleData.iot_type}
            onChange={(e) => handleChange("iot_type", e.target.value)}
            sx={selectStyle}
            error={!!errors.iot_type}
          >
            {iotTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          {errors.iot_type
 && (
    <FormHelperText sx={{color:'red',fontSize:'0.8rem',ml:1}}>{errors.iot_type}</FormHelperText>
  )}
        </Grid>

        {/* IOT Name */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            IOT Name <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={vehicleData.iot_name}
            onChange={(e) => handleChange("iot_name", e.target.value)}
            sx={textFieldStyle}
            placeholder="Enter IoT Name"
            error={!!errors.iot_name}
          />
            {errors.iot_name
 && (
    <FormHelperText sx={{color:'red',fontSize:'0.8rem',ml:1}}>{errors.iot_name}</FormHelperText>
  )}
        </Grid>

        {/* IMEI */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            IMEI <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <CustomTextField
            value={vehicleData.imei}
            onChange={(e) => handleChange("imei", e.target.value)}
            sx={textFieldStyle}
            placeholder='Enter IMEI' 
            error={!!errors.imei}
          />
          {errors.imei
 && (
    <FormHelperText sx={{color:'red',fontSize:'0.8rem',ml:1}}>{errors.imei}</FormHelperText>
  )}
        </Grid>

        {/* Bluetooth Flow Enabled */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Bluetooth Flow Enabled <span style={{ color: theme.palette.text.tertiary }}>*</span>
          </InputLabel>
          <Select
            value={vehicleData.bluetooth_flow}
            onChange={(e) => handleChange("bluetooth_flow", e.target.value)}
            sx={selectStyle}

          >
            {bluetoothOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      {/* Save and Cancel buttons */}
      <Grid container justifyContent="flex-start" spacing={2} sx={{ mt: 10,bottom:0 }}>
        <Grid item>
          <FilledButton
     sx={{
      border: `1px solid ${theme.palette.text.secondary}`,
      width: "150px",
      color: "black",
      background: "white",
      "&:hover": {
        background: "white",
      },
    }}
    onClick={()=>navigate("/dashboard/vehicles")}
    >
            Cancel
          </FilledButton>
        </Grid>
        <Grid item>
          <FilledButton
      sx={{
        width: "150px",
      }} onClick={handleSubmit}>
            Save
          </FilledButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddVehicle;
