import React, { useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import SearchTextField from "../../components/SearchTextField";
import TripsTableComponent from "../../components/TripsTable/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import backicon from "../../assets/Icons/backicon.svg";
import UserTimeline from "../UserManagement/UserTimeline";
import TripDetails from "../UserManagement/TripDetails";
import { tripTableCellItem, userVehicleEdit } from "../Vehicles/utils";
import DriverTimeline from "./DriverTimeline";
import { MapComponent } from "../../components/MapComponent";
import AnimatedNumber from "../../components/AnimatedNumbers";
import { driverTripTableCellItem, Tripdata } from "./utils";
import { updatedDriver } from "../../slices/driverManageSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import dummypng from "../../assets/Images/dummy.png";
import CustomDatePicker from "../../components/DateRangePicker.js";
import TripMap from "../../components/TripMap";
import NoResultsFound from "../../components/NoTImelineData";
import UserTableComponent from "../UserManagement/UserTable";

const tabStyles = {
  width: "191px",
  height: "40px",
  fontWeight: 500,
  fontSize: "1rem",
  textTransform: "none",
  color: "#F99494",
  "&.Mui-selected": {
    background: "linear-gradient(90deg, #535A6A 0%, #283246 100%)",
    color: "#FFFFFF",
    borderRadius: "12px",
  },
};

const selectStyle = {
  borderRadius: "10px",
  height: "40px",
  width: {
    xs: "100px", // Width for small screens
    sm: "300px", // Width for medium screens
    md: "400px", // Width for large screens
    lg: "300px", // Width for larger screens
    xl: "400px", // Width for extra-large screens
  },
};

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    // Apply responsive width based on breakpoints
    width: {
      xs: "100px", // Width for small screens
      sm: "300px", // Width for medium screens
      md: "400px", // Width for large screens
      lg: "300px",
      xl: "400px",
    },
  },
};

const inputLabel = {
  fontSize: "1rem",
  fontWeight: 500,
  mb: 1,
  color: "#283246",
};

const driverStatus = ["Active", "InActive"];

export const EditDriver = () => {
  const [value, setValue] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [openTransaction, setTransactionOpen] = useState(false);
  const [tripDetails, setTripDetails] = useState("");

  const { driver } = useSelector((state) => state.driverManage);

  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();

  // Filter the driver from either driver or deactivatedUser based on the id
  const drivers = driver.find((u) => u.id === id);

  const [driverDetails, setDriverDetails] = useState({
    driver_name: drivers?.driver_name,
    phone: drivers?.phone,
    age: drivers?.age,
    licence_no: drivers?.licence_no,
    licence_exp_date: drivers?.licence_exp_date,
    total_exp: drivers?.total_exp,
    date_of_joining: drivers?.date_of_joining,
    notes: drivers?.notes,
    address: drivers?.address,
    status: drivers?.status,
    vehicle_assigned: drivers?.vehicle_assigned,
    driver_image: drivers?.driver_image,
    email: drivers?.email,
    work_phone: drivers?.work_phone,
    experience: 3,
    id: id,
  });

  // Handle the case when the drivers is not found
  if (!drivers) {
    return <Typography variant="h4">User not found</Typography>;
  }

  const getImageSrc = (image) => {
    if (!image) {
      // Handles null, undefined, or empty strings
      return ""; // Return a default image placeholder or an empty string
    }

    if (typeof image === "string") {
      // Check if it's a base64 encoded image
      if (image.startsWith("data:image")) {
        return image;
      }
      return ""; // Return a default image placeholder or an empty string if the string is not a base64 image
    } else if (typeof image === "object" && Object.keys(image).length > 0) {
      // It's a File object and not empty, create an object URL
      try {
        return URL.createObjectURL(image);
      } catch (error) {
        console.error("Failed to create an object URL:", error);
        return ""; // Return a default image placeholder or an empty string on failure
      }
    }

    return ""; // Return a default image placeholder or an empty string if none is provided or if the object is empty
  };

  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDriverDetails({
      ...driverDetails,
      [name]: value,
    });
  };

  // Handle save changes
  const handleSaveChanges = () => {
    const updatedDriverData = {
      id: id,
      updatedFields: driverDetails,
    };
    dispatch(updatedDriver(updatedDriverData));
  };

  const handleChange = (event, newValue) => {
    setSearchInput("");
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  // const filteredTripDetails = searchInput
  //   ? drivers?.trip_details
  //       .filter((drivers) =>
  //         drivers?.driver?.toLowerCase().includes(searchInput?.toLowerCase())
  //       )
  //       .sort((a, b) => a?.driver.localeCompare(b?.driver))
  //   : drivers.trip_details;

    const filteredTripDetails = drivers.trips_details
    ?.filter((drivers) => {
      const matchesName = searchInput
        ? drivers?.vehicle_name?.toLowerCase().includes(searchInput.toLowerCase())
        : true; // If searchInput is empty, return true to ignore this filter
      return matchesName
    })
    .sort((a, b) => a.vehicle_name.localeCompare(b.vehicle_name));

  const handleTransactionDetails = (userId) => {
    setTransactionOpen(true);
    setTripDetails(userId);
  };

  const hanldeTrip = (trip) => {
    setTransactionOpen(true);
    setTripDetails(trip);
  };

  const handleTransactionClose = () => {
    setTransactionOpen(false);
  };

  const handleDateChange = (selected) => {
    console.log("Selected Date/Range:", selected);
  };


  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#FFFFFF",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            borderBottom: `1px solid ${theme.palette.divider}`,
            pb: 2,
          }}
        >
          <IconButton onClick={() => navigate("/dashboard/drivers")}>
            <img src={backicon} alt="back-icon" />
          </IconButton>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              ml: 2,
              fontSize: "1.375rem",
              color: theme.palette.text.primary,
            }}
          >
            Driver Information
          </Typography>
        </Box>

        {/* Driver Details */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 3,
            borderRadius: 1,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {/* Driver Profile Section */}
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Avatar
              src={getImageSrc(driverDetails.driver_image)}
              alt={driverDetails?.name}
              sx={{ width: 100, height: 100, mr: 3 }}
            />
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, color: theme.palette.text.primary }}
              >
                {driverDetails.driver_name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  mt: 2,
                }}
              >
                {/* Parent container */}
<Box
  sx={{
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap:2
  }}
>
  {/* Email Row */}
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      mb: 2, // adjust spacing as needed
    }}
  >
    <Typography
      variant="caption"
      sx={{
        fontWeight: 600,
        color: theme.palette.text.secondary,
      }}
    >
      Email
    </Typography>
    <Typography
      variant="body1"
      sx={{
        color: theme.palette.text.primary,
      }}
    >
      {driverDetails.email}
    </Typography>
  </Box>

  {/* Phone Number Row */}
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      mb: 2, // adjust spacing as needed
    }}
  >
    <Typography
      variant="caption"
      sx={{
        fontWeight: 600,
        color: theme.palette.text.secondary,
      }}
    >
      Phone Number
    </Typography>
    <Typography
      variant="body1"
      sx={{
        color: theme.palette.text.primary,
      }}
    >
      {driverDetails.work_phone}
    </Typography>
  </Box>

  {/* Experience Row */}
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      mb: 2, // adjust spacing as needed
    }}
  >
    <Typography
      variant="caption"
      sx={{
        fontWeight: 600,
        color: theme.palette.text.secondary,
      }}
    >
      Experience (yrs)
    </Typography>
    <Typography
      variant="body1"
      sx={{
        color: theme.palette.text.primary,
      }}
    >
      {driverDetails.experience}
    </Typography>
  </Box>
</Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#FFFFFF",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Active Users"
            indicatorColor="primary"
            sx={{
              borderRadius: "12px",
              p: 1,
              "& .MuiTabs-indicator": {
                background: "#FE7676",
                height: "4px",
              },
              "& .Mui-selected": {
                "& .MuiTab-root": {
                  color: "red",
                  borderBottom: "2px solid red",
                },
              },
            }}
          >
            {["Trips", "Driver Timeline"].map((label, index) => (
              <Tab
                key={label}
                disableRipple
                label={label}
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: 500,
                  "&.Mui-selected": {
                    color: "#283246",
                  },
                  minWidth: "250px",
                }}
              />
            ))}
          </Tabs>
        </Box>
        <Box>
          {value === 0 && (
            <>
              {/* <TripsTableComponent
                data={drivers.trips_details}
                tableHeaders={driverTripTableCellItem}
                showActions={false}
              /> */}
               <Box
                  sx={{
                    // border: "1px solid #DDDEE1",
                    display: "flex",
                    height: "42px",
                    p: 1,
                    background: "#FFFFFF",
                    borderRadius: "6px",
                    mb: 3,
                  }}
                >
                  <SearchTextField
                    value={searchInput}
                    onChange={handleSearchChange}
                    placeholder="Search by Vehicle"
                    sx={{
                      height: "35px",
                      width: "14.75rem",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "6px",
                        color: "#283246",
                        backgroundColor: "#FFF",
                        mr: 1,
                        fontSize: "1rem",
                        "& fieldset": {
                          border: "1px solid #DDDEE1", // Border color
                        },
                      },
                    }}
                  />
                </Box>
               <UserTableComponent
                 data={filteredTripDetails}
                 tableHeaders={driverTripTableCellItem}
                  showActions={false}
                  handleEdit={() => {}}
                  handleDelete={() => {}}
                />
            </>
          )}
          {value === 1 && (
            <>
            {drivers.timeline_details?
            <UserTimeline
            timelineData={drivers.timeline_details}
            heading={drivers.timeline_heading}
            description={drivers.timeline_description}
          />:
             <NoResultsFound />
            }
            </>
            
          )}
        </Box>

        <TripDetails
          open={openTransaction}
          handleClose={handleTransactionClose}
          TripData={tripDetails}
        />
      </Paper>
    </>
  );
};
