import {
  Box,
  Divider,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import TripMap from "../../components/TripMap/index";
import backicon from "../../assets/Icons/backicon.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CustomTimeline from "../Drivers/DriverTimeline";
import Chart from "react-apexcharts";
import { mergedTripData } from "../../components/TripMap/utils";

const options = {
  chart: {
    type: "line",
    height: 200,
    toolbar: {
      show: false,
    },
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  colors: ["#556cd6", "#20a96b", "#6f42c1"], // Colors for the lines
  xaxis: {
    categories: [
      "12:00",
      "12:20",
      "12:40",
      "13:00",
      "13:20",
      "13:40",
      "14:00",
      "14:20",
      "14:25",
      "14:30",
    ],
    labels: {
      style: {
        fontSize: "12px",
      },
    
      rotateAlways: true,
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "center",
    labels: {
      colors: ["#444"],
      useSeriesColors: true,
    },
  },
  grid: {
    borderColor: "#e7e7e7",
    strokeDashArray: 4,
  },
  yaxis: {
    min: 0,
    max: 100,
    labels: {
      style: {
        fontSize: "12px",
      },
    },
  },
};

const series = [
  {
    name: "Average Speed(km/h)",
    data: [
      30, 32, 34, 36, 38,
      58, 56, 54, 56,40,
    ], // Data gradually rises to and then fluctuates around the average speed of 57 km/h
  },
  {
    name: "Device Connectivity(%)",
    data: [
      100, 95, 70, 100,  // Device disconnected at 12:15
      98, 100, 100, 98,    // Device disconnected at 13:00 and 13:45
   70, 100      // Device connected at the end
    ]
  }
];

export const TripsTimeline = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        minHeight: "90vh",
        width: "100%",
        background: "#FFFFFF",
        boxShadow: "none",
        display: "flex",
        p: 1,
        borderRadius: "10px",
      }}
    >
      <Paper
        sx={{
          width: "45%",
          boxShadow: "none",
          height: "100%",
          padding: "1.563rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton onClick={() => navigate("/dashboard/completed")}>
            <img src={backicon} alt="back-icon" />
          </IconButton>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              ml: 2,
              mr: 2,
              fontSize: "1.375rem",
              color: "#283246",
              display: "flex",
              alignItems: "center",
            }}
          >
            Trip ID: 26597
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.tertiary,
              fontSize: "1rem",
              fontWeight: 500,
              ml: 2,
            }}
          >
            &#8226; Last active:Today
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Active Users"
            indicatorColor="primary"
            sx={{
              borderRadius: "12px",
              p: 1,
              width: "100%",
              "& .MuiTabs-indicator": {
                background: "#FE7676",
                height: "4px",
              },
              "& .Mui-selected": {
                "& .MuiTab-root": {
                  color: "red",
                  borderBottom: "2px solid red",
                },
              },
            }}
          >
            {["Tracking", "Analytics"].map((label, index) => (
              <Tab
                key={label}
                disableRipple
                label={label}
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: 500,
                  "&.Mui-selected": {
                    color: "#283246",
                  },
                  minWidth: { lg: "230px", xl: "300px" },
                }}
              />
            ))}
          </Tabs>
        </Box>
        {value === 0 && (
          <>
            <Box>
              <CustomTimeline timelinedata={mergedTripData} />
            </Box>
          </>
        )}

        {value === 1 && (
          <>
            <Box sx={{ maxWidth: 600, m: "auto", p: 3 }}>
              {/* Top section with Smoother, Safer, Cleaner */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center", // Ensure vertical alignment
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    mx: 1, // Horizontal margin for spacing
                    p: 2,
                    textAlign: "center",
                    backgroundColor: "#fff", // Background color to resemble design
                    borderRadius: "8px", // Rounded corners if needed
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "1rem", color: "gray" }}
                  >
                    Average Speed
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary.main"
                    sx={{ fontWeight: 600, fontSize: "2rem" }}
                  >
                    57 km/h
                  </Typography>
                  {/* <Typography sx={{ fontSize: "14px", mt: 1, color: "gray" }}>
                  A measure of the trip's efficiency, reflecting the average speed maintained throughout the journey.
                  </Typography> */}
                </Box>

                <Box
                  sx={{
                    flex: 1,
                    mx: 1,
                    p: 2,
                    textAlign: "center",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography
                   sx={{ fontWeight: 500,fontSize:'1rem',color:'gray' }}
                  >
                    Device Connectivity
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary.main"
                    sx={{ fontWeight: 600 }}
                  >
                  99%
                  </Typography>
                </Box>

                {/* <Box
                  sx={{
                    flex: 1,
                    mx: 1,
                    p: 2,
                    textAlign: "center",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography
                   sx={{ fontWeight: 500,fontSize:'1rem',color:'gray' }}
                  >
                    Cleaner
                  </Typography>
                  <Typography
                    variant="h4"
                    color="primary.main"
                    sx={{ fontWeight: 600 }}
                  >
                    99.3%
                  </Typography>
                  <Typography
                   sx={{ fontSize: "14px",color:'#20a96b' }}
                  >
                    +3.2%
                  </Typography>
                </Box> */}
              </Box>

              {/* Line chart */}
              <Chart
                options={options}
                series={series}
                type="line"
                height={200}
              />

              {/* Footer with driving data */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{ fontWeight: 400, fontSize: "1rem", color: "#283246" }}
                  >
                    Driving Time
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1.5rem",
                      color: "#283246",
                    }}
                  >
                    2.5 hr
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{ fontWeight: 400, fontSize: "1rem", color: "#283246" }}
                  >
                    Idling Time
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1.5rem",
                      color: "#283246",
                    }}
                  >
                    30 min
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    sx={{ fontWeight: 400, fontSize: "1rem", color: "#283246" }}
                  >
                    Distance
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1.5rem",
                      color: "#283246",
                    }}
                  >
                    10 km
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Paper>
      <Box sx={{ width: "55%", height: "100%" }}>
        <TripMap />
      </Box>
    </Box>
  );
};
