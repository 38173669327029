// import React, { useState, useEffect } from "react";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   Box,
//   Typography,
// } from "@mui/material";
// import dayjs from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import ReusableCalendar from "../../components/Calendar";
// import FilledButton from "../../components/MainButtons/FilledButton";
// import { InputFieldWrapper } from "../../components/CustomInputWithLabel";
// import { CustomSelect } from "../../components/CustomSelection";
// import { useGetData, usePostData } from "../../hooks/useApi";
// import { useSnackbar } from "../../hooks/Snackbar";

// const AssignVehicleDialog = ({
//   open,
//   onClose,
//   onAssign,
//   vehicles,
//   drivers,
//   errorsText,
//   selectedTrip,
//   setError,
// }) => {
//   const [selectedVehicle, setSelectedVehicle] = useState("");
//   const [selectedDriver, setSelectedDriver] = useState("");
//   const [startDate, setStartDate] = useState(dayjs());
//   const [endDate, setEndDate] = useState(dayjs().add(1, "hour"));
//   const [notes, setNotes] = useState("");
//   const [vehicleOptions, setVehicleOptions] = useState([]);
//   const [driverOptions, setDriverOptions] = useState([]);
//   const postData = usePostData();
//   const getData = useGetData();
//   const showSnackbar = useSnackbar();

//   const getVehicleData = async () => {
//     try {
//       const { data } = await getData.mutateAsync({
//         endpoint: "/vehicles",
//         data: {
//           page: "All",
//         },
//       });
//       const options = data?.map((e) => ({
//         label: e.name,
//         value: e.id,
//       }));
//       setVehicleOptions(options);
//       console.log(options, "vehicleoption");
//     } catch (error) {
//       showSnackbar("Failed to load data", "error");
//     } finally {
//     }
//   };

//   const getDriverData = async () => {
//     try {
//       const { data } = await getData.mutateAsync({
//         endpoint: "/drivers",
//         data: {
//           page: "ALL",
//         },
//       });
//       const options = data?.map((e) => ({
//         label: `${e.first_name} ${e?.last_name}`,
//         value: e.id,
//       }));
//       setDriverOptions(options);
//       console.log(options, "vehicleoption");
//     } catch (error) {
//       showSnackbar("Failed to load data", "error");
//     } finally {
//     }
//   };
//   console.log("vehicleoption");

//   useEffect(() => {
//     getVehicleData();
//     getDriverData();
//   }, []);

//   // Use effect to pre-fill the modal when editing an existing trip
//   useEffect(() => {
//     if (selectedTrip) {
//       setSelectedDriver(selectedTrip.title || "");
//       setSelectedVehicle(selectedTrip.vehicle_qr_code || "");
//       setStartDate(dayjs(selectedTrip.start));
//       setEndDate(dayjs(selectedTrip.end));
//       setNotes(selectedTrip.notes || "");
//     }
//   }, [selectedTrip]);

//   const start_date = new Date(startDate);
//   const end_date = new Date(endDate);

//   const formattedStartDate =
//     start_date.toISOString().split("T")[0] +
//     " " +
//     start_date.toISOString().split("T")[1].slice(0, 5);
//   const formattedEndDate =
//     end_date.toISOString().split("T")[0] +
//     " " +
//     end_date.toISOString().split("T")[1].slice(0, 5);

//   const addNewTrip = async () => {
//     try {
//       const { data } = await postData.mutateAsync({
//         endpoint: `/trips`,
//         data: {
//           vehicle_id: selectedVehicle,
//           driver_id: selectedDriver,
//           start_date: formattedStartDate,
//           //  "2024-05-11 17:35",
//           end_date: formattedEndDate,
//           // "2024-05-11 19:30",
//         },
//       });

//       if (data) {
//         showSnackbar("Trip added successfully!", "success");
//       }
//     } catch (error) {
//       // if (error?.response?.status === 401) {
//       //   showSnackbar(
//       //     "Oops! Something went wrong, and you've been logged out.",
//       //     "error"
//       //   );
//       //   dispatch(logout());
//       //   return;
//       // }
//       showSnackbar(
//         error?.response?.data?.message || "Failed to load data",
//         "error"
//       );
//     } finally {
//       // setIsLoadingData(false);
//     }
//   };

//   const handleSubmit = () => {
//     // Reset error before validation
//     setError("");

//     // Validation checks
//     if (!selectedDriver || !selectedVehicle) {
//       setError("Please select both a vehicle and a driver.");
//       return;
//     }
//     if (endDate.isBefore(startDate)) {
//       setError("End date must be after the start date.");
//       return;
//     }

//     // Prepare assignment data
//     const assignment = {
//       vehicle: selectedVehicle,
//       driver: selectedDriver,
//       startDate: startDate.toDate(),
//       endDate: endDate.toDate(),
//       notes,
//       id: selectedTrip?.id || null,
//     };

//     onAssign(assignment);
//     addNewTrip();
//   };

//   const handleDialogClose = () => {
//     setSelectedDriver("");
//     setSelectedVehicle("");
//     setStartDate(dayjs());
//     setEndDate(dayjs());
//     setNotes("");
//     setError("");
//     onClose();
//   };

//   return (
//     <Dialog
//       open={open}
//       onClose={handleDialogClose}
//       fullWidth
//       PaperProps={{
//         sx: { borderRadius: "1.25rem", width: "47rem" },
//       }}
//     >
//       <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: 500 }}>
//         {selectedDriver ? "Edit Trip Assignment" : "Add Trip Assignment"}
//       </DialogTitle>
//       <DialogContent>
//         <Box sx={{ display: "flex", width: "100%", gap: "2rem" }}>
//           <CustomSelect
//             label="Select Vehicle"
//             value={selectedVehicle}
//             onChange={(e) => setSelectedVehicle(e.target.value)}
//             options={vehicleOptions}
//             name="selectedVehicle"
//           />

//           <CustomSelect
//             label="Select Driver"
//             value={selectedDriver}
//             onChange={(e) => setSelectedDriver(e.target.value)}
//             options={driverOptions}
//             name="selectedDriver"
//           />
//         </Box>
//         <ReusableCalendar
//           startDate={startDate}
//           setStartDate={setStartDate}
//           endDate={endDate}
//           setEndDate={setEndDate}
//           includeTime={true}
//         />
//         {/* <InputFieldWrapper
//           label="Notes (up to 40 characters)"
//           name="notes"
//           value={notes}
//           onChange={(e) => setNotes(e.target.value)}
//           placeholder="Enter Notes"
//           height={"50px"}
//           width={"100%"}
//           maxLength={40}
//         /> */}
//       </DialogContent>
//       <Typography sx={{ color: "red", fontSize: "0.9rem", ml: 3, mb: 2 }}>
//         {errorsText}
//       </Typography>
//       <DialogActions sx={{ justifyContent: "center" }}>
//         <FilledButton onClick={handleSubmit} sx={{ width: "250px" }}>
//           {selectedDriver ? "Save Changes" : "Create"}
//         </FilledButton>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default AssignVehicleDialog;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReusableCalendar from "../../components/Calendar";
import FilledButton from "../../components/MainButtons/FilledButton";
import { InputFieldWrapper } from "../../components/CustomInputWithLabel";
import { CustomSelect } from "../../components/CustomSelection";

const AssignVehicleDialog = ({
  open,
  onClose,
  onAssign,
  vehicles,
  drivers,
  errorsText,
  selectedTrip,
  setError,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "hour"));
  const [notes, setNotes] = useState("");

  // Use effect to pre-fill the modal when editing an existing trip
  useEffect(() => {
    if (selectedTrip) {
      setSelectedDriver(selectedTrip.title || "");
      setSelectedVehicle(selectedTrip.vehicle_qr_code || "");
      setStartDate(dayjs(selectedTrip.start));
      setEndDate(dayjs(selectedTrip.end));
      setNotes(selectedTrip.notes || "");
    }
  }, [selectedTrip]);

  const handleSubmit = () => {
    // Reset error before validation
    setError("");

    // Validation checks
    if (!selectedDriver || !selectedVehicle) {
      setError("Please select both a vehicle and a driver.");
      return;
    }
    if (endDate.isBefore(startDate)) {
      setError("End date must be after the start date.");
      return;
    }

    // Prepare assignment data
    const assignment = {
      vehicle: selectedVehicle,
      driver: selectedDriver,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      notes,
      id: selectedTrip?.id || null,
    };

    onAssign(assignment);
  };

  const handleDialogClose = () => {
    setSelectedDriver("");
    setSelectedVehicle("");
    setStartDate(dayjs());
    setEndDate(dayjs());
    setNotes("");
    setError("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      PaperProps={{
        sx: { borderRadius: "1.25rem", width: "47rem" },
      }}
    >
      <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: 500 }}>
        {selectedDriver ? "Edit Trip Assignment" : "Add Trip Assignment"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", width: "100%", gap: "2rem" }}>
          <CustomSelect
            label="Select Vehicle"
            value={selectedVehicle}
            onChange={(e) => setSelectedVehicle(e.target.value)}
            options={vehicles}
            name="selectedVehicle"
          />

          <CustomSelect
            label="Select Driver"
            value={selectedDriver}
            onChange={(e) => setSelectedDriver(e.target.value)}
            options={drivers}
            name="selectedDriver"
          />
        </Box>
        <ReusableCalendar
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          includeTime={true}
        />
        <InputFieldWrapper
          label="Notes (up to 40 characters)"
          name="notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Enter Notes"
          height={"50px"}
          width={"100%"}
          maxLength={40}
        />
      </DialogContent>
      <Typography sx={{ color: "red", fontSize: "0.9rem", ml: 3, mb: 2 }}>
        {errorsText}
      </Typography>
      <DialogActions sx={{ justifyContent: "center" }}>
        <FilledButton onClick={handleSubmit} sx={{width:'250px'}}>
          {selectedDriver ? "Save Changes" : "Create"}
        </FilledButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignVehicleDialog;