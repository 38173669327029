import tripspervehicle from "../../assets/Icons/tripspervehicle.svg";
import tripsperduration from "../../assets/Icons/tripsperduration.svg";
import totaltrips from "../../assets/Icons/totaltrip.svg";

export const tripsData = [
  { name: "Total Trips", count: "100", icon: totaltrips },
  { name: "Trips Per Vehicle", count: "50", icon: tripspervehicle },
  { name: "Avg Trips Duration", count: "30", icon: tripsperduration },
];

export const scheduleTripTableCellItem = [
  { label: "Driver ID", key: "driver_id" },
  { label: "Driver Name", key: "title" },
  { label: "Vehicle QR Code", key: "vehicle_qr_code" },
  { label: "Start Date/ Time", key: "start" },
  { label: "End Date/ Time", key: "end" },
  { label: "Notes", key: "notes" },
  { label: "Duration", key: "duration" },
];
export const completedTripTableCellItem = [
  { label: "Driver ID", key: "id" },
  { label: "Driver", key: "driver_name" },
  { label: "Vehicle QR Code", key: "vehicle_qr_code" },
  { label: "Start Date/ Time", key: "start_date_time" },
  { label: "End Date/ Time", key: "end_date_time" },
  { label: "Duration", key: "duration" },
];
export const onGoingTripTableCellItem = [
  { label: "Driver ID", key: "driver_id" },
  { label: "Driver Name", key: "driver_name" },
  { label: "Vehicle QR Code", key: "vehicle_qr_code" },
  { label: "Start Date/ Time", key: "start_date_time" },
];

export const scheduleTripsTableData = [
  {
    driver_id: "D001",
    title: "John Doe",
    vehicle_qr_code: "QR123456",
    start: "2024-09-17 08:00",
    end: "2024-09-17 10:00",
    fleet: "King Street Transit",
    status: "Automatically ended",
    duration: "2 hours",
    allDay: false,
    notes: "First Shift Delivery",
  },
  {
    driver_id: "D002",
    title: "Jane Smith",
    vehicle_qr_code: "QR654321",
    start: "2024-09-17 09:00",
    end: "2024-09-17 11:00",
    duration: "2 hours",
    fleet: "King Street Transit",
    status: "Grace period",
    allDay: false,
    notes: "Sunrise Delivery Schedule",
  },
  {
    driver_id: "D003",
    title: "Robert Brown",
    vehicle_qr_code: "QR987654",
    start: "2024-09-17 07:30",
    end: "2024-09-17 09:00",
    duration: "1 hours",
    fleet: "King Street Transit",
    status: "Force Stop",
    allDay: false,
    notes: "Start Early, Finish Early",
  },
  {
    driver_id: "D004",
    title: "Emily Davis",
    vehicle_qr_code: "QR192837",
    start: "2024-09-17 10:00",
    end: "2024-09-17 12:00",
    duration: "2 hours",
    fleet: "Evans City Shuttle",
    status: "Grace period",
    allDay: false,
    notes: "Morning Rush Drop-Off",
  },
  {
    driver_id: "D005",
    title: "Michael Johnson",
    vehicle_qr_code: "QR918273",
    start: "2024-09-17 06:00",
    end: "2024-09-17 08:00",
    duration: "2 hours",
    fleet: "Evans City Shuttle",
    status: "Automatically ended",
    allDay: false,
    notes: "Pre-Noon Deliveries Only",
  },
  {
    driver_id: "D007",
    title: "David Martinez",
    vehicle_qr_code: "QR657483",
    start: "2024-09-17 08:30",
    end: "2024-09-17 10:00",
    duration: "1 hours",
    fleet: "Jackson Line Metro",
    status: "Automatically ended",
    allDay: false,
    notes: "Early Bird Route",
  },
  {
    driver_id: "D008",
    title: "Sophia Taylor",
    vehicle_qr_code: "QR584739",
    start: "2024-09-17 09:30",
    end: "2024-09-17 12:30",
    duration: "3 hours",
    fleet: "Martin Urban Transit",
    status: "Grace period",
    allDay: false,
    notes: "Day Starter Deliveries",
  },
];

export const ongoingTripsTableData = [
  {
    driver_id: "D101",
    driver_name: "Liam Brown",
    vehicle_qr_code: "QR292841",
    start_date_time: "2024-09-17 14:00",
    duration: "2 hours",
    fleet: "King Street Transit",
    status: "Automatically ended",
  },
  {
    driver_id: "D102",
    driver_name: "Emma Wilson",
    vehicle_qr_code: "QR563489",
    start_date_time: "2024-09-17 14:15",
    duration: "2 hours",
    fleet: "King Street Transit",
    status: "Grace period",
  },
  {
    driver_id: "D103",
    driver_name: "Noah Miller",
    vehicle_qr_code: "QR589763",
    start_date_time: "2024-09-17 14:30",
    duration: "2 hours",
    fleet: "Evans City Shuttle",
    status: "Force Stop",
  },
  {
    driver_id: "D104",
    driver_name: "Ava Smith",
    vehicle_qr_code: "QR478216",
    start_date_time: "2024-09-17 14:45",
    duration: "2 hours",
    fleet: "Evans City Shuttle",
    status: "Force Stop",
  },
  {
    driver_id: "D105",
    driver_name: "Oliver Jones",
    vehicle_qr_code: "QR324189",
    start_date_time: "2024-09-17 15:00",
    duration: "2 hours",
    fleet: "Martin Urban Transit",
    status: "Automatically ended",
  },
  {
    driver_id: "D106",
    driver_name: "Sophia Garcia",
    vehicle_qr_code: "QR781345",
    start_date_time: "2024-09-17 15:15",
    duration: "2 hours",
    fleet: "Norton Neighborhood Buses",
    status: "Force Stop",
  },
  {
    driver_id: "D107",
    driver_name: "Mason Lee",
    vehicle_qr_code: "QR896745",
    start_date_time: "2024-09-17 15:30",
    duration: "2 hours",
    fleet: "Norton Neighborhood Buses",
    status: "Automatically ended",
  },
  {
    driver_id: "D108",
    driver_name: "Isabella Martinez",
    vehicle_qr_code: "QR017234",
    start_date_time: "2024-09-17 15:45",
    duration: "2 hours",
    fleet: "Martin Urban Transit",
    status: "Grace period",
  },
];

export const completedTripsTableData = [
  {
    id: "D109",
    driver_id: "usr_Bob1234SmithXYZ",
    driver_name: "Bob Smith",
    vehicle_qr_code: "QR481267",
    start_date_time: "2024-09-17 08:00",
    end_date_time: "2024-09-17 10:00",
    duration: "2 hours",
    fleet: "King Street Transit",
    status: "Grace period",
  },
  {
    id: "D110",
    driver_id: "usr_Alice5678JohnABC",
    driver_name: "Alice Johnson",
    vehicle_qr_code: "QR582947",
    start_date_time: "2024-09-17 08:15",
    end_date_time: "2024-09-17 10:15",
    duration: "2 hours",
    fleet: "King Street Transit",
    status: "Grace period",
  },
  {
    id: "D111",
    driver_id: "usr_John7890DoeXYZ",
    driver_name: "John Doe",
    vehicle_qr_code: "QR673891",
    start_date_time: "2024-09-17 08:30",
    end_date_time: "2024-09-17 10:30",
    duration: "2 hours",
    fleet: "Evans City Shuttle",
    status: "Grace period",
  },
  {
    id: "D112",
    driver_id: "usr_Sarah2345WillDEF",
    driver_name: "Sarah Wilson",
    vehicle_qr_code: "QR164739",
    start_date_time: "2024-09-17 08:45",
    end_date_time: "2024-09-17 10:45",
    duration: "2 hours",
    fleet: "Martin Urban Transit",
    status: "Automatically ended",
  },
  {
    id: "D113",
    driver_id: "usr_Mike1234JonesXYZ",
    driver_name: "Mike Jones",
    vehicle_qr_code: "QR265984",
    start_date_time: "2024-09-17 09:00",
    end_date_time: "2024-09-17 11:00",
    duration: "2 hours",
    fleet: "Martin Urban Transit",
    status: "Automatically ended",
  },
  {
    id: "D114",
    driver_id: "usr_James4567BrownXYZ",
    driver_name: "James Brown",
    vehicle_qr_code: "QR376105",
    start_date_time: "2024-09-17 09:15",
    end_date_time: "2024-09-17 11:15",
    duration: "2 hours",
    fleet: "Norton Neighborhood Buses",
    status: "Automatically ended",
  },
  {
    id: "D115",
    driver_id: "usr_Emily6789DavisDEF",
    driver_name: "Emily Davis",
    vehicle_qr_code: "QR487216",
    start_date_time: "2024-09-17 09:30",
    end_date_time: "2024-09-17 11:30",
    duration: "2 hours",
    fleet: "Norton Neighborhood Buses",
    status: "completed",
  },
  {
    id: "D116",
    driver_id: "usr_Mike1234JonesXYZ",
    driver_name: "Mike Jones",
    vehicle_qr_code: "QR598327",
    start_date_time: "2024-09-17 09:45",
    end_date_time: "2024-09-17 11:45",
    duration: "2 hours",
    fleet: "Norton Neighborhood Buses",
    status: "completed",
  },
];
