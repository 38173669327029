import React, { useContext, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import mobileScreen1 from "../../assets/Images/mobileScreen1.png";
import mobileScreen2 from "../../assets/Images/mobileScreen2.png";
import mobileScreen3 from "../../assets/Images/mobileScreen3.png";
import FilledButton from "../../components/MainButtons/FilledButton";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FeatureToggle from "./test";
import IOSSwitch from "../../components/Switch";
import ColorPickers from "../../components/ColorTheme";
import { ThemeContext } from "../../styles/theme";
// import ColorPickerPopover from "./ColorPickerPopover";

const fields = [
  {
    name: "currentVersion",
    label: "Current Version",
    required: true,
    placeholder: "Version 3.0",
  },
  {
    name: "appStoreUrl",
    label: "App store URL",
    required: true,
    placeholder: "https://apps.apple.com/us/app/Axons",
  },
  {
    name: "description",
    label: "Description",
    required: true,
    placeholder: "Please update your application",
  },
  {
    name: "playStoreUrl",
    label: "Play store URL",
    required: true,
    placeholder: "https://play.google.com/store/apps/id=com.Axons",
  },
  { name: "iotType", label: "IOT Type", required: true },
];

const tabLabels = ["Application"];
const currentVersion = ["Version 1.0", "Version 2.0", "Version 3.0"];
const mobileScreens = [mobileScreen1, mobileScreen2, mobileScreen3];

const tabStyles = {
  width: "191px",
  height: "40px",
  fontWeight: 500,
  fontSize: "1rem",
  textTransform: "none",
  color: "#F99494",
  "&.Mui-selected": {
    background: "linear-gradient(90deg, #535A6A 0%, #283246 100%)",
    color: "#FFFFFF",
    borderRadius: "12px",
  },
};

const features = [
  { key: "analysis", label: "Analysis" },
  { key: "liveMap", label: "Live Map" },
  { key: "heatMap", label: "Heat Map" },
];

const inputLabel = {
  fontSize: "1rem",
  fontWeight: 500,
  mb: 1,
  color: "#283246",
};

const selectStyle = {
  borderRadius: "10px",
  width: {
    xs: "100px", // Width for small screens
    sm: "300px", // Width for medium screens
    md: "400px", // Width for large screens
    lg: "300px", // Width for larger screens
    xl: "400px", // Width for extra-large screens
  },
  height: "40px",
  fontSize: "0.9rem",
};

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  name,
  placeholder,
}) => (
  <Box sx={{ width: 300 }}>
    <InputLabel sx={inputLabel}>
      {label} <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
    </InputLabel>
    <Select
      value={value}
      onChange={onChange}
      sx={selectStyle}
      name={name}
      displayEmpty
    >
      <MenuItem value="">
        <Typography color="text.primary">{placeholder}</Typography>
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </Box>
);

const CustomField = ({
  label,
  placeholder,
  required,
  value,
  onChange,
  error,
}) => (
  <Box sx={{ mb: 2, mt: 1 }}>
    <Typography
      sx={{ fontSize: "0.85rem", fontWeight: 500, mb: 1, color: "#283246" }}
    >
      {label} {required && <span style={{ color: "#F99494" }}>*</span>}
    </Typography>
    <CustomTextField
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          width: {
            xs: "100px", // Width for small screens
            sm: "300px", // Width for medium screens
            md: "400px", // Width for large screens
            lg: "300px",
            xl: "400px",
          },
        },
        input: {
          fontSize: "0.9rem",
        },
      }}
      error={!!error}
      helperText={error}
    />
  </Box>
);

export const Settings = () => {
  const [value, setValue] = useState(0); // Default to "Settings"
  const [showFirstGredientColor, setShowFirstGredientColor] = useState(false);
  const [showSecondGredientColor, setShowSecondGredientColor] = useState(false);
  const [showButtonGredientColor, setShowButtonGredientColor] = useState(false);
  const [textFieldStyles, setTextFieldStyles] = useState({
    firstGradientColor: {},
    secondGradientColor: {},
    buttonTitleColor: {},
  });

  const firstGradientColorRef = useRef();
  const secondGradientColorRef = useRef();
  const buttonGradientColorRef = useRef();

  const [formData, setFormData] = useState({
    currentVersion: "Version 1.0",
    appStoreUrl: "https://apps.apple.com/us/app/Axons",
    description: "Please update your application",
    playStoreUrl: "https://play.google.com/store/apps/id=com.Axons",
    iotType:'Axons Micro',
    autolock:'10 (Minute)',
    end_ride_verify:'Verified',
    
  });
  const { setButtonSetting, buttonSetting, firstTheme, setFirstTheme } =
    useContext(ThemeContext);

  const [firstColor, setFirstColor] = useState(firstTheme);
  const [secondColor, setSecondColor] = useState("#FF5C6A");
  const [thirdColor, setThirdColor] = useState(
    buttonSetting?.color || buttonSetting
  );
  const [isChecked, setIsChecked] = useState(false);

  const handleChangeTheme = (event) => {
    setIsChecked(event.target.checked);
  };

  const [colors, setColors] = useState({
    firstThemeColor: "#ff0000",
    secondThemeColor: "#00ff00",
    buttonColor: "#0000ff",
  });

  const [dashboardColor, setDashboardColor] = useState({
    parkingzone: "#ff0000",
    restrictedzone: "#00ff00",
    servicezone: "#0000ff",
    slowspeedzone: "#0000ff",
  });

  const [activePicker, setActivePicker] = useState(null);
  const [errors, setErrors] = useState({});

  const theme = useTheme();

  const inputLabelStyle = {
    fontSize: "0.8rem",
    fontWeight: 600,
    mb: 0.2,
    color: "#283246",
  };

  const handleColorChange = (color, colorKey) => {
    setColors((prevColors) => ({
      ...prevColors,
      [colorKey]: color.hex,
    }));
    setActivePicker(null); // Close the color picker after selecting a color
  };

  const handleColorChangeDashboard = (color, colorKey) => {
    setDashboardColor((prevColors) => ({
      ...prevColors,
      [colorKey]: color.hex,
    }));
    setActivePicker(null); // Close the color picker after selecting a color
  };

  const themeColors = [
    {
      label: "First Theme Color",
      colorKey: "firstThemeColor",
      color: colors.firstThemeColor,
      id: "first",
    },
    {
      label: "Second Theme Color",
      colorKey: "secondThemeColor",
      color: colors.secondThemeColor,
      id: "second",
    },
    {
      label: "Button Color",
      colorKey: "buttonColor",
      color: colors.buttonColor,
      id: "button",
    },
  ];

  const dashboardThemeColor = [
    {
      label: "Parking Zone",
      colorKey: "parkingzone",
      color: dashboardColor.parkingzone,
      id: "parking",
    },
    {
      label: "Service Zone",
      colorKey: "servicezone",
      color: dashboardColor.servicezone,
      id: "servicezone",
    },
    {
      label: "Fuel Station Zone",
      colorKey: "slowspeedzone",
      color: dashboardColor.slowspeedzone,
      id: "slowspeed",
    },
  ];

  const handleChange = (_, newValue) => setValue(newValue);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    const newErrors = {};
    fields.forEach(({ name, label, required }) => {
      if (required && !formData[name]) {
        newErrors[name] = `${label} is required`;
      }
    });
    return newErrors;
  };

  const handleUpdate = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    console.log("Form Data:", formData);
  };

  const handleUpdateTheme = () => {
    console.log(isChecked, "isChecked");
    if (isChecked) {
      setFirstTheme("#283246");
      setButtonSetting(
        "linear-gradient(90deg, #535A6A 0%, #283246 100%)",
        "gradient"
      );
    } else {
      setFirstTheme(firstColor);
      setButtonSetting(thirdColor, "solid");
    }
  };

  const handleFirstGredientColorChange = (e) => {
    setFirstColor(e);
  };

  const handleSecondGredientColorChange = (e) => {
    setSecondColor(e);
  };

  const handleButtonGredientColorChange = (e) => {
    setThirdColor(e);
  };

  const handleFeaturesChange = (activeFeatures) => {
    console.log("Active Features:", activeFeatures);
  };

  const handleFirstGradeintColorClick = () => {
    setShowFirstGredientColor(!showFirstGredientColor);
  };

  const handleSecondGradeintColorClick = () => {
    setShowSecondGredientColor(!showSecondGredientColor);
  };

  const handleButtonGradeintColorClick = () => {
    setShowButtonGredientColor(!showButtonGredientColor);
  };

  return (
    <Paper
      sx={{
        borderRadius: "1.25rem",
        background: "#F8F8F8",
        p: "1.563rem",
        boxShadow: "none",
        minHeight: "90vh",
      }}
    >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1.2rem",
              color: theme.palette.text.primary,
            }}
          >
            Application
          </Typography>
        </Box>

      {value === 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              width: "100%",
              mt: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                {fields
                  .slice(1, 4)
                  .map(({ name, label, required, placeholder }, index) => (
                    <CustomField
                      key={index}
                      label={label}
                      placeholder={placeholder}
                      required={required}
                      value={formData[name]}
                      onChange={handleInputChange}
                      error={errors[name]}
                    />
                  ))}
              </Box>

              <InputLabel sx={inputLabel}>
                Current version{" "}
                <span style={{ color: theme.palette.text.tertiary }}>*</span>
              </InputLabel>
              <Select
                value={formData.currentVersion}
                sx={selectStyle}
                name="currentVersion"
                onChange={handleInputChange}
                placeholder="current version"
              >
                {currentVersion.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 2, sm: 3 },
              flexDirection: { xs: "column", sm: "row" },
              mt: 5,
              mb: 5,
            }}
          >
            {mobileScreens.map((screen, index) => (
              <img
                key={index}
                src={screen}
                alt={`Mobile screen ${index + 1}`}
                style={{
                  width: index === 1 ? "136px" : "96px",
                  height: index === 1 ? "276px" : "195px",
                  borderRadius: "10px",
                }}
              />
            ))}
          </Box>

          <Box sx={{ display: "flex", gap: 2,  mt:{
        lg: 8,
        xl: 18
      } }}>
            <FilledButton sx={{ width: "150px" }} onClick={handleUpdate}>
              Update
            </FilledButton>
            <FilledButton
              sx={{
                border: `1px solid ${theme.palette.text.secondary}`,
                width: "150px",
                color: "black",
                background: "white",
                "&:hover": { background: "white" },
              }}
            >
              Cancel
            </FilledButton>
          </Box>
        </>
      )}

      {value === 2 && (
        <Grid>
          <Box>
          <Typography sx={{ fontSize: "1.5rem", color: "#283246", mb: "1rem" }}>
            Dashboard Theme
          </Typography>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <InputLabel sx={inputLabelStyle}>First Theme Color</InputLabel>
              <Popover
                open={showFirstGredientColor}
                onClose={handleFirstGradeintColorClick}
                anchorEl={firstGradientColorRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <ColorPickers
                  defaultColor={firstColor}
                  handleColorChange={(e) => handleFirstGredientColorChange(e)}
                />
              </Popover>
              <Button
                ref={firstGradientColorRef} // Correct ref for the button
                onClick={handleFirstGradeintColorClick}
                sx={{
                  backgroundColor: firstColor, // Set button background color
                  color: "#fff", // Optional: text color contrast
                  "&:hover": {
                    backgroundColor: firstColor, // Maintain color on hover
                  },
                  width: { lg: "300px", xl: "400px" }, // Adjust button width as needed
                  height: "50px", // Adjust button height as needed
                  borderRadius: "16px",
                }}
              >
                {firstColor}
              </Button>
            </Box>
            <Box>
              <InputLabel sx={inputLabelStyle}>Sec Theme Color</InputLabel>
              <Popover
                open={showSecondGredientColor}
                onClose={handleSecondGradeintColorClick}
                anchorEl={secondGradientColorRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <ColorPickers
                  defaultColor={secondColor}
                  handleColorChange={(e) => handleSecondGredientColorChange(e)}
                />
              </Popover>
              <Button
                ref={secondGradientColorRef} // Correct ref for the button
                onClick={handleSecondGradeintColorClick}
                sx={{
                  backgroundColor: secondColor, // Set button background color
                  color: "#fff", // Optional: text color contrast
                  "&:hover": {
                    backgroundColor: secondColor, // Maintain color on hover
                  },
                  width: { lg: "300px", xl: "400px" }, // Adjust button width as needed
                  height: "50px", // Adjust button height as needed
                  borderRadius: "16px",
                }}
              >
                {secondColor}
              </Button>
            </Box>
            <Box>
              <InputLabel sx={inputLabelStyle}>Button Theme Color</InputLabel>
              <Popover
                open={showButtonGredientColor}
                onClose={handleButtonGradeintColorClick}
                anchorEl={buttonGradientColorRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <ColorPickers
                  defaultColor={thirdColor}
                  handleColorChange={(e) => handleButtonGredientColorChange(e)}
                />
              </Popover>
              <Button
                ref={buttonGradientColorRef} // Correct ref for the button
                onClick={handleButtonGradeintColorClick}
                sx={{
                  backgroundColor: thirdColor, // Set button background color
                  color: "#fff", // Optional: text color contrast
                  "&:hover": {
                    backgroundColor: thirdColor, // Maintain color on hover
                  },
                  width: { lg: "300px", xl: "400px" }, // Adjust button width as needed
                  height: "50px", // Adjust button height as needed
                  borderRadius: "16px",
                }}
              >
                {thirdColor}
              </Button>
            </Box>
          </Box>
          </Box>
          <Box sx={{mt:5}}>
          <Typography sx={{ fontSize: "1.5rem", color: "#283246", mb: "1rem" }}>
          Geofences Theme
          </Typography>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <InputLabel sx={inputLabelStyle}>Parking Zone </InputLabel>
              {/* <Popover
                open={showFirstGredientColor}
                onClose={handleFirstGradeintColorClick}
                anchorEl={firstGradientColorRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <ColorPickers
                  defaultColor={firstColor}
                  handleColorChange={(e) => handleFirstGredientColorChange(e)}
                />
              </Popover> */}
              <Button
                // ref={firstGradientColorRef} // Correct ref for the button
                // onClick={handleFirstGradeintColorClick}
                sx={{
                  backgroundColor: '#00AAFF', // Set button background color
                  color: "#fff", // Optional: text color contrast
                  "&:hover": {
                    backgroundColor: '#00AAFF', // Maintain color on hover
                  },
                  width: { lg: "300px", xl: "400px" }, // Adjust button width as needed
                  height: "50px", // Adjust button height as needed
                  borderRadius: "16px",
                }}
              >
                {'#00AAFF'}
              </Button>
            </Box>
            <Box>
              <InputLabel sx={inputLabelStyle}>Restricted Zone</InputLabel>
              {/* <Popover
                open={showSecondGredientColor}
                onClose={handleSecondGradeintColorClick}
                anchorEl={secondGradientColorRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <ColorPickers
                  defaultColor={secondColor}
                  handleColorChange={(e) => handleSecondGredientColorChange(e)}
                />
              </Popover> */}
              <Button
                // ref={secondGradientColorRef} // Correct ref for the button
                // onClick={handleSecondGradeintColorClick}
                sx={{
                  backgroundColor: '#FF000080', // Set button background color
                  color: "#fff", // Optional: text color contrast
                  "&:hover": {
                    backgroundColor: '#FF000080', // Maintain color on hover
                  },
                  width: { lg: "300px", xl: "400px" }, // Adjust button width as needed
                  height: "50px", // Adjust button height as needed
                  borderRadius: "16px",
                }}
              >
                {'#FF000080'}
              </Button>
            </Box>
            <Box>
              <InputLabel sx={inputLabelStyle}>Service Zone</InputLabel>
              {/* <Popover
                open={showButtonGredientColor}
                onClose={handleButtonGradeintColorClick}
                anchorEl={buttonGradientColorRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <ColorPickers
                  defaultColor={thirdColor}
                  handleColorChange={(e) => handleButtonGredientColorChange(e)}
                />
              </Popover> */}
              <Button
                // ref={buttonGradientColorRef} // Correct ref for the button
                // onClick={handleButtonGradeintColorClick}
                sx={{
                  backgroundColor: '#63C690', // Set button background color
                  color: "#fff", // Optional: text color contrast
                  "&:hover": {
                    backgroundColor: '#63C690', // Maintain color on hover
                  },
                  width: { lg: "300px", xl: "400px" }, // Adjust button width as needed
                  height: "50px", // Adjust button height as needed
                  borderRadius: "16px",
                }}
              >
                {'#63C690'}
              </Button>
            </Box>
          </Box>
          <Box sx={{mt:2}}>
              <InputLabel sx={inputLabelStyle}>Fuel Station Zone</InputLabel>
              {/* <Popover
                open={showButtonGredientColor}
                onClose={handleButtonGradeintColorClick}
                anchorEl={buttonGradientColorRef.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <ColorPickers
                  defaultColor={thirdColor}
                  handleColorChange={(e) => handleButtonGredientColorChange(e)}
                />
              </Popover> */}
              <Button
                // ref={buttonGradientColorRef} // Correct ref for the button
                // onClick={handleButtonGradeintColorClick}
                sx={{
                  backgroundColor: '#9747FF', // Set button background color
                  color: "#fff", // Optional: text color contrast
                  "&:hover": {
                    backgroundColor: '#9747FF', // Maintain color on hover
                  },
                  width: { lg: "300px", xl: "400px" }, // Adjust button width as needed
                  height: "50px", // Adjust button height as needed
                  borderRadius: "16px",
                }}
              >
                {'#9747FF'}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              mt:{
                lg: 10,
                xl: 30
              } ,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ padding: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleChangeTheme}
                    sx={{
                      color: "#97A1B6", // Unchecked color
                      "&.Mui-checked": {
                        color: "#283246", // Checked color
                      },
                    }}
                  />
                }
                label="Set to Default Theme (resets colors)"
              />

              <Box sx={{ display: "flex", gap: 2, }}>
                <FilledButton
                  variant="contained"
                  sx={{ width: "150px" }}
                  onClick={handleUpdateTheme}
                >
                  Update
                </FilledButton>
                <FilledButton
                  variant="outlined"
                  sx={{
                    border: `1px solid ${theme.palette.text.secondary}`,
                    width: "150px",
                    color: "black",
                    background: "white",
                    "&:hover": { background: "white" },
                  }}
                >
                  Cancel
                </FilledButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}

      {value === 1 && (
        <>
          <Typography
            sx={{ fontSize: "1.5rem", fontWeight: 500, mb: 1, mt: 1 }}
          >
            IOT Configuration
          </Typography>
          <CustomSelect
            label="IOT Type"
            value={formData.iotType}
            onChange={handleInputChange}
            options={[
              { value: "Axons Micro", label: "Axons Micro" },
              { value: "Axons Micro Pro", label: "Axons Micro Pro" },
            ]}
            name="iotType"
            placeholder="Select Version"
          />

          <Typography
            sx={{ fontSize: "1.5rem", fontWeight: 500, mb: 2, mt: 5 }}
          >
            Vehicle Configuration
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "90%",
            }}
          >
            <CustomSelect
              label="End ride location verification"
              value={formData.end_ride_verify}
              onChange={handleInputChange}
              options={[
                { value: "Verified", label: "Verified" },
                { value: "Unverified", label: "Unverified" },
                { value: "Not Applicable", label: "Not Applicable" },
              ]}
              name="end_ride_verify"
              placeholder="Select Type"
            />

            <CustomSelect
              label="Auto lock vehicle (minutes)"
              value={formData.autolock}
              onChange={handleInputChange}
              options={[
                { value: "10 (Minute)", label: "10 (Minute)" },
                { value: "20 (Minute)", label: "20 (Minute)" },
                { value: "50 (Minute)", label: "50 (Minute)" },
              ]}
              name="autolock"
              placeholder="Select Type"
            />

            <FormControlLabel
              control={<IOSSwitch sx={{ mr: 2 }} />}
              label="Torch on ride"
              labelPlacement="end"
              sx={{ ml: 4, mt: 4 }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: 2, mt:{
                lg: 23,
                xl: 40
              } , }}>
                <FilledButton
                  variant="contained"
                  sx={{ width: "150px" }}
                >
                  Update
                </FilledButton>
                <FilledButton
                  variant="outlined"
                  sx={{
                    border: `1px solid ${theme.palette.text.secondary}`,
                    width: "150px",
                    color: "black",
                    background: "white",
                    "&:hover": { background: "white" },
                  }}
                >
                  Cancel
                </FilledButton>
              </Box>
        </>
      )}

      {value === 3 && (
        <FeatureToggle features={features} onChange={handleFeaturesChange} />
      )}
    </Paper>
  );
};

export default Settings;
