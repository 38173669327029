// src/pages/Login.js
import React, { useState } from "react";
import LoginEmail from "./LoginEmail";
import LoginOtp from "./LoginOtp";


const Login = () => {
  const [emailAuth, setEmailAuth] = useState(false);
  return (
    <>
      {emailAuth ? <LoginOtp
       /> : <LoginEmail setEmailAuth={setEmailAuth} />}
    </>
  );
};

export default Login;
