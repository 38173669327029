import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  useTheme,
  Divider,
  FormHelperText,
  Tooltip,
  Avatar,
  Checkbox,
  ListItemText,
} from "@mui/material";
import crossicon from "../../assets/Icons/drawercross.svg";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import redDot from "../../assets/Icons/reddot.svg";
import completedCheck from "../../assets/Icons/completedCheck.svg";
import graydot from "../../assets/Icons/graydot.svg";
import uploadimage from "../../assets/Icons/uploadimage.svg";
import actionedit from "../../assets/Icons/actionedit.svg";
import actiondelete from "../../assets/Icons/actiondelete.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import checked from "../../assets/Icons/checkicon.svg";
import dayjs from "dayjs";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { addDriver } from "../../slices/driverManageSlice";
import { useSnackbar } from "../../hooks/Snackbar";
import OtpVerificationModal from "../../components/OtpModalVerification";
import { fleetTableData } from "../Fleet/utils";

const inputLabel = {
  fontSize: "0.8rem",
  fontWeight: 700,
  mb: 1,
  color: "#283246",
};

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    // Apply responsive width based on breakpoints
    width: {
      xs: "100px", // Width for small screens
      sm: "300px", // Width for medium screens
      md: "400px", // Width for large screens
      lg: "300px",
      xl: "400px",
    },
  },
};

const selectStyle = {
  borderRadius: "10px",
  height: "40px",
  width: {
    xs: "100px", // Width for small screens
    sm: "300px", // Width for medium screens
    md: "400px", // Width for large screens
    lg: "300px", // Width for larger screens
    xl: "400px", // Width for extra-large screens
  },
};

export const getImageSrc = (image) => {
  if (!image) {
    // Handles null, undefined, or empty strings
    return ""; // Return a default image placeholder or an empty string
  }

  if (typeof image === "string") {
    // Check if it's a base64 encoded image
    if (image.startsWith("data:image")) {
      return image;
    }
    return ""; // Return a default image placeholder or an empty string if the string is not a base64 image
  } else if (typeof image === "object" && Object.keys(image).length > 0) {
    // It's a File object and not empty, create an object URL
    try {
      return URL.createObjectURL(image);
    } catch (error) {
      console.error("Failed to create an object URL:", error);
      return ""; // Return a default image placeholder or an empty string on failure
    }
  }

  return ""; // Return a default image placeholder or an empty string if none is provided or if the object is empty
};

const requiredFields = {
  driver_name: "This field is required",
  last_name: "This field is required",
  fleet: "This field is required",
  email: "This field is required",
  mobile_phone: "This field is required",
  work_phone: "This field is required",
  address: "This field is required",
  address_lane2: "This field is required",
  city: "This field is required",
  state: "This field is required",
  zip_code: "This field is required",
  country: "This field is required",
  licence_no: "This field is required",
  licence_state: "This field is required",
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePhone = (phone) => {
  const re = /^\d{10}$/; // Adjust regex as needed based on your locale
  return re.test(phone);
};

const InputFieldWrapper = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  type,
  editable = true,
  required = true,
  validationRequired = false,
  setOtpModal,
  verificationStatus,
}) => {
  const [verificationLoading, setVerificationLoading] = useState(false);

  const handleVerification = () => {
    setVerificationLoading(true);
    setTimeout(() => {
      setVerificationLoading(false);
      setOtpModal(true, name);
    }, 1500);
  };

  const showVerificationButton =
    validationRequired &&
    !verificationStatus &&
    ((name === "email" && validateEmail(value)) ||
      ((name === "mobile_phone" || name === "work_phone") &&
        validatePhone(value)));

  return (
    <Box>
      <Box>
        <InputLabel sx={inputLabel}>
          {label} {""}
          {required && (
            <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
          )}
        </InputLabel>
        <CustomTextField
          value={value}
          onChange={onChange}
          name={name}
          type={type}
          sx={textFieldStyle}
          error={!!error}
          placeholder={placeholder}
          InputProps={{
            disabled: !editable,
          }}
        />
        {error && (
          <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
            {error}
          </FormHelperText>
        )}
      </Box>
      {showVerificationButton && (
        <FilledButton
          onClick={handleVerification}
          sx={{
            width: "140px",
            height: "30px",
            borderRadius: "6px",
            mt: 1,
            background: "#FE7676",
            "&:hover": {
              background: "#FE7676",
            },
          }}
        >
          {verificationLoading
            ? "Just a moment..."
            : name === "email"
            ? "Email verification"
            : "Mobile verification"}
        </FilledButton>
      )}
      {verificationStatus && (
        <Box sx={{ display: "flex", gap: 1, padding: "0.2rem" }}>
          <img src={checked} style={{ width: "20px", height: "20px", mt: 2 }} />{" "}
          <Typography sx={{ color: "#52C37F", fontSize: "14px" }}>
            Verification completed.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  name,
  placeholder,
  error,
  editable = true,
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        sx={selectStyle}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Adjust this value to control the height of the visible area
            },
          },
        }}
        name={name}
        error={!!error}
        disabled={!editable}
        displayEmpty
      >
        <MenuItem value="">
          <Typography color="text.primary">{placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

const FleetMultiSelect = ({
  label,
  value = [],
  onChange,
  options,
  name,
  placeholder,
  error,
  editable = true,
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={onChange}
        sx={selectStyle}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Adjust this value to control the height of the visible area
            },
          },
        }}
        name={name}
        error={!!error}
        disabled={!editable}
        displayEmpty
        renderValue={(selected) => selected.join(", ") || placeholder}
      >
        <MenuItem disabled value="">
          <Typography color="text.primary">{placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox
              checked={value.indexOf(option.value) > -1}
              sx={{
                "&.Mui-checked": {
                  color: "#283246", // Color when checked
                },
              }}
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

const steps = [
  { title: "Get Started", subtitle: "Provide Basic Details" },
  { title: "Your Contact Details", subtitle: "Add Contact Information" },
  { title: "License Details", subtitle: "Enter License Information" },
];

// Component to render each step header with status and subtitle
const StepHeader = ({ value, completedSteps, editable }) => (
  <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
    {steps.map((step, index) => {
      const isCompleted = completedSteps.includes(index);
      const isActive = index === value;

      return (
        <>
          <Box
            key={step.title}
            sx={{
              flex: 1,
              cursor: "pointer",
              borderBottom:
                editable && isActive
                  ? "4px solid #FE7676"
                  : editable && isCompleted
                  ? "4px solid #52C37F"
                  : "2px solid #ddd",
              padding: 1,
            }}
            // onClick={handleClick}
          >
            <Typography
              sx={{
                display: "flex",
                color: "#97A1B6",
                alignItems: "center",
                fontWeight: 300,
                fontSize: "0.938rem",
              }}
            >
              {isActive ? "Current" : isCompleted ? "Complete" : "In progress"}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                color: "#283246",
              }}
            >
              {`${index + 1}. ${step.title}`}
              {editable && (
                <>
                  {isActive ? (
                    <img
                      src={redDot}
                      style={{
                        height: "25px",
                        width: "25px",
                        marginLeft: "12px",
                      }}
                    />
                  ) : isCompleted ? (
                    <img
                      src={completedCheck}
                      style={{
                        height: "25px",
                        width: "25px",
                        marginLeft: "12px",
                      }}
                    />
                  ) : (
                    <img
                      src={graydot}
                      style={{
                        height: "25px",
                        width: "25px",
                        marginLeft: "12px",
                      }}
                    />
                  )}
                </>
              )}
            </Typography>
            <Typography
              sx={{ color: "#97A1B6", fontWeight: 300, fontSize: "0.938rem" }}
            >
              {step.subtitle}
            </Typography>
          </Box>
        </>
      );
    })}
  </Box>
);

const AddDriverForm = ({
  drawerOpen,
  setDrawerOpen,
  handleDelete,
  editDriver,
  setEditDriver,
}) => {
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [editable, setEditable] = useState(true);
  const { driver } = useSelector((state) => state.driverManage);
  const drivers = driver.find((u) => u.id === editDriver);

  const [formData, setFormData] = useState({
    driver_name: "",
    last_name: "",
    fleet: [],
    email: "",
    total_exp: "",
    mobile_phone: "",
    notes: "",
    work_phone: "",
    address: "",
    address_lane2: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    date_of_birth: dayjs().format("YYYY-MM-DD"),
    licence_no: "",
    licence_start_date: dayjs().format("YYYY-MM-DD"),
    licence_exp_date: dayjs().format("YYYY-MM-DD"),
    licence_state: "",
    date_of_joining: dayjs().format("YYYY-MM-DD"),
    driver_image: "",
    id: "",
    email_verification: false,
    mobile_phone_verification: false,
    work_phone_verification: false,
  });
  const [value, setValue] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [otpModalInfo, setOtpModalInfo] = useState({
    open: false,
    verifyField: null,
  });

  const showSnackbar = useSnackbar();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { fleet } = useSelector((state) => state.fleetManage);

  useEffect(() => {
    setFormData({
      driver_name: drivers?.driver_name,
      last_name: drivers?.last_name,
      fleet: drivers?.fleet,
      email: drivers?.email,
      total_exp: drivers?.total_exp,
      mobile_phone: drivers?.mobile_phone,
      notes: drivers?.notes,
      work_phone: drivers?.work_phone,
      address: drivers?.address,
      address_lane2: drivers?.address_lane2,
      city: drivers?.city,
      state: drivers?.state,
      zip_code: drivers?.zip_code,
      country: drivers?.country,
      date_of_birth: dayjs(drivers?.date_of_birth).format("YYYY-MM-DD"),
      licence_no: drivers?.licence_no,
      licence_start_date: dayjs(drivers?.licence_start_date).format(
        "YYYY-MM-DD"
      ),
      licence_exp_date: dayjs(drivers?.licence_exp_date).format("YYYY-MM-DD"),
      licence_state: drivers?.licence_state,
      date_of_joining: dayjs(drivers?.date_of_joining).format("YYYY-MM-DD"),
      driver_image: drivers?.driver_image,
      email_verification: drivers?.email_verification,
      mobile_phone_verification: drivers?.mobile_phone_verification,
      work_phone_verification: drivers?.work_phone_verification,
      id: drivers?.id,
    });
  }, [drivers]);

  function resetFormData() {
    setFormData({
      driver_name: "",
      last_name: "",
      fleet: [],
      email: "",
      total_exp: "",
      mobile_phone: "",
      notes: "",
      work_phone: "",
      address: "",
      address_lane2: "",
      city: "",
      state: "",
      zip_code: "",
      country: "",
      date_of_birth: dayjs().format("YYYY-MM-DD"),
      licence_no: "",
      licence_start_date: dayjs().format("YYYY-MM-DD"),
      licence_exp_date: dayjs().format("YYYY-MM-DD"),
      licence_state: "",
      date_of_joining: dayjs().format("YYYY-MM-DD"),
      driver_image: "",
      id: "",
      email_verification: false,
      mobile_phone_verification: false,
      work_phone_verification: false,
    });
    setValue(0);
    setCompletedSteps([]);
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown") {
      return;
    }
    setDrawerOpen(open);
    setEditDriver(false);
    setEditable(true);
    resetFormData();
    setErrors({});
  };

  const handleEditview = () => {
    setEditable(!editable);
  };

  const fleetOptions = fleetTableData?.map((fleet) => ({
    label: fleet.fleet_name,
    value: fleet.fleet_name,
  }));

  console.log(fleetOptions, "fleetOptionsfleetOptions");

  const handleInputChange = (e, fieldName = null) => {
    if (e.target) {
      const { name, value, files } = e.target;

      // Check if the input is a file input
      if (files && files.length > 0) {
        const file = files[0];

        // Convert the image file to a base64 string
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;

          // Update the form data with the base64 image string
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: base64String,
          }));
        };
        reader.readAsDataURL(file);
      } else {
        // Handle standard inputs or multi-select
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: Array.isArray(value) ? value : value, // Handle multiple selection
        }));

        // Update error state based on input validation
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          if ((typeof value === "string" && value.trim()) || value) {
            delete newErrors[name];
          }
          return newErrors;
        });
      }
    } else if (fieldName) {
      // Handle DateTimePicker inputs
      setFormData((prevFormData) => ({
        ...prevFormData,
        [fieldName]: dayjs(e).isValid()
          ? dayjs(e).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"), // Store Date object
      }));
    }
  };

  const handleSaveChanges = () => {
    let isValid = true;
    let newErrors = {};

    // Check each required field
    Object.keys(requiredFields).forEach((field) => {
      const value = formData[field];

      // Check if the value is a string before calling trim()
      if (!value || (typeof value === "string" && value.trim() === "")) {
        isValid = false;
        newErrors[field] = requiredFields[field];
      }
    });

    // Check if at least one verification is complete
    if (
      !formData.email_verification &&
      !formData.mobile_phone_verification &&
      !formData.work_phone_verification
    ) {
      isValid = false;
      newErrors.verification =
        "Please go back and complete at least one verification (Email, Mobile Phone, or Work Phone).";
    }

    setErrors(newErrors); // Set errors or clear them

    if (isValid) {
      // Create a new formData object with formatted dates
      const formattedFormData = {
        ...formData,
        date_of_birth: dayjs(formData.date_of_birth).format("YYYY-MM-DD"),
        licence_start_date: dayjs(formData.licence_start_date).format(
          "YYYY-MM-DD"
        ),
        licence_exp_date: dayjs(formData.licence_exp_date).format("YYYY-MM-DD"),
        date_of_joining: dayjs(formData.date_of_joining).format("YYYY-MM-DD"),
      };

      dispatch(addDriver(formattedFormData));
      setDrawerOpen(false);
      setErrors({});
      showSnackbar(
        editable ? "Driver edited successfully!" : "Driver added successfully!",
        "success"
      );
      resetFormData();
    }
  };

  const handleDriverDelete = () => {
    handleDelete(editDriver);
  };

  useEffect(() => {
    if (editDriver) {
      setEditable(false);
    }
  }, [editDriver]);

  const handleNext = () => {
    const errors = {};

    // Define the required fields based on the step in the form
    const requiredFields =
      value === 0
        ? ["driver_name", "last_name", "fleet", "email", "total_exp"]
        : [
            "mobile_phone",
            "work_phone",
            "address",
            "address_lane2",
            "city",
            "state",
            "zip_code",
          ];

    // Validate required fields
    requiredFields.forEach((key) => {
      if (
        !formData[key] ||
        (typeof formData[key] === "string" && !formData[key].trim())
      ) {
        errors[key] = "This field is required";
      }
    });

    // Additional validation for email and phone numbers
    if (value === 0 && formData.email && !validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (value === 1) {
      if (formData.mobile_phone && !validatePhone(formData.mobile_phone)) {
        errors.mobile_phone = "Please enter a valid mobile phone number.";
      }
      if (formData.work_phone && !validatePhone(formData.work_phone)) {
        errors.work_phone = "Please enter a valid work phone number.";
      }
    }

    setErrors(errors);

    // Proceed to the next step if there are no errors
    if (Object.keys(errors).length === 0) {
      setCompletedSteps((prev) => [...prev, value]);
      setValue((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setCompletedSteps((prev) => prev.slice(0, -1));
    setValue((prev) => prev - 1);
  };

  const setOtpModal = (isOpen, verifyField = null, verified = false) => {
    if (!isOpen && verified) {
      // Assuming verification is successful, update the corresponding verification field
      setFormData((prev) => ({
        ...prev,
        [`${verifyField}_verification`]: true,
      }));
    }
    // Update the modal info state
    setOtpModalInfo({ open: isOpen, verifyField });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        transitionDuration={{
          enter: 500,
          exit: 500,
        }}
        PaperProps={{
          sx: {
            width: { lg: "60%", xl: "56%" },
            boxSizing: "border-box",
            background: theme.palette.text.quaternary,
            border: "none",
            borderRadius: "1rem",
          },
        }}
        sx={{
          width: { lg: "60%", xl: "56%" },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: "100%",
              padding: "20px",
              backgroundColor: "#ffff",
              // height: '100%'
            }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
          >
            <Box
              sx={{
                display: "flex",
                background: "F8F8F8",
                justifyContent: "space-between",
                p: 0.5,
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography sx={{ fontSize: "1rem", mt: 0.5, fontWeight: 600 }}>
                {editDriver ? formData.driver_name : "Add New Driver"}
              </Typography>

              <Box sx={{ display: "flex" }}>
                {editDriver && (
                  <>
                    <Box
                      onClick={handleEditview}
                      sx={{
                        border: "1px solid #DDDEE1",
                        borderRadius: "6px",
                        p: 0.2,
                        mr: 0.5,
                        ml: 0.5,
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip
                        title="Edit"
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#424242", // Dark grey background for high contrast
                              color: "white", // White text for clarity
                              fontWeight: "600",
                              fontSize: "14px",
                              padding: "10px 15px",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                            },
                          },
                          arrow: {
                            sx: {
                              color: "#424242", // Matching the tooltip background
                            },
                          },
                        }}
                      >
                        <IconButton
                          sx={{
                            color: theme.palette.text.primary,
                            width: "25px",
                            height: "25px",
                          }}
                        >
                          <img
                            src={actionedit}
                            alt="edit"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      onClick={handleDriverDelete}
                      sx={{
                        border: "1px solid #DDDEE1",
                        borderRadius: "6px",
                        p: 0.2,
                        mr: 0.5,
                        ml: 0.5,
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip
                        title="Delete"
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#424242", // Dark grey background for high contrast
                              color: "white", // White text for clarity
                              fontWeight: "600",
                              fontSize: "14px",
                              padding: "10px 15px",
                              borderRadius: "8px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                            },
                          },
                          arrow: {
                            sx: {
                              color: "#424242", // Matching the tooltip background
                            },
                          },
                        }}
                      >
                        <IconButton
                          sx={{
                            color: theme.palette.text.primary,
                            width: "25px",
                            height: "25px",
                          }}
                        >
                          <img
                            src={actiondelete}
                            alt="delete"
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </>
                )}
                <IconButton
                  onClick={toggleDrawer(false)}
                  sx={{ color: theme.palette.text.primary }}
                >
                  <img src={crossicon} alt="Close" />
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <StepHeader
              value={value}
              completedSteps={completedSteps}
              editable={editable}
              handleNext={handleNext}
              handleBack={handleBack}
            />

            <Box sx={{ display: "flex", justifyContent: "start", mb: 2 }}></Box>
            {value === 0 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { lg: "1rem", xl: "1.6rem" },
                  }}
                >
                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <InputFieldWrapper
                      label="First Name"
                      name="driver_name"
                      value={formData.driver_name}
                      error={errors.driver_name}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="Chris"
                    />
                    <InputFieldWrapper
                      label="Last Name"
                      name="last_name"
                      value={formData.last_name}
                      error={errors.last_name}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="Evans"
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    {/* <CustomSelect
                      label="Fleet"
                      value={formData.fleet}
                      onChange={handleInputChange}
                      error={errors.fleet}
                      options={fleetOptions}
                      editable={editable}
                      name="fleet"
                    /> */}

                    <FleetMultiSelect
                      label="Fleet"
                      value={formData.fleet}
                      onChange={handleInputChange}
                      error={errors.fleet}
                      options={fleetOptions}
                      editable={editable}
                      name="fleet"
                      placeholder="Select Fleets"
                    />

                    <InputFieldWrapper
                      label="Email"
                      name="email"
                      value={formData.email}
                      error={errors.email}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="chris@gmail.com"
                      validationRequired={true}
                      setOtpModal={setOtpModal}
                      verificationStatus={formData.email_verification}
                    />
                  </Box>
                  <Box>
                    <InputFieldWrapper
                      label="Message / Note"
                      name="notes"
                      value={formData.notes}
                      error={errors.notes}
                      onChange={handleInputChange}
                      editable={editable}
                      required={false}
                      placeholder="Enter message"
                    />
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <InputLabel sx={inputLabel}>Upload Image</InputLabel>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderStyle: "dotted",
                          background: "#FE76761A",
                          borderWidth: "4px",
                          borderColor: "#DDDEE1",
                          cursor: "pointer",
                          p: 4,
                        }}
                        onClick={() =>
                          document.getElementById("upload-file").click()
                        } // Trigger file input on box click
                      >
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          id="upload-file"
                          onChange={handleInputChange}
                          name="driver_image"
                        />

                        <label
                          htmlFor="upload-file"
                          style={{
                            alignItems: "center", // Center vertically
                            justifyContent: "center", // Center horizontally
                            // height: "100px", // Set a height for the box to make vertical centering visible
                            textAlign: "center", // Ensure text is centered if it wraps to a new line
                            width: "100%",
                          }}
                        >
                          <FilledButton
                            sx={{
                              width: "150px",
                              height: "50px",
                              background: "none",
                              boxShadow: "none",
                              color: "#97A1B6",
                              "&:hover": {
                                background: "none",
                                boxShadow: "none",
                              },
                            }}
                          >
                            <img src={uploadimage} />
                          </FilledButton>

                          {formData?.driver_image ? (
                            <Box sx={{ minWidth: 135, minHeight: 115 }}>
                              <img
                                src={getImageSrc(formData?.driver_image)}
                                alt="Product"
                                width={135}
                                height={115}
                              />
                            </Box>
                          ) : (
                            <>
                              <Typography
                                sx={{
                                  color: "#97A1B6",
                                  mt: 2,
                                  fontSize: "1rem",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Upload Image here
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#97A1B6",
                                  mt: 2,
                                  fontSize: "1rem",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                Only JPG and PNG files are accepted. Maximum
                                size: 5MB.
                              </Typography>
                            </>
                          )}
                        </label>
                      </Box>
                    </Box>
                    <InputFieldWrapper
                      label="Experience (in years)"
                      name="total_exp"
                      type={"number"}
                      value={formData.total_exp}
                      error={errors.total_exp}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="Enter total experience"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    mt: {
                      lg: 16,
                      xl: 34,
                    },
                  }}
                >
                  <FilledButton sx={{ width: "150px" }} onClick={handleNext}>
                    Next
                  </FilledButton>
                </Box>
              </>
            )}

            {value === 1 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { lg: "1rem", xl: "1.6rem" },
                  }}
                >
                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <InputFieldWrapper
                      label="Mobile Phone Number"
                      name="mobile_phone"
                      value={formData.mobile_phone}
                      error={errors.mobile_phone}
                      type={"number"}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="+91 8675564567"
                      validationRequired={true}
                      setOtpModal={setOtpModal}
                      verificationStatus={formData.mobile_phone_verification}
                    />
                    <InputFieldWrapper
                      label="Work Phone Number"
                      name="work_phone"
                      value={formData.work_phone}
                      error={errors.work_phone}
                      type={"number"}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="+91 8675564567"
                      validationRequired={true}
                      setOtpModal={setOtpModal}
                      verificationStatus={formData.work_phone_verification}
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <InputFieldWrapper
                      label="Address"
                      name="address"
                      value={formData.address}
                      error={errors.address}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="Shymal cross road"
                    />

                    <InputFieldWrapper
                      label="Address Line 2"
                      name="address_lane2"
                      value={formData.address_lane2}
                      error={errors.address_lane2}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="Shymal cross road"
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <InputFieldWrapper
                      label="City"
                      name="city"
                      value={formData.city}
                      error={errors.city}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="Ahmedabad"
                    />
                    <InputFieldWrapper
                      label="State/province/Region"
                      name="state"
                      value={formData.state}
                      error={errors.state}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="Gujarat"
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <InputFieldWrapper
                      label="Zip/Postal Code"
                      name="zip_code"
                      type={"number"}
                      value={formData.zip_code}
                      error={errors.zip_code}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="380013"
                    />
                    <InputFieldWrapper
                      label="Country"
                      name="country"
                      value={formData.country}
                      error={errors.country}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="India"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    gap: 2,
                    mt: {
                      lg: 8,
                      xl: 20,
                    },
                  }}
                >
                  <FilledButton
                    sx={{
                      border: `1px solid ${theme.palette.text.secondary}`,
                      width: "150px",
                      color: "black",
                      background: "white",
                      "&:hover": {
                        background: "white",
                        transform: "scale(1.05)",
                      },
                    }}
                    onClick={handleBack}
                  >
                    Back
                  </FilledButton>
                  <FilledButton sx={{ width: "150px" }} onClick={handleNext}>
                    Next
                  </FilledButton>
                </Box>
              </>
            )}

            {value === 2 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { lg: "1rem", xl: "1.6rem" },
                    height: "auto",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <Box>
                      <InputLabel sx={inputLabel}>
                        Date Of Birth{" "}
                        <span style={{ color: "#f99494", fontSize: "1rem" }}>
                          *
                        </span>
                      </InputLabel>
                      <DatePicker
                        value={dayjs(formData.date_of_birth)}
                        onChange={(date) =>
                          handleInputChange(date, "date_of_birth")
                        }
                        name="date_of_birth"
                        sx={{
                          width: {
                            xs: "100px", // Width for small screens
                            sm: "300px", // Width for medium screens
                            md: "400px", // Width for large screens
                            lg: "300px",
                            xl: "400px",
                          },
                          height: "45px",
                          "& .MuiInputBase-root": {
                            height: "100%",
                          },
                        }}
                        disabled={!editable}
                        slotProps={{
                          textField: {
                            InputProps: {
                              sx: {
                                borderRadius: "10px",
                              },
                            },
                          },
                        }}
                      />
                    </Box>
                    <InputFieldWrapper
                      label="License Number"
                      name="licence_no"
                      value={formData.licence_no}
                      error={errors.licence_no}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="45WXYZ6789A1"
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <Box>
                      <InputLabel sx={inputLabel}>
                        License Start Date{" "}
                        <span style={{ color: "#f99494", fontSize: "1rem" }}>
                          *
                        </span>
                      </InputLabel>
                      <DatePicker
                        value={dayjs(formData.licence_start_date)}
                        onChange={(date) =>
                          handleInputChange(date, "licence_start_date")
                        }
                        name="licence_start_date"
                        sx={{
                          width: {
                            xs: "100px", // Width for small screens
                            sm: "300px", // Width for medium screens
                            md: "400px", // Width for large screens
                            lg: "300px",
                            xl: "400px",
                          },
                          height: "45px",
                          "& .MuiInputBase-root": {
                            height: "100%",
                          },
                        }}
                        disabled={!editable}
                        slotProps={{
                          textField: {
                            InputProps: {
                              sx: {
                                borderRadius: "10px",
                              },
                            },
                          },
                        }}
                      />
                    </Box>
                    <Box>
                      <InputLabel sx={inputLabel}>
                        License End Date{" "}
                        <span style={{ color: "#f99494", fontSize: "1rem" }}>
                          *
                          {/* dayjs(formData.licence_exp_date).second(30).millisecond(596).toISOString()*/}
                        </span>
                      </InputLabel>
                      <DatePicker
                        value={dayjs(formData.licence_exp_date)}
                        onChange={(date) =>
                          handleInputChange(date, "licence_exp_date")
                        }
                        name="licence_exp_date"
                        sx={{
                          width: {
                            xs: "100px", // Width for small screens
                            sm: "300px", // Width for medium screens
                            md: "400px", // Width for large screens
                            lg: "300px",
                            xl: "400px",
                          },
                          height: "45px",
                          "& .MuiInputBase-root": {
                            height: "100%",
                          },
                        }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              sx: {
                                borderRadius: "10px",
                              },
                            },
                          },
                        }}
                        disabled={!editable}
                      />
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", gap: "2rem" }}>
                    <InputFieldWrapper
                      label="License State/province/Region"
                      name="licence_state"
                      value={formData.licence_state}
                      error={errors.licence_state}
                      onChange={handleInputChange}
                      editable={editable}
                      placeholder="Gujarat"
                    />

                    <Box>
                      <InputLabel sx={inputLabel}>
                        Date Of Joining{" "}
                        <span style={{ color: "#f99494", fontSize: "1rem" }}>
                          *
                        </span>
                      </InputLabel>
                      <DatePicker
                        value={dayjs(formData.date_of_joining)}
                        onChange={(date) =>
                          handleInputChange(date, "date_of_joining")
                        }
                        name="date_of_joining"
                        disabled={!editable}
                        sx={{
                          width: {
                            xs: "100px", // Width for small screens
                            sm: "300px", // Width for medium screens
                            md: "400px", // Width for large screens
                            lg: "300px",
                            xl: "400px",
                          },
                          height: "45px",
                          "& .MuiInputBase-root": {
                            height: "100%",
                          },
                        }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              sx: {
                                borderRadius: "10px",
                              },
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                {errors && (
                  <Typography sx={{ mt: 3, color: "red" }}>
                    {errors.verification}
                  </Typography>
                )}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    gap: 2,
                    mt: {
                      lg: 17,
                      xl: 37,
                    },
                  }}
                >
                  <FilledButton
                    sx={{
                      border: `1px solid ${theme.palette.text.secondary}`,
                      width: "150px",
                      color: "black",

                      background: "white",
                      "&:hover": {
                        background: "white",
                        transform: "scale(1.05)",
                      },
                    }}
                    onClick={handleBack}
                  >
                    Back
                  </FilledButton>
                  <FilledButton
                    sx={{
                      width: "150px",
                      "&.Mui-disabled": {
                        color: "white",
                        bgcolor: "grey",
                      },
                    }}
                    onClick={handleSaveChanges}
                    disabled={!editable}
                  >
                    {editDriver ? "Save Changes" : "Create"}
                  </FilledButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <OtpVerificationModal
          open={otpModalInfo.open}
          handleClose={() => setOtpModal(false)}
          setOtpModal={setOtpModal}
          verifyField={otpModalInfo.verifyField}
        />
      </Drawer>
    </LocalizationProvider>
  );
};

export default AddDriverForm;
