import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const TripDetails = ({ open, handleClose, TripData }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "576px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1.5,
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            id="modal-title"
            sx={{ color:"#283246",fontSize:'1.5rem',fontWeight:500 }}
          >
            Trip Details
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ padding: 1, border: '1px solid #E0E0E0', borderRadius: 2 ,width:'90%',margin:'auto',mt:5,mb:5}}>
  <Grid container spacing={2} sx={{p:1}}>
    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary">Vehicle</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary">{TripData.vehicle}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>

    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary">Trip id</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary">{TripData.id}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    
    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary">Start Date</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary">{TripData.start_date}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    
    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary">End Date</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary">{TripData.end_date}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    
    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary" >Trip Duration</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary" >{TripData.duration}</Typography>
    </Grid>

    <Grid item xs={8}>
      <Typography variant="body2" color="textSecondary">Pause Duration</Typography>
    </Grid>
    <Grid item xs={4} sx={{ textAlign: 'right' }}>
      <Typography variant="body2" color="textSecondary">{TripData.pause_duration}</Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
  </Grid>
</Box>
    
      </Box>
    </Modal>
  );
};

export default TripDetails;
