import axios from "axios";
import { store } from "../store";

const apiInstance = axios.create({
  baseURL: "https://fleetmanagement-api.axonslab.io/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem("token");
    const state = store.getState();
    const token = state.auth.authToken;
    // const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiInstance;
