import React, { useState } from "react";
import { GoogleMap, LoadScript, DirectionsService, DirectionsRenderer } from "@react-google-maps/api";

const MapWithRoutes = () => {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [directions, setDirections] = useState(null);
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(0);
  
  const mapContainerStyle = {
    width: "60%",
    height: "600px",
  };

  const center = {
    lat: 20.5937,
    lng: 78.9629,
  };

  const handleFindRoute = () => {
    if (start && end) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: start,
          destination: end,
          travelMode: window.google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: true, // Get multiple routes
        },
        (result, status) => {
          if (status === "OK" && result) {
            setDirections(result);
          } else {
            console.error(`Error fetching directions ${result}`);
          }
        }
      );
    }
  };



  return (
    <div style={{ display: "flex", width: "100%", height: "600px" }}>
      {/* Left: Input Form */}
      <div style={{ width: "40%", padding: "20px" }}>
        <h3>Enter Start and End Points</h3>
        <form>
          <div>
            <label>Start Location: </label>
            <input
              type="text"
              value={start}
              onChange={(e) => setStart(e.target.value)}
              placeholder="Hyderabad"
            />
          </div>
          <div>
            <label>End Location: </label>
            <input
              type="text"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
              placeholder="Bangalore"
            />
          </div>
          <button type="button" onClick={handleFindRoute}>
            Find Route
          </button>
        </form>
      </div>

      {/* Right: Google Map */}
      <div style={{ width: "60%" }}>
        <LoadScript googleMapsApiKey="AIzaSyDiytvXP2dWy_3E-rJX2NWwnnFWZe4aSjU">
          <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={6}>
            {/* Render Directions on Map */}
            {directions && (
              <>
                {directions.routes.map((route, index) => (
                  <DirectionsRenderer
                    key={index}
                    directions={directions}
                    routeIndex={index} // Render all route options
                    options={{
                      polylineOptions: {
                        strokeColor: index === selectedRouteIndex ? "#1976D2" : "#A9A9A9",
                        strokeOpacity: 0.7,
                        strokeWeight: 4,
                      },
                    }}
                  />
                ))}
              </>
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default MapWithRoutes;
