import { useState } from "react";
import { Grid, InputLabel, Paper, Typography, MenuItem, Select } from "@mui/material";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton";

const selectStyle = {
  width: "300px", 
  borderRadius: "10px", 
  height: '40px'
};

export const AddReminders = () => {
  // State to manage the form fields
  const [remindersData, setRemindersData] = useState({
    driver_name: "",
    vehicle_name: "",
    message: "",
    created_date: "",
  });

  // Options for the select fields
  const driver_list = ["Cybertruck", "GenericCar"];
  const vehicle_list = ["Shyamal Cross Road Public Vehicle"];
  const fuelTypes = ["Diesel", "Petrol", "Electric"];
  const drivers = ["Driver 1", "Driver 2"];
  const iotTypes = ["Axons Micro Pro", "Axons Micro"];
  const bluetoothOptions = ["Yes", "No"];

  // Handle form field changes
  const handleChange = (field, value) => {
    setRemindersData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = () => {
    console.log(remindersData);
    // Add your logic to save or handle the vehicle data
  };

  return (
    <Paper
      sx={{
        borderRadius: "1.25rem",
        background: "#F8F8F8",
        padding: "1.563rem",
        boxShadow: "none",
        gap: 2,
        mt: 1,
        mb: 2,
      }}
    >
      <Typography sx={{ color: "#283246", fontSize: "1.5rem", fontWeight: 500 }}>
        Add Reminders
      </Typography>

      <Grid container spacing={2}>
        {/* Driver */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Driver <span style={{ color: "#F99494" }}>*</span>
          </InputLabel>
          <Select
            value={remindersData.driver_name}
            onChange={(e) => handleChange("driver_name", e.target.value)}
            sx={selectStyle}
            placeholder="Driver"
          >
            {driver_list.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Vehicle */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Vehicle <span style={{ color: "#F99494" }}>*</span>
          </InputLabel>
          <Select
            value={remindersData.vehicle_name}
            onChange={(e) => handleChange("vehicle_name", e.target.value)}
            sx={selectStyle}
          >
            {vehicle_list.map((vehicle_name) => (
              <MenuItem key={vehicle_name} value={vehicle_name}>
                {vehicle_name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/* Date / Time */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Date / Time <span style={{ color: "#F99494" }}>*</span>
          </InputLabel>
          <CustomTextField
            value={remindersData.message}
            onChange={(e) => handleChange("message", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
          />
        </Grid>

        {/* Message */}
        <Grid item xs={12} md={4}>
          <InputLabel sx={{ fontSize: "1rem", fontWeight: 500, mb: 1, color: "#283246" }}>
            Message<span style={{ color: "#F99494" }}>*</span>
          </InputLabel>
          <CustomTextField
            value={remindersData.created_date}
            onChange={(e) => handleChange("created_date", e.target.value)}
            sx={{ "& .MuiOutlinedInput-root": { width: "300px" } }}
          />
        </Grid>
      </Grid>

      {/* Save and Cancel buttons */}
      <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
        <Grid item>
          <FilledButton
            sx={{
              border: "1px solid #97A1B6",
              width: "150px",
              color: "black",
              background: "white",
              "&:hover": {
                background: "white",
              },
            }}
          >
            Cancel
          </FilledButton>
        </Grid>
        <Grid item>
          <FilledButton
            sx={{ width: "150px" }}
            onClick={handleSubmit}
          >
            Save
          </FilledButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddReminders;
