import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// External Library Imports
import { Avatar, Box, Card, Paper, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { CSVLink } from "react-csv";

// Internal Module Imports
import { activeTableCellItem, deactivateTableCellItem, deletedTableCellItem, userData } from "./utils";
import SearchTextField from "../../components/SearchTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import csv from "../../assets/Icons/csv.svg";
import UserTableComponent from "./UserTable";
import DeleteUserModal from "./DeleteUser";
import { deleteActiveUser, deleteDeactivateUser } from "../../slices/userManageSlice";
import DeleteDeactivatedUser from "./DeleteDeactivatedUser";

const tabStyles = {
  width: '191px',
  height: '40px',
  fontWeight: 500,
  fontSize: '1rem',
  textTransform: 'none',
  color: '#F99494',
  '&.Mui-selected': {
    background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
    color: '#FFFFFF',
    borderRadius: '12px',
  },
};

export const UserManagement = () => {
  const [value,setValue] = useState(0)
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [userDelete,setUserDelete] = useState(false)
  const [deactivatedUserDelete,setDeactivatedUserDelete] = useState(false)
  const [userToDelete,setUserToDelete] = useState('')
  const [deactivateUserToDelete,setDeactivateUserToDelete] = useState('')
  const { activeUser,deactivatedUser,deletedUser} = useSelector((state) => state.userManage);
  
  const theme = useTheme();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setSearchInput('')
    setValue(newValue);
  };

  const handleDeleteUser = (userId)=>{
    setUserDelete(true)
    setUserToDelete(userId)
  }

  const handleDeactivateUserDelete = (userId)=>{
    setDeactivatedUserDelete(true)
    setDeactivateUserToDelete(userId)
  }


  const handleDeleteUserClose = ()=>{
    setUserDelete(false)
    setDeactivatedUserDelete(false)
  }


  const handleDeleteUserEdit = () => {
    setUserDelete(false);
    dispatch(deleteActiveUser(userToDelete))
  };

  const handleDeactivatedUserDeleteEdit = () => {
    setDeactivatedUserDelete(false);
    dispatch(deleteDeactivateUser(deactivateUserToDelete))
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };


  const filteredActiveUsers = searchInput
  ? activeUser
      .filter((user) =>
        user?.user_name?.toLowerCase().includes(searchInput?.toLowerCase())
      )
      .sort((a, b) => a.user_name.localeCompare(b.user_name))
  : activeUser; // Return full list if search input is empty

  const filteredDeactiveUsers = searchInput
  ? deactivatedUser
      .filter((user) =>
        user?.user_name?.toLowerCase().includes(searchInput?.toLowerCase())
      )
      .sort((a, b) => a.user_name.localeCompare(b.user_name))
  : deactivatedUser; // Return full list if search input is empty


  const filteredDeletedUsers = searchInput
  ? deletedUser
      .filter((user) =>
        user?.user_name?.toLowerCase().includes(searchInput?.toLowerCase())
      )
      .sort((a, b) => a.user_name.localeCompare(b.user_name))
  : deletedUser; // Return full list if search input is empty

  const handleEditUser = (userId) => {
    navigate(`/dashboard/user-edit/${userId}`);
  };

  return (
    <>

      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          display: "flex",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        {userData.map((user) => (
          <Card
            sx={{
              width: "20%",
              borderRadius: "1rem",
              padding: "1rem 3.438rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center content horizontally
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                width: "52px",
                height: "52px",
                background: "#97A1B61A",
                display: "flex",
                alignItems: "center", 
                justifyContent: "center",
                mb: 1,
              }}
            >
              <Avatar src={user.icon} />
            </Box>

            <Typography
              sx={{
                fontSize:"0.75rem",
                color: "#283246",
                fontWeight: 500,
                marginBottom: "0.5rem"}}
            >
              {user.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.375rem",
                color: "#283246",
                fontWeight: 700,
              }}
            >
              {user.count}
            </Typography>
          </Card>
        ))}
      </Paper>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "start",mb:2 }}>
        <Tabs
      value={value}
      onChange={handleChange}
      aria-label="Active Users"
      sx={{
        bgcolor: '#F8F8F8',
        borderRadius: '12px',
        border: '1px solid #DDDEE1',
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      }}
    >
      {['Active Users', 'Deactivated Users', 'Deleted Users'].map((label, index) => (
        <Tab
          key={label}
          disableRipple
          label={label}
          sx={tabStyles}
        />
      ))}
    </Tabs>
          </Box>
          
        <Box>
          {value===0 && 
          <>
        
          <Box sx={{border:'1px solid #DDDEE1',height:'42px',p:1,background:'#DDDEE1',borderRadius:'6px',mb:5}}>
        <SearchTextField  value={searchInput}
                onChange={handleSearchChange} />
                <CSVLink   data={filteredActiveUsers}
        filename={"activeuser.csv"}>
                <FilledButton startIcon={<img src={csv}  style={{ width: "18px", height: "18px" }}/>} sx={{width:'102px',height:'35px',borderRadius:'6px',ml:3,mt:0.3}}>Get Csv</FilledButton>

                </CSVLink>
        </Box>
         
          <UserTableComponent
    data={filteredActiveUsers}
    tableHeaders={activeTableCellItem}
    showActions={true}
    handleEdit={handleEditUser}
    handleDelete={handleDeleteUser}
  />
   </>
  }

  {value===1 && 
  <>
   <Box sx={{border:'1px solid #DDDEE1',height:'42px',p:1,background:'#DDDEE1',borderRadius:'6px',mb:5}}>
        <SearchTextField  value={searchInput}
                onChange={handleSearchChange} />
                <CSVLink  data={filteredDeactiveUsers}
        filename={"deactivateduser.csv"}>
        <FilledButton startIcon={<img src={csv}  style={{ width: "18px", height: "18px" }}/>} sx={{width:'102px',height:'35px',borderRadius:'6px',ml:3,mt:0.3}}>Get Csv</FilledButton>
        </CSVLink>
        </Box>
  <UserTableComponent
    data={filteredDeactiveUsers}
    tableHeaders={deactivateTableCellItem}
    showActions={true}
    handleEdit={() => {}}
    handleDelete={handleDeactivateUserDelete}
  />
  </>
  }

{value===2 && 
<>
<Box sx={{border:'1px solid #DDDEE1',height:'42px',p:1,background:'#DDDEE1',borderRadius:'6px',mb:5}}>
        <SearchTextField  value={searchInput}
                onChange={handleSearchChange} />
                <CSVLink data={filteredDeletedUsers}
        filename={"deleteduser.csv"}>
        <FilledButton startIcon={<img src={csv}  style={{ width: "18px", height: "18px" }}/>} sx={{width:'102px',height:'35px',borderRadius:'6px',ml:3,mt:0.3}}>Get Csv</FilledButton>
        </CSVLink>
        </Box>
<UserTableComponent
    data={filteredDeletedUsers}
    tableHeaders={deletedTableCellItem}
    showActions={false}
    handleEdit={() => {}}
    handleDelete={() => {}}
  />
  </>
  }
</Box>

        <DeleteUserModal open={userDelete} handleClose={handleDeleteUserClose} handleDeleteUser={handleDeleteUserEdit}/>
       <DeleteDeactivatedUser   open={deactivatedUserDelete} handleClose={handleDeleteUserClose} handleDeleteUser={handleDeactivatedUserDeleteEdit}/>
      </Paper>
    </>
  );
};




