import styled from "styled-components";

export const ColorPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 13.625rem;
  background: #d3cfe3; 
  border-radius: 0.5rem;
  box-shadow: 0rem -0.1875rem 1.25rem rgba(0, 0, 0, 0.1);

  > span {
    padding: 0.625rem;
    background: #536570;
    color: white;
  }

  > div:nth-child(2) {
    width: auto !important;
    padding: 0.875rem 1.5rem;
  }

  > div:last-child {
    padding: 0.625rem;
  }

  .react-colorful {
    height: 7.5rem;
  }

  .react-colorful__saturation {
    border-radius: 0.25rem;
    border-bottom: 0;
  }

  .react-colorful__last-control {
    height: 0.5rem;
    border-radius: 0.25rem;
    margin-top: 0.5rem;
  }

  .react-colorful__pointer {
    width: 0.9375rem;
    height: 0.9375rem;
  }
`;
