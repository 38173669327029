// src/slices/tripsManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { completedTripsTableData, ongoingTripsTableData, scheduleTripsTableData } from "../features/Trips/utils";

const initialState = {
  scheduleTrips: scheduleTripsTableData,
  ongoingTrips: ongoingTripsTableData,
  completedTrips: completedTripsTableData,
  driverIdCounter: 0 // Initialize a counter for driver IDs
};

const tripsManageSlice = createSlice({
  name: "trips",
  initialState,
  reducers: {
    addScheduledTrip: (state, action) => {
        state.driverIdCounter += 1; // Increment the driver ID counter
        const driver_id = `D${state.driverIdCounter.toString().padStart(3, '0')}`; // Format it as D001, D002, etc.
        
        // Define the fleet array
        const fleets = ['King Street Transit', 'Evans City Shuttle', 'Jackson Line Metro', 'Martin Urban Transit'];
        
        // Select a random fleet
        const randomFleet = fleets[Math.floor(Math.random() * fleets.length)];
        
        // Add the new trip with the random fleet
        state.scheduleTrips.push({
            driver_id,
            fleet: randomFleet, // Assign the randomly selected fleet
            ...action.payload
        });
    }
}

});

export const { addScheduledTrip } = tripsManageSlice.actions;
export default tripsManageSlice.reducer;
