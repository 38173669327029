import { Box, Paper, Typography, useTheme } from "@mui/material";
import SearchTextField from "../../components/SearchTextField";
import plus from "../../assets/Icons/plus.svg";
import FilledButton from "../../components/MainButtons/FilledButton";
import UserTableComponent from "../UserManagement/UserTable";
import { reminderTableCellItem } from "./utils";
import { useNavigate } from "react-router-dom";
import reminderinfo from "../../assets/Icons/reminderinfo.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteUserModal from "../../components/DeleteModal";
import { deleteReminders } from "../../slices/remindersManageSlice";
import { useSnackbar } from "../../hooks/Snackbar";
import AddReminderForm from "./addReminderForm";

export const Reminder = () => {
  const [searchInput, setSearchInput] = useState("");
  const [createReminder, setCreateReminder] = useState(false);
  const [userDelete, setUserDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [reminderToDelete,setReminderToDelete] = useState("")
  const [editUser, setEditUser] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const { reminders } = useSelector((state) => state.remindersManage);

  const handleEditUser = (userId) => {
    setEditUser(userId);
    setCreateReminder(true);
  };

  const handleDeleteUser = (userId) => {
    const reminderToDelete = reminders.find((reminder)=>reminder.id===userId)
    setUserDelete(true);
    setUserToDelete(userId);
  
    setReminderToDelete(reminderToDelete.driver_name)
  };

  const handleConfirmDeleteDriver = () => {
    setUserDelete(false);
    dispatch(deleteReminders(userToDelete));
    setCreateReminder(false)
    showSnackbar("Reminder deleted successfully!", "success");
  };

  const handleDeleteDriverClose = () => {
    setUserDelete(false);
  };

  const filteredReminders = searchInput
    ? reminders
        .filter((user) => {
          const matchesName = user?.driver_name
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase() || "");
          return matchesName;
        })
        .sort((a, b) => a.driver_name.localeCompare(b.driver_name))
    : reminders; // Return full list if search input is empty

  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#FFFFFF",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
          minHeight: "90vh",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <img
              src={reminderinfo}
              alt="Reminder Info"
              style={{
                height: "22px",
                width: "22px",
                marginRight: "10px",
              }}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "1.2rem",
                color: theme.palette.text.primary,
              }}
            >
              Reminder
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            // border: "1px solid #DDDEE1",
            height: "42px",
            justifyContent: "space-between",
            padding: "8px",
            background:'#FFFFFF',
            display: "flex",
            borderRadius: "6px",
            marginBottom: "20px",
          }}
        >
          <SearchTextField
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <FilledButton
            startIcon={<img src={plus} alt="Add" />}
            sx={{ width: "9rem", height: "2.188rem", borderRadius: "6px" }}
            onClick={() => setCreateReminder(true)}
          >
            Add Reminder
          </FilledButton>
        </Box>

        <DeleteUserModal
          open={userDelete}
          handleClose={handleDeleteDriverClose}
          handleDeleteUser={handleConfirmDeleteDriver}
          header={"Confirm Reminder Deletion"}
          title={
            <span>Are you sure you want to permanently delete the reminder for <strong>{reminderToDelete}</strong>? This action cannot be undone.</span>}
          confirmText={"Yes, Delete"}
        />

        <Box>
          <AddReminderForm
            drawerOpen={createReminder}
            setDrawerOpen={setCreateReminder}
            editUser={editUser}
            setEditUser={setEditUser}
            handleDelete={handleDeleteUser}
          />
          <UserTableComponent
            data={filteredReminders}
            tableHeaders={reminderTableCellItem}
            showActions={true}
            handleEdit={handleEditUser}
            handleDelete={handleDeleteUser}
            handleRowClick={handleEditUser}
          />
        </Box>
      </Paper>
    </>
  );
};

export default Reminder;
