import { Box, InputLabel, MenuItem, Select, Typography } from "@mui/material";


const inputLabel = {
    fontSize: "0.8rem",
    fontWeight: 700,
    mb: 1,
    color: "#283246"
}


export const CustomSelect = ({ label, value, onChange, options, name, placeholder }) => {
    return (
        <Box>
            <InputLabel sx={inputLabel}>
                {label} <span style={{ color: '#f99494', fontSize: '1rem' }}>*</span>
            </InputLabel>
            <Select
                value={value}
                onChange={onChange}
                sx={{
                    borderRadius: "10px",
                    width: {
                      xs: "100px",   // Width for small screens
                      sm: "300px",   // Width for medium screens
                      md: "400px",   // Width for large screens
                      lg: "250px",   // Width for larger screens
                      xl: "250px"    // Width for extra-large screens
                    },
                    height: '40px',
                    fontSize:'0.9rem'
                }}
                MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Adjust this value to control the height of the visible area
                      },
                    },
                  }}
                name={name}
                displayEmpty
            >
                <MenuItem value="">
                    <Typography color="text.primary">{placeholder}</Typography>
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};