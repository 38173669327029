import totaltickets from "../../assets/Icons/totaltickets.svg";
import resolvedticket from "../../assets/Icons/resolveticket.svg";
import avgresolutiontime from "../../assets/Icons/resolutiontime.svg";

export const driverDataa = [
  { name: "Total Tickets", count: "80", icon: totaltickets },
  { name: "Resolved Tickets", count: "50", icon: resolvedticket },
  { name: "Avg Resolution Time", count: "6", icon: avgresolutiontime },
];

export const tabLabels = ["Tickets", "Reports","Issue Types"];

export const workOrderTableCellItem = [
  { label: "Name", key: "vehicle_name" },
  { label: "Type", key: "issue_type" },
  { label: "Created By", key: "created_by" },
  { label: "Created Date", key: "created_date" },
  { label: "Status", key: "status" },
  { label: "Assigned To", key: "assigned_to" },
];

export const issueTypeTableCellItem = [
  { label: "Category", key: "category" },
  { label: "Name", key: "name" },
  { label: "Status", key: "status" },
  { label: "Purpose", key: "purpose" },
];

export const workOrderTableData = [
    {
      id: "usr_Xyz1234abcDEF2",
      vehicle_name: "Alice Johnson",
      issue_type: "Battery",
      created_by: "Shubham Patel",
      created_date: "Sep 19, 2024 07:21:22",
      status: "Pending",
      assigned_to: "Tejal",
      issue_attachment:null,
    },
    {
      id: "usr_Xyz1234abcDEF3",
      vehicle_name: "John",
      issue_type: "Battery",
      created_by: "Shubham Patel",
      created_date: "Sep 19, 2024 07:21:22",
      status: "Pending",
      assigned_to: "N/A",
      issue_attachment:null,

    },
    {
      id: "usr_Xyz1234abcDEF4",
      vehicle_name: "Emily Smith",
      issue_type: "Tire",
      created_by: "Raj Singh",
      created_date: "Sep 20, 2024 08:45:00",
      status: "Open",
      assigned_to: "Michael",
      issue_attachment:null,

    },
    {
      id: "usr_Xyz1234abcDEF5",
      vehicle_name: "David Brown",
      issue_type: "Engine",
      created_by: "Lisa White",
      created_date: "Sep 21, 2024 09:15:30",
      status: "Open",
      assigned_to: "Sarah",
      issue_attachment:null,

    },
    {
      id: "usr_Xyz1234abcDEF6",
      vehicle_name: "Sophia Green",
      issue_type: "Transmission",
      created_by: "Carlos Gomez",
      created_date: "Sep 22, 2024 10:30:00",
      status: "Pending",
      assigned_to: "Tejal",
      issue_attachment:null,

    },
    {
      id: "usr_Xyz1234abcDEF7",
      vehicle_name: "James Wilson",
      issue_type: "Brake",
      created_by: "Shubham Patel",
      created_date: "Sep 23, 2024 11:00:00",
      status: "Pending",
      assigned_to: "N/A",
      issue_attachment:null,

    },
]

export const issueTypeTableData = [
  {
    id: "usr_Xyz1234abcDEF2",
    category: "Battery",
    name: "Change battery",
    status: "Active",
    purpose: "Without it, your car won't run",
  },
  {
    id: "usr_Xyz1234abcDEF3",
    category: "Tires",
    name: "Rotate tires",
    status: "Active",
    purpose: "Ensures even tire wear and extends tire life",
  },
  {
    id: "usr_Xyz1234abcDEF4",
    category: "Engine",
    name: "Oil change",
    status: "Active",
    purpose: "Maintains engine health and performance",
  },
  {
    id: "usr_Xyz1234abcDEF5",
    category: "Brakes",
    name: "Inspect brake pads",
    status: "Active",
    purpose: "Critical for safe stopping power",
  },
  
  {
    id: "usr_Xyz1234abcDEF7",
    category: "Battery",
    name: "Test battery health",
    status: "Active",
    purpose: "Ensures battery is functioning properly",
  },

];
