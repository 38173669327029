import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {CardContent, Typography, Avatar ,Box, Paper, useTheme} from '@mui/material';
import attachment from "../../assets/Icons/attachment.svg"

export default function UserTimeline({timelineData,heading,description}) {
  const theme = useTheme()

  return (
    <>
    {/* <Typography sx={{textAlign:'center',color:"#283246",fontSize:'1rem',fontWeight:500}}>{heading}</Typography> */}
    <Typography sx={{textAlign:'center',color:"#283246",fontSize:'0.75rem',fontWeight:400,width:'80%',margin:'auto'}}>{description}</Typography>
    <Timeline position="alternate">
      {timelineData?.map((event, index) => (
        <>       
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot   sx={{
      backgroundColor: '#F99494',
    }}/>
            {index !== timelineData.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Paper
              sx={{
                backgroundColor: "#ffffff",
                display: "flex",
                alignItems: "center",
                borderRadius:'1rem',
                padding: "5px",
                maxWidth: "600px",
                marginLeft: index % 2 === 0 ? "auto" : 0,
                marginRight: index % 2 !== 0 ? "auto" : 0,
                boxShadow:'none'
              }}
            >
              <Avatar
                sx={{ background: "#FEEEEE", marginRight: "10px" }}
                children={<img src={attachment} />}
              />
              <CardContent>
                <Typography sx={{color:"#283246",fontSize:'1rem',fontWeight:500}}>
                  {event.title}
                </Typography>
                <Typography sx={{color:theme.palette.text.secondary,fontSize:'0.95rem',fontWeight:400}}>
                  {event.description}
                </Typography>
              </CardContent>
              <Box sx={{ marginLeft: "auto", paddingLeft: "10px" }}>
                <Typography sx={{color:'#283246',fontSize:'rem',fontWeight:400}}>
                  {event.date}
                </Typography>
              </Box>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        </>
      ))}
    </Timeline>
    </>
  );
}
