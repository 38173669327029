// src/slices/driverManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { driverTableData } from "../features/Drivers/utils";


const initialState = {
  driver: driverTableData,
};

const generateRandomId = () => {
  return 'usr_' + Math.random().toString(36).substr(2, 9);
};

const driverManageSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    updatedDriver: (state, action) => {
      const { id, updatedFields } = action.payload;
      const driverIndex = state.driver.findIndex(driver => driver.id === id);     
      if (driverIndex !== -1) {
        // Only update the fields that are provided in updatedFields
        state.driver[driverIndex] = {
          ...state.driver[driverIndex], 
          ...updatedFields
        };
      }
    },
    addDriver: (state, action) => {
      const fleetIndex = state.driver.findIndex(d => d.id === action.payload.id);
      if (fleetIndex !== -1) {
        // Driver exists, so update it
        state.driver[fleetIndex] = {
            ...state.driver[fleetIndex],
            ...action.payload,
        };
    } else{
      const newDriver = {
        ...action.payload,
        id: generateRandomId(),
        fleet: "Norton Neighborhood Buses",          
        status: "Active",
        vehicle_assigned:"NA"
      };
      
      state.driver.push(newDriver);
    }

    },
    deleteDriver: (state, action) => {
      const userId = action.payload;
      // Find the user in the driver array
      const userToDelete = state.driver.find(user => user.id === userId);

      if (userToDelete) {
        // Remove the user from the driver array
        state.driver = state.driver.filter(user => user.id !== userId);
      }
    },
  },
});

export const { updatedDriver,deleteDriver,addDriver} = driverManageSlice.actions;
export default driverManageSlice.reducer;
